import React, {ReactElement} from "react";
import {ButtonWrapper} from "./styled/pagination.styled";

export interface PaginatorButtonInterface {
    icon: any,
    onClick: any,
    disabled: boolean
}

const PaginatorButton = ({ icon: Icon, onClick, disabled } : PaginatorButtonInterface): ReactElement => {
    return <ButtonWrapper onClick={onClick} disabled={disabled}>
        <Icon disabled={disabled} />
    </ButtonWrapper>;
};

export default PaginatorButton;