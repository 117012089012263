import React, {ReactElement} from "react";
import {
    LabeledFileField,
    LabeledFile,
    LabeledInputLabel,
    LabeledInputRow, LabeledInputTextArea,
    LabeledInputWrapper
} from "./styled/LabeledTextInput.styled"
import styled from "styled-components";
import InputValidationMessage from "./InputValidationMessage";
import {ReactComponent as IArrowIcon} from "../../../resources/icons/arrow-up-from-line.svg";
import {useState} from "react";
import IDelete from "../../../resources/icons/circle-xmark.svg";


export interface LabeledFileInputInterface {
    label: string;
    name: string;
    valid: boolean;
    errorMessage?: string;
    accept?: Array<string>;
    wide?: boolean;
    currentFile?: ReactElement;
    onDelete?: any;

    onChange?(e: File): void;
}

const LabeledFileInput = React.forwardRef(({
                                               label,
                                               name,
                                               valid,
                                               errorMessage,
                                               wide,
                                               accept,
                                               currentFile,
                                                onDelete,
                                               onChange
                                           }: LabeledFileInputInterface, ref: any): ReactElement => {
    const [file, setFile] = useState<string>("Žádný soubor vybrán.");
    const fileData = (data: any) => {
        if (data.target.files[0]) setFile(data.target.files[0].name);
        if (onChange) onChange(data.target.files[0]);
    };
    return <LabeledInputWrapper>
        <LabeledInputRow>
            <LabeledInputLabel valid={valid}>{label}</LabeledInputLabel>
        </LabeledInputRow>
        <LabeledInputRow>
            {currentFile && <div>
                {currentFile}
                <DeleteButton onClick={onDelete} src={IDelete}/>
            </div>}
        </LabeledInputRow>
        <LabeledInputRow>
            <LabeledFileField htmlFor={name} wide={wide}>
                <LabeledFile type="file" multiple={false} accept={accept && `${accept.join(", ")}`} name={name}
                             id={name} ref={ref} onChange={fileData} valid={valid}/>
                <div>{file}</div>
                <FileButton>
                    <IArrowIcon/> Vybrat soubor
                </FileButton>
            </LabeledFileField>
        </LabeledInputRow>
        {errorMessage &&
        <LabeledInputRow>
            <InputValidationMessage type="error" message={errorMessage}/>
        </LabeledInputRow>}
    </LabeledInputWrapper>;
});

export const FileLink = styled.a`
  color: #296EFA;
  font-weight: 700;
  text-decoration: none;
`;

const DeleteButton = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;
  top: 2px;
`;

const FileButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 16px;
  background-color: ${({theme}) => theme.colors.blue};
  border: 1px solid ${({theme}) => theme.colors.darkBlue};
  color: ${({theme}) => theme.colors.white};
  border-radius: 4px;

  svg {
    fill: ${({theme}) => theme.colors.white};
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export default LabeledFileInput;