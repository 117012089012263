import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as ISaveIcon} from "../../../../resources/icons/floppy-disk.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import UtilityButton from "../../../../components/shared/UtilityButton";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import {useForm, useWatch} from "react-hook-form";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import PersonSelectorInput from "../../../../components/shared/inputs/PersonSelectorInput";
import {GetProjectStateOptions, GetProjectTypes} from "../../../../enums/ProjectEnums";
import editProject from "../../api/project/editProject";
import toast from "react-hot-toast";
import {ReactComponent as ICaretIcon} from "../../../../resources/icons/caret-down.svg";
import {mapAgentsSelect, mapProjectsSelect} from "../../finance-detail/EditFinancePage";
import {fetchSelectProjects, fetchSelectProviders} from "../../api/fetchSelects";
import dayjs from "dayjs";
import {formatPrice} from "../../../../shared/utils/format";

const EditSection = ({data, onEdit}: any) => {
    const {slug}: {slug: string} = useParams();
    const {register, handleSubmit, errors, getValues, setValue, watch} = useForm();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const [agents, setAgents] = useState<any>([]);

    const fetchAgents = async () => {
        const response = await fetchSelectProviders();
        if(response.status == 200) {
            const fetchedData = await response.json();
            setAgents(fetchedData.result);
        }
    };
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        const initialInvestment = parseFloat(data.initialInvestment?.replaceAll('.', '').replace(/\s+/g, ''));
        const requiredInvestment = parseFloat(data.requiredInvestment?.replaceAll('.', '').replace(/\s+/g, ''));
        const response = await editProject({
            ...data,
            location: "",
            endDate: data.endDate == "" ? null : data.endDate,
            agentId: data.agentId == "0" ? null : data.agentId,
            requiredInvestment: data.requiredInvestment == "" ? null : (isNaN(requiredInvestment) ? 0 : requiredInvestment),
            initialInvestment: data.initialInvestment == "" ? null : (isNaN(initialInvestment) ? 0 : initialInvestment) //.replace(/\s+/g, '')
        });
        if(response.status == 200) {
            const fetchedData = await response.json();
            onEdit();
            toast.success("Projekt byl upraven.");
            history.push(Routes.PROJECT_DETAIL.replace(":slug", slug));
        } else {
            toast.error("Projekt se nepodařilo upravit.");
        };
    };
    useEffect(() => {
        fetchAgents();

        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<ISaveIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, [])
    return (<form><SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Základní údaje" icon={<IBuildingIcon/>}>
                    <LabeledSelectInput label="Stav projektu" name="state"
                                        placeholder="Vyberte..."
                                        valid={!errors.state}
                                        errorMessage={errors.state?.message}
                                        defaultValue={data.generalInformation?.state}
                                        options={GetProjectStateOptions()}
                                        ref={register({valueAsNumber:true, validate: (e) => e != "0" ? true : "Stav projektu musí být uveden!"})}/>
                    <LabeledSelectInput label="Typ projektu" name="type"
                    placeholder="Vyberte..."
                    valid={!errors.type}
                    errorMessage={errors.type?.message}
                    defaultValue={data.generalInformation?.type}
                    options={GetProjectTypes()}
                    ref={register({valueAsNumber:true, validate: (e) => e != "0" ? true : "Typ projektu musí být uveden!"})}/>
                    {/**<LabeledTextInput
                        type="text"
                        label="Lokalita"
                        name="location"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.location?.message}
                        valid={!errors.location}
                        defaultValue={data.generalInformation?.location.city || ""}
                        ref={register}
                    />**/}
                    <LabeledTextInput
                        type="number"
                        label="Požadované investiční období"
                        name="requiredInvestmentMonthsPeriod"
                        wide
                        placeholder={"Číslo (měsíce)..."}
                        errorMessage={errors.requiredInvestmentMonthsPeriod?.message}
                        valid={!errors.requiredInvestmentMonthsPeriod}
                        defaultValue={data.generalInformation?.requiredInvestmentMonthsPeriod || 0}
                        ref={register({valueAsNumber:true, required: {value: true, message: "Požadované investiční období musí být uvedeno."}})}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Požadovaná investice"
                        name="requiredInvestment"
                        wide
                        isPrice
                        placeholder={"Číslo (Kč)..."}
                        errorMessage={errors.requiredInvestment?.message}
                        valid={!errors.requiredInvestment}
                        defaultValue={data.generalInformation?.requiredInvestmentValue || 0}
                        ref={register({required: {value: true, message: "Požadovaná investice musí být uvedena."}})}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Vstupní investice"
                        name="initialInvestment"
                        wide
                        isPrice
                        placeholder={"Číslo (Kč)..."}
                        errorMessage={errors.initialInvestment?.message}
                        valid={!errors.initialInvestment}
                        defaultValue={data.generalInformation?.investmentValue || 0}
                        ref={register({required: {value: true, message: "Vstupní investice musí být uvedena."}})}
                    />
                    <LabeledTextInput
                        type="date"
                        label="Datum čerpání"
                        name="startDate"
                        wide
                        placeholder={"Datum..."}
                        errorMessage={errors.startDate?.message}
                        valid={!errors.startDate}
                        defaultValue={data.generalInformation?.startDate ? dayjs(data.generalInformation?.startDate).toDate().toISOString() : ""}
                        ref={register}
                        onDateChange={(e) => {
                            const date = new Date(e);
                            const otherDate = new Date(getValues('endDate'));
                            if(date > otherDate) {
                                setValue('startDate', otherDate);
                                return otherDate.toISOString();
                            }
                            return e;
                        }}
                    />
                    <LabeledTextInput
                        type="date"
                        label="Datum vrácení"
                        name="endDate"
                        wide
                        placeholder={"Datum..."}
                        errorMessage={errors.endDate?.message}
                        valid={!errors.endDate}
                        defaultValue={data.generalInformation?.endDate ? dayjs(data.generalInformation?.endDate).toDate().toISOString() : ""}
                        ref={register}
                        onDateChange={(e) => {
                            const date = new Date(e);
                            const otherDate = new Date(getValues('startDate'));
                            if(date < otherDate) {
                                setValue('endDate', otherDate);
                                return otherDate.toISOString();
                            }
                            return e;
                        }}
                    />
                    <LabeledSelectInput label="Zprostředkovatel"
                                        name="agentId"
                                        placeholder={agents.length > 0 ? "Vyberte..." : "Agenti nejsou dostupné."}
                                        disable={agents.length <= 0}
                                        defaultValue={data.generalInformation?.agentId}
                                        wide
                                        icon={<ICaretIcon/>}
                                        valid={!errors.agentId}
                                        errorMessage={errors.agentId?.message}
                                        options={mapAgentsSelect(agents) || []}
                                        ref={register}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput
                        type="textarea"
                        label="Poznámka"
                        name="note"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.note?.message}
                        valid={!errors.note}
                        defaultValue={data.note || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer></form>)
};

const Container = styled.div``;

export default EditSection;