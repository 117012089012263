import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {ModalContext, UtilityButtonsContext} from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import ProfileColumn from "../../../../components/table/components/ProfileColumn";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import IPhoto from "../../../../resources/images/TempProfilePhoto.jpg";
import {useHistory, useParams} from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import fetchProjectCollaterals from "../../api/project/fetchProjectCollaterals";
import toast from "react-hot-toast";
import fetchProjectTeam from "../../api/project/fetchProjectTeam";
import dayjs from "dayjs";
import AssignUserModal from "../../AssignUserModal";
import {GetUserProjectRoleLabel} from "../../../../enums/UserEnums";
import {getPhotoFromCdn} from "../../../../shared/api/photoApi";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IHatIcon} from "../../../../resources/icons/hat-cowboy.svg";
import {ReactComponent as ILightningIcon} from "../../../../resources/icons/bolt-lightning.svg";
import {ReactComponent as IPersonIcon} from "../../../../resources/icons/street-view.svg";
import {ReactComponent as ICalendarIcon} from "../../../../resources/icons/calendar-exclamation.svg";
import useFilters from "../../../../components/methods/useFilters";

const TeamSection = () => {
    const {slug}: { slug: string } = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        role: "", permissions: "", placement: "",
        assignedTermsFrom: "", assignedTermsTo: "",
    });

    const fetchData = async () => {
        const response = await fetchProjectTeam(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst složení týmu.")
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                color="gray"
                icon={<ICloudIcon/>}
                onClick={() => console.log("export zajištění")}/>
            <UtilityButton
                title="Přidat uživatele"
                color="blue"
                icon={<IPlusIcon/>}
                onClick={() => {
                    modal.openModal(<AssignUserModal onCreate={fetchData}/>)
                }}/>
        </>)
    }, []);

    return (<SectionContainer>
        <TableContainer>
            <FilterWrap maxWidth={"50%"}>
            <SearchBar name={"teamOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                    label={`Role`}
                    onChange={(filters: any) => changeFilter("role", filters)}
                    icon={<IHatIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterSelectInput
                    label={`Oprávnění`}
                    onChange={(filters: any) => changeFilter("permissions", filters)}
                    icon={<ILightningIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterSelectInput
                    label={`Umístění`}
                    onChange={(filters: any) => changeFilter("placement", filters)}
                    icon={<IPersonIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterRangeInput
                label={`Přiřazených termínů`}
                icon={<ICalendarIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["assignedTermsFrom", "assignedTermsTo"], [values.from, values.to])}
            />
            </FilterWrap>
            <Table headers={[
                {key: "name", value: "JMÉNO", sortable: true},
                {key: "role", value: "ROLE", sortable: true},
                {key: "permissions", value: "OPRÁVNĚNÍ", sortable: true},
                {key: "location", value: "UMÍSTĚNÍ", sortable: false},
                {key: "tasks", value: "ÚKOLY V PROJEKTU", sortable: false},
                {key: "lastUpdate", value: "POSLEDNÍ AKTUALIZACE", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.teamMembers ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.teamMembers?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const RowContainer = styled.div`
    display: flex;

    div:not(:first-of-type) {
        margin-left: 8px;
    }
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            name: {
                value: (<ProfileColumn icon={
                    getPhotoFromCdn(o.imageName)
                } title={
                    <b>{o.firstName} {o.lastName}</b>
                } subtitle={
                    <ColoredText text={o.email} color={"grayBlue"} semibold/>
                }/>)
            },
            role: {value: (<RegularBold>{GetUserProjectRoleLabel(o.role)}</RegularBold>)},
            permissions: {value: (<RegularBold>{o.permissionLevel}</RegularBold>)},
            location: {value: (<RegularBold>{o.companyPlacement}</RegularBold>)},
            tasks: {
                value: (<DoubleLineValue firLine={
                    `${o.taskCount} celkem`
                } secLine={
                    <RowContainer>
                        <ColoredText text={`${o.completedTasksCount} splněné`} color={"stripGreen"} semibold/>
                        <ColoredText text={`${o.lateTasksCount} zpožděné`} color={"red"} semibold/>
                    </RowContainer>
                }/>)
            },
            lastUpdate: {
                value: (<DoubleLineValue firLine={
                    o.lastUpdateOn ? dayjs(o.lastUpdateOn).format("DD.MM.YYYY") : "Bez aktualizace"
                } secLine={
                    <ColoredText text={o.lastUpdateOn ? <ColoredText text={dayjs(o.lastUpdateOn).format(`HH:mm`)} color={"grayBlue"} semibold/> : <div>-</div>} color={"grayBlue"} semibold/>
                }/>)
            },
            buttons: [
                { label: "Upravit", action: () => { console.log("upravit") } },
                { label: "Smazat", action: () => { console.log("smazat") } }
            ],
            link: `${Routes.USER_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default TeamSection;