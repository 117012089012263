export const formatPostalCode = (code?: string): string => {
  if(!code) return "";

  const clearedCode = code.replace(/\s+/g, '');
  if(clearedCode.length > 3) {
    return clearedCode.substring(0, 3) + " " + clearedCode.substring(3, clearedCode.length);
  }
  return code;
};

export const formatPrice = (price?: string | number): string => {
  let amount = parseFloat(price?.toString()?.replaceAll('.', '').replace(/\s+/g, '') ?? "");
  if(isNaN(amount)) return "";
  amount = Number(amount);
  return isNaN(amount) ? "" : amount.toLocaleString('en').replaceAll(",", ".")
};