import React, {ReactElement} from "react";
import {InfoBoxRowItem, InfoBoxRowItemLeft, InfoBoxRowItemRight} from "./styled/InfoBoxItem.styled";

export interface InfoBoxItemInterface {
    title: string | ReactElement;
    text: string | ReactElement;
    nextLine?: boolean;
}

const InfoBoxItem = ({title, text, nextLine}: InfoBoxItemInterface): ReactElement => {

    const getTextPlaceholder = (): string | ReactElement => {
        if(text === "" || text === undefined)
            return "-";

        return text;
    }

    return <InfoBoxRowItem nextLine={nextLine}>
        <InfoBoxRowItemLeft>{title}</InfoBoxRowItemLeft>
        <InfoBoxRowItemRight nextLine={nextLine}>{getTextPlaceholder()}</InfoBoxRowItemRight>
    </InfoBoxRowItem>;
};

export default InfoBoxItem;