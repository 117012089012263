import styled from "styled-components";

export const LabeledInputWrapper = styled.div<{ notLast?: boolean; }>`
  width: 100%;
  
  &:not(:last-child) {
    margin-bottom: ${props => props.notLast ? '0' : '24px'};
  }
  position: relative;
`;

export const LabeledInputRow = styled.div<{ isDate?: boolean; }>`
  position: relative;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  
  & > div {
    width: 100%;
    ${({isDate}) => isDate ? `
      min-width: 100%;
    ` : ''}
  }
`;

export const LabeledInputLabel = styled.div<{ valid?: boolean; }>`
  color: ${props => props.valid ? props.theme.colors.black : props.theme.colors.red};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
`;

export const LabeledSelectField = styled.div<{ wide?: boolean; valid?: boolean; enabled?: boolean}>`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  ${({enabled, theme}) => enabled && `background-color: ${theme.colors.lightGray}`};
  color: ${({theme}) => theme.colors.grayBlue};
  font-size: 16px;
  font-weight: 600;
  ${({enabled}) => !enabled && "cursor: pointer"};
  line-height: 20px;
  border: 0.25px solid ${props => props.valid ? props.theme.colors.lighterGray : props.theme.colors.red};
  padding: 8px 12px;
  max-height: 24px;
  border-radius: 4px;
  width: ${props => props.wide ? '100%' : "230px" };

  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;

  &[type=number] {
    -moz-appearance: textfield;
  }

  transition: .25s border;

  &:focus {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabeledFileField = styled.label<{ wide?: boolean}>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  color: ${({theme}) => theme.colors.blueGray};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 0.25px solid ${({theme}) => theme.colors.lighterGray};
  padding: 8px 12px;
  border-radius: 4px;
  width: ${({wide}) => wide ? "100%" : "34.5rem"};

  &[type=number] {
    -moz-appearance: textfield;
  }

  transition: .25s border;

  &:focus {
    border: 0.25px solid ${props => props.theme.colors.black};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabeledFile = styled.input<{valid?: boolean}>`
  display: none;
`;

export const LabeledInputField = styled.input<{ wide?: boolean; valid?: boolean; enabled?: boolean; hasOverlay?: boolean; }>`
  background-color: ${props => props.theme.colors.white};
  ${({enabled, theme}) => enabled && `background-color: ${theme.colors.lightGray}`};
  color: ${({theme, hasOverlay}) => hasOverlay ? 'white' : theme.colors.blueGray};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 0.25px solid ${props => props.valid ? props.theme.colors.lighterGray : props.theme.colors.red};
  padding: 8px 12px;
  border-radius: 4px;
  width: ${props => props.wide ? '100%' : "230px" };

  &[type=number] {
    -moz-appearance: textfield;
  }

  transition: .25s border;

  &:focus {
    border: 0.25px solid ${props => props.theme.colors.black};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabeledDateField = styled.div<{ filter?: boolean; wide?: boolean; valid?: boolean; enabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
  ${({enabled, theme}) => enabled && `background-color: ${theme.colors.lightGray}`};
  ${({enabled, theme}) => !enabled && `cursor: pointer`};
  color: ${({theme}) => theme.colors.blueGray};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 0.25px solid ${props => props.valid ? props.theme.colors.lighterGray : props.theme.colors.red};
  padding: 8px 12px;
  border-radius: 4px;
  ${({filter, wide}) => filter ? `
    max-width: 218px;
    min-width: 190px;
  ` : `
    width: ${wide ? 'calc(100% - 24px)' : "230px" };
  `}

  &[type=number] {
    -moz-appearance: textfield;
  }

  transition: .25s border;

  &:focus {
    border: 0.25px solid ${props => props.theme.colors.black};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabeledInputTextArea = styled.textarea<{ valid?: boolean; enabled?: boolean  }>`
  background-color: ${props => props.theme.colors.white};
  ${({enabled, theme}) => enabled && `background-color: ${theme.colors.lightGray}`};
  color: ${props => props.theme.colors.black};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  border: 1px solid ${props => props.valid ? props.theme.colors.lighterGray : props.theme.colors.red};
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  resize: none;
  height: 240px;

  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.grayBlue};
  }

  :-ms-input-placeholder {
    color: ${props => props.theme.colors.grayBlue};
  }

  ::placeholder {
    color: ${props => props.theme.colors.grayBlue};
  }
`;