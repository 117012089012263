import React, {ReactElement, useEffect, useState} from "react";
import {
    LabeledInputLabel,
    LabeledInputRow,
    LabeledInputWrapper, LabeledSelectField
} from "./styled/LabeledTextInput.styled"
import InputValidationMessage from "./InputValidationMessage";
import styled, {css} from "styled-components";
import {ReactComponent as ICaretIcon} from "../../../resources/icons/caret-down.svg";
import {ReactComponent as IGlassIcon} from "../../../resources/icons/magnifying-glass.svg";

interface OptionInterface {
    id: number;
    imgUrl?: string;
    label: string;
}

interface PersonSelectorInputInterface {
    label: string;
    name: string;
    multiple?: boolean;
    placeholder: string;
    defaultValue?: Array<number> | number;
    icon?: React.ReactNode;
    disable?: boolean;
    valid: boolean;
    errorMessage?: string;
    options: Array<OptionInterface>;
}

const PersonSelectorInput = React.forwardRef(({
                                               label,
                                               placeholder,
                                               name,
                                               valid,
                                               errorMessage,
                                               icon,
                                               defaultValue,
                                               disable,
                                               multiple,
                                               options,
                                           }: PersonSelectorInputInterface, ref: any): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<number>(options.findIndex(o => o.id===defaultValue));
    const [selectedOptions, setSelectedOptions] = useState<Array<number>>(defaultValue as Array<number> ?? []);
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        setSelectedOption(-1);
    }, [options]);

    const setOption = (id: number) => {
        if(multiple !== undefined) {
            setSelectedOptions(prevArr => {
                let index = prevArr.indexOf(id)
                if(index > -1) {
                    const newArray = [...prevArr]
                    newArray.splice(index, 1)
                    return newArray
                } else {
                    return [...prevArr, id]
                }
            })
        } else {
            setSelectedOption(id)
        }
    };
    const handleSearch = ({target}: {target: any}) => setSearch(target.value.toLowerCase());
    const renderSelectedOptions = () => {
        const filteredOptions = [...options].filter((v) => selectedOptions.indexOf(v.id) > -1);
        return (filteredOptions.map((v, i) => {
            return (v.imgUrl !== undefined && <React.Fragment key={v.id}><SelectedImage src={v.imgUrl} alt="Fotka"/></React.Fragment>)
        }))
    };
    return <LabeledInputWrapper>
        {multiple !== undefined && <input type="hidden" value={`[${selectedOptions.join(",")}]`} name={name} ref={ref}/>}
        {multiple===undefined && <input type="hidden" value={selectedOption !== -1 ? selectedOption : ""} name={name} ref={ref}/>}
        <LabeledInputRow>
            <LabeledInputLabel valid={valid}>{label}</LabeledInputLabel>
        </LabeledInputRow>
        <LabeledInputRow>
            <LabeledSelectField onClick={() => {
                if(!disable) {
                    setIsOpen(!isOpen)
                }}} valid={valid} enabled={disable}>
                {multiple !== undefined && <SpaceWrap>
                    <div>
                        {selectedOptions.length===0 && <>{placeholder}</>}
                        {selectedOptions.length > 0 && renderSelectedOptions()}
                    </div>
                    <IconWrap>{icon || <ICaretIcon/>}</IconWrap>
                </SpaceWrap>}
                {multiple===undefined && <SpaceWrap>
                    {selectedOption===-1 && <>{placeholder}</>}
                    {selectedOption !== -1 && <SingleSelect>
                        <SelectedImage src={options[options.findIndex(o => o.id===selectedOption)].imgUrl} alt="Fotka"/>{options[options.findIndex(o => o.id===selectedOption)].label}
                    </SingleSelect>}
                    <IconWrap>{icon || <ICaretIcon/>}</IconWrap>
                </SpaceWrap>}
            </LabeledSelectField>
            <OptionList toggle={isOpen} $overflow={options.length <= 3}>
                {options.length > 3 && <>
                    <SearchBarContainer htmlFor={`${name}Search`}>
                        <SearchBar id={`${name}Search`} onChange={handleSearch}/>
                        <SearchIcon/>
                    </SearchBarContainer>
                    <OverflowDiv>
                        {multiple != undefined && options.map((v,i) => {
                            if(v.label.toLowerCase().includes(search)) return <Option selected={selectedOptions.indexOf(v.id) > -1} key={i} onClick={() => {
                                setOption(v.id)
                                setIsOpen(false)
                            }}>
                            {v.imgUrl != undefined && <Image src={v.imgUrl} alt="Profilová fotka"/>} {v.label}
                        </Option>})}
                        {multiple == undefined && options.map((v,i) => {
                            if(v.label.toLowerCase().includes(search)) return <Option selected={selectedOption == v.id} key={i} onClick={() => {
                                setOption(v.id)
                                setIsOpen(false)
                            }}>
                            {v.imgUrl != undefined && <Image src={v.imgUrl} alt="Profilová fotka"/>} {v.label}
                        </Option>})}
                    </OverflowDiv>
                </>}
                {options.length <= 3 && <>
                    {multiple != undefined && options.map((v,i) => <Option selected={selectedOptions.indexOf(v.id) > -1} key={i} onClick={() => {
                        setOption(v.id)
                        setIsOpen(false)
                    }}>
                        {v.imgUrl != undefined && <Image src={v.imgUrl} alt="Profilová fotka"/>} {v.label}
                    </Option>)}
                    {multiple == undefined && options.map((v,i) => <Option selected={selectedOption == v.id} key={i} onClick={() => {
                        setOption(v.id)
                        setIsOpen(false)
                    }}>
                        {v.imgUrl != undefined && <Image src={v.imgUrl} alt="Profilová fotka"/>} {v.label}
                    </Option>)}
                </>}
            </OptionList>
        </LabeledInputRow>
        {errorMessage &&
        <LabeledInputRow>
            <InputValidationMessage type="error" message={errorMessage}/>
        </LabeledInputRow>}
    </LabeledInputWrapper>;
});

const OptionList = styled.div<{toggle: boolean; $overflow: boolean}>`
    display: ${({toggle}) => toggle ? "block" : "none"};
    position: absolute;
    top: 2.4rem;
    left: 0;
    width: 90%;
    color: ${({theme}) => theme.colors.black};
    background-color: ${({theme}) => theme.colors.lightGray};
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    border-radius: 4px;
    ${({$overflow}) => $overflow ? css`
        max-height: 190px;
        overflow-y: scroll;
    ` : css`
        max-height: 242px;
    `}
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all 0.1s;
    z-index: 99;
`;

const OverflowDiv = styled.div`
    position: relative;
    max-height: 194px;
    overflow-y: scroll;
`;

const SearchBar = styled.input`
    border: none;
    font-size: 18px;
    margin-right: 8px;
    background-color: transparent;
    color: ${({theme}) => theme.colors.black};
    width: 100%;
    outline: none;
`;

const SearchBarContainer = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 8px;
    border-bottom: 2px solid ${({theme}) => theme.colors.lighterGray};
`;

const SearchIcon = styled(IGlassIcon)`
    width: 16px;
    height: 16px;
    fill: ${({theme}) => theme.colors.stripGray};
`;

const Image = styled.img`
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
    width: 23px;
    max-height: 23px;
`;

const SelectedImage = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 23px;
    max-height: 23px;
    border: 2px solid ${({theme}) => theme.colors.white};
`;

const Option = styled.div<{selected: boolean}>`
    padding: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({selected, theme}) => selected ? theme.colors.stripGray : "blueGray"};
    transition: 0.2s all;
    &:hover {
        background-color: ${({theme}) => theme.colors.lighterGray};
    };
    &:not(:last-of-type) {
        border-bottom: 1px solid ${({theme}) => theme.colors.lighterGray};
    };
`;

const SingleSelect = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.black};
    img {
        margin-right: 8px;
    }
`;

const SpaceWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const IconWrap = styled.div`
    width: 9px;
    height: 16px;
    fill: ${({theme}) => theme.colors.stripGray};
`;

export default PersonSelectorInput;