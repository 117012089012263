import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import deleteAppraiser from "../../api/deleteAppraiser";
import { Routes } from "../../../../router/Routes";
import toast from "react-hot-toast";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-dollar-pen.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import dayjs from "dayjs";
import {awardThings, countryRegions, ranges} from "./EditSection";
import {banks} from "../../../../components/shared/BankMapper";

interface ProfileSectionProps {
    data: any,
}

const ProfileSection: React.FC<ProfileSectionProps> = (props) => {
    const {slug}: any = useParams();
    const {data} = props;
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                const response = await deleteAppraiser(slug);
                if(response.status == 200) {
                    history.push(Routes.APPRAISERS);
                    toast.success("Odhadce smazán.");
                } else {
                    toast.error("Při mazání nastala chyba!");
                }
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export odhadce")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.APPRAISER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<InfoBoxFullContainer>
            <InfoBoxColumn>
                {data?.isCompany ? <>
                    <InfoBox title="Kontaktní osoba" icon={<IUserIcon/>}>
                        <InfoBoxItem title="Jméno a příjmení" text={data.contact?.fullName}/>
                        <InfoBoxItem title="Email" text={<a href={`mailto:${data.contact?.email}`}>{data.contact?.email}</a>}/>
                        <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contact?.phoneNumber}`}>{data.contact?.phoneNumber}</a>}/>
                    </InfoBox>
                    <InfoBox title="Firemní údaje" icon={<IBuildingIcon/>}>
                        <InfoBoxItem title="Název" text={data.company?.companyName}/>
                        <InfoBoxItem title="IČO" text={data.company?.identificationNumber}/>
                        <InfoBoxItem title="Ulice" text={`${data.company?.location.streetAddressFirstLine} ${data.company?.location.streetAddressSecondLine}`}/>
                        <InfoBoxItem title="Obec/Město" text={data.company?.location.city}/>
                        <InfoBoxItem title="PSČ" text={data.company?.location.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3')}/>
                        <InfoBoxItem title="Země" text={data.company?.location.country}/>
                    </InfoBox>
                </> : <>
                    <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                        <InfoBoxItem title="Jméno a příjmení" text={data.contact?.fullName}/>
                        <InfoBoxItem title="Email" text={<a href={`mailto:${data.contact?.email}`}>{data.contact?.email}</a>}/>
                        <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contact?.phoneNumber}`}>{data.contact?.phoneNumber}</a>}/>
                        <InfoBoxItem title="Ulice" text={`${data.contact?.location.streetAddressFirstLine} ${data.contact?.location.streetAddressSecondLine}`}/>
                        <InfoBoxItem title="Obec/Město" text={data.contact?.location.city}/>
                        <InfoBoxItem title="PSČ" text={data.contact?.location.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3')}/>
                        <InfoBoxItem title="Země" text={data.contact?.location.country}/>
                    </InfoBox>
                </>}
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Působení" icon={<IMoneyCheckIcon/>}>
                    <InfoBoxItem title="Oceňované věci" text={data.effect?.awardThings.map((o: any) => {
                        const awardThing = awardThings.filter(a => a.id == o)[0];
                        if(awardThing) return <div style={{textAlign: 'right'}}>{awardThing.label}</div>;
                    })}/>
                    <InfoBoxItem title="Rozsah" text={ranges.filter(o => o.id == data.effect?.range)[0]?.label ?? ""}/>
                    <InfoBoxItem title="Preferovaná lokalita" text={countryRegions.filter(o => o.id == data.effect?.preferredLocation)[0]?.label ?? ""}/>
                    <InfoBoxItem title="Scoring" text={data.effect?.scoring}/>
                    <InfoBoxItem title="Platné banky" text={data.effect?.validBanks.map((o: any) => {
                        const bank = banks.filter(a => a.id == o)[0];
                        if(bank) return <div style={{textAlign: 'right'}}>{bank.label}</div>;
                    })}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note}
                </InfoBox>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
            </InfoBoxColumn>
    </InfoBoxFullContainer>)
};

export default ProfileSection;