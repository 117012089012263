import React, { useContext, useEffect, useState } from "react";
import SectionContainer from "../../../projects/project-detail/components/SectionContainer";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as ISaveIcon} from "../../../../resources/icons/floppy-disk.svg";
import { Routes } from "../../../../router/Routes";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import updateAppraiser from "../../api/updateAppraiser";
import toast from "react-hot-toast";
import StyledCheckboxInput from "../../../../components/shared/inputs/StyledCheckboxInput";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-dollar-pen.svg";
import {BankMapper} from "../../../../components/shared/BankMapper";
import LabeledMultiSelectInput from "../../../../components/shared/inputs/LabeledMultiSelectInput";
import {formatPostalCode} from "../../../../shared/utils/format";

export const countryRegions = [
    {
        id: 1,
        label: "Celá ČR"
    },
    {
        id: 2,
        label: "Hlavní město Praha"
    },
    {
        id: 3,
        label: "Středočeský kraj"
    },
    {
        id: 4,
        label: "Karlovarský kraj"
    },
    {
        id: 5,
        label: "Ústecký kraj"
    },
    {
        id: 6,
        label: "Jihočeský kraj"
    },
    {
        id: 7,
        label: "Královehradecký kraj"
    },
    {
        id: 8,
        label: "Liberecký kraj"
    },
    {
        id: 9,
        label: "Pardubický kraj"
    },
    {
        id: 10,
        label: "Jihomoravský kraj"
    },
    {
        id: 11,
        label: "Zlínský kraj"
    },
    {
        id: 12,
        label: "Moravskoslezský kraj"
    },
    {
        id: 13,
        label: "Olomoucký kraj"
    },
    {
        id: 14,
        label: "Plzeňský kraj"
    }
];

export const ranges = [
    {
        id: 1,
        label: "Malý"
    },
    {
        id: 2,
        label: "Střední"
    },
    {
        id: 3,
        label: "Velký"
    },
];

export const awardThings = [
    {
        id: "1",
        label: "Nemovité"
    },
    {
        id: "2",
        label: "Movité"
    }
];

interface EditSectionProps {
    data: any,
    onEdit(): void,
}

const EditSection: React.FC<EditSectionProps> = ({data, onEdit}) => {
    const {register, errors, handleSubmit, watch} = useForm();
    const {slug}: {slug: string} = useParams();
    const [checked, setChecked] = useState<boolean>(data?.isCompany ?? false);
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const postalCode = watch('postalCode');

    const onSubmit = async (editedData: any) => {
        editedData.country = GetCountryByValue(editedData.country)?.id ?? "";
        editedData.range = parseInt(editedData.range);
        const response = await updateAppraiser(slug, editedData);
        if(response.status == 200) {
            let fetchedData = await response.json();
            onEdit();
            toast.success("Odhadce upraven.");
            history.push(Routes.APPRAISER_DETAIL.replace(":slug", slug));
        } else {
            toast.error("Při uprávě nastala chyba!");
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.APPRAISER_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<ISaveIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);
    return (<form>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <StyledCheckboxInput
                    label={"Odhadce je právnická osoba"}
                    name={"isCompany"}
                    isSolo
                    defaultValue={data.isCompany ?? false}
                    onChange={e => setChecked(!e)}
                    ref={register}
                />
                {!checked ? <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                    <LabeledTextInput label="Jméno" type="text" name="firstName"
                    defaultValue={data.contact?.firstName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.firstName}
                    ref={register({required: {value: true, message: "Jméno je povinné."}})}
                    />
                    <LabeledTextInput label="Příjmení" type="text" name="lastName"
                    defaultValue={data.contact?.lastName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.lastName}
                    ref={register({required: {value: true, message: "Příjmení je povinné."}})}
                    />
                    <LabeledTextInput label="Email" type="text" name="email"
                    defaultValue={data.contact?.email || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.email}
                    ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="text" name="phoneNumber"
                    defaultValue={data.contact?.phoneNumber || ""}
                    placeholder="Číslo.."
                    wide
                    valid={!errors.phoneNumber}
                    ref={register}
                    />
                    <LabeledTextInput label="Ulice" type="text" name="streetAddressFirstLine"
                    defaultValue={data.contact?.location.streetAddressFirstLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.streetAddressFirstLine}
                    ref={register}
                    />
                        {/**<LabeledTextInput label="Ulice 2. řádek" type="text" name="streetAddressSecondLine"
                    defaultValue={data.contact?.location.streetAddressSecondLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.streetAddressSecondLine}
                    ref={register}
                    />**/}
                    <LabeledTextInput label="PSČ" type="text" name="postalCode"
                    defaultValue={data.contact?.location.postalCode || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.postalCode}
                                      isCode
                    ref={register({maxLength: 6, minLength: 6})}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="city"
                    defaultValue={data.contact?.location.city || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.city}
                    ref={register}
                    />
                    <LabeledSelectInput label="Země" name="country"
                    placeholder="Vyberte..."
                    defaultValue={"CZ"}
                    valid={!errors.message}
                    disable={true}
                    options={CountryMapper()}
                    ref={register}
                    />
                </InfoBox> : <>
                    <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                        <LabeledTextInput label="Jméno" type="text" name="firstName"
                            defaultValue={data.contact?.firstName || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.firstName}
                            ref={register({required: {value: true, message: "Jméno je povinné."}})}
                        />
                        <LabeledTextInput label="Příjmení" type="text" name="lastName"
                            defaultValue={data.contact?.lastName || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.lastName}
                            ref={register({required: {value: true, message: "Příjmení je povinné."}})}
                        />
                        <LabeledTextInput label="Email" type="text" name="email"
                            defaultValue={data.contact?.email || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.email}
                            ref={register}
                        />
                        <LabeledTextInput label="Telefon" type="text" name="phoneNumber"
                            defaultValue={data.contact?.phoneNumber || ""}
                            placeholder="Číslo.."
                            wide
                            valid={!errors.phoneNumber}
                            ref={register}
                        />
                    </InfoBox>
                    <InfoBox title="Firemní údaje" icon={<IBuildingIcon/>}>
                        <LabeledTextInput label="Název" type="text" name="companyName"
                            defaultValue={data.company?.companyName || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.companyName}
                            ref={register}
                        />
                        <LabeledTextInput label="IČO" type="text" name="identificationNumber"
                            defaultValue={data.company?.identificationNumber || ""}
                            placeholder="Číslo..."
                            wide
                            valid={!errors.identificationNumber}
                            ref={register}
                        />
                        <LabeledTextInput label="Ulice" type="text" name="streetAddressFirstLine"
                            defaultValue={data.company?.location.streetAddressFirstLine || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.streetAddressFirstLine}
                            ref={register}
                        />
                        {/**<LabeledTextInput label="Ulice 2. řádek" type="text" name="streetAddressSecondLine"
                            defaultValue={data.company?.location.streetAddressSecondLine || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.streetAddressSecondLine}
                            ref={register}
                        />**/}
                        <LabeledTextInput label="PSČ" type="text" name="postalCode"
                            defaultValue={data.company?.location.postalCode || ""}
                            placeholder="Číslo..."
                            wide
                            valid={!errors.postalCode}
                                          isCode
                            ref={register({maxLength: 6, minLength: 6})}
                        />
                        <LabeledTextInput label="Obec/Město" type="text" name="city"
                            defaultValue={data.company?.location.city || ""}
                            placeholder="Text..."
                            wide
                            valid={!errors.city}
                            ref={register}
                        />
                        <LabeledSelectInput label="Země" name="country"
                            placeholder="Vyberte..."
                            defaultValue={"CZ"}
                            valid={!errors.message}
                            options={CountryMapper()}
                            disable={true}
                            ref={register}
                        />
                    </InfoBox>
                </>}
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Působení" icon={<IMoneyCheckIcon/>}>
                    <LabeledMultiSelectInput label="Oceňované věci" name="awardThings"
                                             placeholder="Vyberte..."
                                             valid={!errors.awardThings}
                                             errorMessage={errors.awardThings?.message}
                                             defaultValues={data.effect?.awardThings ?? []}
                                             wide
                                             options={awardThings}
                                             ref={register}
                    />
                    <LabeledSelectInput label="Rozsah" name="range"
                        placeholder="Vyberte..."
                        defaultValue={data.effect?.range ?? 3}
                        valid={!errors.range}
                        errorMessage={errors.range?.message}
                        wide
                        options={ranges}
                        ref={register}
                    />
                    <LabeledSelectInput label="Preferovaná lokalita" name="preferredLocation"
                        placeholder="Vyberte..."
                        defaultValue={parseInt(data.effect.preferredLocation)}
                        valid={!errors.preferredLocation}
                        errorMessage={errors.preferredLocation?.message}
                        wide
                        options={countryRegions}
                        ref={register}
                    />
                    <LabeledMultiSelectInput label="Platné banky" name="validBanks"
                        placeholder="Vyberte..."
                        valid={!errors.validBanks}
                        errorMessage={errors.validBanks?.message}
                        defaultValues={data.effect?.validBanks ?? []}
                        wide
                        options={BankMapper()}
                        ref={register}
                    />
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput
                        type="text"
                        label="Poznámka"
                        name="note"
                        wide
                        placeholder={"Text..."}
                        valid={!errors.note}
                        defaultValue={data.note || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </form>)
};

export default EditSection;