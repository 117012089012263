import { getAccessToken } from '../../../contexts/UserIdentityContext';
const fetchRealEstateEstimation = async (realEstateid: string | number) => {
    const response = await fetch(`/api/v1/realestates/${realEstateid}/estimation`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    })
    return response;
};

export default fetchRealEstateEstimation;