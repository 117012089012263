import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import fetchAppraiserEstimates from "../../api/fetchAppraiserEstimates";
import toast from "react-hot-toast";
import { useParams } from "react-router";
import {formatPrice} from "../../../../shared/utils/format";

interface EstimatesSectionProps {
    data: any,
}

const EstimatesSection: React.FC<EstimatesSectionProps> = (props) => {
    const buttons = useContext(UtilityButtonsContext);
    const {slug}: any = useParams();
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await fetchAppraiserEstimates(slug, pageNum, pageSize, search, sortId, sortOrder);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst odhady.")
        }
    }, [pageNum, pageSize, search, sortId, sortOrder]);
    
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
        </>);
        fetchData();
    }, [])
    
    return (<TableContainer>
            <FilterWrap>
                <SearchBar name={"appraiserEstimatesOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            </FilterWrap>
            <Table headers={[
                    {key: "realEstate", value: "NEMOVITOST", sortable: true},
                    {key: "estimate", value: "ODHAD", sortable: true},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(loading ? [] : (data.estimates ?? []))}/>
                <Pagination
                    total={loading ? 0 : data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.estimates?.length ?? 0}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
    </TableContainer>)
};


const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            realEstate: {value: (<RegularBold>{o.realEstate}</RegularBold>)},
            estimate: {value: (<RegularBold>{formatPrice(o.estimate)}</RegularBold>)},
            link: `${Routes.REAL_ESTATE_ESTIMATION_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default EstimatesSection;