import {getAccessToken} from "../../../contexts/UserIdentityContext";
const deleteDocument = async (id: number | string) => {
    const response = await fetch(`/api/v1/documents/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default deleteDocument;