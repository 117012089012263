import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IBorderIcon} from "../../../../resources/icons/border-top-left.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import { useHistory } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import { Routes } from "../../../../router/Routes";
import { useParams } from "react-router";
import { GetREOwnershipLabel } from "../../../../enums/RealEstateEnums";
import dayjs from "dayjs";
import deleteRealEstate from "../../api/deleteRealEstate";
import toast from "react-hot-toast";
import {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import {formatPrice} from "../../../../shared/utils/format";

const REInfoSection = ({data}: any) => {
    const {slug}: {[index: string]: any} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Smazat"
                icon={<ITrashIcon/>}
                color={"red"}
                onClick={async () => {
                    if(window.confirm("Doopravdy si přejete smazat nemovitost?")) {
                        const response = await deleteRealEstate(slug);
                        if(response.status == 200) {
                            const data = await response.json();
                            history.push(Routes.REAL_ESTATE);
                            toast.success("Nemovitost smazána.");
                        } else {
                            toast.error("Nemovitost se nepodařilo smazat.")
                        }
                    }
                }}
            />
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Upravit"
                icon={<IPenIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_DETAIL_EDIT.replace(":slug", slug))}
            />
        </>)
    }, []);
    return (<Container>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Lokalita" icon={<IMapIcon/>}>
                    <InfoBoxItem title="Hodnota nemovitosti interní" text={(formatPrice(data.general?.estimatedValueInternal) ?? "-") + " Kč"}/>
                    <InfoBoxItem title="Hodnota nemovitosti externí" text={(formatPrice(data.general?.estimatedValueExternal) ?? "-") + " Kč"}/>
                    <InfoBoxItem title="Katastrální území" text={data.general?.cadastralTerritory ?? ""}/>
                    <InfoBoxItem title="Číslo LV" text={data.general?.listOfOwners ?? ""}/>
                    <InfoBoxItem title="Ulice" text={data.location?.streetAddressFirstLine ?? ""}/>
                    <InfoBoxItem title="Číslo popisné" text={data.location?.streetAddressSecondLine ?? ""}/>
                    <InfoBoxItem title="Číslo jednotky" text={data.general?.identificationNumber ?? ""}/>
                    <InfoBoxItem title="Podlaží umístění" text={data.roomsInformation?.floorLocation ?? ""}/>
                    <InfoBoxItem title="PSČ" text={data.location?.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') ?? ""}/>
                    <InfoBoxItem title="Obec/Město" text={data.location?.city ?? ""}/>
                    <InfoBoxItem title="Země" text={GetCountryByValue(data.location?.country)?.label ?? ""}/>
                </InfoBox>
                {/**<InfoBox title="Obecné" icon={<IInfoIcon/>}>
                    <InfoBoxItem title="Číslo jednotky" text={data.general?.identificationNumber ?? ""}/>
                    <InfoBoxItem title="PENB" text={data.general?.penb ?? ""}/>
                    <InfoBoxItem title="Náklady na bydlení" text={data.general?.housingCosts ?? ""}/>
                    <InfoBoxItem title="Kategorie" text={data.general?.spaceCategory ?? ""}/>
                    <InfoBoxItem title="Vlastnictví" text={GetREOwnershipLabel(data.general?.ownership) ?? ""}/>
                    <InfoBoxItem title="Voda" text={data.general?.water ?? ""}/>
                    <InfoBoxItem title="Topení" text={data.general?.heating ?? ""}/>
                    <InfoBoxItem title="Plyn" text={data.general?.gas ?? ""}/>
                    <InfoBoxItem title="Elektřina" text={data.general?.electricity ?? ""}/>
                    <InfoBoxItem title="Odpad" text={data.general?.drain ?? ""}/>
                    <InfoBoxItem title="Rok výstavby" text={data.general?.builtOn ?? ""}/>
                    <InfoBoxItem title="Rok kolaudace" text={data.general?.approvedOn ?? ""}/>
                    <InfoBoxItem title="Výtah" text={data.general?.elevator ? "Ano" : "Ne"}/>
                    <InfoBoxItem title="Zařízený" text={data.general?.furnishedAccomodation ? "Ano" : "Ne"}/>
                    <InfoBoxItem title="Stav objektu" text={data.general?.stateDescription ?? ""}/>
                    <InfoBoxItem title="Budova" text={data.general?.buildingType ?? ""}/>
                    <InfoBoxItem title="Umístění objektu" text={data.general?.placementDescription ?? ""}/>
                    <InfoBoxItem title="Poloha objektu" text={data.general?.locationDescription ?? ""}/>
                    <InfoBoxItem title="Zástavba" text={data.general?.development ?? ""}/>
                    <InfoBoxItem title="Parkování" text={data.general?.parking ?? ""}/>
                    <InfoBoxItem title="Garáž" text={data.general?.garage ? "Ano" : "Ne"}/>
                    <InfoBoxItem title="Komunikace" text={data.general?.roads ?? ""}/>
                    <InfoBoxItem title="Doprava" text={data.general?.transportation ?? ""}/>
                </InfoBox>**/}
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Parametry" icon={<IBorderIcon/>}>
                    <InfoBoxItem title="Existuje nájem" text={data.general?.isRentable ? "Ano" : "Ne"}/>
                </InfoBox>
                {/**<InfoBox title="Místnosti" icon={<IBorderIcon/>}>
                    <InfoBoxItem title="Předsíň" text={data.roomsInformation?.hallRoom ?? ""}/>
                    <InfoBoxItem title="Koupelna" text={data.roomsInformation?.bathroom ?? ""}/>
                    <InfoBoxItem title="Kuchyň" text={data.roomsInformation?.kitchen ?? ""}/>
                    <InfoBoxItem title="WC" text={data.roomsInformation?.toilet ?? ""}/>
                    <InfoBoxItem title="1. pokoj" text={data.roomsInformation?.firstRoom ?? ""}/>
                    <InfoBoxItem title="2. pokoj" text={data.roomsInformation?.secondRoom ?? ""}/>
                    <InfoBoxItem title="Obývácí pokoj" text={data.roomsInformation?.livingRoom ?? ""}/>
                    <InfoBoxItem title="Balkón" text={data.roomsInformation?.balcony ?? ""}/>
                    <InfoBoxItem title="Sklep" text={data.roomsInformation?.cellar ?? ""}/>
                    <InfoBoxItem title="Podlaží umístění" text={data.roomsInformation?.floorLocation ?? ""}/>
                    <InfoBoxItem title="Počet podlaží" text={data.roomsInformation?.numberOfFloors ?? ""}/>
                    <InfoBoxItem title="Plocha užitná" text={data.roomsInformation?.usableArea ?? ""}/>
                    <InfoBoxItem title="Plocha podlahová" text={data.roomsInformation?.floorArea ?? ""}/>
                    <InfoBoxItem title="Celková výměra bytu" text={data.roomsInformation?.totalArea ?? ""}/>
                </InfoBox>**/}
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note ?? ""}
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </Container>)
};

const Container = styled.div``;

export default REInfoSection;