import React, {useLayoutEffect, useState} from "react";
import jwtDecode from "jwt-decode";

type UserIdentityContextType = {
    initialized: boolean;
    isLoggedIn: boolean;
    signIn(identity: string): void;
    signOut(): void;
    getName(): string;
};

export const UserIdentityContext = React.createContext<UserIdentityContextType>({
    initialized: false,
    isLoggedIn: false,
    signIn: (identity: string) => {
    },
    signOut: () => {
    },
    getName: () => ""
});

interface UserIdentityProviderInterface {
    children: React.ReactNode
}


export const getAccessToken = (): string | null => {
    const identity = localStorage.getItem("identity");
    if (identity === null) return null;
    const json = JSON.parse(identity);
    return json.token;
};

export const getCurrentUserId = (): string | null => {
    const identity = localStorage.getItem("identity");
    if (identity === null) return null;
    const json = JSON.parse(identity);
    return json.id;
};

export const UserIdentityProvider = ({children}: UserIdentityProviderInterface): React.ReactElement => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const signIn = (identity: string) => {
        localStorage.setItem("identity", identity);
        setIsLoggedIn(true);
    };

    const signOut = () => {
        localStorage.removeItem("identity");
        setIsLoggedIn(false);
    };

    const getName = (): string => {
        const identity: any = localStorage.getItem("identity");
        const parsedIdentity = JSON.parse(identity);
        return parsedIdentity.name;
    }

    const processExpirationDate = () => {
        const identity: any = localStorage.getItem("identity");
        if (identity === null)
            setIsLoggedIn(false);
        else {
            const parsedIdentity = JSON.parse(identity);
            const {exp}: {[index: string]: any} = jwtDecode(parsedIdentity.token);
            if (Date.now() >= exp * 1000) {
                signOut();
            } else
                setIsLoggedIn(true);
        }

        setIsInitialized(true);
    };

    useLayoutEffect(() => {
        processExpirationDate();
    }, []);

    return (<UserIdentityContext.Provider value={{
        initialized: isInitialized, isLoggedIn: isLoggedIn, signIn: signIn, signOut: signOut, getName: getName
    }}>
        {children}
    </UserIdentityContext.Provider>);
}

export default UserIdentityProvider;