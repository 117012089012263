import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import PageHeader from "../../../components/shared/PageHeader";
import Strip from "../../../components/shared/Strip";
import {ReactComponent as ITagIcon} from "../../../resources/icons/tag.svg";
import {ReactComponent as ITimelineIcon} from "../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as IInfoIcon} from "../../../resources/icons/circle-info.svg";
import { Routes } from "../../../router/Routes";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as ICheckIcon} from "../../../resources/icons/check.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import deleteTask from "../api/tasks/deleteTask";
import toast from "react-hot-toast";
import fetchTask from "../api/tasks/fetchTask";
import dayjs from "dayjs";
import { GetTransactionTypeLabel } from "../../../enums/FinanceEnums";
import {formatPrice} from "../../../shared/utils/format";

type ParamsType = {
    [index: string]: string;
}

const TaskDetailPage = () => {
    const {slug, secondslug}: ParamsType = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [task, setTask] = useState<any>({});
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat tento úkol?")) {
                    const response = await deleteTask(secondslug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        toast.success("Úkol byl smazán.")
                        history.push(Routes.TASKS);
                    } else {
                        toast.error("Úkol se nepodařilo smazat.")
                    }
                }
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export úkol")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_TASK_EDIT.replace(":slug", slug).replace(":secondslug", secondslug))}/>
            <UtilityButton
            title="Splněno"
            color="fullGreen"
            icon={<ICheckIcon/>}
            onClick={async () => console.log("splnit úkol")}/>
        </>)
        const fetchData = async () => {
            const response = await fetchTask(secondslug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setTask(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Úkol se nepodařilo načíst.")
            }
        };
        fetchData();
    }, []);
    return (<Container>
        {!loading && <><PageHeader
        title={task.data?.taskName}
        subtitle={`Projekty   —   Přehled   —   ${task.data?.projectInternalName}   —   Úkoly   —   ${task.data?.taskName}`}
        noContainerBottomMargin
        noContainerLeftMargin
        returnPath={Routes.PROJECT_DETAIL_TASKS.replace(":slug", task.data?.projectId)}
        strip={<Strip color="gray"/>}/>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Položka" icon={<ITagIcon/>}>
                    <InfoBoxItem title="Označení" text={task.data?.internalName}/>
                    <InfoBoxItem title="Datum plnění" text={task.data?.expectedFinishDate ? <><b>{dayjs(task.data?.expectedFinishDate).format("DD.MM.YYYY")}</b><span> za {dayjs(task.data?.expectedFinishDate).diff(Date.now(), "day")} dní</span></> : ""}/>
                    <InfoBoxItem title="Řešitel" text={<a href={Routes.USER_DETAIL.replace(":slug", task.data?.assigneeId)}>{task.data?.assigneeName}</a>}/>
                    <InfoBoxItem title="Kategorie" text={GetTransactionTypeLabel(task.data?.type)}/>
                    <InfoBoxItem title="Částka" text={<b>{task.data?.amount}</b>}/>
                    <InfoBoxItem title="Podíl ERICONN" text={task.data?.ericonnPercentage ? `${task.data?.ericonnPercentage} % = ${formatPrice(((task.data?.amount / 100) * task.data?.ericonnPercentage))} Kč` : ""}/>
                    <InfoBoxItem title="Podíl investorů" text={task.data?.investorPercentage ? `${task.data?.investorPercentage} % = ${formatPrice(((task.data?.amount / 100) * task.data?.investorPercentage))} Kč` : ""}/>
                    <InfoBoxItem title="Projekt" text={<a href={Routes.PROJECT_DETAIL.replace(":slug", task.data?.projectId)}>{task.data?.projectInternalName}</a>}/>
                </InfoBox>
                <InfoBox title="Popis" icon={<IInfoIcon/>}>
                    {task.note ?? ""}
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                <InfoBoxItem title="Vytvořen" text={<>{
                    dayjs(task.activity?.createdOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(task.activity?.createdOn).format(`HH:mm`)
                }</span></>}/>
                <InfoBoxItem title="Vytvořil/a" text={task.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", task.activity?.createdById.toString())}>
                    {task.activity?.createdByName}
                </a> : ""}/>
                <InfoBoxItem title="Upraveno" text={task.activity?.lastUpdatedOn ? <>{
                    dayjs(task.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(task.activity?.lastUpdatedOn).format(`HH:mm`)
                }</span></> : ""}/>
                <InfoBoxItem title="Upravil/a" text={task.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", task.activity?.lastUpdatedById.toString())}>
                    {task.activity?.lastUpdatedByName}
                 </a> : ""}/>
                <InfoBoxItem title="Součástí investičního zápisu" text={<a href={Routes.PROJECT_INVESTMENT_LOG_DETAIL.replace(":slug", task.data?.projectId).replace(":secondslug", task.data?.investmentLogId)}>{dayjs(task.data?.investmentLogDate).format(`DD.MM.YYYY`)}</a>}/>
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer></>}
    </Container>)
};

const Container = styled.div`

`;

export default TaskDetailPage;