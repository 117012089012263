import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import ProfileColumn from "../../../../components/table/components/ProfileColumn";
import ColoredText from "../../../../components/table/components/ColoredText";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import {ReactComponent as IPDFIcon} from "../../../../resources/icons/file-pdf.svg";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import fetchProjectDocuments from "../../api/project/fetchProjectDocuments";
import toast from "react-hot-toast";
import CreateDocumentModal from "../../../documents/CreateDocumentModal";
import { GetDocumentCategoryTypeLabel } from "../../../../enums/DocumentEnums";
import dayjs from "dayjs";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IFileIcon} from "../../../../resources/icons/file.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as ISizeIcon} from "../../../../resources/icons/file.svg";
import {ReactComponent as IFolderIcon} from "../../../../resources/icons/folder-open.svg";
import useFilters from "../../../../components/methods/useFilters";

const DocumentsSection = () => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        author: "",
        type: "",
        category: "",
        sizeFrom: "", sizeTo: ""
    });
    
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export zajištění")}/>
            <UtilityButton
            title="Nahrát dokument"
            color="blue"
            icon={<IPlusIcon/>}
            onClick={() => {
                modal.openModal(<CreateDocumentModal projectId={slug}/>)
            }}/>
        </>)
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjectDocuments(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Dokumenty se nepodařilo načíst.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);
    return (<SectionContainer>
        <TableContainer>
            <FilterWrap maxWidth={"50%"}>
                <SearchBar name={"documentsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                label={`Kategorie`}
                onChange={(filters: string) => changeFilter("category", filters)}
                icon={<IFolderIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Typ`}
                onChange={(filters: string) => changeFilter("type", filters)}
                icon={<IFileIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Autor`}
                onChange={(filters: string) => changeFilter("author", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterRangeInput
                label={`Velikost v kB`}
                icon={<ISizeIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["sizeFrom", "sizeTo"], [values.from, values.to])}
            />
            </FilterWrap>
             <Table headers={[
                {key: "docName", value: "NÁZEV", sortable: false},
                {key: "docCategory", value: "KATEGORIE", sortable: true},
                {key: "docType", value: "TYP", sortable: false},
                {key: "fileSize", value: "VELIKOST", sortable: false},
                {key: "uploader", value: "NAHRÁL", sortable: true},
                {key: "dateOfUpload", value: "DATUM NAHRÁNÍ", sortable: true},
                {key: "dateOfUpdate", value: "DATUM AKTUALIZACE", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.documents ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.documents?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            docName: {value: (<ProfileColumn icon={
                <IPDFIcon/>
            } title={
                o.internalName
            } subtitle={
                <ColoredText text={o.fileName} color={"grayBlue"} semibold/>
            }/>)},
            docCategory: {value: GetDocumentCategoryTypeLabel(o.category)},
            docType: {value: (<RegularBold>{o.contentType}</RegularBold>)},
            fileSize: {value: (<RegularBold>{o.size} kB</RegularBold>)},
            uploader: {value: (<a>{o.createdByName}</a>)},
            dateOfUpload: {value: (<DoubleLineValue firLine={
                (<RegularBold>{dayjs(o.createdOn).format("DD.MM.YYYY")}</RegularBold>)
            } secLine={
                <ColoredText text={dayjs(o.createdOn).format("HH:mm")} color={"grayBlue"}/>
            }/>)},
            dateOfUpdate: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.lastUpdateOn ? dayjs(o.lastUpdateOn).format("DD.MM.YYYY"): "-"}</RegularBold>
            } secLine={
                <ColoredText text={o.lastUpdateOn ? dayjs(o.lastUpdateOn).format("HH:mm"): "-"} color={"grayBlue"}/>
            }/>)},
            buttons: [
                { label: "Upravit", action: () => { console.log("upravit") } },
                { label: "Smazat", action: () => { console.log("smazat") } }
            ],
            link: `${Routes.DOCUMENT_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}


export default DocumentsSection;