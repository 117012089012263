import React, {ReactElement} from "react";
import {Page} from "../styled/pagination.styled";

export const generatePageButtons = (page: number, total: number, limit: number, onChange: any): Array<ReactElement> => {
    const totalPage: number = Math.ceil(total / limit);
    const result: Array<ReactElement> = [];
    if (totalPage <= 3) {
        for (let i = 0; i < totalPage; i++) {
            result.push(<Page key={i} onClick={() => {
                onChange(i + 1);
            }} active={page === (i + 1)} disabled={page === (i + 1)}>{i + 1}</Page>);
        }
    } else {
        if (page === 1) {
            for (let i = 1; i <= 3; i++) {
                result.push(<Page key={i} onClick={() => {
                    onChange(i);
                }} active={page === i} disabled={page === i}>{i}</Page>);
            }
        } else if (page === totalPage) {
            for (let i = totalPage - 2; i <= totalPage; i++) {
                result.push(<Page key={i} onClick={() => {
                    onChange(i);
                }} active={page === i} disabled={page === i}>{i}</Page>);
            }
        } else {
            for (let i = page - 1; i < page + 2; i++) {
                result.push(<Page key={i} onClick={() => {
                    onChange(i);
                }} active={page === i} disabled={page === i}>{i}</Page>);
            }
        }
    }
    return result;
};