import React, {ReactElement, useRef, useState} from "react";
import {
    LabeledInputLabel,
    LabeledInputRow,
    LabeledInputWrapper, LabeledSelectField
} from "./styled/LabeledTextInput.styled"
import InputValidationMessage from "./InputValidationMessage";
import styled, {css} from "styled-components";
import {ReactComponent as ICaretIcon} from "../../../resources/icons/caret-down.svg";
import {ReactComponent as IGlassIcon} from "../../../resources/icons/magnifying-glass.svg";
import { useEffect } from "react";

export interface OptionInterface {
    id: number | string;
    label: string;
}

interface LabeledSelectInputInterface {
    label: string;
    name: string;
    placeholder: string;
    icon?: React.ReactNode;
    defaultValue?: number | string;
    wide?: boolean;
    valid: boolean;
    disable?: boolean;
    errorMessage?: string;
    onSelect?: (id: number | string) => void;
    options: Array<OptionInterface>;
}

const LabeledSelectInput = React.forwardRef(({
                                               label,
                                               placeholder,
                                               name,
                                               valid,
                                               disable,
                                               errorMessage,
                                               defaultValue,
                                               icon,
                                               wide,
                                               options,
                                               onSelect
                                           }: LabeledSelectInputInterface, ref: any): ReactElement => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<number | string>(options.length === 0 ? -1 : defaultValue ?? -1 );
    const [displayedOptions, setDisplayedOptions] = useState<Array<number | string>>([]);
    const searchBarRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const func = function(event: any) {
            if ((event?.target as any)?.closest(".iza-select-input")) return
            setIsOpen(false);
        };
        document.addEventListener("click", func);
        return () => {
            document.removeEventListener("click", func);
        };
    }, []);

    useEffect(() => {
        if(defaultValue)
            setSelectedOption(defaultValue);
    }, [defaultValue]);

    const renderSelectedOption = () => {
        const optionIndex = options.findIndex(o => o.id === selectedOption)
        const v = options[optionIndex];
        if(optionIndex !== -1 && v) {
            return (<div>{v.label}</div>);
        } else {
            return (<></>)
        }
    };
    const handleSelect = (id: number | string) => {
        setSelectedOption(id)
        setIsOpen(false);
        if(onSelect) onSelect(id);
    };
    const handleSearch = ({target}: {target: any}) => {
        const search = target.value;
        let copiedDisplayedOptions = [...displayedOptions];
        options.map((o) => {
            const label = o.label;
            const doesLabelContainSearch = label.toLowerCase().includes(search.toLowerCase());
            const optDisplayIndex = copiedDisplayedOptions.findIndex(val => val === o.id);
            if(optDisplayIndex !== -1) {
                if(!doesLabelContainSearch) copiedDisplayedOptions.splice(optDisplayIndex);
            } else {
                if(doesLabelContainSearch) copiedDisplayedOptions.push(o.id);
            };
        });
        setDisplayedOptions(copiedDisplayedOptions);
    };
    useEffect(() => {
        setDisplayedOptions(options.map(o => o.id))
    }, [options])

    useEffect(() => {
        if(searchBarRef.current !== null)
            searchBarRef.current!.focus();
    }, [isOpen]);


    return <LabeledInputWrapper className={'iza-select-input'}>
        <input type="hidden" value={`${selectedOption !== -1 ? selectedOption : "0"}`} name={name} ref={ref}/>
        <LabeledInputRow>
            <LabeledInputLabel valid={valid}>{label}</LabeledInputLabel>
        </LabeledInputRow>
        <LabeledInputRow>
            <LabeledSelectField onClick={() => {
                if(!disable) {
                    setIsOpen(!isOpen)
                }}} valid={valid} wide={wide} enabled={(disable !== undefined) ? disable : true}>
                <SpaceWrap>
                    {selectedOption === -1 && <>{placeholder}</>}
                    {selectedOption !== -1 && <SingleSelect>
                        {renderSelectedOption()}
                    </SingleSelect>}
                    <IconWrap>{icon || <ICaretIcon/>}</IconWrap>
                </SpaceWrap>
            </LabeledSelectField>
            <OptionContainer toggle={isOpen} $overflow={options.length <= 3}>
                {options.length > 3 && <>
                    <SearchBarContainer htmlFor={`${name}Search`}>
                        <SearchBar id={`${name}Search`} onChange={handleSearch} ref={searchBarRef}/>
                        <SearchIcon/>
                    </SearchBarContainer>
                    <OverflowDiv>
                    {options.map((v,i) => {
                        if(v.id !== 0) return (<Option $display={displayedOptions.findIndex(o => o === v.id) !== -1} selected={selectedOption === v.id} key={i} onClick={() => handleSelect(v.id)}>
                        {v.label}
                    </Option>)})}
                    </OverflowDiv>
                </>}
                {options.length <= 3 && options.map((v,i) => v.id !== 0 && <Option $display={true} selected={selectedOption === v.id} key={i} onClick={() => handleSelect(v.id)}>
                        {v.label}
                </Option>)}
            </OptionContainer>
        </LabeledInputRow>
        {(errorMessage) &&
        <LabeledInputRow>
            <InputValidationMessage type="error" message={errorMessage || "Chybí hodnota."}/>
        </LabeledInputRow>}
    </LabeledInputWrapper>;
});

const OptionContainer = styled.div<{toggle: boolean; $overflow: boolean}>`
    display: ${({toggle}) => toggle ? "block" : "none"};
    position: absolute;
    top: 2.4rem;
    left: 0;
    color: ${({theme}) => theme.colors.black};
    background-color: ${({theme}) => theme.colors.lightGray};
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    border-radius: 4px;
    ${({$overflow}) => $overflow ? css`
        max-height: 190px;
        overflow-y: scroll;
    ` : css`
        max-height: 242px;
    `}
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: all 0.1s;
    z-index: 99;
`;

const SearchBar = styled.input`
    border: none;
    font-size: 18px;
    margin-right: 8px;
    background-color: transparent;
    color: ${({theme}) => theme.colors.black};
    width: 100%;
    outline: none;
`;

const SearchBarContainer = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    padding: 8px;
    border-bottom: 2px solid ${({theme}) => theme.colors.lighterGray};
`;

const SearchIcon = styled(IGlassIcon)`
    width: 16px;
    height: 16px;
    fill: ${({theme}) => theme.colors.stripGray};
`;

const Option = styled.div<{selected: boolean; $display: boolean}>`
    padding: 8px;
    font-weight: 500;
    width: 90%;
    line-height: 22px;
    white-space: pre-wrap;
    display: ${({$display}) => $display ? "flex" : "none"};
    align-items: center;
    cursor: pointer;
    color: ${({selected, theme}) => selected ? theme.colors.stripGray : "blueGray"};
    transition: 0.2s all;
    &:hover {
        background-color: ${({theme}) => theme.colors.lighterGray};
    };
    &:not(:last-of-type) {
        border-bottom: 1px solid ${({theme}) => theme.colors.lighterGray};
    };
`;

const OverflowDiv = styled.div`
    position: relative;
    max-height: 194px;
    overflow-y: scroll;
`;

const SingleSelect = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme}) => theme.colors.black};
`;

const SpaceWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const IconWrap = styled.div`
    width: 9px;
    height: 16px;
    fill: ${({theme}) => theme.colors.stripGray};
`;

export default LabeledSelectInput;