import {getAccessToken} from "../../../contexts/UserIdentityContext";

const updateUser = async (id: string, data: any): Promise<any> => {
    const response = await fetch(`/api/v1/admins/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + getAccessToken()
        },
        body: JSON.stringify(data),
    })
    return response;
};

export default updateUser;