import React from "react";
import styled from "styled-components";

const PreviewTitle = ({title}: {title: string}) => {
    return (<TitleWrap>
        {title}
    </TitleWrap>)
};

const TitleWrap = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
    color: ${({theme}) => theme.colors.black};
`;

const PreviewContainer = styled.div`
    border-left: 1px solid ${({theme}) => theme.colors.lighterGray};
    padding: 24px;
    margin-top: -32px;
    min-width: 600px;
    max-width: 648px;
    height: 76vh;
`;

export default {
    PreviewTitle,
    PreviewContainer,
};