import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useHistory, useParams} from "react-router";
import {UtilityButtonsContext} from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import {ReactComponent as IFlagIcon} from "../../../../resources/icons/flag.svg";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-pen.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as IHandDollarIcon} from "../../../../resources/icons/hand-holding-dollar.svg";
import {ReactComponent as IHandBoxIcon} from "../../../../resources/icons/hand-holding-box.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import {ReactComponent as IUsersIcon} from "../../../../resources/icons/users.svg";
import {ReactComponent as IShieldIcon} from "../../../../resources/icons/shield-check.svg";
import {ReactComponent as ISackIcon} from "../../../../resources/icons/sack.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import {ReactComponent as ICalendarsIcon} from "../../../../resources/icons/calendars.svg";
import {Routes} from "../../../../router/Routes";
import {TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import SectionContainer from "../../project-detail/components/SectionContainer";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import toast from "react-hot-toast";
import deleteInvestor from "../../api/investors/deleteInvestor";
import Pagination from "../../../../components/pagination/Pagination";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import useFilters from "../../../../components/methods/useFilters";
import {GetProjectTypeColor, GetProjectTypeLabel} from "../../../../enums/ProjectEnums";
import {GetRETypeLabel} from "../../../../enums/RealEstateEnums";
import dayjs from "dayjs";
import fetchInvestorProjects from "../../api/investors/fetchInvestorProjects";
import {formatPrice} from "../../../../shared/utils/format";

const ProjectsSection = () => {
    const {slug}: { slug: string } = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");

    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        state: "",
        type: "",
        collateral: "",
        investor: "",
        agent: "",
        location: "",
        admin: "",
        colValueFrom: "", colValueTo: "",
        totalInvestedFrom: "", totalInvestedTo: "",
        ltvFrom: "", ltvTo: "",
        dateFrom: "", dateTo: ""
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Smazat"
                color="red"
                icon={<ITrashIcon/>}
                onClick={async () => {
                    if (window.confirm("Doopravdy chcete smazat investora?")) {
                        const response = await deleteInvestor(slug);
                        if (response.status == 200) {
                            const fetchedData = await response.json();
                            toast.success("Investor smazán.");
                            history.push(Routes.PROJECTS_INVESTORS);
                        } else {
                            toast.error("Investora se nepodařilo smazat");
                        }
                    }
                }}/>
            <UtilityButton
                title="Exportovat"
                color="gray"
                icon={<ICloudIcon/>}
                onClick={() => console.log("export investor")}/>
            <UtilityButton
                title="Upravit"
                color="gray"
                icon={<IPenIcon/>}
                onClick={() => history.push(Routes.PROJECT_INVESTOR_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchInvestorProjects(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
            if (response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst projekty.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);
    return (<SectionContainer>
        <TableContainer>
            <FilterWrap maxWidth={"65%"}>
                <SearchBar name={"projectOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                    label={`Stav`}
                    onChange={(filters: string) => changeFilter("state", filters)}
                    icon={<IFlagIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Druh`}
                    onChange={(filters: string) => changeFilter("type", filters)}
                    icon={<IMoneyCheckIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Zajištění`}
                    onChange={(filters: string) => changeFilter("collateral", filters)}
                    icon={<IBuildingIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Investor`}
                    onChange={(filters: string) => changeFilter("investor", filters)}
                    icon={<IHandDollarIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Zprostředkovatel`}
                    onChange={(filters: string) => changeFilter("provider", filters)}
                    icon={<IHandBoxIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Lokalita`}
                    onChange={(filters: string) => changeFilter("location", filters)}
                    icon={<IMapIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Uživatelé`}
                    onChange={(filters: string) => changeFilter("admin", filters)}
                    icon={<IUsersIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
            </FilterWrap>
            <FilterWrap maxWidth={"57.5%"}>
                <FilterRangeInput
                    label={`Hodnota zajištění`}
                    icon={<IShieldIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Investováno`}
                    icon={<ISackIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`LTV`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["ltvFrom", "ltvTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Období`}
                    icon={<ICalendarsIcon/>}
                    dateRange={true}
                    onChange={(values: any) => changeFilter(["dateFrom", "dateTo"], [values.from, values.to])}
                />
            </FilterWrap>
            <Table
                headers={[
                    {key: "projectId", value: "ID PROJEKTU", sortable: true},
                    {key: "type", value: "TYP INVESTICE", sortable: true},
                    {key: "collateral", value: "ZAJIŠTĚNÍ", sortable: true},
                    {key: "investors", value: "INVESTOŘI", sortable: true},
                    {key: "collateralValue", value: "HOD. ZAJIŠTĚNÍ", sortable: false},
                    {key: "investments", value: "INVESTOVÁNO", sortable: false},
                    {key: "ltv", value: "LTV", sortable: false},
                    {key: "period", value: "OBDOBÍ", sortable: true},
                    {key: "updatedOn", value: "AKTUALIZACE", sortable: true},
                ]}
                sizes={["12px"]}
                body={mapper(loading ? [] : (data.projects ?? []))}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {
                    setSortId(filter);
                    setSortOrder(filterOrder)
                }}
            />
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.projects?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </SectionContainer>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            id: o.id,
            projectId: {value: (<b>{o.internalName || "Nenastaveno"}</b>)},
            type: {value: (<ColoredText text={GetProjectTypeLabel(o.type)} color={GetProjectTypeColor(o.type)} semibold/>)},
            collateral: {value: <DoubleLineValue firLine={GetRETypeLabel(o.mainCollateralType)} secLine={<ColoredText text={`+ ${o.otherCollateralCount} další`} color={"grayBlue"}/>}/>},
            investors: {value: <DoubleLineValue firLine={o.investorCount} secLine={<ColoredText text={`podíl ${o.investorPercentageCut} %`} color={"grayBlue"}/>}/>},
            collateralValue: {value: `${formatPrice(o.collateralValue)} Kč`},
            investments: {value: `${formatPrice(o.totalInvested)} Kč`},
            ltv: {value: (<RegularBold>{o.loanToValue || "0"} %</RegularBold>)},
            period: {
                value: <DoubleLineValue firLine={<><b>{o.endDate ? dayjs(o.startDate).diff(o.endDate, "month") : "?"}</b>/12</>}
                                        secLine={<ColoredText text={`${dayjs(o.startDate).format(`DD.MM.YYYY`)} - ${o.endDate ? dayjs(o.endDate).format(`DD.MM.YYYY`) : "Nenastaveno"}`}
                                                              color={"grayBlue"}/>}/>
            },
            updatedOn: {
                value: <DoubleLineValue firLine={o.lastUpdateOn ? dayjs(o.lastUpdateOn).format("DD.MM.YYYY") : "Bez aktualizace"}
                                        secLine={o.lastUpdateOn ? <ColoredText text={dayjs(o.lastUpdateOn).format(`HH:mm`)} color={"grayBlue"} semibold/> : <div>-</div>}/>
            },
            link: `${Routes.PROJECT_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}
export default ProjectsSection;