import { getAccessToken } from '../../../contexts/UserIdentityContext';
const editDocument = async (id: number | string, data: any) => {
    const response = await fetch(`/api/v1/documents/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: data,
    });
    return response
};

export default editDocument;