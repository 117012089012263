import React, { useContext } from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand-holding-box.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import ModalRowTitle from "../../components/shared/modal/ModalRowTitle";
import ModalChecked from "../../components/shared/modal/ModalChecked";
import ModalUnchecked from "../../components/shared/modal/ModalUnchecked";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/Routes";
import { ModalContext } from "../../components/layout/Layout";
import createProvider from "./api/provider/createProvider";
import toast from "react-hot-toast";

const CreateProviderModal = () => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        const response = await createProvider(data);
        if(response.status == 200) {
            modal.closeCurrentModal();
            const fetchedData = await response.json();
            history.push(Routes.PROJECT_PROVIDER_DETAIL.replace(":slug", fetchedData.result))
            toast.success("Zprostředkovatel vytvořen.");
        } else {
            toast.error("Zprostředkovatele se nepodařilo vytvořit.");
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<IHandIcon/>}
        title="Vytvořit zprostředkovatele"
        >
        <ModalCheckboxContainer>
            <ModalCheckboxLabel htmlFor="investorCheckbox">
                <ModalCheckbox name="investorCheck" type="checkbox" id="investorCheckbox"/>
                <ModalCheckedWrap><ModalChecked/></ModalCheckedWrap>
                <ModalUncheckedWrap><ModalUnchecked/></ModalUncheckedWrap>
                Zprostředkovatel je právnická osoba
            </ModalCheckboxLabel>
        </ModalCheckboxContainer>
        <ModalInputRow>
            <LabeledTextInput
                label='Jméno'
                type="text"
                name="firstName"
                valid={!errors.firstName}
                errorMessage={errors.firstName?.message}
                placeholder="Jméno..."
                defaultValue=""
                ref={register}
            />
            <LabeledTextInput
                label='Příjmení'
                type="text"
                name="lastName"
                valid={!errors.lastName}
                errorMessage={errors.lastName?.message}
                placeholder="Příjmení..."
                defaultValue=""
                ref={register}
            />
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const ModalCheckboxContainer = styled.div`
    width: 100%;
    text-align: left;
`;

const ModalCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({theme}) => theme.colors.black};
    font-size: 16px;
    font-weight: 600;
`;

const ModalCheckedWrap = styled.span`
    display: none;
`;
const ModalUncheckedWrap = styled.span``;

const ModalCheckbox = styled.input`
    display: none;
    &:checked ~ ${ModalCheckedWrap} {
        display: inline-block;
    }
    &:checked ~ ${ModalUncheckedWrap} {
        display: none
    }
`;

export default CreateProviderModal;