import React, {ReactElement} from "react";
import {ReactComponent as IHouseIcon} from "../resources/icons/house.svg";
import {ReactComponent as IHouseUserIcon} from "../resources/icons/house-user.svg";
import {ReactComponent as IHouseCrackIcon} from "../resources/icons/house-chimney-crack.svg";
import {ReactComponent as IHouseFloodIcon} from "../resources/icons/house-flood.svg";
import {OptionInterface} from "../components/shared/inputs/LabeledSelectInput";


export enum ProjectState {
    // CZECH: Rozpracováno
    InPreparation = 1,
    // CZECH: Ke schválení
    ForApproval = 2,
    // CZECH: Běžící
    InProgress = 3,
    // CZECH: Uzavřený
    Closed = 4,
    // CZECH: Zamítnutý
    Declined = 5
}

export const GetProjectStateLabel = (state: ProjectState): string => {
    switch (state) {
        case ProjectState.InPreparation:
            return "Rozpracováno";
        case ProjectState.ForApproval:
            return "Ke schválení";
        case ProjectState.InProgress:
            return "Běžící";
        case ProjectState.Closed:
            return "Uzavřený";
        case ProjectState.Declined:
            return "Zamítnutý";
    }
};

export const GetProjectStateOptions = (): Array<OptionInterface> => {
    return [
        {
            id: ProjectState.InPreparation,
            label: (GetProjectStateLabel(ProjectState.InPreparation)),
        },
        {
            id: ProjectState.ForApproval,
            label: (GetProjectStateLabel(ProjectState.ForApproval)),
        },
        {
            id: ProjectState.InProgress,
            label: (GetProjectStateLabel(ProjectState.InProgress)),
        },
        {
            id: ProjectState.Closed,
            label: (GetProjectStateLabel(ProjectState.Closed)),
        },
        {
            id: ProjectState.Declined,
            label: (GetProjectStateLabel(ProjectState.Declined)),
        },
    ]
};



export enum ProjectType {
    // CZECH: Postoupení pohledávky
    AssignmentOfReceivables = 1,
    // CZECH: Výkup nemovitosti s nájemníkem
    PurchaseOfRealEstateWithTenant = 2,
    // CZECH: Výkup nemovitosti bez nájemníka
    PurchaseOfRealEstateWithoutTenant = 3,
    // CZECH: Výkup nemovitosti s opčním právem
    PurchaseOfRealEstateWithOption = 4
}

export const GetProjectTypeLabel = (type: ProjectType): string => {
    switch (type) {
        case ProjectType.AssignmentOfReceivables:
            return "Postoupení pohledávky";
        case ProjectType.PurchaseOfRealEstateWithTenant:
            return "Výkup nemovitosti s nájemníkem";
        case ProjectType.PurchaseOfRealEstateWithoutTenant:
            return "Výkup nemovitosti bez nájemníka";
        case ProjectType.PurchaseOfRealEstateWithOption:
            return "Výkup nemovitosti s opčním právem";
        default:
            return "Nenastaveno";
    }
};

export const GetProjectTypeColor = (type: ProjectType): string => {
    switch (type) {
        case ProjectType.AssignmentOfReceivables:
            return "grayBlue";
        case ProjectType.PurchaseOfRealEstateWithTenant:
            return "blueGreen";
        case ProjectType.PurchaseOfRealEstateWithoutTenant:
            return "lightBlue";
        case ProjectType.PurchaseOfRealEstateWithOption:
            return "purple";
        default:
            return "grayBlue";
    }
};


export interface ProjectTypeInfo {
    id: number;
    label: string;
    icon: ReactElement;
}

export const GetProjectTypes = (): Array<ProjectTypeInfo> => {
    return [
        {
            id: ProjectType.AssignmentOfReceivables,
            label: (GetProjectTypeLabel(ProjectType.AssignmentOfReceivables)),
            icon: <IHouseFloodIcon/>
        },
        {
            id: ProjectType.PurchaseOfRealEstateWithTenant,
            label: (GetProjectTypeLabel(ProjectType.PurchaseOfRealEstateWithTenant)),
            icon: <IHouseUserIcon/>
        },
        {
            id: ProjectType.PurchaseOfRealEstateWithoutTenant,
            label: (GetProjectTypeLabel(ProjectType.PurchaseOfRealEstateWithoutTenant)),
            icon: <IHouseIcon/>
        },
        {
            id: ProjectType.PurchaseOfRealEstateWithOption,
            label: (GetProjectTypeLabel(ProjectType.PurchaseOfRealEstateWithOption)),
            icon: <IHouseCrackIcon/>
        },
    ]
};


export const GetProjectTypeInfo = (id: number): ProjectTypeInfo | undefined => {
    return GetProjectTypes().find(t => t.id === id);
};