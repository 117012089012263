export const defaultTheme = {
    colors: {
        white: "#FFFFFF",
        greenWhite: "#F6FCF2",
        redWhite: "#FFF5F6",
        black: "#222222",
        red: "#FF1744",
        redGray: "FFF5F6",
        grayRed: "#E5DCDD",
        transparentRed: "rgba(255, 23, 68, 0.9)",
        lightPink: "#FD3A58",
        purple: "#B06FE6",
        green: "#00C853",
        stripGreen: "#4FC50B",
        blueGreen: "#33B199",
        stripYellow: "#FDB94E",
        darkYellow: "#FFA726",
        lightOrange: "#F88C00",
        stripGray: "#6D7E90",
        gray: "#D8DADC",
        darkGray: "#4C4B4F",
        borderGray: "#E0E1E2",
        veryLightGray: "#DADCE2",
        lightGray: "#F9FAFC",
        lighterGray: "#EDEEF3",
        blue: "#296EFA",
        selectBlue: "rgb(41, 110, 250, 0.1)",
        darkBlue: "#2159CA",
        grayBlue: "#6D7E90",
        lightBlue: "#00B9FE",
        backgroundBlue: "#f4f8ff"
    }
};