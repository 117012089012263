import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {InfoBoxColumn, InfoBoxFullContainer} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {useHistory, useParams} from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {UtilityButtonsContext} from "../../../../components/layout/Layout";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../../resources/icons/floppy-disk.svg";
import {Routes} from "../../../../router/Routes";
import {useForm} from "react-hook-form";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import toast from "react-hot-toast";
import {BankMapper} from "../../../../components/shared/BankMapper";
import {ReactComponent as ICaretIcon} from "../../../../resources/icons/caret-down.svg";
import {mapAppraisersSelect} from "../../../projects/finance-detail/EditFinancePage";
import {fetchSelectAppraisers} from "../../../projects/api/fetchSelects";
import LabeledFileInput, {FileLink} from "../../../../components/shared/inputs/LabeledFileInput";
import fetchRealEstateEstimation from "../../api/fetchRealEstateEstimation";
import uploadRealEstateEstimationFile from "../../api/uploadRealEstateEstimationFile";
import updateRealEstateEstimation from "../../api/updateRealEstateEstimation";
import deleteEstimationFile from "../../api/deleteEstimationFile";

const REEditEstimationSection = ({onEdit} : any) => {
    const {slug}: { [index: string]: any } = useParams();
    const {register, errors, handleSubmit} = useForm();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [appraisers, setAppraisers] = useState<any>([]);
    const [appraiserId, setAppraiserId] = useState<any>(null);

    const fetchData = async () => {
        const response = await fetchRealEstateEstimation(parseInt(slug));
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
            setAppraiserId(fetchedData.result.info?.appraiserId);
        } else {
            toast.error("Odhad se nepodařilo načíst.")
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    const fetchAppraisers = async () => {
        const response = await fetchSelectAppraisers();
        if (response.status == 200) {
            const fetchedData = await response.json();
            setAppraisers(fetchedData.result);
        }
    };
    const onSubmit = async (data: any) => {
        const estExtValue = parseFloat(data.estimatedValueExternal?.replaceAll('.', '').replace(/\s+/g, ''));
        const response = await updateRealEstateEstimation(parseInt(slug), {
            appraiserId: data.appraiserId == "0" ? null : data.appraiserId,
            bank: isNaN(data.bank) ? "" : data.bank,
            dateOfOrder: data.dateOfOrder == "" ? null : data.dateOfOrder,
            dateOfCreation: data.dateOfCreation == "" ? null : data.dateOfCreation,
            note: data.note,
            estimatedValueExternal: data.estimatedValueExternal == "" ? null : (isNaN(estExtValue) ? 0 : estExtValue)
        });
        if (response.status == 200) {
            onEdit();
            toast.success("Odhad upraven.");
            history.push(Routes.REAL_ESTATE_ESTIMATION_DETAIL.replace(":slug", slug));
        } else {
            toast.error("Nepodařilo se upravit odhad.")
        }
    };
    useEffect(() => {
        fetchAppraisers();

        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Zrušit"
                icon={<ICrossIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_ESTIMATION_DETAIL.replace(":slug", slug))}
            />
            <UtilityButton
                title="Uložit"
                icon={<IDiskIcon/>}
                color={"green"}
                onClick={handleSubmit(onSubmit)}
            />
        </>)
    }, []);
    return (<InfoBoxFullContainer>
        {!loading && <>
            <InfoBoxColumn>
                <InfoBox title="Odhad" icon={<IInfoIcon/>}>
                    <LabeledTextInput type="text" label="Hodnota nemovitosti externí"
                                      name="estimatedValueExternal"
                                      defaultValue={data.info?.estimatedValueExternal ?? ""}
                                      placeholder="Hodnota..."
                                      wide
                                      isPrice
                                      valid={!errors.estimatedValueExternal}
                                      errorMessage={errors.estimatedValueExternal?.message}
                                      ref={register}/>
                    <LabeledFileInput name="file"
                                      label="Soubor"
                                      valid={!errors.file}
                                      accept={[
                                          ".pdf"
                                      ]}
                                      currentFile={data.info?.file ? <FileLink target={'_blank'} href={'/api/v1/realestates/estimation/file/' + data.info?.file}>{data.info?.fileName}</FileLink> : undefined}
                                      errorMessage={errors.file?.message}
                                      onDelete={() => {
                                          deleteEstimationFile(parseInt(slug)).then(e => {
                                              if(e.status === 200) {
                                                  toast.success('Odhad smazán');
                                                  fetchData()
                                              }
                                              else toast.error('Soubor se nepodařilo smazat');
                                          });
                                      }}
                                      onChange={(e: File) => {
                                          uploadRealEstateEstimationFile(parseInt(slug), e).then(e => {
                                              if(e.status === 200) {
                                                  toast.success('Odhad nahrán');
                                              }
                                              else toast.error('Soubor se nepodařilo nahrát');
                                          });
                                      }}
                                      ref={register}/>
                    <LabeledSelectInput label="Odhadce"
                                        name="appraiserId"
                                        placeholder={appraisers.length > 0 ? "Vyberte..." : "Odhadci nejsou dostupní."}
                                        disable={appraisers.length <= 0}
                                        defaultValue={data.info?.appraiserId}
                                        onSelect={(e) => setAppraiserId(e)}
                                        wide
                                        icon={<ICaretIcon/>}
                                        valid={!errors.appraiserId}
                                        errorMessage={errors.appraiserId?.message}
                                        options={mapAppraisersSelect(appraisers) || []}
                                        ref={register}/>
                    <LabeledSelectInput label="Zpracováno pro" name="bank"
                                        placeholder="Vyberte..."
                                        valid={!errors.validBanks}
                                        errorMessage={errors.validBanks?.message}
                                        defaultValue={parseInt(data.info?.estimationFor)}
                                        wide
                                        options={BankMapper(appraisers.filter((e: any) => e.id == appraiserId)[0]?.banks)}
                                        ref={register}
                                        disable={appraiserId == null}
                    />
                    <LabeledTextInput name="dateOfOrder" label="Datum objednávky" type="date"
                                      placeholder="Vyberte..."
                                      defaultValue={data.info?.dateOfOrder}
                                      wide
                                      valid={!errors.dateOfOrder}
                                      errorMessage={errors.dateOfOrder?.message}
                                      ref={register}/>
                    <LabeledTextInput name="dateOfCreation" label="Datum vytvoření" type="date"
                                      placeholder="Vyberte..."
                                      defaultValue={data.info?.dateOfCreation}
                                      wide
                                      valid={!errors.dateOfCreation}
                                      errorMessage={errors.dateOfCreation?.message}
                                      ref={register}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput
                        type={"textarea"}
                        label={"Poznámka"}
                        placeholder={"Text..."}
                        name={"note"}
                        defaultValue={data.note ?? ""}
                        valid={!errors.note}
                        errorMessage={errors.note?.message}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </>}
    </InfoBoxFullContainer>)
};

const Container = styled.div``;

export default REEditEstimationSection;