import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IBuildingIcon} from "../../resources/icons/building.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import ModalRowTitle from "../../components/shared/modal/ModalRowTitle";
import ModalChecked from "../../components/shared/modal/ModalChecked";
import ModalUnchecked from "../../components/shared/modal/ModalUnchecked";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/Routes";
import { ModalContext } from "../../components/layout/Layout";
import toast from "react-hot-toast";
import {GetUserProjectRoles} from "../../enums/UserEnums";
import { fetchSelectRealEstates } from "./api/fetchSelects";
import { useParams } from "react-router";
import assignProjectCollateral from "./api/project/assignProjectCollateral";
import {formatPrice} from "../../shared/utils/format";

interface AssignRealEstateModalInterface {
    existingIds: Array<number>;
    onCreate?: () => void;
}

const AssignRealEstateModal = ({existingIds, onCreate}: AssignRealEstateModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const [realEstates, setRealEstates] = useState<any>([]);
    const {slug}: any = useParams();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        const response = await assignProjectCollateral(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            if(onCreate) onCreate();
            modal.closeCurrentModal();
            toast.success("Zajištění přidáno.");
        } else {
            toast.error("Zajištění se nepodařilo přidat.");
        }
    };
    useEffect(() => {
        const fetchRealEstates = async () => {
            const response = await fetchSelectRealEstates(existingIds);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setRealEstates(fetchedData.result);
            };
        };
        fetchRealEstates();
    }, [])
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<IBuildingIcon/>}
        title="Přidat zajištění"
        >
        <ModalCheckboxContainer>
            <ModalCheckboxLabel htmlFor="isMainCollateral">
                <ModalCheckbox name="isMainCollateral" type="checkbox" id="isMainCollateral" ref={register}/>
                <ModalCheckedWrap><ModalChecked/></ModalCheckedWrap>
                <ModalUncheckedWrap><ModalUnchecked/></ModalUncheckedWrap>
                Zajištění je hlavní.
            </ModalCheckboxLabel>
        </ModalCheckboxContainer>
        <ModalInputRow>
            <LabeledSelectInput name="collateralId" label="Nemovitost"
            placeholder={realEstates.length > 0 ? "Vyberte..." : "Nemovitosti nejsou dostupné."}
            disable={realEstates.length <= 0}
            valid={!errors.collateralId}
            errorMessage={errors.collateralId?.message}
            icon={<ICaretIcon/>}
            options={mapRESelect(realEstates) || []}
            ref={register({valueAsNumber: true, validate: (e) => e != "0" ? true : "Nemovitost je povinná."})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const ModalCheckboxContainer = styled.div`
    width: 100%;
    text-align: left;
`;

const ModalCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({theme}) => theme.colors.black};
    font-size: 16px;
    font-weight: 600;
`;

const ModalCheckedWrap = styled.span`
    display: none;
`;
const ModalUncheckedWrap = styled.span``;

const ModalCheckbox = styled.input`
    display: none;
    &:checked ~ ${ModalCheckedWrap} {
        display: inline-block;
    }
    &:checked ~ ${ModalUncheckedWrap} {
        display: none
    }
`;

const idPad = (num: number, size: number) => String(num).padStart(size, '0');
const mapRESelect = (realestates: Array<any>) => {
    let result: Array<any> = [];
    realestates.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.location}\n${formatPrice(o.value)} Kč`,
    }))
    return result;
};

export default AssignRealEstateModal;