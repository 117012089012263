import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IUserIcon} from "../../resources/icons/user.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import ModalRowTitle from "../../components/shared/modal/ModalRowTitle";
import ModalChecked from "../../components/shared/modal/ModalChecked";
import ModalUnchecked from "../../components/shared/modal/ModalUnchecked";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {Routes} from "../../router/Routes";
import { ModalContext } from "../../components/layout/Layout";
import toast from "react-hot-toast";
import IProfilePhoto from "../../resources/images/TempProfilePhoto.jpg";
import {GetUserProjectRoles} from "../../enums/UserEnums";
import { fetchSelectUsers } from "./api/fetchSelects";
import assignProjectUser from "./api/project/assignProjectUser";
import { getPhotoFromCdn } from "../../shared/api/photoApi";

interface AssignUserModalInterface {
    onCreate?: () => void;
}

const AssignUserModal = ({onCreate}: AssignUserModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const {slug}: any = useParams();
    const [users, setUsers] = useState<any>([]);
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        data.adminId = parseInt(data.adminId);
        data.role = parseInt(data.role);
        const response = await assignProjectUser(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            if(onCreate) onCreate();
            modal.closeCurrentModal();
            toast.success("Uživatel přidán.");
        } else {
            toast.error("Uživatele se nepodařilo přidat.");
        };
    };
    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetchSelectUsers();
            if(response.status == 200) {
                const fetchedData = await response.json();
                setUsers(fetchedData.result);
            } else {
                toast.error("Nepovedlo se načíst uživatele.")
            }
        };
        fetchUsers();
    }, [])
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<IUserIcon/>}
        title="Přidat uživatele"
        >
        <ModalInputRow>
            <PersonSelectorInput name="adminId" label="Uživatel"
            placeholder={users.length > 0 ? "Vyberte..." : "Uživatelé nejsou dostupní."}
            disable={users.length <= 0}
            valid={!errors.adminId}
            errorMessage={errors.adminId?.message}
            icon={<ICaretIcon/>}
            options={mapUserSelect(users) || []}
            ref={register({validate: (e) => e != "" ? true : "Uživatel je povinný."})}/>
            <LabeledSelectInput name="role" label="Role"
            placeholder={"Vyberte...."}
            disable={users.length <= 0}
            valid={!errors.role}
            errorMessage={errors.role?.message}
            icon={<ICaretIcon/>}
            options={GetUserProjectRoles()}
            ref={register({validate: (e) => e != "0" ? true : "Musíte vybrat roli."})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const mapUserSelect = (users: Array<any>) => {
    let result: Array<any> = [];
    users.map(o => result.push({
        id: o.id,
        label: o.fullName,
        imgUrl: getPhotoFromCdn(o.imageName), //o.imageName
    }))
    return result;
};

export default AssignUserModal;