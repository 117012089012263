import styled from "styled-components";

export const AdminFormLabel = styled.label`
  display: block;
  color: ${props => props.theme.colors.darkGray};
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 10px;
`;


export const AdminFormInput = styled.input`
  display: block;
  font-weight: 400;
  font-size: 18px;
  padding: 11px 11px 11px 47px;
  border: 1px solid ${props => props.theme.colors.gray};
  width: calc(100% - 60px);
  background-color: transparent;
  border-radius: 8px;
  color: ${props => props.theme.colors.black};
  transition: .25s border;
  outline: none;

  &:focus {
    border: 1px solid ${props => props.theme.colors.white};
  }

  ::-webkit-input-placeholder, ::-ms-input-placeholder, ::placeholder {
    color: ${props => props.theme.colors.white};
  }
`;