import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import { Route, Switch, useHistory, useLocation, useParams } from "react-router";
import ProfileSection from "./components/ProfileSection";
import EstimatesSection from "./components/EstimatesSection";
import toast from "react-hot-toast";
import { GetRETypeIcon, GetRETypeLabel } from "../../../enums/RealEstateEnums";
import fetchAppraiser from "../api/fetchAppraiser";
import EditSection from "./components/EditSection";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";
import uploadAppraiserImage from "../api/uploadAppraiserImage";

const AppraiserDetailPage = () => {
    const { slug }: {slug: string} = useParams();
    const [loading, setLoading] = useState<boolean>(true); 
    const [data, setData] = useState<any>({});
    const {pathname} = useLocation();

    const fetchData = useCallback(async () => {
        const response = await fetchAppraiser(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Odhadce se nepodařilo načíst.")
        }
    }, []);

    
    const handleUpload = useCallback(async (e: any) => {
        const response = await uploadAppraiserImage(slug, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            fetchData();
            toast.success("Fotka odhadce byla změněna.");
        } else {
            toast.error("Fotku odhadce se nepodařilo změnit.");
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [])
console.log(data.imageName)
    return (<>{!loading && <Container>
        <PageHeader title={`${data.contact?.fullName}`}
        subtitle={`Odhadci — ${data.contact?.fullName}`}
        noContainerLeftMargin
        onUpload={handleUpload}
        returnPath={Routes.APPRAISERS}
        image={getPhotoFromCdn(data.imageName)}/>
        {!pathname.includes("upravit") && <CategoryButtons buttons={[
            {
                text: "Profil",
                route: Routes.APPRAISER_DETAIL,
                exact: true,
                index: slug,
            },
            {
                text: "Odhady",
                route: Routes.APPRAISER_DETAIL_ESTIMATES,
                exact: true,
                index: slug,
            },
        ]}/>}
        <ContentWrapper> 
            <Switch>
                <Route path={Routes.APPRAISER_DETAIL} render={() => <ProfileSection data={data}/>} exact/>
                <Route path={Routes.APPRAISER_DETAIL_ESTIMATES} render={() => <EstimatesSection data={data}/>} exact/>
                <Route path={Routes.APPRAISER_DETAIL_EDIT} render={() => <EditSection data={data} onEdit={fetchData}/>} exact/>
            </Switch>
        </ContentWrapper>
    </Container>}</>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const ContentWrapper = styled.div`
    margin-top: 15px;
    padding: 24px;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
`;

export default AppraiserDetailPage;