import React from "react";
import styled from "styled-components";

const ModalRowTitle = ({title}: {title: string}) => {
    return (<Container>
        {title}
    </Container>)
};

const Container = styled.div`
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    text-transform: uppercase;
    padding-bottom: 4px;
    border-bottom: 1px solid ${({theme}) => theme.colors.lighterGray};
    color: ${({theme}) => theme.colors.veryLightGray};
`;

export default ModalRowTitle;