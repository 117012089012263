import React from "react";
import styled from "styled-components";

const SectionContainer = ({children} :any) => {
    return (<Container>
        {children}
    </Container>)
};

const Container = styled.div`
    margin-top: 15px;
    padding: 24px;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
`;

export default SectionContainer;