import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../../resources/icons/floppy-disk.svg";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import { Routes } from "../../../../router/Routes";
import { useForm } from "react-hook-form";
import StyledCheckboxInput from "../../../../components/shared/inputs/StyledCheckboxInput";
import editProvider from "../../api/provider/editProvider";
import toast from "react-hot-toast";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import {formatPostalCode} from "../../../../shared/utils/format";

const EditSection = ({data}: any) => {
    const {register, errors, handleSubmit, watch} = useForm();
    const [checked, setChecked] = useState<boolean>(false);
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    const billingPostalCode = watch('billingLocation.postalCode');
    const contactPostalCode = watch('contactLocation.postalCode');

    const onSubmit = async (data: any) => {
        data.agentId = parseInt(slug);
        if(data.contactIsIdenticalAsBilling) data.contactLocation = {...data.billingLocation};
        const response = await editProvider(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Zprostředkovatel upraven.")
        } else {
            toast.error("Zprostředkovatele se nepodařilo upravit.")
        };
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.PROJECT_PROVIDER_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<IDiskIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);
    return (<form onSubmit={handleSubmit(onSubmit)}><Container>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Fakturační údaje" icon={<IBuildingIcon/>}>
                    <LabeledTextInput label="Název" type="text" name="agentName"
                    defaultValue={data.billingInformation?.name || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.agentName}
                    errorMessage={errors.agentName?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="IČO" type="text" name="identificationNumber"
                    defaultValue={data.billingInformation?.identificationNumber || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.identificationNumber}
                    errorMessage={errors.identificationNumber?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Ulice 1. řádek" type="text" name="billingLocation.streetAddressFirstLine"
                    defaultValue={data.billingInformation?.billingLocation.streetAddressFirstLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.billingLocation?.streetAddressFirstLine}
                    errorMessage={errors.billingLocation?.streetAddressFirstLine?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Ulice 2. řádek" type="text" name="billingLocation.streetAddressSecondLine"
                    defaultValue={data.billingInformation?.billingLocation.streetAddressSecondLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.billingLocation?.streetAddressSecondLine}
                    errorMessage={errors.billingLocation?.streetAddressSecondLine?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="PSČ" type="text" name="billingLocation.postalCode"
                    defaultValue={data.billingInformation?.billingLocation.postalCode || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.billingLocation?.postalCode}
                                      isCode
                    errorMessage={errors.billingLocation?.postalCode?.message}
                    ref={register({maxLength: 6, minLength: 6})}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="billingLocation.city"
                    defaultValue={data.billingInformation?.billingLocation.city || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.billingLocation?.city}
                    errorMessage={errors.billingLocation?.city?.message}
                    ref={register}
                    />
                    <LabeledSelectInput label="Země" name="billingLocation.country"
                    placeholder="Vyberte..."
                    valid={!errors.billingLocation?.country}
                    errorMessage={errors.billingLocation?.country?.message}
                    defaultValue={GetCountryByValue(data.billingInformation.billingLocation.country)?.id}
                    wide
                    options={CountryMapper()}
                    ref={register}
                    />
                </InfoBox>
                <InfoBox title="Kontaktní osoba" icon={<IUserIcon/>}>
                    <LabeledTextInput label="Jméno" type="text" name="firstName"
                    defaultValue={data.contactPerson?.firstName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.firstName}
                    errorMessage={errors.firstName?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Příjmení" type="text" name="lastName"
                    defaultValue={data.contactPerson?.lastName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.lastName}
                    errorMessage={errors.lastName?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Email" type="text" name="email"
                    defaultValue={data.contactPerson?.email || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.email}
                    errorMessage={errors.email?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="text" name="phone"
                    defaultValue={data.contactPerson?.phoneNumber || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.phone}
                    errorMessage={errors.phone?.message}
                    ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                    <StyledCheckboxInput
                        label={"Kontaktní adresa je shodná s fakturační"}
                        name={"contactIsIdenticalAsBilling"}
                        isSolo
                        onChange={e => setChecked(!e)}
                        ref={register}
                    />
                    <LabeledTextInput label="Ulice 1. řádek" type="text" name="contactLocation.streetAddressFirstLine"
                    defaultValue={data.contactInformation?.contactLocation.streetAddressFirstLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.contactLocation?.streetAddressFirstLine}
                    errorMessage={errors.contactLocation?.streetAddressFirstLine?.message}
                    ref={register}
                    disable={checked}
                    />
                    <LabeledTextInput label="Ulice 2. řádek" type="text" name="contactLocation.streetAddressSecondLine"
                    defaultValue={data.contactInformation?.contactLocation.streetAddressSecondLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.contactLocation?.streetAddressSecondLine}
                    errorMessage={errors.contactLocation?.streetAddressSecondLine?.message}
                    ref={register}
                    disable={checked}
                    />
                    <LabeledTextInput label="PSČ" type="text" name="contactLocation.postalCode"
                    defaultValue={data.contactInformation?.contactLocation.postalCode || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.contactLocation?.postalCode}
                                      isCode
                    errorMessage={errors.contactLocation?.postalCode?.message}
                    ref={register({maxLength: 6, minLength: 6})}
                    disable={checked}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="contactLocation.city"
                    defaultValue={data.contactInformation?.contactLocation.city || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.contactLocation?.city}
                    errorMessage={errors.contactLocation?.city?.message}
                    ref={register}
                    disable={checked}
                    />
                    <LabeledSelectInput label="Země" name="contactLocation.country"
                    placeholder="Vyberte..."
                    valid={!errors.contactLocation?.country}
                    errorMessage={errors.contactLocation?.country?.message}
                    disable={checked}
                    wide
                    options={CountryMapper()}
                    ref={register}
                    />
                    <LabeledTextInput label="Email" type="text" name="contactEmail"
                    defaultValue={data.contactInformation?.email || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.contactEmail}
                    errorMessage={errors.contactEmail?.message}
                    ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="text" name="contactPhoneNumber"
                    defaultValue={data.contactInformation?.phone || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.contactPhoneNumber}
                    errorMessage={errors.contactPhoneNumber?.message}
                    ref={register}
                    />
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput label="Poznámka" type="textarea" name="note"
                    defaultValue={data.note || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.note}
                    errorMessage={errors.note?.message}
                    ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </Container></form>)
};

const Container = styled.div``;

export default EditSection;