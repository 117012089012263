import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import { Route, Switch, useHistory, useParams } from "react-router";
import REInfoSection from "./components/REInfoSection";
import RERenterSection from "./components/RERenterSection";
import REEditSection from "./components/REEditSection";
import fetchRealEstate from "../api/fetchRealEstate";
import toast from "react-hot-toast";
import { GetRETypeIcon, GetRETypeLabel } from "../../../enums/RealEstateEnums";
import REEstimationSection from "./components/REEstimationSection";
import REEditEstimationSection from "./components/REEditEstimationSection";

const REDetailPage = () => {
    const { slug }: {slug: string} = useParams();
    const [loading, setLoading] = useState<boolean>(true); 
    const [data, setData] = useState<any>({});
    const history = useHistory();

    const fetchData = async () => {
        setLoading(true);
        const response = await fetchRealEstate(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nemovitost se nepodařilo načíst.")
        }
    };

    const getCategoryButtons = useCallback(() => {
        const defaultButtons = [
            {
                text: "Přehled",
                route: Routes.REAL_ESTATE_DETAIL,
                index: slug,
                exact: true,
            },
            {
                text: "Odhad",
                route: Routes.REAL_ESTATE_ESTIMATION_DETAIL,
                index: slug,
                exact: true,
            }
        ];

         if(!data.general.isRentable)
             return defaultButtons;

         return [
             ...defaultButtons,
             {
                text: "Nájemníci",
                route: Routes.REAL_ESTATE_DETAIL_RENTERS,
                index: slug,
                exact: true,
            }
        ];
    }, [data, loading]);

    useEffect(() => {
        fetchData();
    }, [])
    return (<>
        {(!history.location.pathname.includes("detail") && !loading) && (<Container>
            <PageHeader title={`${data.location?.city}, ${GetRETypeLabel(data.general?.type)}`}
            subtitle={`Nemovitosti   —   ${data.location?.city}, ${GetRETypeLabel(data.general?.type)}`}
            returnPath={Routes.REAL_ESTATE}
            image={GetRETypeIcon(data.general?.type)}/>
            <CategoryButtons buttons={getCategoryButtons()}/>
            <ContentWrapper> 
                <Switch>
                    <Route path={Routes.REAL_ESTATE_DETAIL} render={() => <REInfoSection data={data}/>} exact/>
                    <Route path={Routes.REAL_ESTATE_DETAIL_EDIT} render={() => <REEditSection data={data} onEdit={fetchData}/>} exact/>
                    {data.general.isRentable && <Route path={Routes.REAL_ESTATE_DETAIL_RENTERS} component={RERenterSection} exact/> }
                    <Route path={Routes.REAL_ESTATE_ESTIMATION_DETAIL} component={REEstimationSection} exact/>
                    <Route path={Routes.REAL_ESTATE_ESTIMATION_DETAIL_EDIT} render={() => <REEditEstimationSection onEdit={fetchData}/>} exact/>
                </Switch>
            </ContentWrapper>
        </Container>)}
    </>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const ContentWrapper = styled.div`
    margin-top: 15px;
    padding: 24px;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
`;

export default REDetailPage;