import React, {ReactElement} from "react";
import {ReactComponent as ICowboyIcon} from "../resources/icons/user-cowboy-light.svg";
import {ReactComponent as IUserIcon} from "../resources/icons/user-light.svg";

export enum InvestorType {
    MainInvestor = 1,
    CoInvestor = 2
}

export const GetInvestorTypeLabel = (type: InvestorType): string => {
    switch (type){
        case InvestorType.MainInvestor: return "Investor";
        case InvestorType.CoInvestor: return "Co-Investor";
        default: return "Nenastaveno";
    }
};

export const GetInvestorTypes = (): Array<any> => {
    return [
        {
            id: InvestorType.CoInvestor,
            label: GetInvestorTypeLabel(InvestorType.CoInvestor),
            icon: <IUserIcon/>
        },
        {
            id: InvestorType.MainInvestor,
            label: GetInvestorTypeLabel(InvestorType.MainInvestor),
            icon: <ICowboyIcon/>
        },
    ]
};