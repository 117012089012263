import { getAccessToken } from '../../../../contexts/UserIdentityContext';
const uploadProviderImage = async (id: number | string, image: any) => {
    const formData = new FormData();
    formData.append("image", image);
    const response = await fetch(`/api/v1/agents/${id}/image`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: formData,
    });
    return response;
};

export default uploadProviderImage;