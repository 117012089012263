import styled from "styled-components";
import {ReactComponent as HChevronLeftIcon} from "../../../../resources/icons/chevron-left.svg";
import {ReactComponent as HChevronRightIcon} from "../../../../resources/icons/chevron-right.svg";
import {ReactComponent as HChevronDoubleLeftIcon} from "../../../../resources/icons/chevron-double-left.svg";
import {ReactComponent as HChevronDoubleRightIcon} from "../../../../resources/icons/chevron-double-right.svg";

export const ButtonWrapper = styled.button<{ disabled: boolean; }>`
    font-weight: 700;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray};
    width: 36px;
    height: 36px;
    margin-left: 12px;
`;

export const ChevronLeftIcon = styled(HChevronLeftIcon)<{ disabled: boolean }>`
    width: 14px;
    height: 14px;
    fill: ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray};
    position: relative;
    top: 1.5px;
`;

export const ChevronRightIcon = styled(HChevronRightIcon)<{ disabled: boolean }>`
    width: 14px;
    height: 14px;
    fill: ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray};
    position: relative;
    top: 1.5px;
`;

export const ChevronDoubleLeftIcon = styled(HChevronDoubleLeftIcon)<{ disabled: boolean }>`
    width: 14px;
    height: 14px;
    fill: ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray};
    position: relative;
    top: 1.5px;
`;

export const ChevronDoubleRightIcon = styled(HChevronDoubleRightIcon)<{ disabled: boolean }>`
    width: 14px;
    height: 14px;
    fill: ${props => props.disabled ? props.theme.colors.gray : props.theme.colors.darkGray};
    position: relative;
    top: 1.5px;
`;

export const PagesWrapper = styled.div`
    display: flex;
    align-items: center;
`;