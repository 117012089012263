import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import {Routes} from "../../../router/Routes";
import Strip from "../../../components/shared/Strip";
import { useParams, Route, Switch, useHistory } from "react-router";
import CategoryButtons from "../../../components/shared/CategoryButtons";

import OverviewSection from "./components/OverviewSection";
import AnalyticsSection from "./components/AnalyticsSection";
import EditAnalyticsSection from "./components/EditAnalyticsSection";
import ClientSection from "./components/ClientSection";
import ClientEditSection from "./components/ClientEditSection";
import InvestorsSection from "./components/InvestorsSection";
import SecuredSection from "./components/SecuredSection";
import DocumentsSection from "./components/DocumentsSection";
import TeamSection from "./components/TeamSection";
import InvestmentLogsSection from "./components/InvestmentLogsSection";
import TasksSection from "./components/TasksSection";
import EditSection from "./components/EditSection";
import TaskDetailPage from "../task-detail/TaskDetailPage";
import EditTaskDetailPage from "../task-detail/EditTaskPage";
import fetchProject from "../api/project/fetchProject";
import fetchProjectClient from "../api/project/fetchProjectClient";
import toast from "react-hot-toast";

const ProjectDetailPage = () => {
    const history = useHistory();
    const {slug}: {slug: string} = useParams();
    const [data, setData] = useState<any>({});
    const [clientData, setClientData] = useState<any>({});
    const [analyticsData, setAnalyticsData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const fetchProjectData = async () => {
        const response = await fetchProject(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se tento projekt načíst.")
        }
    };
    const fetchClientData = async () => {
        const response = await fetchProjectClient(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setClientData(fetchedData.result);
        } else {
            toast.error("Nepodařilo se načíst klienta tohoto projektu.")
        }
    };
    const fetchAnalyticsData = async () => {
        const response = await fetchProjectClient(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setAnalyticsData(fetchedData.result);
        } else {
            toast.error("Nepodařilo se načíst klienta tohoto projektu.")
        }
    };
    useEffect(() => {
        fetchProjectData();
        fetchClientData();
        fetchAnalyticsData();
    }, []);
    return (<Container>
        {!loading && <>{!history.location.pathname.includes("ukol/") && <>
            <PageHeader title={data.generalInformation?.internalName || "Nenastaveno"} subtitle={`Projekty   —   Přehled   —   ${data.generalInformation?.internalName || "Nenastaveno"}`}
        returnPath={Routes.PROJECTS}
        noContainerBottomMargin={history.location.pathname.includes("analytika/upravit")}
        strip={<Strip color="green"/>}/>
        {!history.location.pathname.includes("/upravit") && <CategoryButtons buttons={[
            {
                text: "Přehled",
                route: Routes.PROJECT_DETAIL,
                index: slug,
                exact: true,
            },
            {
                text: "Analytika",
                route: Routes.PROJECT_DETAIL_ANALYTICS,
                index: slug,
                exact: true,
            },
            {
                text: "Klient",
                route: Routes.PROJECT_DETAIL_CLIENT,
                index: slug,
                exact: true,
            },
            {
                text: "Investoři",
                route: Routes.PROJECT_DETAIL_INVESTORS,
                index: slug,
                exact: true,
            },
            {
                text: "Zajištění",
                route: Routes.PROJECT_DETAIL_SECURED,
                index: slug,
                exact: true,
            },
            {
                text: "Dokumenty",
                route: Routes.PROJECT_DETAIL_DOCUMENTS,
                index: slug,
                exact: true,
            },
            {
                text: "Tým",
                route: Routes.PROJECT_DETAIL_TEAM,
                index: slug,
                exact: true,
            },
            {
                text: "Investiční zápisy",
                route: Routes.PROJECT_DETAIL_INVESTMENT_LOGS,
                index: slug,
                exact: true,
            },
            {
                text: "Úkoly",
                route: Routes.PROJECT_DETAIL_TASKS,
                index: slug,
                exact: true,
            },
        ]}/>}</>}
        <Switch>
            <Route path={Routes.PROJECT_DETAIL} render={() => <OverviewSection data={data} refetch={fetchProjectData}/>} exact/>
            <Route path={Routes.PROJECT_DETAIL_ANALYTICS} component={AnalyticsSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_ANALYTICS_EDIT} render={() => <EditAnalyticsSection data={analyticsData} onEdit={fetchAnalyticsData}/>} exact/>
            <Route path={Routes.PROJECT_DETAIL_CLIENT} render={() => <ClientSection data={clientData}/>} exact/>
            <Route path={Routes.PROJECT_DETAIL_CLIENT_EDIT} render={() => <ClientEditSection data={clientData} onEdit={fetchClientData}/>} exact/>
            <Route path={Routes.PROJECT_DETAIL_INVESTORS} component={InvestorsSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_DOCUMENTS} component={DocumentsSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_SECURED} component={SecuredSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_TEAM} component={TeamSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_INVESTMENT_LOGS} component={InvestmentLogsSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_TASKS} component={TasksSection} exact/>
            <Route path={Routes.PROJECT_DETAIL_EDIT} render={() => <EditSection data={data} onEdit={fetchProjectData} />} exact/>
            <Route path={Routes.PROJECT_TASK} component={TaskDetailPage} exact/>
            <Route path={Routes.PROJECT_TASK_EDIT} component={EditTaskDetailPage} exact/>
        </Switch></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

export default ProjectDetailPage;