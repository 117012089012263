import React, { useContext, useEffect, useState } from "react";
import { ModalContext, UtilityButtonsContext } from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IFileIcon} from "../../resources/icons/file.svg";
import {ReactComponent as IUserIcon} from "../../resources/icons/user.svg";
import {ReactComponent as ISizeIcon} from "../../resources/icons/file.svg";
import {ReactComponent as IFolderIcon} from "../../resources/icons/folder-open.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import CreateDocumentModal from "./CreateDocumentModal";
import styled from "styled-components";
import {HighlightedText, TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import ProfileColumn from "../../components/table/components/ProfileColumn";
import ColoredText from "../../components/table/components/ColoredText";
import DoubleLineValue from "../../components/table/components/DoubleLineValue";
import fetchDocuments from "./api/fetchDocuments";
import {ReactComponent as IPDFIcon} from "../../resources/icons/file-pdf.svg";
import {GetDocumentCategoryTypeLabel} from "../../enums/DocumentEnums";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import SearchBar from "../../components/shared/inputs/SearchBar";
import Pagination from "../../components/pagination/Pagination";
import FilterSelectInput from "../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../components/shared/filters/FilterDateInput";
import FilterRangeInput from "../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import useFilters from "../../components/methods/useFilters";

const DocumentsOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    
    const [filters, changeFilter] = useFilters({
        author: "",
        type: "",
        category: "",
        sizeFrom: "", sizeTo: ""
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit dokument"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateDocumentModal/>)
                }}
            />
        </>)
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchDocuments(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst dokumenty.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
         <TableContainer>
            <FilterWrap maxWidth={"50%"}>
            <SearchBar name={"documentsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            
            <FilterSelectInput
                label={`Kategorie`}
                onChange={(filters: string) => changeFilter("category", filters)}
                icon={<IFolderIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Typ`}
                onChange={(filters: string) => changeFilter("type", filters)}
                icon={<IFileIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Autor`}
                onChange={(filters: string) => changeFilter("author", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterRangeInput
                label={`Velikost v kB`}
                icon={<ISizeIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["sizeFrom", "sizeTo"], [values.from, values.to])}
            />
            </FilterWrap>
            <Table
            headers={[
                {key: "docName", value: "NÁZEV", sortable: false},
                {key: "docCategory", value: "KATEGORIE", sortable: true},
                {key: "docType", value: "TYP", sortable: false},
                {key: "fileSize", value: "VELIKOST", sortable: false},
                {key: "uploader", value: "NAHRÁL", sortable: true},
                {key: "dateOfUpload", value: "DATUM NAHRÁNÍ", sortable: true},
            ]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            sizes={["12px"]}
            body={mapper(loading ? [] : (data.documents ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.documents?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </Container>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            docName: {value: (<ProfileColumn icon={
                <IPDFIcon/>
            } title={
                o.internalName
            } subtitle={
                <ColoredText text={o.fileName} color={"grayBlue"} semibold/>
            }/>)},
            docCategory: {value: GetDocumentCategoryTypeLabel(o.category)},
            docType: {value: (<RegularBold>{o.contentType}</RegularBold>)},
            fileSize: {value: (<RegularBold>{o.size} kB</RegularBold>)},
            uploader: {value: (<a>{o.createdByName}</a>)},
            dateOfUpload: {value: (<DoubleLineValue firLine={
                (<RegularBold>{dayjs(o.createdOn).format("DD.MM.YYYY")}</RegularBold>)
            } secLine={
                <ColoredText text={dayjs(o.createdOn).format("HH:mm")} color={"grayBlue"}/>
            }/>)},
            link: `${Routes.DOCUMENT_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default DocumentsOverviewPage;