import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {useHistory, useParams} from "react-router";
import {UtilityButtonsContext} from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../../resources/icons/floppy-disk.svg";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as ICoinsIcon} from "../../../../resources/icons/coins.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import {Routes} from "../../../../router/Routes";
import {useForm} from "react-hook-form";
import StyledCheckboxInput from "../../../../components/shared/inputs/StyledCheckboxInput";
import {GetInvestorTypes} from "../../../../enums/InvestorEnums";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import toast from "react-hot-toast";
import editInvestor from "../../api/investors/editInvestor";
import {formatPostalCode, formatPrice} from "../../../../shared/utils/format";

const EditSection = ({data, onEdit}: any) => {
    const {register, errors, handleSubmit, watch} = useForm();
    const [checked, setChecked] = useState<boolean>(false);
    const {slug}: { slug: string } = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    const billingPostalCode = watch('billingLocation.postalCode');
    const contactPostalCode = watch('contactLocation.postalCode');
    const availableFunds = watch('availableFunds');

    const onSubmit = async (data: any) => {
        data.investorId = parseInt(slug);
        data.investorType = parseInt(data.investorType);
        data.availableFunds = parseInt(data.availableFunds);
        data.interestRate = parseInt(data.interestRate);
        if (data.contactIsIdenticalAsBilling) data.contactLocation = {...data.billingLocation};
        const response = await editInvestor(data);
        if (response.status == 200) {
            const fetchedData = await response.json();
            onEdit();
            toast.success("Investor upraven.")
        } else {
            toast.error("Investora se nepodařilo upravit.")
        }
        ;
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Zrušit"
                color="gray"
                icon={<ICrossIcon/>}
                onClick={() => history.push(Routes.PROJECT_INVESTOR_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
                title="Uložit"
                color="green"
                icon={<IDiskIcon/>}
                onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);
    return (<form><Container>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Fakturační údaje" icon={<IBuildingIcon/>}>
                    <LabeledSelectInput label="Typ investora" name="investorType"
                                        placeholder="Vyberte..."
                                        valid={!errors.investorType}
                                        disable
                                        errorMessage={errors.investorType?.message}
                                        defaultValue={data.investor?.investorType}
                                        options={GetInvestorTypes()}
                                        ref={register}
                    />
                    <LabeledTextInput label="Název" type="text" name="investorName"
                                      defaultValue={data.investor?.investorName ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.investorName}
                                      errorMessage={errors.investorName?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="IČO" type="text" name="identificationNumber"
                                      defaultValue={data.investor?.identificationNumber ?? ""}
                                      placeholder="Číslo..."
                                      wide
                                      valid={!errors.identificationNumber}
                                      errorMessage={errors.identificationNumber?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Ulice 1. řádek" type="text" name="billingLocation.streetAddressFirstLine"
                                      defaultValue={data.investor?.billingLocation?.streetAddressFirstLine ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.billingLocation?.streetAddressFirstLine}
                                      errorMessage={errors.billingLocation?.streetAddressFirstLine?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Ulice 2. řádek" type="text" name="billingLocation.streetAddressSecondLine"
                                      defaultValue={data.investor?.billingLocation?.streetAddressSecondLine ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.billingLocation?.streetAddressSecondLine}
                                      errorMessage={errors.billingLocation?.streetAddressSecondLine?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="PSČ" type="text" name="billingLocation.postalCode"
                                      defaultValue={data.investor?.billingLocation?.postalCode ?? ""}
                                      placeholder="Číslo..."
                                      wide
                                      isCode
                                      valid={!errors.billingLocation?.postalCode}
                                      errorMessage={errors.billingLocation?.postalCode?.message}
                                      ref={register({maxLength: 6, minLength: 6})}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="billingLocation.city"
                                      defaultValue={data.investor?.billingLocation?.city ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.billingLocation?.city}
                                      errorMessage={errors.billingLocation?.city?.message}
                                      ref={register}
                    />
                    <LabeledSelectInput label="Země" name="billingLocation.country"
                                        placeholder="Vyberte..."
                                        wide
                                        valid={!errors.billingLocation?.country}
                                        errorMessage={errors.billingLocation?.country?.message}
                                        defaultValue={GetCountryByValue(data.investor?.billingLocation?.country)?.id}
                                        options={CountryMapper()}
                                        ref={register}
                    />
                </InfoBox>
                <InfoBox title="Kontaktní osoba" icon={<IUserIcon/>}>
                    <LabeledTextInput label="Jméno" type="text" name="firstName"
                                      defaultValue={data.investor?.firstName ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.firstName}
                                      errorMessage={errors.firstName?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Příjmení" type="text" name="lastName"
                                      defaultValue={data.investor?.lastName ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.lastName}
                                      errorMessage={errors.lastName?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Email" type="text" name="email"
                                      defaultValue={data.investor?.email ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.email}
                                      errorMessage={errors.email?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="text" name="phoneNumber"
                                      defaultValue={data.investor?.phoneNumber ?? ""}
                                      placeholder="Číslo..."
                                      wide
                                      valid={!errors.phoneNumber}
                                      errorMessage={errors.phoneNumber?.message}
                                      ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                    <StyledCheckboxInput
                        name="contactIsIdenticalAsBilling"
                        label="Kontaktní adresa je shodná s fakturační"
                        isSolo
                        onChange={e => setChecked(!e)}
                        ref={register}
                    />
                    <LabeledTextInput label="Ulice 1. řádek" type="text" name="contactLocation.streetAddressFirstLine"
                                      defaultValue={data.investor?.contactLocation.streetAddressFirstLine ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.contactLocation?.streetAddressFirstLine}
                                      errorMessage={errors.contactLocation?.streetAddressFirstLine?.message}
                                      ref={register}
                                      disable={checked}
                    />
                    <LabeledTextInput label="Ulice 2. řádek" type="text" name="contactLocation.streetAddressSecondLine"
                                      defaultValue={data.investor?.contactLocation.streetAddressSecondLine ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.contactLocation?.streetAddressSecondLine}
                                      errorMessage={errors.contactLocation?.streetAddressSecondLine?.message}
                                      ref={register}
                                      disable={checked}
                    />
                    <LabeledTextInput label="PSČ" type="text" name="contactLocation.postalCode"
                                      defaultValue={data.investor?.contactLocation.postalCode ?? ""}
                                      placeholder="Číslo..."
                                      wide
                                      valid={!errors.contactLocation?.postalCode}
                                      isCode
                                      errorMessage={errors.contactLocation?.postalCode?.message}
                                      ref={register({maxLength: 6, minLength: 6})}
                                      disable={checked}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="contactLocation.city"
                                      defaultValue={data.investor?.contactLocation.city ?? ""}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.contactLocation?.city}
                                      errorMessage={errors.contactLocation?.city?.message}
                                      ref={register}
                                      disable={checked}
                    />
                    <LabeledSelectInput label="Země" name="contactLocation.country"
                                        placeholder="Vyberte..."
                                        valid={!errors.contactLocation?.country}
                                        errorMessage={errors.contactLocation?.country?.message}
                                        disable={checked}
                                        wide
                                        defaultValue={GetCountryByValue(data.investor?.contactLocation?.country)?.id}
                                        options={CountryMapper()}
                                        ref={register}
                    />
                    <LabeledTextInput label="Email" type="text" name="contactEmail"
                                      defaultValue={data.investor?.contactEmail}
                                      placeholder="Text..."
                                      wide
                                      valid={!errors.contactEmail}
                                      errorMessage={errors.contactEmail?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="number" name="contactPhoneNumber"
                                      defaultValue={data.investor?.contactPhoneNumber}
                                      placeholder="Číslo..."
                                      wide
                                      valid={!errors.contactPhoneNumber}
                                      errorMessage={errors.contactPhoneNumber?.message}
                                      ref={register}
                    />
                </InfoBox>
                <InfoBox title="Finanční údaje" icon={<ICoinsIcon/>}>
                    <LabeledTextInput label="Volné prostředky" type="text" name="availableFunds"
                                      defaultValue={data.investor?.availableFunds ?? ""}
                                      placeholder="Číslo v Kč..."
                                      wide
                                      valid={!errors.availableFunds}
                                      isPrice
                                      errorMessage={errors.availableFunds?.message}
                                      ref={register}
                    />
                    <LabeledTextInput label="Úroková sazba p.a." type="number" name="interestRate"
                                      defaultValue={data.investor?.interestRate ?? ""}
                                      placeholder="Číslo v %..."
                                      wide
                                      valid={!errors.interestRate}
                                      errorMessage={errors.interestRate?.message}
                                      ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </Container></form>)
};

const Container = styled.div``;

export default EditSection;