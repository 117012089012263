import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as ICalendarIcon} from "../../resources/icons/calendar-exclamation.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import {useHistory} from "react-router-dom";
import { useParams } from "react-router";
import {Routes} from "../../router/Routes";
import { ModalContext } from "../../components/layout/Layout";
import toast from "react-hot-toast";
import createInvestmentLog from "./api/investment-logs/createInvestmentLog";

interface CreateInvestmentLogModalInterface {
    defaultValue?: string;
    onCreate?(data: any): void;
}

const CreateInvestmentLogModal = ({defaultValue, onCreate}: CreateInvestmentLogModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const {slug}: any = useParams();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        if(defaultValue == undefined) {
            data.projectId = parseInt(slug);
            const response = await createInvestmentLog(data);
            if(response.status == 200) {
                const fetchedData = await response.json();
                history.push(Routes.PROJECT_INVESTMENT_LOG_DETAIL.replace(":slug", slug).replace(":secondslug", fetchedData.result))
                modal.closeCurrentModal();
                toast.success("Investiční zápis vytvořen.");
            } else {
                toast.error("Investiční zápis se nepodařilo vytvořit.");
            }
        } else {
            data.id = 0;
            if(onCreate) onCreate(data);
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<ICalendarIcon/>}
        title={`${defaultValue == undefined ? "Vytvořit" : "Upravit"} zápis`}
        >
        <ModalInputRow>
            <LabeledTextInput name="logDate" label="Datum zápisu" type="date"
            placeholder="Vyberte..."
            defaultValue={defaultValue == undefined ? "" : defaultValue}
            valid={!errors.logDate}
            errorMessage={errors.logDate?.message}
            ref={register({required: {value: true, message: "Datum zápisu je povinné."}})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

export default CreateInvestmentLogModal;