import React, {useCallback, useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {ModalContext, UtilityButtonsContext} from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import {TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import SearchBar from "../../components/shared/inputs/SearchBar";
import Pagination from "../../components/pagination/Pagination";
import fetchAppraisers from "./api/fetchAppraisers";
import toast from "react-hot-toast";
import CreateRealEstateAppraiserModal from "./CreateRealEstateAppraiserModal";
import {getPhotoFromCdn} from "../../shared/api/photoApi";
//import dayjs from "dayjs";

const REAppraisersOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");

    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await fetchAppraisers(pageNum, pageSize, search, sortId, sortOrder);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst odhadce.")
        }
    }, [pageNum, pageSize, search, sortId, sortOrder]);

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit odhadce"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateRealEstateAppraiserModal onCreate={() => fetchData()}/>);
                }}
            />
        </>);
        fetchData();
    }, [])

    return (<Container>
        <TableContainer>
            <FilterWrap>
                <SearchBar name={"appraisersOverview"} placeholder="Hledat..."
                           onSearch={(search: string) => setSearch(search)}/>
            </FilterWrap>
            <Table headers={[
                {key: "name", value: "JMÉNO", sortable: true},
                {key: "range", value: "ROZSAH", sortable: true},
                {key: "prizedItems", value: "OCENĚNÉ VĚCI", sortable: true}
            ]}
                   sizes={["12px"]}
                   sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                   onSortChange={(filter: string, filterOrder: string) => {
                       setSortId(filter);
                       setSortOrder(filterOrder)
                   }}
                   body={mapper(loading ? [] : (data ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </Container>)
};

const Container = styled.div``;

const Regular = styled.b`
  font-weight: 400;
`;

const Bold = styled.b`
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-left: 40px;
  position: relative;
`;

const Image = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 0;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            name: {value: (<Bold><Image src={getPhotoFromCdn("")}/>{o.name}</Bold>)},
            range: {value: (<Regular>{o.range}</Regular>)},
            prizedItems: {value: (<Regular>{o.prizedItems}</Regular>)},
            link: `${Routes.APPRAISER_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default REAppraisersOverviewPage;