import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const fetchInvestmentLog = async (projectId: number | string, logId: number | string) => {
    const response = await fetch(`/api/v1/projects/${projectId}/investmentlogs/${logId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchInvestmentLog;