import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const deleteTransaction = async (id: string | number) => {
    const response = await fetch(`/api/v1/finances/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default deleteTransaction;