import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import ColoredText from "../../../../components/table/components/ColoredText";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import CreateInvestmentLogModal from "../../CreateInvestmentLogModal";
import fetchInvestmentLogs from "../../api/investment-logs/fetchInvestmentLogs";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterDateInput from "../../../../components/shared/filters/FilterDateInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as ICalendarIcon} from "../../../../resources/icons/calendar.svg";
import {ReactComponent as ICExclamationIcon} from "../../../../resources/icons/calendar-exclamation.svg";
import deleteInvestmentLog from "../../api/investment-logs/deleteInvestmentLog";
import editInvestmentLog from "../../api/investment-logs/editInvestmentLog";
import useFilters from "../../../../components/methods/useFilters";

const InvestmentLogsSection = () => {
    const {slug}: {slug: string} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        author: "",
        updateDate: "",
        creationDate: "",
        includeTermsFrom: "", includeTermsTo: "",
    });
    
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export zápisů")}/>
            <UtilityButton
            title="Vytvořit zápis"
            color="blue"
            icon={<IPlusIcon/>}
            onClick={() => {
                modal.openModal(<CreateInvestmentLogModal/>)
            }}/>
        </>)
    }, []);
    
    const fetchData = async () => {
        const response = await fetchInvestmentLogs(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Investiční zápisy se nepovedlo načíst.")
        }
    };
    useEffect(() => {
        fetchData();
    }, [pageNum,pageSize, search, sortId, sortOrder, filters]);
    return (<SectionContainer>
         <TableContainer>
            <FilterWrap maxWidth={"55%"}>
            <SearchBar name={"investmentLogsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Autor`}
                onChange={(filters: any) => changeFilter("author", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterDateInput
                label={`Datum zápisu`}
                onChange={(filters: any) => changeFilter(["creationDate"], [filters])}
                icon={<ICalendarIcon/>}
            />
            <FilterDateInput
                label={`Datum aktualizace`}
                onChange={(filters: any) => changeFilter(["updateDate"], [filters])}
                icon={<ICalendarIcon/>}
            />
            <FilterRangeInput
                label={`Obsahuje termínů`}
                icon={<ICExclamationIcon/>}
                dateRange={false}
                onChange={(filters: any) => changeFilter(["includeTermsFrom", "includeTermsTo"], [filters.from, filters.to])}
            />
            </FilterWrap>
            <Table headers={[
                {key: "date", value: "DATUM", sortable: true},
                {key: "author", value: "AUTOR", sortable: false},
                {key: "tasks", value: "ÚKOLY", sortable: true},
                {key: "completedTasks", value: "SPLNĚNÝCH", sortable: true},
                {key: "lateTasks", value: "ZPOŽDĚNÝCH", sortable: true},
                {key: "creationDate", value: "VYTVOŘENO", sortable: true},
                {key: "lastUpdate", value: "POSLEDNÍ AKTUALIZACE", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(modal, slug, loading ? [] : (data.investmentLogs ?? []), () => fetchData())}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.investmentLogs?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (modal: any, projectId: number | string, items: Array<any>, onAction: Function): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            id: o.id,
            date: {value: (<b>{dayjs(o.logDate).format("DD.MM.YYYY")}</b>)},
            author: {value: (<a>{o.createdByName}</a>), link: Routes.USER_DETAIL.replace(":slug", o.createdById)},
            tasks: {value: (<RegularBold>{o.tasksCount}</RegularBold>)},
            completedTasks: {value: (<ColoredText text={o.completedTasksCount} color={"stripGreen"}/>)},
            lateTasks: {value: (<ColoredText text={o.delayedTasksCount} color={"red"}/>)},
            creationDate: {value: (<DoubleLineValue firLine={
                <RegularBold>{dayjs(o.createdOn).format("DD.MM.YYYY")}</RegularBold>
            } secLine={
                <ColoredText text={dayjs(o.createdOn).format("HH:mm")} color={"grayBlue"} semibold/>
            }/>)},
            lastUpdate: {value: (<DoubleLineValue firLine={
                o.lastModifiedOn ? <RegularBold>{dayjs(o.lastModifiedOn).format("DD.MM.YYYY")}</RegularBold> : ""
            } secLine={
                <ColoredText text={o.lastModifiedOn ? dayjs(o.lastModifiedOn).format("HH:mm") : ""} color={"grayBlue"} semibold/>
            }/>)},
            buttons: [
                {
                    label: "Upravit",
                    action: () => {
                        modal.setContent(<CreateInvestmentLogModal defaultValue={o.logDate} onCreate={async (data: any) => {
                            const response = await editInvestmentLog(projectId, o.id, data);
                            if(response.status == 200) {
                                const fetchedData = await response.json();
                                onAction();
                                modal.setOpen(false);
                                toast.success("Investiční zápis upraven.");
                            } else {
                                toast.error("Investiční zápis se nepodařilo upravit.");
                            }
                        }}/>);
                        modal.setOpen(true);
                    },
                },
                {
                    label: "Smazat",
                    action: async () => {
                        if(window.confirm("Přejete si smazat investiční zápis?")) {
                            const response = await deleteInvestmentLog(projectId, o.id);
                            if(response.status == 200) {
                                const fetchedData = await response.json();
                                onAction();
                                toast.success("Investiční zápis smazán.");
                            } else {
                                toast.error("Investiční zápis se nepodařilo smazat.");
                            }
                        }
                    },
                },
            ],
            link: `${Routes.PROJECT_INVESTMENT_LOG_DETAIL.replace(":slug", projectId.toString()).replace(":secondslug", o.id.toString())}`,
        });
    });
    return result;
}

export default InvestmentLogsSection;