import { getAccessToken } from "../../../../contexts/UserIdentityContext";

const fetchProvider = async (id: string) => {
    const response = await fetch(`/api/v1/agents/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchProvider;