import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import dayjs from "dayjs";

const ClientSection = ({data}: any) => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => console.log("smazat projekt")}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export user")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL_CLIENT_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Osobné údaje" icon={<IBuildingIcon/>}>
                    <InfoBoxItem title="Jméno a příjmení" text={<b>{`${data.personal?.firstName} ${data.personal?.lastName}` || ""}</b>} />
                    <InfoBoxItem title="Číslo občanského průkazu" text={data.personal?.identificationCardNumber || ""} />
                    <InfoBoxItem title="Rodné číslo" text={data.personal?.personalIdentificationNumber || ""} />
                </InfoBox>
                <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                    <InfoBoxItem title="Ulice 1. řádek" text={data.contact?.location.streetAddressFirstLine || ""}/>
                    <InfoBoxItem title="Ulice 2. řádek" text={data.contact?.location.streetAddressSecondLine || ""}/>
                    <InfoBoxItem title="PSČ" text={data.contact?.location.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') || ""}/>
                    <InfoBoxItem title="Obec/Město" text={data.contact?.location.city || ""}/>
                    <InfoBoxItem title="Země" text={data.contact?.location.country || ""}/>
                    <InfoBoxItem title="Email" text={<a href={`mailto:${data.contact?.email}`}>{data.contact?.email}</a>}/>
                    <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contact?.phone}`}>{data.contact?.phone}</a>}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Investorem od" text={""}/>
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer>)
};

export default ClientSection;