import styled from "styled-components";

export const InfoBoxFullContainer = styled.div`
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 0 36px;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    padding: 0 12px;
  }
`;

export const InfoBoxColumn = styled.div`
  flex: 1 1 max(400px, calc(50% - 56px));;
  
  display: flex;
  flex-direction: column;
`

export const InfoBoxWrapper = styled.div`
  margin-top: 28px;
  border: 1px solid ${props => props.theme.colors.lighterGray};
  border-radius: 4px;
`;

export const InfoBoxHeader = styled.div`
  background-color: ${props => props.theme.colors.lightGray};
  border-bottom: 1px solid ${props => props.theme.colors.lighterGray};
  padding: 12px 36px;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.black};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 12px;
  }
`;

export const InfoBoxBody = styled.div`
  padding: 24px 36px;
  font-size: 16px;
  color: ${({theme}) => theme.colors.black};
  @media screen and (max-width: 600px) {
    padding: 12px 12px;
  }
`;

export const InfoBoxContent = styled.div`
    font-weight: 500;
  font-size: 16px;
    line-height: 24px;
`;