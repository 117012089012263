import FilterParameters from '../../../../components/methods/FilterParameters';
import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const fetchMyTasks = async (pageNum: number, pageSize: number, search: string, sortId: string, sortOrder: string, filters: Object) => {
    const response = await fetch(`/api/v1/tasks/my?${FilterParameters(filters)}${sortId && `sorting=${sortId}&sortingorder=${sortOrder}&`}pageNumber=${pageNum}&pageSize=${pageSize}&search=${search}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
    });
    return response
};

export default fetchMyTasks;