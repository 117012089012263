import React, {useContext} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IUserIcon} from "../../resources/icons/user.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {useForm} from "react-hook-form";

import {useHistory} from "react-router-dom";
import {Routes} from "../../router/Routes";
import createUser from "./api/createUser";
import {ModalContext} from "../../components/layout/Layout";
import toast from "react-hot-toast";

const CreateUserModal = (props: any) => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        const response = await createUser(data);
        if (response.status == 200) {
            const data = await response.json();
            const newUserId = data.result;
            toast.success("Uživatel vytvořen.");
            modal.closeCurrentModal()
            history.push(Routes.USER_DETAIL.replace(":slug", newUserId));
        } else {
            toast.error("Při tvorbě uživatele nastala chyba!");
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
            cancelModal={() => modal.closeCurrentModal()}
            icon={<IUserIcon/>}
            title="Vytvořit uživatele"
        >
            <ModalInputRow>
                <LabeledTextInput
                    label='Jméno'
                    type="text"
                    name="firstName"
                    valid={!errors.firstName}
                    errorMessage={errors.firstName?.message}
                    placeholder="Jméno..."
                    defaultValue=""
                    ref={register}
                />
                <LabeledTextInput
                    label='Příjmení'
                    type="text"
                    name="lastName"
                    valid={!errors.lastName}
                    errorMessage={errors.lastName?.message}
                    placeholder="Příjmení..."
                    defaultValue=""
                    ref={register}
                />
            </ModalInputRow>
            <ModalSubmit type="submit">
                <ICheckIcon/> Dokončit a přidat
            </ModalSubmit>
            <ModalCancel onClick={() => modal.closeCurrentModal()}>
                <ICrossIcon/> Zavřít a nic nedělat
            </ModalCancel>
        </ModalContentWrap>
    </form>)
};

export default CreateUserModal;