import React, {useContext, useEffect, useState} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand-holding-dollar.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalCancel, ModalInputRow, ModalSubmit} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import {useForm} from "react-hook-form";
import {ModalContext} from "../../components/layout/Layout";
import toast from "react-hot-toast";
import {useParams} from "react-router";
import assignProjectInvestor from "./api/project/assignProjectInvestor";
import {fetchSelectInvestors} from "./api/fetchSelects";
import {getPhotoFromCdn} from "../../shared/api/photoApi";
import {GetInvestorTypes, InvestorType} from "../../enums/InvestorEnums";

interface AssignInvestorModalInterface {
    allowInvestor: boolean;
    excludedInvestors: Array<number>;
    onCreate?: () => void;
}

const AssignInvestorModal = ({onCreate, allowInvestor, excludedInvestors = []}: AssignInvestorModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const [selectedInvestorType, setSelectedInvestorType] = useState<number>(allowInvestor ? InvestorType.MainInvestor : InvestorType.CoInvestor);
    const [investors, setInvestors] = useState<any>([]);
    const {slug}: any = useParams();

    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        data.investorId = parseInt(data.investorId);
        data.totalInvested = parseInt(data.totalInvested);
        const response = await assignProjectInvestor(data);
        if (response.status == 200) {
            const fetchedData = await response.json();
            if (onCreate) onCreate();
            modal.closeCurrentModal();
            toast.success("Investor přidán.")
        } else {
            toast.error("Investora se nepodařilo přidat.")
        }
    };

    const fetchInvestors = async (type: InvestorType) => {
        const response = await fetchSelectInvestors(excludedInvestors, type);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setInvestors(fetchedData.result);
        }
    };
    useEffect(() => {
        fetchInvestors(selectedInvestorType);
    }, [selectedInvestorType])
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
            cancelModal={() => modal.closeCurrentModal()}
            icon={<IHandIcon/>}
            title="Přidat investora"
        >
            <ModalInputRow>
                <LabeledSelectInput label="Typ investora" name="investorType"
                                    placeholder="Vyberte..."
                                    valid={true}
                                    defaultValue={allowInvestor ? InvestorType.MainInvestor : InvestorType.CoInvestor}
                                    options={allowInvestor ? GetInvestorTypes() : GetInvestorTypes().filter(t => t.id !== InvestorType.MainInvestor)}
                                    onSelect={(id) => setSelectedInvestorType(id as number)}
                />
            </ModalInputRow>
            <ModalInputRow>
                <PersonSelectorInput name="investorId" label="Investor"
                                     placeholder={investors.length > 0 ? "Vyberte..." : "Investoři nejsou dostupní."}
                                     disable={investors.length <= 0}
                                     valid={!errors.investorId}
                                     errorMessage={errors.investorId?.message}
                                     icon={<ICaretIcon/>}
                                     options={mapInvestorSelect(investors) ?? []}
                                     ref={register({validate: (e) => e != "" ? true : "Investor je povinný."})}/>
            </ModalInputRow>
            <ModalInputRow>
                <LabeledTextInput name="totalInvested" label="Částka investovaná" type="number"
                                  placeholder="Číslo..."
                                  defaultValue=""
                                  wide
                                  valid={!errors.totalInvested}
                                  errorMessage={errors.totalInvested?.message}
                                  ref={register({required: {value: true, message: "Částka je povinná."}})}/>
            </ModalInputRow>
            <ModalInputRow>
                <LabeledTextInput name="investorSince" label="Investorem od" type="date"
                                  placeholder="Vyberte..."
                                  defaultValue=""
                                  wide
                                  valid={!errors.investorSince}
                                  errorMessage={errors.investorSince?.message}
                                  ref={register({required: {value: true, message: "Datum je povinné."}})}/>
            </ModalInputRow>
            <ModalSubmit type="submit">
                <ICheckIcon/> Dokončit a přidat
            </ModalSubmit>
            <ModalCancel onClick={() => modal.closeCurrentModal()}>
                <ICrossIcon/> Zavřít a nic nedělat
            </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const idPad = (num: number, size: number) => String(num).padStart(size, '0');

const mapInvestorSelect = (investors: Array<any>) => {
    let result: Array<any> = [];
    investors.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.firstName} ${o.lastName}`,
        imgUrl: getPhotoFromCdn(o.imageName),
    }))
    return result;
};

export default AssignInvestorModal;