import React, {ReactElement, useEffect, useState} from "react";
import {
    Body,
    Col,
    Header,
    HeaderItem,
    HeaderItemInner,
    Row,
    SortDownIcon,
    SortUpIcon,
    TableWrapper, SortWrapper
} from "./Table.styled";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {ActionButton, BodyInterface, BodyItem, TableInterface} from "./Table.interface";
import Strip from "../shared/Strip";

interface ShowedActionsInterface {
    rowId: number;
    buttons: Array<any>;
}

const Table = ({headers, body, sizes, onSortChange, sortState, notWide}: TableInterface): ReactElement => {
    const history = useHistory();
    const [modifiedHeaders, setModifiedHeaders] = useState<Array<any>>([]);
    const [showedActions, setShowedActions] = useState<ShowedActionsInterface>({ rowId: -1, buttons: [] })

    useEffect(() => {
        setModifiedHeaders((oldArr: any) =>  [...oldArr, {
            key: "state-id",
        }]);
        headers.map((o) => {
            setModifiedHeaders((oldArr: any) =>  [...oldArr, o]);
        });
        setModifiedHeaders((oldArr: any) =>  [...oldArr, {
            key: "buttons",
        }]);
    }, []);

    const idPad = (num: number, size: number) => String(num).padStart(size, '0');
    const renderTableBody = (body: Array<BodyInterface>) => {
        return  body.map((o, index) => {
            return(<Row key={index} onClick={() => {
                if(o.buttons == undefined) {
                    if(o.onClick) {
                        o.onClick(index + 1)
                    }   else {
                        history.push(o.link as string);
                    }
                }}} background={
                    o.tableRowState != "none" &&  o.tableRowState != "green" &&  o.tableRowState != "red" &&
                    (o.stateBackground != undefined && o.tableRowState) 
                } hover={true}>
                    {modifiedHeaders.map(a => {
                        if(a.key != "state-id" && a.key != "buttons") {
                            const item = o[a.key] as BodyItem;
                            const demiseLink = o.buttons != undefined;
                            return (<Col data-key={a.key} key={a.key} onClick={() => {
                                if(item.link && !demiseLink) history.push(item.link)
                                if(demiseLink && !item.link) history.push(o.link as string)
                            }}>
                                {item.value}
                            </Col>);
                        } else if(a.key == "state-id") {
                            return (<Col data-key={a.key} key={a.key} idCol>
                                <AssetWrap>
                                    <IDWrap noPad={o.tableRowState == "none"}>
                                        {idPad(o.id, 2)}
                                    </IDWrap>
                                    {o.tableRowState != "none" && 
                                    o.tableRowState == "red" && <Strip color="red" height={"70px"} left={"-16px"} top={"-18px"} absolute/> ||
                                    o.tableRowState == "green" && <Strip color="green" height="70px" left={"-16px"} top={"-18px"} absolute/> ||
                                    o.tableRowState == "yellow" && <Strip color="yellow" height="70px" left={"-16px"} top={"-18px"} absolute/> ||
                                    o.tableRowState == "gray" && <Strip color="gray" height="70px" left={"-16px"} top={"-18px"} absolute/>}
                                </AssetWrap>
                            </Col>);   
                        } else if(a.key == "buttons" && o[a.key] != undefined) {
                            return (<Col data-key={a.key} key={a.key} onClick={() => {
                                if(showedActions.rowId != index) {
                                    setShowedActions({rowId: index, buttons: o[a.key]});
                                } else {
                                    setShowedActions({rowId: -1, buttons: []});
                                }}}>
                                <ActionWrap>...</ActionWrap>
                                {showedActions.rowId == index && <ActionsSelectWrap>
                                    {showedActions.buttons.map(o => {
                                        return (<Action onClick={() => {setShowedActions({rowId: -1, buttons: []}); o.action()}}>
                                            {o.label}
                                        </Action>)
                                    })}
                                </ActionsSelectWrap>}
                            </Col>);
                    }})}
            </Row>);
        })
    };

    return <TableWrapper notWide={notWide}>
        <Header>
            <tr>
                {modifiedHeaders.map((o, index) => {
                    const isSorted = (o.sortable && sortState && sortState[o.key] !== undefined);
                    return <HeaderItem isSortable={o.sortable == true} fit={sizes[index]} data-key={o.key} key={o.key} onClick={() => {
                        if(o.sortable && sortState && onSortChange) {
                            if(sortState[o.key] !== undefined) {
                                if(sortState[o.key] == "des") onSortChange("", "");
                                if(sortState[o.key] == "asc") onSortChange(o.key, "des");
                            } else if (sortState[o.key] === undefined || sortState[o.key] == "") {
                                onSortChange(o.key, "asc");
                            }
                        }
                    }}>
                        <HeaderItemInner isSorted={isSorted != undefined && isSorted == true}>
                            {o.value}
                            {(isSorted != undefined && isSorted == true) && <SortWrapper>
                                {(sortState as any)[o.key] === "des" ? <SortDownIcon/> : ''}
                                {(sortState as any)[o.key] === "asc" ? <SortUpIcon/> : ''}
                            </SortWrapper>}
                        </HeaderItemInner>
                    </HeaderItem>;
                })}
            </tr>
        </Header>
        <Body>
            {body !== null && Array.isArray(body) && renderTableBody(body)}
            {body !== null && !Array.isArray(body) && renderTableBody(body())}
        </Body>
    </TableWrapper>;
};

const IDWrap = styled.div<{noPad?: boolean}>`
    font-size: 12px;
    font-weight: 400;
    ${({noPad}) => !noPad && `
        padding-left: 16px;
    `};
    color: ${({theme}) => theme.colors.grayBlue};
`;

const AssetWrap = styled.div`
    position: relative;
    display: flex;
`;

const ActionWrap = styled.span`
    font-weight: 700;
    font-size: 24px;
`;

const ActionsSelectWrap = styled.div`
    position: absolute;
    top: -3rem;
    padding: 4px;
    border-radius: 4px;
    background-color: ${({theme}) => theme.colors.lighterGray};
    z-index: 99;
`;

const Action = styled.div`
    padding: 8px;
    border-radius: 4px;
    font-weight: 600;
    &:hover {
        background-color: ${({theme}) => theme.colors.lightGray};
    }
`;

export default Table;