import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import PageHeader from "../../../components/shared/PageHeader";
import UtilityButton from "../../../components/shared/UtilityButton";
import { Routes } from "../../../router/Routes";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../resources/icons/floppy-disk.svg";
import {ReactComponent as IUserIcon} from "../../../resources/icons/user.svg";
import {ReactComponent as IMapIcon} from "../../../resources/icons/map-location.svg";
import {ReactComponent as INoteIcon} from "../../../resources/icons/note.svg";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../components/shared/inputs/LabeledSelectInput";
import CountryMapper, {GetCountryByValue} from "../../../components/shared/CountryMapper";
import fetchRealEstateRenter from "../api/fetchRealEstateRenter";
import toast from "react-hot-toast";
import updateRealEstateRenter from "../api/updateRealEstateRenter";
import {formatPostalCode} from "../../../shared/utils/format";

const REEditRenterPage = () => {
    const { slug, secondslug }: {[index: string]: any} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const {register, handleSubmit, errors, watch} = useForm();
    const REProject = slug;
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const postalCode = watch('postalCode');

    const onSubmit = async (inputData: any) => {
        inputData.renterId = parseInt(secondslug);
        inputData.monthlyPayment = parseInt(inputData.monthlyPayment);
        inputData.annualPayment = parseInt(inputData.annualPayment);

        const response = await updateRealEstateRenter(slug, inputData);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Nájemník upraven.");
        } else {
            toast.error("Nájemníka se nepodařilo upravit.");
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title={"Zrušit"}
                icon={<ICrossIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_RENTER_DETAIL.replace(":slug", slug).replace(":secondslug", secondslug))}
            />
            <UtilityButton
                title={"Uložit"}
                icon={<IDiskIcon/>}
                color={"green"}
                onClick={handleSubmit(onSubmit)}
            />
        </>);
        const fetchData = async () => {
            const response = await fetchRealEstateRenter(slug, secondslug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nájemníka se nepodařilo načíst.");
            }
        };
        fetchData();
    }, []);
    return (<Container>
        {!loading && <><PageHeader title={`${data.general?.firstName} ${data.general?.lastName}`}
        subtitle={`Nemovitosti   —   ${REProject}   —   ${`${data.general?.firstName} ${data.general?.lastName}`}`}
        noContainerBottomMargin
        returnPath={Routes.REAL_ESTATE_DETAIL_RENTERS.replace(":slug", slug)}/>
                <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Nájemník" icon={<IUserIcon/>}>
                    <LabeledTextInput
                        type={"text"}
                        label={"Jméno"}
                        placeholder={"Text..."}
                        name={"firstName"}
                        defaultValue={data.general?.firstName || ""}
                        valid={!errors.firstName}
                        errorMessage={errors.firstName?.message}
                        ref={register({required: {value: true, message: "Jméno je povinné."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Příjmení"}
                        placeholder={"Text..."}
                        name={"lastName"}
                        defaultValue={data.general?.lastName || ""}
                        valid={!errors.lastName}
                        errorMessage={errors.lastName?.message}
                        ref={register({required: {value: true, message: "Příjmení je povinné."}})}
                    />
                    <LabeledTextInput
                        type={"date"}
                        label={"Nájemníkem od"}
                        placeholder={"Datum..."}
                        name={"renterFrom"}
                        defaultValue={data.general?.renterFrom || ""}
                        valid={!errors.renterFrom}
                        errorMessage={errors.renterFrom?.message}
                        ref={register({required: {value: true, message: "Počátek nájemného je povinné."}})}
                    />
                    <LabeledTextInput
                        type={"date"}
                        label={"Nájemníkem do"}
                        placeholder={"Datum..."}
                        name={"renterUntil"}
                        defaultValue={data.general?.renterUntil || ""}
                        valid={!errors.renterUntil}
                        errorMessage={errors.renterUntil?.message}
                        ref={register({required: {value: true, message: "Konec nájemného je povinný."}})}
                    />
                    <LabeledTextInput
                        type={"number"}
                        label={"Nájem měsíčně"}
                        placeholder={"Číslo..."}
                        name={"monthlyPayment"}
                        defaultValue={data.general?.monthlyPayment || ""}
                        valid={!errors.monthlyPayment}
                        errorMessage={errors.monthlyPayment?.message}
                        ref={register({required: {value: true, message: "Měsíčný nájem je povinný."}})}
                    />
                    <LabeledTextInput
                        type={"number"}
                        label={"Nájem ročně"}
                        placeholder={"Číslo..."}
                        name={"annualPayment"}
                        defaultValue={data.general?.annualPayment || ""}
                        valid={!errors.annualPayment}
                        errorMessage={errors.annualPayment?.message}
                        ref={register({required: {value: true, message: "Roční nájem je povinný."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Email"}
                        placeholder={"Text..."}
                        name={"email"}
                        defaultValue={data.general?.email || ""}
                        valid={!errors.email}
                        errorMessage={errors.email?.message}
                        ref={register({required: {value: true, message: "Email je povinný."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Telefon"}
                        placeholder={"Číslo..."}
                        name={"phoneNumber"}
                        defaultValue={data.general?.phoneNumber || ""}
                        valid={!errors.phoneNumber}
                        errorMessage={errors.phoneNumber?.message}
                        ref={register({required: {value: true, message: "Telefonní číslo je povinné."}})}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Trvalé bydliště" icon={<IMapIcon/>}>
                    <LabeledTextInput
                        type={"text"}
                        label={"Ulice 1. řádek"}
                        placeholder={"Text..."}
                        name={"permanentLocation.streetAddressFirstLine"}
                        defaultValue={data.location?.streetAddressFirstLine || ""}
                        valid={!errors.permanentLocation?.streetAddressFirstLine}
                        errorMessage={errors.permanentLocation?.streetAddressFirstLine?.message}
                        ref={register({required: {value: true, message: "Ulice je povinná."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Ulice 2. řádek"}
                        placeholder={"Text..."}
                        name={"permanentLocation.streetAddressSecondLine"}
                        defaultValue={data.location?.streetAddressSecondLine || ""}
                        valid={!errors.permanentLocation?.streetAddressSecondLine}
                        errorMessage={errors.permanentLocation?.streetAddressSecondLine?.message}
                        ref={register({required: {value: false, message: "Ulice je povinná."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"PSČ"}
                        placeholder={"Číslo..."}
                        name={"permanentLocation.postalCode"}
                        defaultValue={data.location?.postalCode || ""}
                        valid={!errors.permanentLocation?.postalCode}
                        isCode
                        errorMessage={errors.permanentLocation?.postalCode?.message}
                        ref={register({ maxLength: 6, minLength: 6, required: {value: false, message: "PSČ je povinné."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Obec/Město"}
                        placeholder={"Text..."}
                        name={"permanentLocation.city"}
                        defaultValue={data.location?.city || ""}
                        valid={!errors.permanentLocation?.city}
                        errorMessage={errors.permanentLocation?.city?.message}
                        ref={register({required: {value: false, message: "Obec/Město je povinné."}})}
                    />
                    <LabeledSelectInput
                        label={"Země"}
                        placeholder={"Text..."}
                        name={"permanentLocation.country"}
                        defaultValue={GetCountryByValue(data.location?.country)?.id}
                        valid={!errors.permanentLocation?.country}
                        errorMessage={errors.permanentLocation?.country?.message}
                        options={CountryMapper()}
                        ref={register({required: {value: false, message: "Země je povinná."}})}
                    />
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput
                        type={"textarea"}
                        label={"Poznámka"}
                        placeholder={"Text..."}
                        name={"note"}
                        defaultValue={data.note || ""}
                        valid={!errors.note}
                        errorMessage={errors.note?.message}
                        ref={register({required: {value: false, message: "Poznámka je povinná."}})}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

export default REEditRenterPage;