import { getAccessToken } from '../../../contexts/UserIdentityContext';

const fetchAppraiser = async (id: string | number) => {
    const response = await fetch(`/api/v1/appraisers/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchAppraiser;