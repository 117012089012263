import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const deleteInvestor = async (id: number | string) => {
    const response = await fetch(`/api/v1/investors/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default deleteInvestor;