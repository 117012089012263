import { getAccessToken } from './../../../contexts/UserIdentityContext';
const createRealEstateRenter = async (data: any) => {
    const response = await fetch(`/api/v1/realestates/${data.realEstateId}/renters`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
    }) 
    return response;
};

export default createRealEstateRenter;