import React, {ReactElement, useState} from "react";
import {ChevronDown, SelectInputWrapper, SelectItem, SelectList, SelectText} from "./styled/selectInput.styled";

const DEFAULT_LIMITS = [12, 24, 48, 96];

export interface SelectInputInterface {
    selected: number,
    onChange: any
}

const SelectInput = ({selected, onChange}: SelectInputInterface): ReactElement => {
    const [active, setActive] = useState<boolean>(false);

    const handleSelected = (e: number) => {
        setActive(false);
        localStorage.setItem("limit", e.toString());
        onChange(e);
    };

    return <SelectInputWrapper active={active}>
        <SelectText onClick={() => setActive(!active)}>{selected}
            <ChevronDown/>
        </SelectText>
        <SelectList active={active}>
            {DEFAULT_LIMITS.map((o, i) => <SelectItem key={i} onClick={() => handleSelected(o)}>{o}</SelectItem>)}
        </SelectList>
    </SelectInputWrapper>;
};

export default SelectInput;