import React, { useContext, useEffect, useState } from "react";
import { ModalContext, UtilityButtonsContext } from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import CreateRealEstateModal from "./CreateRealEstateModal";
import styled from "styled-components";
import {HighlightedText, TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import ProfileColumn from "../../components/table/components/ProfileColumn";
import ColoredText from "../../components/table/components/ColoredText";
import DoubleLineValue from "../../components/table/components/DoubleLineValue";
import fetchRealEstates from "./api/fetchRealEstates";
import toast from "react-hot-toast";
import { GetRECollateralLabel, GetREOwnershipLabel, GetRETypeIcon, GetRETypeLabel } from "../../enums/RealEstateEnums";
import SearchBar from "../../components/shared/inputs/SearchBar";
import Pagination from "../../components/pagination/Pagination";
import FilterSelectInput from "../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../components/shared/filters/FilterDateInput";
import FilterRangeInput from "../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand.svg";
import {ReactComponent as IPinIcon} from "../../resources/icons/location-dot.svg";
import {ReactComponent as IShieldIcon} from "../../resources/icons/shield-check.svg";
import {ReactComponent as ITrophyIcon} from "../../resources/icons/trophy.svg";
import {ReactComponent as IBuildingIcon} from "../../resources/icons/building.svg";
import useFilters from "../../components/methods/useFilters";
import {formatPrice} from "../../shared/utils/format";

const REOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        type: "",
        location: "",
        colValueFrom: "", colValueTo: "",
        ownership: "",
        project: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit nemovitost"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateRealEstateModal/>)
                }}
            />
        </>)
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchRealEstates(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nemovitosti se nepodařilo načíst.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
         <TableContainer>
            <FilterWrap maxWidth={"55%"}>
            <SearchBar name={"realEstateOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Typ`}
                onChange={(filters: string) => changeFilter("type", filters)}
                icon={<IBuildingIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Lokalita`}
                onChange={(filters: string) => changeFilter("location", filters)}
                icon={<IPinIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterRangeInput
                label={`Hodnota zajištění`}
                icon={<IShieldIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
            />
            <FilterSelectInput
                label={`Vlastnictví`}
                onChange={(filters: string) => changeFilter("ownership", filters)}
                icon={<IHandIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Projekt`}
                onChange={(filters: string) => changeFilter("project", filters)}
                icon={<ITrophyIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            </FilterWrap>
            <Table
                headers={[
                    {key: "realEstate", value: "NEMOVITOST", sortable: false},
                    {key: "location", value: "LOKALITA", sortable: false},
                    {key: "collateralValue", value: "HODNOTA ZAJIŠTĚNÍ", sortable: true},
                    {key: "projectCreditValue", value: "HODNOTA ÚVĚRU V PROJEKTU", sortable: true},
                    {key: "ltv", value: "LTV", sortable: true},
                    {key: "ownership", value: "VLASTNICTVÍ", sortable: false},
                    {key: "project", value: "PROJEKT", sortable: false},
                    {key: "renters", value: "NÁJEMNÍCI", sortable: true},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(loading ? [] : (data.realEstates ?? []))}/>
                <Pagination
                    total={loading ? 0 : data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.realEstates?.length ?? 0}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
        </TableContainer>
    </Container>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            realEstate: {value: (<ProfileColumn icon={
                GetRETypeIcon(o.realEstateType)
            } title={
                GetRETypeLabel(o.realEstateType)
            } subtitle={
                <ColoredText text={GetRECollateralLabel(o.mainCollateralType)} color={"grayBlue"} semibold/>
            }/>)},
            location: {value: (<DoubleLineValue firLine={
                o.location
            } secLine={
                <ColoredText text={o.postalCode} color={"grayBlue"} semibold/>
            }/>)},
            collateralValue: {value: (<ColoredText text={`${formatPrice(o.value)} Kč`} color={"stripGreen"}/>)},
            projectCreditValue: {value: `${formatPrice(o.valueInProject)} Kč`},
            ltv: {value: (<RegularBold>{o.loanToValue.toFixed(2)}%</RegularBold>)},
            ownership: {value: (<RegularBold>{GetREOwnershipLabel(o.ownershipType)}</RegularBold>)},
            project: {value: (o.assignedProjectId ? <a href={Routes.PROJECT_DETAIL.replace(":slug", o.assignedProjectId)}>{o.assignedProjectName}</a> : <ColoredText text={"Bez projektu"} color={"grayBlue"}/>)},
            renters: {value: (<DoubleLineValue firLine={
                <ColoredText text={o.rentersCount} color={"stripGreen"}/>
            } secLine={
                <ColoredText text={`${formatPrice(o.monthlyRent)} Kč / měsíc`} color={"grayBlue"} semibold/>
            }/>)},
            link: `${Routes.REAL_ESTATE_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default REOverviewPage;