import { getAccessToken } from '../../../contexts/UserIdentityContext';

export const fetchSelectUsers = async () => {
    const response = await fetch(`/api/v1/admins/select`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectRealEstates = async (excludedIds: Array<number> = [], type?: number) => {
    const response = await fetch(`/api/v1/realestates/select?${excludedIds.map((id: number) => `excludedIds=${id}`).join("&")} ${type ? `&type=${type}` : ""}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectProviders = async () => {
    const response = await fetch(`/api/v1/agents/select`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectAppraisers = async () => {
    const response = await fetch(`/api/v1/appraisers/select`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectProjects = async () => {
    const response = await fetch(`/api/v1/projects/select`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectInvestmentLogs = async (id: number | string) => {
    const response = await fetch(`/api/v1/projects/${id}/investmentlogs/select`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export const fetchSelectInvestors = async (excludedIds: Array<number> = [], type?: number) => {
    const response = await fetch(`/api/v1/investors/select?${excludedIds.map((id: number) => `excludedIds=${id}`).join("&")} ${type ? `&type=${type}` : ""}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};