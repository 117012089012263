import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components"
import IProfilePhoto from "../../../resources/images/TempProfilePhoto.jpg";
import { Routes } from "../../../router/Routes";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../components/shared/inputs/LabeledTextInput";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IBuildingIcon} from "../../../resources/icons/building.svg";
import PageHeader from "../../../components/shared/PageHeader";
import { useForm } from "react-hook-form";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as IDiskIcon} from "../../../resources/icons/floppy-disk.svg";
import {getCurrentUserId, UserIdentityContext} from "../../../contexts/UserIdentityContext";
import toast from "react-hot-toast";
import changePassword from "../api/changePasswored";
import uploadUserImage from "../../users/api/uploadUserImage";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import fetchUserImage from "../../users/api/fetchUserImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

const SecurityPage = () => {
    const [currentImage, setCurrentImage] = useState<string>("");
    const {register, handleSubmit, errors, control, reset} = useForm();
    const identity = useContext(UserIdentityContext);
    const buttons = useContext(UtilityButtonsContext)
    const name = identity.getName();
    const slug = getCurrentUserId();

    const fetchData = async () => {
        const imageResponse = await fetchUserImage(slug as string);
        if(imageResponse.status == 200) {
            const fetchedData = await imageResponse.json();
            setCurrentImage(fetchedData.result);
        } else {
            setCurrentImage("");
        }
    };

    useEffect(() => {
        fetchData();
    }, [identity.initialized]);

    const onSubmit = async (data: any) => {
        const response = await changePassword(data);
        if(response.status == 200) {
            toast.success("Heslo úspěšně změněno.");
            reset();
        } else {
            toast.error("Nepodařilo se uložit nové heslo.")
        }
    };

    useEffect(() => {
        buttons.setUtilityButtons(<UtilityButton
            title="Uložit"
            color={"green"}
            onClick={handleSubmit(onSubmit)}
            icon={<IDiskIcon/>}
        />)
    }, []);
    const handleUpload = async (e: any) => {
        const response = await uploadUserImage(slug as string, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setCurrentImage(fetchedData.result);
            toast.success("Fotka uživatele byla změněna.");
        } else {
            toast.error("Fotku uživatele se nepodařilo změnit.");
        }
    };
    return (<Container>
        <PageHeader title={name} 
        subtitle={`Uživatelé — ${name}`}
        onUpload={handleUpload}
        image={getPhotoFromCdn(currentImage)}/>
        <StyledInfoBoxContainer>
            <InfoBoxColumn>
                <InfoBox title="Heslo" icon={<IBuildingIcon/>}>
                    <LabeledTextInput type="text" label="Původní heslo" defaultValue="" placeholder="Zadejte..."
                    name="currentPassword"
                    valid={!errors.currentPassword}
                    errorMessage={errors.currentPassword?.message}
                    ref={register}/>
                    <LabeledTextInput type="text" label="Nové heslo" defaultValue="" placeholder="Zadejte..."
                    name="newPassword"
                    valid={!errors.newPassword}
                    errorMessage={errors.newPassword?.message}
                    ref={register}/>
                    <LabeledTextInput type="text" label="Nové heslo znovu" defaultValue="" placeholder="Zadejte..."
                    name="newPasswordRepeat"
                    valid={!errors.newPasswordRepeat}
                    errorMessage={errors.newPasswordRepeat?.message}
                    ref={register}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn></InfoBoxColumn>
        </StyledInfoBoxContainer>
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const StyledInfoBoxContainer = styled(InfoBoxFullContainer)`
    margin-top: -35px;
`;

export default SecurityPage;