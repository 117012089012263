import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface ButtonInterface {
    text: string;
    route: string;
    index?: string;
    secondIndex?: string;
    exact?: boolean;
}

interface CategoryButtonsInterface {
    buttons: Array<ButtonInterface>;
} 

const CategoryButtons = ({buttons = []}: CategoryButtonsInterface) => {
    return (<Container>
        {buttons.map((v, i) => <Button key={i} to={() => {
            if(v.index != undefined) {
                if(v.secondIndex != undefined) {
                    let route = v.route.replace(":slug", v.index);
                    return route.replace(":secondslug", v.secondIndex);
                } else {
                    return v.route.replace(":slug", v.index);
                };
            } else {
                return v.route;
            }
            }} exact={v.exact} activeClassName="activeCategory">
            {v.text}
        </Button>)}
    </Container>)
};

const Container = styled.div`
`;

const Button = styled(NavLink)`
    padding: 12px 56px;
    text-transform: uppercase;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    color: ${({theme}) => theme.colors.black};
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    &.activeCategory {
        color: ${({theme}) => theme.colors.blue};
        border-bottom: 4px solid ${({theme}) => theme.colors.blue};
    }
`;

export default CategoryButtons;