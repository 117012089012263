import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as IERICLogo} from "../../../resources/eric_logo.svg";
import IconButton from "./components/IconButton";

import {ReactComponent as IExitIcon} from "../../../resources/icons/right-from-bracket.svg";
import {ReactComponent as IGearIcon} from "../../../resources/icons/gear.svg";
import {ReactComponent as IBuildingIcon} from "../../../resources/icons/building.svg";
import {ReactComponent as ITrophyIcon} from "../../../resources/icons/trophy.svg";
import {ReactComponent as ICalendarIcon} from "../../../resources/icons/calendar-exclamation-light.svg";
import {ReactComponent as IDocumentIcon} from "../../../resources/icons/file-lines.svg";
import {ReactComponent as IUsersIcon} from "../../../resources/icons/users.svg";
import {ReactComponent as IMoneyIcon} from "../../../resources/icons/money-check-dollar-pen.svg";
import {ReactComponent as ISettingsIcon} from "../../../resources/icons/screwdriver-wrench.svg";

import {useHistory, useLocation} from "react-router";
import {Routes} from "../../../router/Routes";
import NavButton from "./components/NavButton";
import { UtilityButtonsContext } from "../Layout";
import {getCurrentUserId, UserIdentityContext} from "../../../contexts/UserIdentityContext";
import toast from "react-hot-toast";
import fetchUserTaskCount from "../../../pages/users/api/fetchUserTaskCount";
import fetchUserImage from "../../../pages/users/api/fetchUserImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

const Header = () => {
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    const userIdentity = useContext(UserIdentityContext);
    const location = useLocation();
    const [activeTasksCount, setActiveTasksCount] = useState<string>("");
    const [currentImage, setCurrentImage] = useState<string>("");

    const fetchData = async () => {
        if(userIdentity.isLoggedIn){
            const currentUserId = getCurrentUserId() as string;
            const taskCountResponse = await fetchUserTaskCount(currentUserId);
            if(taskCountResponse.status == 200) {
                const fetchedData = await taskCountResponse.json();
                setActiveTasksCount(fetchedData.result);
            } else {
                toast.error("Nepodařilo se načíst počet úkolů.")
            }

            const imageResponse = await fetchUserImage(currentUserId);
            if(imageResponse.status == 200) {
                const fetchedData = await imageResponse.json();
                setCurrentImage(fetchedData.result);
            } else {
                setCurrentImage("");
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [location, userIdentity.initialized]);

    return (<Container>
        <LoginRow>
            <ERICLogo onClick={() => window.scrollTo(0, 0)}/>
            <LoginSection>
                <IconButton action={() => history.push(Routes.SETTINGS_PROFILE)} icon={<GearIcon/>}/>
                <ProfileIcon src={getPhotoFromCdn(currentImage)} alt="Profilová fotka"/>
                <IconButton action={() => userIdentity.signOut()} icon={<ExitIcon/>}/>
            </LoginSection>
        </LoginRow>
        <NavigationRow>
            <NavigationButtons>
                <NavButton text="Projekty" route={Routes.PROJECTS} icon={<TrophyIcon/>} innerLinks={
                    [
                        { text: "Přehled", route: Routes.PROJECTS, exact: true },
                        { text: "Finance", route: Routes.PROJECTS_FINANCE, exact: true },
                        { text: "Investoři", route: Routes.PROJECTS_INVESTORS, exact: true },
                        { text: "Zprostředkovatelé", route: Routes.PROJECTS_PROVIDERS, exact: true },
                    ]}/>
                <NavButton text="Nemovitosti" route={Routes.REAL_ESTATE} icon={<BuildingIcon/>} innerLinks={
                    [
                        { text: "Přehled", route: Routes.REAL_ESTATE, exact: true },
                        { text: "Odhadci", route: Routes.APPRAISERS, exact: true },
                    ]}/>
                <NavButton text="Úkoly" highlightedText={activeTasksCount} route={Routes.TASKS} icon={<CalendarIcon/>} exact/>
                <NavButton text="Dokumenty" route={Routes.DOCUMENTS} icon={<DocumentIcon/>}/>
                <NavButton text="Uživatelé" route={Routes.USERS} icon={<UsersIcon/>}/>
                <NavButton text="Možnosti" route={Routes.SETTINGS} icon={<SettingsIcon/>} innerLinks={
                [
                    { text: "Profil", route: Routes.SETTINGS_PROFILE, exact: false },
                    { text: "Zabezpečení", route: Routes.SETTINGS_SECURITY, exact: true }
                ]}/>
            </NavigationButtons>
            <UtilityButtonsContainer>
                {buttons.utilityButtons}
            </UtilityButtonsContainer>
        </NavigationRow>
    </Container>);
};

const Container = styled.div`
    padding: 12px 24px 8px 24px;
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
`;

const LoginRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ERICLogo = styled(IERICLogo)`
    cursor: pointer;
    min-width: 126px;
    min-height: 24px;
`;

const LoginSection = styled.div`
    display: flex;
`;

const GearIcon = styled(IGearIcon)`
    width: 18px;
    height: 18px;
    fill: ${({theme}) => theme.colors.black};
`;

const ExitIcon = styled(IExitIcon)`
    width: 18px;
    height: 18px;
    fill: ${({theme}) => theme.colors.black};
`;

const ProfileIcon = styled.img`
    border-radius: 50%;
    margin: 0 16px;
    object-fit: cover;
    width: 32px;
    max-height: 32px;
`;

const TrophyIcon = styled(ITrophyIcon)`
    width: 28px;
`;
const BuildingIcon = styled(IBuildingIcon)`
    width: 18px;
`;
const CalendarIcon = styled(ICalendarIcon)`
    width: 21px;
`;
const DocumentIcon = styled(IDocumentIcon)`
    width: 18px;
`;
const UsersIcon = styled(IUsersIcon)`
    width: 30px;
`;
const SettingsIcon = styled(ISettingsIcon)`
    width: 24px;
`;

const NavigationRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const NavigationButtons = styled.div`
    display: flex;
`;

const UtilityButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default Header;