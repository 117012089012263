import React, {ReactElement} from "react";
import "./components/theme/GlobalStyle.css";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import styled, {ThemeProvider} from "styled-components";
import {defaultTheme} from "./components/theme/defaultTheme";
import {Routes} from "./router/Routes";

import LoginPage from "./pages/login/index";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import ResetPasswordPage from "./pages/login/ResetPasswordPage";

import ProjectOverviewPage from "./pages/projects/ProjectOverviewPage";
import ProjectFinanceOverviewPage from "./pages/projects/ProjectFinanceOverviewPage";
import ProjectInvestorsOverviewPage from "./pages/projects/ProjectInvestorsOverviewPage";
import ProjectProvidersOverviewPage from "./pages/projects/ProjectProvidersOverviewPage";

import UserListPage from "./pages/users/users-list/UsersListPage";
import UserDetailPage from "./pages/users/user-detail/UserDetailPage";

import SecurityPage from "./pages/settings/security/SecurityPage";
import ProfilePage from "./pages/settings/profile/ProfilePage";
import LogsPage from "./pages/settings/system-logs/LogsPage";

import ProjectDetailPage from "./pages/projects/project-detail/ProjectDetailPage";
import InvestmentLogDetailPage from "./pages/projects/investment-log-detail/InvestmentLogDetailPage";

import FinanceDetailPage from "./pages/projects/finance-detail/FinanceDetailPage";
import EditFinancePage from "./pages/projects/finance-detail/EditFinancePage";

import InvestorDetailPage from "./pages/projects/investor-detail/InvestorDetailPage";
import ProviderDetailPage from "./pages/projects/provider-detail/ProviderDetailPage";

import REOverviewPage from "./pages/real-estate/REOverviewPage";
import REDetailPage from "./pages/real-estate/detail/REDetailPage";
import RERenterPage from "./pages/real-estate/detail/RERenterPage";
import REEditRenterPage from "./pages/real-estate/detail/REEditRenterPage";

import TasksPage from "./pages/tasks/tasks-page/TasksPage";

import DocumentsOverviewPage from "./pages/documents/DocumentsOverviewPage";
import DocumentDetailPage from "./pages/documents/detail/DocumentDetailPage";
import EditDocumentPage from "./pages/documents/detail/EditDocumentPage";
import TaskDetailPage from "./pages/projects/task-detail/TaskDetailPage";
import EditTaskDetailPage from "./pages/projects/task-detail/EditTaskPage";
import ProtectedRoute from "./router/ProtectedRoute";
import {Toaster} from "react-hot-toast";
import {UserIdentityProvider} from "./contexts/UserIdentityContext";
import REAppraisersOverviewPage from "./pages/real-estate/REAppraisersOverviewPage";
import AppraiserDetailPage from "./pages/real-estate/appraiser-detail/AppraiserDetailPage";

import cs from "date-fns/locale/cs";
import { registerLocale, setDefaultLocale } from "react-datepicker";

registerLocale("cs", cs);
setDefaultLocale("cs");

const App = (): ReactElement => {
    return (<UserIdentityProvider>
        <ThemeProvider theme={defaultTheme}>
            <Toaster position="bottom-right" reverseOrder={false}/>
            <Router>
                <Switch>
                    <Route component={LoginPage} path={Routes.LOGIN} exact/>
                    <Route component={ForgotPasswordPage} path={Routes.FORGOT_PASSWORD} exact/>
                    <Route component={ResetPasswordPage} path={Routes.RESET_PASSWORD} exact/>

                    <ProtectedRoute component={ProjectOverviewPage} path={"/"} exact/>
                    <ProtectedRoute component={ProjectOverviewPage} path={Routes.PROJECTS} exact/>
                    <ProtectedRoute component={ProjectFinanceOverviewPage} path={Routes.PROJECTS_FINANCE} exact/>
                    <ProtectedRoute component={ProjectInvestorsOverviewPage} path={Routes.PROJECTS_INVESTORS} exact/>
                    <ProtectedRoute component={ProjectProvidersOverviewPage} path={Routes.PROJECTS_PROVIDERS} exact/>

                    <ProtectedRoute component={InvestmentLogDetailPage} path={Routes.PROJECT_INVESTMENT_LOG_DETAIL} exact/>
                    <ProtectedRoute component={ProjectDetailPage} path={Routes.PROJECT_DETAIL}/>

                    <ProtectedRoute component={FinanceDetailPage} path={Routes.PROJECT_FINANCE_DETAIL} exact/>
                    <ProtectedRoute component={EditFinancePage} path={Routes.PROJECT_FINANCE_DETAIL_EDIT} exact/>

                    <ProtectedRoute component={InvestorDetailPage} path={Routes.PROJECT_INVESTOR_DETAIL}/>
                    <ProtectedRoute component={ProviderDetailPage} path={Routes.PROJECT_PROVIDER_DETAIL}/>

                    <ProtectedRoute component={REOverviewPage} path={Routes.REAL_ESTATE} exact/>
                    <ProtectedRoute component={RERenterPage} path={Routes.REAL_ESTATE_RENTER_DETAIL} exact/>
                    <ProtectedRoute component={REEditRenterPage} path={Routes.REAL_ESTATE_RENTER_DETAIL_EDIT} exact/>
                    <ProtectedRoute component={REDetailPage} path={Routes.REAL_ESTATE_DETAIL}/>
                    <ProtectedRoute component={REAppraisersOverviewPage} path={Routes.APPRAISERS} exact/>
                    <ProtectedRoute component={AppraiserDetailPage} path={Routes.APPRAISER_DETAIL}/>

                    <ProtectedRoute component={DocumentsOverviewPage} path={Routes.DOCUMENTS} exact/>
                    <ProtectedRoute component={DocumentDetailPage} path={Routes.DOCUMENT_DETAIL} exact/>
                    <ProtectedRoute component={EditDocumentPage} path={Routes.DOCUMENT_DETAIL_EDIT} exact/>

                    <Route path={Routes.SETTINGS} exact render={() => <Redirect to={Routes.SETTINGS_PROFILE}/>}/>
                    <ProtectedRoute component={ProfilePage} path={Routes.SETTINGS_PROFILE}/>
                    <ProtectedRoute component={SecurityPage} path={Routes.SETTINGS_SECURITY} exact/>
                    <ProtectedRoute component={LogsPage} path={Routes.SETTINGS_LOGS}/>

                    <ProtectedRoute component={TasksPage} path={Routes.TASKS}/>
                    <ProtectedRoute component={TaskDetailPage} path={Routes.PROJECT_TASK} exact/>
                    <ProtectedRoute component={EditTaskDetailPage} path={Routes.PROJECT_TASK_EDIT} exact/>

                    <ProtectedRoute component={UserListPage} path={Routes.USERS} exact/>
                    <ProtectedRoute component={UserDetailPage} path={Routes.USER_DETAIL}/>

                    <Route component={LoginPage} path="/*"/>
                </Switch>
            </Router>
        </ThemeProvider>
    </UserIdentityProvider>);
};

export default App;