import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import {Routes} from "../../../router/Routes";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IUserIcon} from "../../../resources/icons/user.svg";
import {ReactComponent as ITimelineIcon} from "../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as IMapIcon} from "../../../resources/icons/map-location.svg";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../../components/shared/UtilityButton";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import fetchRealEstateRenter from "../api/fetchRealEstateRenter";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import ColoredText from "../../../components/table/components/ColoredText";
import deleteRealEstateRenter from "../api/deleteRealEstateRenter";
import {formatPrice} from "../../../shared/utils/format";

const RERenterPage = () => {
    const { slug, secondslug }: {[index: string]: any} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const REProject = slug;
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title={"Smazat"}
                icon={<ITrashIcon/>}
                color={"red"}
                onClick={async () => {
                    if(window.confirm("Doopravdy si přejete smazat nájemníka?")) {
                        const response = await deleteRealEstateRenter(slug, secondslug);
                        if(response.status == 200) {
                            const fetchedData = await response.json()
                            history.push(Routes.REAL_ESTATE_DETAIL_RENTERS.replace(":slug", slug))
                            toast.success("Nájemník smazán.");
                        } else {
                            toast.error("Nájemníka se nepodařilo smazat.");
                        }
                    }
                }}
            />
            <UtilityButton
                title={"Exportovat"}
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => console.log("exportovat nájemníka")}
            />
            <UtilityButton
                title={"Upravit"}
                icon={<IPenIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_RENTER_DETAIL_EDIT.replace(":slug", slug).replace(":secondslug", secondslug))}
            />
        </>);
        const fetchData = async () => {
            const response = await fetchRealEstateRenter(slug, secondslug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nájemníka se nepodařilo načíst.")
            }
        };
        fetchData()
    }, []);
    return (<Container>
        {!loading && <><PageHeader title={`${data.general?.firstName} ${data.general?.lastName}`}
        subtitle={`Nemovitosti   —   ${REProject}   —   ${`${data.general?.firstName} ${data.general?.lastName}`}`}
        noContainerBottomMargin
        returnPath={Routes.REAL_ESTATE_DETAIL_RENTERS.replace(":slug", slug).replace(":secondslug", secondslug)}/>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Nájemník" icon={<IUserIcon/>}>
                    <InfoBoxItem title="Jméno a příjmení" text={`${data.general?.firstName} ${data.general?.lastName}`}/>
                    <InfoBoxItem title="Nájemníkem od" text={data.general?.renterFrom ? dayjs(data.general?.renterFrom).format("DD.MM.YYYY") : ""}/>
                    <InfoBoxItem title="Nájemníkem do" text={data.general?.renterUntil ? dayjs(data.general?.renterUntil).format("DD.MM.YYYY") : ""}/>
                    <InfoBoxItem title="Stav" text={data.general?.isActive ? <ColoredText semibold color="stripGreen" text="Aktivní"/> : <ColoredText semibold color="lightPink" text="Neaktivní"/>}/>
                    <InfoBoxItem title="Nájem měsíčně" text={<b>{formatPrice(data.general?.monthlyPayment || 0)} Kč</b>}/>
                    <InfoBoxItem title="Nájem ročně" text={`${formatPrice(data.general?.annualPayment || 0)} Kč`}/>
                    <InfoBoxItem title="Email" text={data.general?.email ? <a href={`mailto:${data.general?.email}`}>{data.general?.email}</a> : ""}/>
                    <InfoBoxItem title="Telefon" text={data.general?.phoneNumber ?<a href={`tel:${data.general?.phoneNumber}`}>{data.general?.phoneNumber}</a> : ""}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Trvalé bydliště" icon={<IMapIcon/>}>
                    <InfoBoxItem title="Ulice 1. řádek" text={data.location?.streetAddressFirstLine}/>
                    <InfoBoxItem title="Ulice 2. řádek" text={data.location?.streetAddressSecondLine}/>
                    <InfoBoxItem title="PSČ" text={data.location?.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3')}/>
                    <InfoBoxItem title="Obec/Město" text={data.location?.city}/>
                    <InfoBoxItem title="Země" text={data.location?.country}/>
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer></>}
    </Container>)
};

const Container = styled.div`    
    padding: 0 24px;
`;

const Active = styled.b`
    color: ${({theme}) => theme.colors.stripGreen};
    font-weight: 400;
`;

const Inactive = styled.b`
    color: ${({theme}) => theme.colors.lightPink};
    font-weight: 400;
`;

export default RERenterPage;