import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as ITimelineIcon} from "../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../resources/icons/note.svg";
import {ReactComponent as IPDFIcon} from "../../../resources/icons/file-pdf.svg";
import {ReactComponent as IFileIcon} from "../../../resources/icons/file.svg";
import {ReactComponent as IGlassIcon} from "../../../resources/icons/magnifying-glass.svg";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import fetchDocument from "../api/fetchDocument";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {GetDocumentCategoryTypeLabel} from "../../../enums/DocumentEnums";
import deleteInvestor from "../../projects/api/investors/deleteInvestor";
import deleteDocument from "../api/deleteDocument";

const DocumentDetailPage = () => {
    const {slug}: {[index: string]: any} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat dokument?")) {
                    const response = await deleteDocument(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        toast.success("Investor smazán.");
                        history.push(Routes.DOCUMENTS);
                    } else {
                        toast.error("Document se nepodařilo smazat");
                    }
                }
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export dokument")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.DOCUMENT_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
        const fetchData = async () => {
            const response = await fetchDocument(slug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Dokument se nepodařilo načíst.")
            }
        };
        fetchData();
    }, []);
    return (<Container>
        {!loading && <><PageHeader title={data.internalName} 
        subtitle={`Dokumenty   —   ${data.internalName}`}
        image={<IPDFIcon/>}
        noContainerLeftMargin
        returnPath={Routes.DOCUMENTS}/>
        <StyledInfoBoxContainer>
            <InfoBoxColumn>
                <InfoBox title="Náhled" icon={<IGlassIcon/>}>
                    Náhled dokumentu (TBD)
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Dokument" icon={<IFileIcon/>}>
                    <InfoBoxItem title="Typ" text={data.contentType}/>
                    <InfoBoxItem title="Velikost" text={`${data.size} kB`}/>
                    <InfoBoxItem title="Kategorie" text={GetDocumentCategoryTypeLabel(data.category)}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note || ""}
                </InfoBox>
            </InfoBoxColumn>
        </StyledInfoBoxContainer></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const StyledInfoBoxContainer = styled(InfoBoxFullContainer)`
    margin-top: -32px;
`;

export default DocumentDetailPage;