import FilterParameters from "../../../../components/methods/FilterParameters";
import { getAccessToken } from "../../../../contexts/UserIdentityContext";

const fetchProviders = async (pageNum: number, pageSize: number, search: string, sortId: string, sortOrder: string, filters: any) => {
    const response = await fetch(`/api/v1/agents?${FilterParameters(filters)}${sortId && `sorting=${sortId}&sortingorder=${sortOrder}&`}pageNumber=${pageNum}&pageSize=${pageSize}&search=${search}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchProviders;