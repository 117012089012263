import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import Strip from "../../../components/shared/Strip";
import ColoredText from "../../../components/table/components/ColoredText";
import DoubleLineValue from "../../../components/table/components/DoubleLineValue";
import Table from "../../../components/table/Table";
import {BodyInterface} from "../../../components/table/Table.interface";
import { TableContainer } from "../../../components/table/Table.styled";
import { Routes } from "../../../router/Routes";
import dayjs from "dayjs";
import fetchInvestmentLogTasks from "../api/investment-logs/fetchInvestmentLogTasks";
import fetchInvestmentLog from "../api/investment-logs/fetchInvestmentLog";
import toast from "react-hot-toast";
import { ModalContext, UtilityButtonsContext } from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../resources/icons/plus.svg";
import CreateTasksModal from "../../tasks/CreateTasksModal";
import SearchBar from "../../../components/shared/inputs/SearchBar";
import Pagination from "../../../components/pagination/Pagination";
import FilterWrap from "../../../components/shared/filters/FilterWrap";
import FilterSelectInput from "../../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../../components/shared/filters/FilterDateInput";
import {ReactComponent as IUserIcon} from "../../../resources/icons/user.svg";
import {ReactComponent as ICalendarIcon} from "../../../resources/icons/calendar.svg";
import {ReactComponent as IFlagIcon} from "../../../resources/icons/flag.svg";
import useFilters from "../../../components/methods/useFilters";

const InvestmentLogDetailPage = () => {
    const {slug, secondslug}: {slug: string; secondslug: string} = useParams();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    const [headData, setHeadData] = useState<any>({});
    const [tasksData, setTasksData] = useState<any>({});
    const [headLoading, setHeadLoading] = useState<boolean>(true);
    const [tasksLoading, setTasksLoading] = useState<boolean>(true);
    const modal = useContext(ModalContext);
    const buttons = useContext(UtilityButtonsContext);
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    
    const [filters, changeFilter] = useFilters({
        state: "",
        solver: "",
        date: "",
    });

    useEffect(() => {
        const fetchHeadData = async () => {
            const response = await fetchInvestmentLog(slug, secondslug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setHeadData(fetchedData.result);
                setHeadLoading(false);
            } else {
                toast.error("Nepovedlo se načíst zápis.")
            }
        };
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export zápis")}/>
            <UtilityButton
            title="Vytvořit úkol"
            color="blue"
            icon={<IPlusIcon/>}
            onClick={() => {
                modal.openModal(<CreateTasksModal projectId={parseInt(slug)} projectLogId={parseInt(secondslug)}/>)
            }}/>
        </>)
        fetchHeadData();
    }, [])
    useEffect(() => {
        const fetchTasksData = async () => {
            const response = await fetchInvestmentLogTasks(slug, secondslug, pageNumber, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setTasksData(fetchedData.result);
                setTasksLoading(false);
            } else {
                toast.error("Nepovedlo se načíst úkoly.")
            }
        };
        fetchTasksData();
    }, [pageNumber, pageSize, search, sortId, sortOrder, filters]);
    return (<Container>
        {!headLoading && <><PageHeader title={`
            Investiční zápis ${dayjs(headData.logDate).format(`DD.MM.YYYY`)}
        `} subtitle={`
            Projekty   —   Přehled   —   ${headData.projectInternalName}   —   Investiční zápisy
        `}
        noContainerLeftMargin
        returnPath={Routes.PROJECT_DETAIL_INVESTMENT_LOGS.replace(":slug", headData.projectId)}
        strip={<Strip color="green"/>}/>
        <TableContainer>
            <FilterWrap maxWidth={"40%"}>
            <SearchBar name={"investmentLogOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Řešitel`}
                onChange={(filters: any) => changeFilter("solver", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Stav`}
                onChange={(filters: any) => changeFilter("state", filters)}
                icon={<IFlagIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterDateInput
                label={`Datum plnění`}
                onChange={(date: string) => changeFilter("date", filters)}
                icon={<ICalendarIcon/>}
            />
            </FilterWrap>
        <Table headers={[
                {key: "dateOfFilling", value: "DATUM PLNĚNÍ", sortable: true},
                {key: "title", value: "NÁZEV", sortable: false},
                {key: "solver", value: "ŘEŠITEL", sortable: false},
                {key: "state", value: "STAV", sortable: false},
                {key: "dateOfEntry", value: "DATUM ZADÁNÍ", sortable: true},
                {key: "lastUpdate", value: "POSLEDNÍ AKTUALIZACE", sortable: true},
            ]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            sizes={["12px"]}
            body={mapper(headData.projectId, tasksLoading ? [] : (tasksData.tasks ?? []))}/>
            <Pagination
                total={tasksLoading ? 0 : tasksData.totalCount}
                pageSize={pageSize}
                pageNumber={pageNumber}
                listed={tasksData.tasks?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNumber(e)}
            />
        </TableContainer></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;
const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (projectId: number | string, items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            stateBackground: true,
            id: o.id,
            dateOfFilling: {value: (<DoubleLineValue firLine={
                <b>{dayjs(o.expectedFinishDate).format("DD.MM.YYYY")}</b>
            } secLine={
                <ColoredText text={`za ${dayjs(o.expectedFinishDate).diff(Date.now(), "day")} dní`} color={"grayBlue"} semibold/>
            }/>)},
            title: {value: (<RegularBold>{o.name}</RegularBold>)},
            solver: {value: (<a href={Routes.USER_DETAIL.replace(":slug", o.assigneeId)}>{o.assigneeName}</a>)},
            state: {value: (<RegularBold>{o.state}</RegularBold>)},
            dateOfEntry: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.finishedOn ? dayjs(o.finishedOn).format("DD.MM.YYYY") : "-"}</RegularBold>
            } secLine={
                <ColoredText text={o.finishedOn ? dayjs(o.finishedOn).format("HH:mm") : "-"} color={"grayBlue"}/>
            }/>)},
            lastUpdate: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.lastUpdatedOn ? dayjs(o.lastUpdatedOn).format("DD.MM.YYYY"): "-"}</RegularBold>
            } secLine={
                <ColoredText text={o.lastUpdatedOn ? dayjs(o.lastUpdatedOn).format("HH:mm"): "-"} color={"grayBlue"}/>
            }/>)},
            link: Routes.PROJECT_TASK.replace(":slug", projectId.toString()).replace(":secondslug", o.id),
        });
    });
    return result;
}

export default InvestmentLogDetailPage;