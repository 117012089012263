import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IMoneyCheckIcon} from "../../resources/icons/money-check-dollar-pen.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import {useForm} from "react-hook-form";
import ModalRowTitle from "../../components/shared/modal/ModalRowTitle";
import ModalChecked from "../../components/shared/modal/ModalChecked";
import ModalUnchecked from "../../components/shared/modal/ModalUnchecked";
import {useHistory, useParams} from "react-router-dom";
import {Routes} from "../../router/Routes";
import {ModalContext} from "../../components/layout/Layout";
import createAppraiser from "./api/createAppraiser";
import toast from "react-hot-toast";
import {ModalTileItem, ModalTileSelector} from "../../components/shared/modal/ModalTileSelector";
import {GetRETypes} from "../../enums/RealEstateEnums";
import {ReactComponent as IHouseIcon} from "../../resources/icons/building-light.svg";
import LabeledMultiSelectInput from "../../components/shared/inputs/LabeledMultiSelectInput";
import {BankMapper} from "../../components/shared/BankMapper";
import {awardThings, countryRegions, ranges} from "./appraiser-detail/components/EditSection";

interface FirstStepModalProps {
    register: any;
    watch: any;
    errors: any;
    continue(): void;
    close(): void;
    setFirstModal: any;
    getValues: any;
    setValue: any;
}

const FirstStepModal: React.FC<FirstStepModalProps> = (props) => {
    const isCompany = props.watch('isCompany');

    useEffect(() => {
        document.getElementsByName("firstName")[0].focus()
    }, []);

    return <ModalContentWrap
        cancelModal={() => props.close()}
        icon={<IMoneyCheckIcon/>}
        title="Vytvořit odhadce"
    >
        <ModalCheckboxContainer>
            <ModalCheckboxLabel htmlFor="isCompany">
                <ModalCheckbox name="isCompany" type="checkbox" id="isCompany" ref={props.register}/>
                <ModalCheckedWrap><ModalChecked/></ModalCheckedWrap>
                <ModalUncheckedWrap><ModalUnchecked/></ModalUncheckedWrap>
                Odhadce je právnická osoba
            </ModalCheckboxLabel>
        </ModalCheckboxContainer>
        <ModalRowTitle title="Údaje kontaktní osoby"/>
        <ModalInputRow>
            <LabeledTextInput
                label='Jméno'
                type="text"
                name="firstName"
                valid={!props.errors.firstName}
                onChange={(e) => props.setFirstModal('firstName', e.currentTarget.value)}
                errorMessage={props.errors.firstName?.message}
                placeholder="Jméno..."
                defaultValue=""
                ref={props.register}
            />
            <LabeledTextInput
                label='Příjmení'
                type="text"
                name="lastName"
                valid={!props.errors.lastName}
                errorMessage={props.errors.lastName?.message}
                onChange={(e) => props.setFirstModal('lastName', e.currentTarget.value)}
                placeholder="Příjmení..."
                defaultValue=""
                ref={props.register}
            />
        </ModalInputRow>
        {isCompany === true && <>
            <ModalRowTitle title="Údaje společnosti"/>
            <ModalInputRow>
                <LabeledTextInput
                    label='Název'
                    type="text"
                    name="companyName"
                    valid={!props.errors.companyName}
                    errorMessage={props.errors.companyName?.message}
                    onChange={(e) => props.setFirstModal('companyName', e.currentTarget.value)}
                    placeholder="Text..."
                    defaultValue=""
                    ref={props.register}
                />
            </ModalInputRow>
        </>}
        <ModalSubmit type={'button'} onClick={() => {
            let valid = true;
            const values = props.getValues();
            if(values.firstName == "") valid = false;
            if(values.lastName == "") valid = false;
            if(values.isCompany && (!values.companyName || values.companyName == "")) valid = false;
            if(valid) props.continue();
        }}>
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.close}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};

interface SecondStepModalProps {
    register: any;
    watch: any;
    errors: any;
    close(): void;
    getValues: any;
    goBack(): void;
    setValue: any;
}

const SecondStepModal: React.FC<SecondStepModalProps> = (props) => {
    useEffect(() => {
        document.getElementsByName("email")[0].focus()
    }, []);

    return <ModalContentWrap
        cancelModal={() => props.close()}
        icon={<IMoneyCheckIcon/>}
        title="Vytvořit odhadce"
    >
        <ModalInputRow>
            <LabeledTextInput label="Email" type="text" name="email"
                              defaultValue={""}
                              placeholder="Text..."
                              wide
                              valid={!props.errors.email}
                              ref={props.register}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput label="Telefon" type="text" name="phoneNumber"
                              defaultValue={""}
                              placeholder="Číslo.."
                              wide
                              valid={!props.errors.phoneNumber}
                              ref={props.register}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledMultiSelectInput label="Oceňované věci" name="awardThings"
                                     placeholder="Vyberte..."
                                     valid={!props.errors.awardThings}
                                     errorMessage={props.errors.awardThings?.message}
                                     defaultValues={["1"]}
                                     wide
                                     options={awardThings}
                                     ref={props.register}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledSelectInput label="Rozsah" name="range"
                                placeholder="Vyberte..."
                                defaultValue={2}
                                valid={!props.errors.range}
                                errorMessage={props.errors.range?.message}
                                wide
                                options={ranges}
                                ref={props.register}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledSelectInput label="Preferovaná lokalita" name="preferredLocation"
                                placeholder="Vyberte..."
                                defaultValue={1}
                                valid={!props.errors.preferredLocation}
                                errorMessage={props.errors.preferredLocation?.message}
                                wide
                                options={countryRegions}
                                ref={props.register}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledMultiSelectInput label="Platné banky" name="validBanks"
                                     placeholder="Vyberte..."
                                     valid={!props.errors.validBanks}
                                     errorMessage={props.errors.validBanks?.message}
                                     defaultValues={[]}
                                     wide
                                     options={BankMapper()}
                                     ref={props.register}
            />
        </ModalInputRow>
        <ModalSubmit>
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.close}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};

interface CreateRealEstateAppraiserModalProps {
    onCreate(): void;
}

const CreateRealEstateAppraiserModal: React.FC<CreateRealEstateAppraiserModalProps> = (props) => {
    const {onCreate} = props;
    const {register, handleSubmit, watch, errors, getValues, setValue} = useForm();
    const modal = useContext(ModalContext);
    const isCompany = watch("isCompany", false);
    const [modalStep, setModalStep] = useState<number>(1);
    const history = useHistory();
    const [firstModal, setFirstModal] = useState<any>({
        firstName: "",
        lastName: "",
        companyName: ""
    })

    const onFirstModalChange = (key: string, value: string): void => {
        setFirstModal({
            ...firstModal,
            [key]: value
        });
    };

    const onSubmit = async (data: any) => {
        const response = await createAppraiser({
            ...data,
            ...firstModal,
            range: parseInt(data.range),
            isCompany
        });
        if (response.status === 200) {
            const fetchedData = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.APPRAISER_DETAIL.replace(":slug", fetchedData.result))
        } else {
            toast.error("Odhadce se nepodařilo vytvořit.")
        }
    };

    return (<form onSubmit={handleSubmit(onSubmit)}>
        {modalStep === 1 && <FirstStepModal
            errors={errors}
            setValue={setValue}
            register={register}
            setFirstModal={onFirstModalChange}
            watch={watch}
            continue={() => setModalStep(2)}
            getValues={getValues}
            close={() => modal.closeCurrentModal()}/>}
        {modalStep === 2 && <SecondStepModal
            errors={errors}
            setValue={setValue}
            register={register}
            watch={watch}
            goBack={() => setModalStep(1)}
            getValues={getValues}
            close={() => modal.closeCurrentModal()}/>}
    </form>)
};

const ModalCheckboxContainer = styled.div`
  width: 100%;
  text-align: left;
`;

const ModalCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({theme}) => theme.colors.black};
  font-size: 16px;
  font-weight: 600;
`;

const ModalCheckedWrap = styled.span`
  display: none;
`;
const ModalUncheckedWrap = styled.span``;

const ModalCheckbox = styled.input`
  display: none;

  &:checked ~ ${ModalCheckedWrap} {
    display: inline-block;
  }

  &:checked ~ ${ModalUncheckedWrap} {
    display: none
  }
`;

export default CreateRealEstateAppraiserModal;