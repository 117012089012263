import React, { useState } from "react";
import styled from "styled-components";
import {ReactComponent as ICheckIcon} from "../../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import { defaultTheme } from "../../theme/defaultTheme";

interface StyledCheckboxInterface {
    name: string;
    label: string;
    defaultValue?: boolean;
    isSolo?: boolean;
    onChange?: (check: boolean) => void;
}

const StyledCheckboxInput = React.forwardRef(({name, label, defaultValue, onChange, isSolo}: StyledCheckboxInterface, ref: any) => {
    const [isChecked, setIsChecked] = useState<boolean>(defaultValue != undefined ? defaultValue : false);
    const handleChange = (e: any) => {
        setIsChecked(!isChecked);
        if(isSolo && onChange) onChange(isChecked);
    };
    return (<Container>
        <StyledCheckboxLabel htmlFor={name}>
            <CheckboxInput name={name} checked={isChecked} type="checkbox" id={name} ref={ref} onClick={handleChange}/>
            <BoxCheckedWrap><CheckWrap checked><ICheckIcon/></CheckWrap></BoxCheckedWrap>
            <BoxUncheckedWrap><CheckWrap checked={false}><ICrossIcon/></CheckWrap></BoxUncheckedWrap>
            {label}
        </StyledCheckboxLabel>
    </Container>)
});

const Container = styled.div`
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
`;

const StyledCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({theme}) => theme.colors.black};
    font-size: 16px;
    font-weight: 600;
`;

const BoxCheckedWrap = styled.span`
    display: none;
`;

const BoxUncheckedWrap = styled.span``;

const CheckboxInput = styled.input`
    display: none;
    &:checked ~ ${BoxCheckedWrap} {
        display: inline-block;
    }
    &:checked ~ ${BoxUncheckedWrap} {
        display: none
    }
`;

const CheckWrap = styled.div<{checked: boolean}>`
    width: 24px;
    height: 24px;
    margin-right: 16px;
    background-color: ${({theme, checked}) => checked ? theme.colors.blue : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({theme, checked}) => checked ? theme.colors.blue : theme.colors.black};
    border-radius: 4px;
    svg {
        width: 18px;
        height: 18px;
        fill: ${({theme, checked}) => checked ? theme.colors.white : 'transparent'};
    }
`;

export default StyledCheckboxInput;