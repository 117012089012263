import React, {ReactElement, useState} from "react";
import {
    LeftSide,
    PaginationWrapper,
    RightSide,
    Total,
    Bold,
    PageInput,
    Paginator
} from "./styled/pagination.styled";
import {
    ChevronDoubleLeftIcon, ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon, PagesWrapper
} from "./components/styled/pagination.styled";
import {generatePageButtons} from "./functions/buttonGenerator";
import PaginatorButton from "./components/PaginatorButton";
import SelectInput from "./components/SelectInput";

export interface PaginationInterface {
    pageNumber: number;
    pageSize: number;
    total: number;
    listed: number;

    onPageChange(page: number): void;
    onLimitChange(limit: number): void;
}

const Pagination = ({pageNumber, pageSize, total, listed, onPageChange, onLimitChange}: PaginationInterface): ReactElement => {
    const [pageInput, setPageInput] = useState<string>("1");

    const onPageSelectFinish = (): void => {
        const page = parseInt(pageInput);
        if (isNaN(page) || page > Math.ceil(total / pageSize) || page <= 0)
            setPageInput("1");
        else if (pageNumber !== page) onPageChange(page);
    };

    return <PaginationWrapper>
        <LeftSide>
            <Total bold>Strana</Total>
            <PageInput value={pageInput} type={'text'} onChange={(e) => setPageInput(e.target.value)}
                       onKeyPress={e => {
                           if (e.key === "Enter") onPageSelectFinish();
                       }} onBlur={onPageSelectFinish}
            />
            <Paginator>
                <PaginatorButton icon={ChevronDoubleLeftIcon} onClick={() => {
                    onPageChange(1);
                }} disabled={pageNumber === 1}/>
                <PaginatorButton icon={ChevronLeftIcon} onClick={() => {
                    onPageChange(pageNumber - 1);
                }} disabled={pageNumber <= 1}/>
                <PagesWrapper>{generatePageButtons(pageNumber, total, pageSize, onPageChange)}</PagesWrapper>
                <PaginatorButton icon={ChevronRightIcon} onClick={() => {
                    onPageChange(pageNumber + 1);
                }} disabled={pageNumber >= Math.ceil(total / pageSize)}/>
                <PaginatorButton icon={ChevronDoubleRightIcon} onClick={() => {
                    onPageChange(Math.ceil(total / pageSize));
                }} disabled={pageNumber === Math.ceil(total / pageSize)}/>
            </Paginator>
        </LeftSide>
        <RightSide>
            <Total>{(pageNumber - 1) * pageSize + 1}-{(pageNumber - 1) * pageSize + listed} z <Bold>{total}</Bold></Total>
            <SelectInput
                selected={pageSize}
                onChange={(e: number) => {
                    onLimitChange(e);
                    onPageChange(1);
                }}
            />
        </RightSide>
    </PaginationWrapper>;
};

export default Pagination;