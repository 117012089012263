import React from "react";
import styled from "styled-components";

interface ProfileColumnInterface {
    icon: React.ReactNode | string;
    title: React.ReactNode | string;
    subtitle: React.ReactNode | string;
}

const ProfileColumn = ({icon, title, subtitle}: ProfileColumnInterface) => {
    return (<Container>
        {(typeof icon == "string" || React.isValidElement(icon)) ? <>
            {React.isValidElement(icon) && <><br/><IconWrapper>{icon}</IconWrapper></>}
            {!React.isValidElement(icon) && <><br/><Picture src={icon as string} alt="Fotka"/></>}
        </> : <><br/><IconWrapper>{(icon as any).render()}</IconWrapper></>}
        <TitleWrapper>
            <Title>
                {title}
            </Title>
             <Subtitle>
                {subtitle}
            </Subtitle>
        </TitleWrapper>
    </Container>)
};

const Container = styled.div`
    display: flex;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    background-color: ${({theme}) => theme.colors.lighterGray};
    border-radius: 50%;
    svg {
        max-width: 18px;
        max-height: 20px;
    }
`;

const Picture = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 36px;
    max-height: 36px;
`;

const TitleWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.div`
    color: ${({theme}) => theme.colors.black};
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 3px;
`;

const Subtitle = styled.div`
    font-size: 12px;
    color: ${({theme}) => theme.colors.grayBlue};
`;

export default ProfileColumn;