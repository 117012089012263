import { getAccessToken } from '../../../../contexts/UserIdentityContext';
const createProjectDocument = async (projectId: number | string, data: any) => {
    const response = await fetch(`/api/v1/projects/${projectId}/documents`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: data,
    });
    return response;
};

export default createProjectDocument;