import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import fetchAllTasks from "../../../projects/api/tasks/fetchAllTasks";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../../../components/shared/filters/FilterDateInput";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as ICalendarIcon} from "../../../../resources/icons/calendar.svg";
import {ReactComponent as IFlagIcon} from "../../../../resources/icons/flag.svg";
import useFilters from "../../../../components/methods/useFilters";

const AllTasksSection = () => {
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        state: "",
        solver: "",
        date: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchAllTasks(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst úkoly.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
        <TableContainer>
            <FilterWrap maxWidth={"40%"}>
            <SearchBar name={"allTasksOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Řešitel`}
                onChange={(filters: any) => changeFilter("solver", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Stav`}
                onChange={(filters: any) => changeFilter("state", filters)}
                icon={<IFlagIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterDateInput
                label={`Datum plnění`}
                onChange={(date: string) => changeFilter("date", filters)}
                icon={<ICalendarIcon/>}
            />
            </FilterWrap>
            <Table headers={[
                {key: "dateOfFilling", value: "DATUM PLNĚNÍ", sortable: true},
                {key: "title", value: "NÁZEV", sortable: false},
                {key: "solver", value: "ŘEŠITEL", sortable: false},
                {key: "state", value: "STAV", sortable: false},
                {key: "dateOfEntry", value: "DATUM ZADÁNÍ", sortable: true},
                {key: "lastUpdate", value: "POSLEDNÍ AKTUALIZACE", sortable: true},
                {key: "partOfTask", value: "SOUČAST ZÁPISU Z", sortable: true},
                {key: "project", value: "PROJEKT", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.tasks ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.tasks?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </Container>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            stateBackground: true,
            id: o.id,
            dateOfEntry: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.finishedOn ? dayjs(o.finishedOn).format("DD.MM.YYYY") : "-"}</RegularBold>
            } secLine={
                <ColoredText text={o.finishedOn ? dayjs(o.finishedOn).format("HH:mm") : "-"} color={"grayBlue"}/>
            }/>)},
            title: {value: (<RegularBold>{o.name}</RegularBold>)},
            solver: {value: (<a href={Routes.USER_DETAIL.replace(":slug", o.assigneeId)}>{o.assigneeName}</a>)},
            state: {value: (<RegularBold>{o.state}</RegularBold>)},
            dateOfFilling: {value: (<DoubleLineValue firLine={
                <b>{dayjs(o.expectedFinishDate).format("DD.MM.YYYY")}</b>
            } secLine={
                <ColoredText text={`za ${dayjs(o.expectedFinishDate).diff(Date.now(), "day")} dní`} color={"grayBlue"} semibold/>
            }/>)},
            lastUpdate: {value: (<a>{o.lastUpdatedOn ? dayjs(o.lastUpdatedOn).format("DD.MM.YYYY") : "-"}</a>)},
            partOfTask: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.includedInList ? dayjs(o.includedInList).format("DD.MM.YYYY") : "-"}</RegularBold>
            } secLine={
                <ColoredText text={o.includedInList ? dayjs(o.includedInList).format("HH:mm") : "-"} color={"grayBlue"}/>
            }/>)},
            project: {value: (<a href={Routes.PROJECT_DETAIL.replace(":slug", o.projectId.toString())}>{o.projectInternalName}</a>)},
            link: `${Routes.PROJECT_TASK.replace(":slug", o.projectId.toString()).replace(":secondslug", o.id.toString())}`
        });
    });
    return result;
}

export default AllTasksSection;