import React, { useContext, useEffect, useState } from "react";
import { Switch, useHistory, useParams, Route } from "react-router";
import styled from "styled-components";
import IProfilePhoto from "../../../resources/images/TempProfilePhoto.jpg";
import { Routes } from "../../../router/Routes";
import PageHeader from "../../../components/shared/PageHeader";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import ProfileSection from "./components/ProfileSection";
import ProjectsSection from "./components/ProjectsSection";
import TasksSection from "./components/TasksSection";
import EditSection from "./components/EditSection";
import fetchUser from "../api/fetchUser";
import toast from "react-hot-toast";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import uploadUserImage from "../api/uploadUserImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

interface UserDetailInterface {
    slug: string;
}

const UserDetailPage = () => {
    const { slug }: UserDetailInterface = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userData, setUserData] = useState<any>({});
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const fetchUserData = async () => {
        setLoading(true);
        const response = await fetchUser(slug);
        if(response.status == 200) {
            let data = await response.json();
            data.result.contact.fullName = `${data.result.contact?.firstName} ${data.result.contact?.lastName}`;
            setUserData(data.result);
            setLoading(false);
        } else {
            toast.error("Uživatel nenalezen!");
        }
    };
    useEffect(() => {
        buttons.clear();
        fetchUserData();
    }, [])

    const handleUpload = async (e: any) => {
        const response = await uploadUserImage(slug, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            fetchUserData();
            toast.success("Fotka uživatele byla změněna.");
        } else {
            toast.error("Fotku uživatele se nepodařilo změnit.");
        }
    };
    return (<>{!loading && <Container>
        <PageHeader title={userData.contact?.fullName} onUpload={handleUpload} subtitle={`Uživatelé — ${userData.contact?.fullName}`} returnPath={Routes.USERS} image={getPhotoFromCdn(userData.imageName)}/>
        {!history.location.pathname.includes("upravit") && <CategoryButtons buttons={[
                {
                    text: "Profil",
                    route: Routes.USER_DETAIL,
                    index: slug,
                    exact: true,
                },
                {
                    text: "Projekty",
                    route: Routes.USER_DETAIL_PROJECTS,
                    index: slug,
                    exact: true,
                },
                {
                    text: "Úkoly",
                    route: Routes.USER_DETAIL_TASKS,
                    index: slug,
                    exact: true,
                },
        ]}/>}
        <Switch>
            <Route render={() => <ProfileSection data={userData}/>} path={Routes.USER_DETAIL} exact/>
            <Route render={() => <ProjectsSection/>} path={Routes.USER_DETAIL_PROJECTS} exact/>
            <Route render={() => <TasksSection/>} path={Routes.USER_DETAIL_TASKS} exact/>
            <Route render={() => <EditSection data={userData} onEdit={fetchUserData}/>} path={Routes.USER_DETAIL_EDIT} exact/>
        </Switch>
    </Container>}</>)
};

const Container = styled.div`
    padding: 0 24px;
`;

export default UserDetailPage;