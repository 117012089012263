import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import {ReactComponent as ISquareIcon} from "../../../resources/icons/square2.svg";
import {ReactComponent as ICheckIcon} from "../../../resources/icons/square-check.svg";
import {ReactComponent as IGearIcon} from "../../../resources/icons/gear.svg";
import {ReactComponent as ISCheckIcon} from "../../../resources/icons/check.svg";
import { FilterSelectButton, FilterSubmitButton, FilterContainer } from "./Filter.styled";
import SearchBar from "../inputs/SearchBar";

interface FilterInterface {
    label: string;
    key: number;
    imgUrl?: string;
}

interface FilterSelectInterface {
    label: string;
    icon?: React.ReactNode;
    multiple?: boolean;
    onChange(filters: string): void;
    filters: Array<FilterInterface>;
}

const FilterSelectInput = ({label, icon, multiple, onChange, filters}: FilterSelectInterface) => {
    const [open, setOpen] = useState<boolean>(false);
    const [savedFilters, setSavedFilters] = useState<number[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
    const [search, setSearch] = useState<string>("");
    const handleFilterChange = (key: number) => {
        const copFilters = [...selectedFilters];
        const filterId = copFilters.indexOf(key);
        if(multiple) {
            if(filterId == -1) {
                copFilters.push(key);
            } else {
                copFilters.splice(filterId, 1);
            }
        } else {
            if(copFilters.length == 0 && filterId == -1) {
                copFilters.push(key);
            } else if(copFilters.length > 0 && filterId != -1) {
                copFilters.splice(filterId, 1);
            }
        };
        setSelectedFilters(copFilters);
    };
    const handleClose = () => {
        if(open) setSelectedFilters([]);
        if(!open) {
            const copFilters = [...savedFilters];
            setSelectedFilters(copFilters);
        };
        setOpen(!open);
    };
    const handleSubmit = () => {
        const copFilters = [...selectedFilters];
        setSavedFilters(copFilters);
        onChange(copFilters.join(","));
        handleClose();
    };
    return (<div>
        <FilterSelectButton onClick={handleClose} onReset={() => {
            setSavedFilters([]);
            setSelectedFilters([]);
            const copFilters = [...selectedFilters];
            onChange(copFilters.join(","));
        }} icon={icon ? icon : <IGearIcon/>} isActive={savedFilters.length > 0}>
            {label}
        </FilterSelectButton>
        {open && <FilterContainer>
            {filters.length > 3 && <SearchBar
                name={`filterSelect${label.toUpperCase()}`}
                notWide={true}
                placeholder="Hledat..."
                onSearch={(search: string) => setSearch(search)}
            />}
            <FiltersCap cap={filters.length > 3}>
                {filters.map((filter) => {
                    const selected = selectedFilters.indexOf(filter.key) != -1;
                    if(filter.label.toLowerCase().includes(search.toLowerCase())) {
                        return (<Filter htmlFor={`${filter.key}-filter`} onClick={() => handleFilterChange(filter.key)} selected={selected}>
                            <CheckWrap selected={selected}>
                                {selected ? <ICheckIcon/> : <ISquareIcon/>}
                            </CheckWrap>
                            {filter.imgUrl && <Image src={filter.imgUrl} alt={filter.label}/>}
                            {filter.label}
                        </Filter>)
                    }
                })}
            </FiltersCap>
            <FilterSubmitButton onClick={handleSubmit}>
                <SCheckIcon/> Potvrdit vybrané
            </FilterSubmitButton>
        </FilterContainer>}
    </div>)
};
const Filter = styled.label<{selected: boolean}>`
    display: flex;
    justify-content: left;
    align-items: center;
    ${({theme, selected}) => selected && `background-color: ${theme.colors.selectBlue}`};
    border-radius: 4px;
    margin: 0 8px;
    &:not(:first-of-type) {
        margin-top: 6px;
    };
    padding: 8px;
    width: 256px;
`;

const CheckWrap = styled.div<{selected: boolean}>`
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    svg {
        width: 18px;
        height: 18px;
        fill: ${({theme, selected}) => selected ? theme.colors.blue : theme.colors.grayBlue};
    }
    transition: 0.2s all;
`;

const SCheckIcon = styled(ISCheckIcon)`
    fill: ${({theme}) => theme.colors.white};
    height: 16px;
    width: 16px;
`;

const FiltersCap = styled.div<{cap: boolean}>`
    ${({cap}) => cap && `
        max-height: 205px;
        overflow-y: scroll;
    `};
`;

const Image = styled.img`
    margin-right: 8px;
    border-radius: 50%;
    object-fit: cover;
    width: 23px;
    max-height: 23px;
`;

export default FilterSelectInput;