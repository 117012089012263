import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import IPhoto from "../../../resources/images/TempProfilePhoto.jpg";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import { Route, Switch, useHistory, useParams } from "react-router";
import ProfileSection from "./components/ProfileSection";
import ProjectsSection from "./components/ProjectsSection";
import ScoringSection from "./components/ScoringSection";
import EditSection from "./components/EditSection";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import fetchInvestor from "../api/investors/fetchInvestor";
import deleteInvestor from "../api/investors/deleteInvestor";
import toast from "react-hot-toast";
import uploadInvestorImage from "../api/investors/uploadInvestorImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

const InvestorDetailPage = () => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const fetchData = async () => {
        const response = await fetchInvestor(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Investora se nepodařilo načíst.")
        };
    };
    const handleUpload = async (e: any) => {
        const response = await uploadInvestorImage(slug, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Fotka investora byla změněna.");
            fetchData();
        } else {
            toast.error("Fotku investora se nepodařilo změnit.");
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat investora?")) {
                    const response = await deleteInvestor(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        toast.success("Investor smazán.");
                        history.push(Routes.PROJECTS_INVESTORS);
                    } else {
                        toast.error("Investora se nepodařilo smazat");
                    }
                }
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export investor")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_INVESTOR_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
        fetchData()
    }, []);
    return (<Container>
        {!loading && <><HeaderWrap>
            <PageHeader title={data.investor?.investorName} subtitle={`Projekty   —   Investoři   —   ${data.investor?.investorName}`}
            image={getPhotoFromCdn(data.imageName)}
            noContainerBottomMargin={history.location.pathname.includes("upravit")}
            noContainerLeftMargin
            onUpload={handleUpload}
            returnPath={Routes.PROJECTS_INVESTORS}/>
        </HeaderWrap>
        {!history.location.pathname.includes("upravit") && <CategoryButtons buttons={[
            {
                text: "Profil",
                index: slug,
                route: Routes.PROJECT_INVESTOR_DETAIL,
                exact: true,
            },
            {
                text: "Projekty",
                index: slug,
                route: Routes.PROJECT_INVESTOR_DETAIL_PROJECTS,
                exact: true,
            },
            {
                text: "Scoring",
                index: slug,
                route: Routes.PROJECT_INVESTOR_DETAIL_SCORING,
                exact: true,
            },
        ]}/>}
        <Switch>
            <Route path={Routes.PROJECT_INVESTOR_DETAIL} render={() => <ProfileSection data={data}/>} exact/>
            <Route path={Routes.PROJECT_INVESTOR_DETAIL_PROJECTS} component={ProjectsSection} exact/>
            <Route path={Routes.PROJECT_INVESTOR_DETAIL_SCORING} component={ScoringSection} exact/>
            <Route path={Routes.PROJECT_INVESTOR_DETAIL_EDIT} render={() => <EditSection data={data} onEdit={fetchData}/>} exact/>
        </Switch></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const HeaderWrap = styled.div`
    padding: 8px;
`;

export default InvestorDetailPage;