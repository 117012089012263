import {getAccessToken} from "../../../contexts/UserIdentityContext";

const changePassword = async (data: any) => {
    const response = await fetch(`/api/v1/authentication/change-password`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
    });
    return response;
};

export default changePassword;