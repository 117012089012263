import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand-holding-dollar.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput, {OptionInterface} from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import ModalRowTitle from "../../components/shared/modal/ModalRowTitle";
import ModalChecked from "../../components/shared/modal/ModalChecked";
import ModalUnchecked from "../../components/shared/modal/ModalUnchecked";
import {useHistory} from "react-router-dom";
import {Routes} from "../../router/Routes";
import { ModalContext } from "../../components/layout/Layout";
import createInvestor from "./api/investors/createInvestor";
import toast from "react-hot-toast";
import {ModalTileItem, ModalTileSelector} from "../../components/shared/modal/ModalTileSelector";
import {GetProjectStateOptions, GetProjectTypeInfo, GetProjectTypes} from "../../enums/ProjectEnums";
import {ReactComponent as ITrophyIcon} from "../../resources/icons/building-columns.svg";
import {GetInvestorTypeLabel, GetInvestorTypes} from "../../enums/InvestorEnums";
import {GetRETypes, RealEstateType} from "../../enums/RealEstateEnums";
import {fetchSelectRealEstates} from "./api/fetchSelects";


interface FirstStepModalProps {
    investorType: number;

    setInvestorType(investorType: number): void;

    continue(): void;

    close(): void;
}

const FirstStepModal: React.FC<FirstStepModalProps> = (props) => {
    const tiles = useMemo<ModalTileItem[]>(() => GetInvestorTypes().map(t => ({name: t.label, value: t.id.toString(), icon: t.icon })), []);

    return <ModalContentWrap
        cancelModal={props.close}
        icon={<ITrophyIcon/>}
        title="Nový investor"
        subtitle="Pro pokračování vyberte typ investora."
    >
        <ModalTileSelector selectedValue={props.investorType.toString()} setSelectedValue={(value) => props.setInvestorType(parseInt(value))} tiles={tiles}/>
        <ModalSubmit onClick={props.continue}>
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.close}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};


interface SecondStepModalProps {
    investorType: number;
    register: any;
    errors: any;
    close(): void;
    goBack(): void;
    isCompany?: boolean;
}

const SecondStepModal: React.FC<SecondStepModalProps> = (props) => {
    const {investorType, register, errors, close, goBack, isCompany} = props;

    useEffect(() => {
        document.getElementsByName("firstName")[0].focus()
    }, []);

    return <ModalContentWrap
        cancelModal={close}
        icon={<IHandIcon/>}
        title={`Vytvořit investora typu ${GetInvestorTypeLabel(investorType).toUpperCase()}`}
    >
        <ModalCheckboxContainer>
            <ModalCheckboxLabel htmlFor="isCompany">
                <ModalCheckbox name="isCompany" type="checkbox" id="isCompany" ref={register}/>
                <ModalCheckedWrap><ModalChecked/></ModalCheckedWrap>
                <ModalUncheckedWrap><ModalUnchecked/></ModalUncheckedWrap>
                Investor je právnická osoba
            </ModalCheckboxLabel>
        </ModalCheckboxContainer>
        {isCompany && <>
            <ModalRowTitle title="Údaje společnosti"/>
            <ModalInputRow>
                <LabeledTextInput
                    label='Název'
                    type="text"
                    name="companyName"
                    valid={!errors.companyName}
                    errorMessage={errors.companyName?.message}
                    placeholder="Text..."
                    defaultValue=""
                    ref={register({required: {value: true, message: "Název je povinný."}})}
                />
                <LabeledTextInput
                    label='IČO'
                    type="text"
                    name="identificationNumber"
                    valid={!errors.identificationNumber}
                    errorMessage={errors.identificationNumber?.message}
                    placeholder="Číslo..."
                    defaultValue=""
                    ref={register({required: {value: true, message: "Identifikační číslo je povinné."}})}
                />
            </ModalInputRow>
        </>}
        <ModalRowTitle title="Údaje kontaktní osoby"/>
        <ModalInputRow>
            <LabeledTextInput
                label='Jméno'
                type="text"
                name="firstName"
                valid={!errors.firstName}
                errorMessage={errors.firstName?.message}
                placeholder="Jméno..."
                defaultValue=""
                ref={register({required: {value: true, message: "Jméno je povinné."}})}
            />
            <LabeledTextInput
                label='Příjmení'
                type="text"
                name="lastName"
                valid={!errors.lastName}
                errorMessage={errors.lastName?.message}
                placeholder="Příjmení..."
                defaultValue=""
                ref={register({required: {value: true, message: "Příjmení je povinné."}})}
            />
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={goBack}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};


const CreateInvestorModal = () => {
    const [modalStep, setModalStep] = useState<number>(1);
    const [investorType, setInvestorType] = useState<number>(1);
    const {register, handleSubmit, errors, watch} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const isCompany = watch("isCompany", false);

    const onSubmit = async (data: any) => {
        data = {...data, type: investorType };
        const response = await createInvestor(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.PROJECT_INVESTOR_DETAIL.replace(":slug", fetchedData.result));
        } else {
            toast.error("Investora se nepodařilo vytvořit.")
        }
    };
    
    return (<form onSubmit={handleSubmit(onSubmit)}>
        {modalStep === 1 && <FirstStepModal investorType={investorType}
                                            setInvestorType={setInvestorType}
                                            continue={() => setModalStep(2)}
                                            close={() => modal.closeCurrentModal()}/>}

        {modalStep === 2 && <SecondStepModal investorType={investorType}
                                             register={register}
                                             errors={errors}
                                             isCompany={isCompany}
                                             close={() => modal.closeCurrentModal()}
                                             goBack={() => setModalStep(1)} />}
    </form>)
};

const ModalCheckboxContainer = styled.div`
    width: 100%;
    text-align: left;
`;

const ModalCheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${({theme}) => theme.colors.black};
    font-size: 16px;
    font-weight: 600;
`;

const ModalCheckedWrap = styled.span`
    display: none;
`;
const ModalUncheckedWrap = styled.span``;

const ModalCheckbox = styled.input`
    display: none;
    &:checked ~ ${ModalCheckedWrap} {
        display: inline-block;
    }
    &:checked ~ ${ModalUncheckedWrap} {
        display: none
    }
`;

export default CreateInvestorModal;