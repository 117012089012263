import styled from "styled-components";
import {AdminFormLabel, AdminFormInput} from "../../../components/shared/forms/styled/inputs.styled.";

export const LoginInputWrapper = styled.div`
  position: relative;
  text-align: left;
  margin-bottom: 24px;
`;

export const LoginInputLabel = styled(AdminFormLabel)<{ valid?: boolean; }>`
  color: ${props => !props.valid ? props.theme.colors.red : props.theme.colors.darkGray};
`;

export const LoginInputBox = styled(AdminFormInput)<{ valid?: boolean; }>`
  border: 1px solid ${props => !props.valid ? props.theme.colors.red : props.theme.colors.darkGray};

  &:focus {
    border: 1px solid ${props => props.theme.colors.green};
  }
`;