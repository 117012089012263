import React from "react";
import styled from "styled-components";

const ModalUnchecked = () => {
    return (<Container>
        <Circle/>
    </Container>)
};

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 34px;
    height: 19px;
    border-radius: 15px;
    background-color: ${({theme}) => theme.colors.white};
    border: 2px solid ${({theme}) => theme.colors.black};
    margin-right: 12px;
    position: relative;
`;

const Circle = styled.div`
    position: absolute;
    min-width: 10px;
    min-height: 10px;
    background-color: ${({theme}) => theme.colors.white};
    border: 2px solid ${({theme}) => theme.colors.black};
    border-radius: 50%;
    left: 0.25rem;
`;

export default ModalUnchecked;