import { getAccessToken } from './../../../contexts/UserIdentityContext';
const updateRealEstateEstimation = async (id: number, data: any) => {
    const response = await fetch(`/api/v1/realestates/${id}/estimation`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data),
    })
    return response
};

export default updateRealEstateEstimation;