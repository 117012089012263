const LOGIN = "/login";
const FORGOT_PASSWORD = "/zapomneni-hesla";
const RESET_PASSWORD = "/obnoveni-hesla";

const PROJECTS = "/projekty";

const PROJECTS_FINANCE = "/projekty/finance";
const PROJECT_FINANCE_DETAIL = "/projekty/finance/detail/:slug";
const PROJECT_FINANCE_DETAIL_EDIT = "/projekty/finance/detail/:slug/upravit";

const PROJECTS_INVESTORS = "/projekty/investori";
const PROJECT_INVESTOR_DETAIL = "/projekty/investori/:slug";
const PROJECT_INVESTOR_DETAIL_PROJECTS = "/projekty/investori/:slug/projekty";
const PROJECT_INVESTOR_DETAIL_SCORING = "/projekty/investori/:slug/scoring";
const PROJECT_INVESTOR_DETAIL_EDIT = "/projekty/investori/:slug/upravit";

const PROJECTS_PROVIDERS = "/projekty/zprostredkovatele";
const PROJECT_PROVIDER_DETAIL = "/projekty/zprostredkovatele/:slug";
const PROJECT_PROVIDER_DETAIL_PROJECTS = "/projekty/zprostredkovatele/:slug/projekty";
const PROJECT_PROVIDER_DETAIL_SCORING = "/projekty/zprostredkovatele/:slug/scoring";
const PROJECT_PROVIDER_DETAIL_EDIT = "/projekty/zprostredkovatele/:slug/upravit";

const PROJECT_DETAIL = "/projekty/detail/:slug";
const PROJECT_DETAIL_ANALYTICS = "/projekty/detail/:slug/analytika";
const PROJECT_DETAIL_ANALYTICS_EDIT = "/projekty/detail/:slug/analytika/upravit";
const PROJECT_DETAIL_CLIENT = "/projekty/detail/:slug/klient";
const PROJECT_DETAIL_CLIENT_EDIT = "/projekty/detail/:slug/klient/upravit";
const PROJECT_DETAIL_INVESTORS = "/projekty/detail/:slug/investori";
const PROJECT_DETAIL_SECURED = "/projekty/detail/:slug/zajisteni";
const PROJECT_DETAIL_DOCUMENTS = "/projekty/detail/:slug/dokumenty";
const PROJECT_DETAIL_TEAM = "/projekty/detail/:slug/tym";
const PROJECT_DETAIL_INVESTMENT_LOGS = "/projekty/detail/:slug/investicni-zapisy";
const PROJECT_DETAIL_TASKS = "/projekty/detail/:slug/ukoly";
const PROJECT_DETAIL_EDIT = "/projekty/detail/:slug/upravit";
const PROJECT_INVESTMENT_LOG_DETAIL = "/projekty/detail/:slug/investicni-zapisy/:secondslug";
const PROJECT_INVESTMENT_LOG_DETAIL_EDIT = "/projekty/detail/:slug/investicni-zapisy/:secondslug/upravit";

const PROJECT_TASK = "/projekty/detail/:slug/ukol/:secondslug";
const PROJECT_TASK_EDIT = "/projekty/detail/:slug/ukol/:secondslug/upravit";

const REAL_ESTATE = "/nemovitosti";
const REAL_ESTATE_DETAIL = "/nemovitosti/:slug";
const REAL_ESTATE_DETAIL_EDIT = "/nemovitosti/:slug/upravit";
const REAL_ESTATE_DETAIL_RENTERS = "/nemovitosti/:slug/najemnici";
const REAL_ESTATE_RENTER_DETAIL = "/nemovitosti/detail/:slug/najemnici/:secondslug";
const REAL_ESTATE_RENTER_DETAIL_EDIT = "/nemovitosti/detail/:slug/najemnici/:secondslug/upravit";
const REAL_ESTATE_ESTIMATION_DETAIL = "/nemovitosti/:slug/odhad";
const REAL_ESTATE_ESTIMATION_DETAIL_EDIT = "/nemovitosti/:slug/odhad/upravit";

const APPRAISERS = "/odhadci";
const APPRAISER_DETAIL = "/odhadci/:slug";
const APPRAISER_DETAIL_ESTIMATES = "/odhadci/:slug/odhady";
const APPRAISER_DETAIL_EDIT = "/odhadci/:slug/upravit";

const TASKS = "/ukoly";
const TASKS_ONLY_TEAMS = "/ukoly/moje-tymy";
const TASKS_ONLY_USERS = "/ukoly/moje";

const DOCUMENTS = "/dokumenty";
const DOCUMENT_DETAIL = "/dokumenty/:slug";
const DOCUMENT_DETAIL_EDIT = "/dokumenty/:slug/upravit";

const USERS = "/uzivatele";
const USER_DETAIL = "/uzivatele/:slug";
const USER_DETAIL_PROJECTS = "/uzivatele/:slug/projekty";
const USER_DETAIL_TASKS = "/uzivatele/:slug/ukoly";
const USER_DETAIL_EDIT = "/uzivatele/:slug/upravit";

const SETTINGS = "/moznosti";
const SETTINGS_PROFILE = "/moznosti/profil"
const SETTINGS_PROFILE_PROJECTS = "/moznosti/profil/projekty";
const SETTINGS_PROFILE_TASKS = "/moznosti/profil/ukoly";
const SETTINGS_SECURITY = "/moznosti/zabezpeceni";
const SETTINGS_LOGS = "/moznosti/zaznamy";
const SETTINGS_LOGS_TASKS = "/moznosti/zaznamy/ukoly";
const SETTINGS_LOGS_REAL_ESTATE = "/moznosti/zaznamy/nemovitosti";

export const Routes = {
    LOGIN,
    FORGOT_PASSWORD,
    RESET_PASSWORD,

    PROJECTS,
    
    PROJECTS_FINANCE,
    PROJECT_FINANCE_DETAIL,
    PROJECT_FINANCE_DETAIL_EDIT,
    
    PROJECTS_INVESTORS,
    PROJECT_INVESTOR_DETAIL,
    PROJECT_INVESTOR_DETAIL_PROJECTS,
    PROJECT_INVESTOR_DETAIL_SCORING,
    PROJECT_INVESTOR_DETAIL_EDIT,
    
    PROJECTS_PROVIDERS,
    PROJECT_PROVIDER_DETAIL,
    PROJECT_PROVIDER_DETAIL_PROJECTS,
    PROJECT_PROVIDER_DETAIL_SCORING,
    PROJECT_PROVIDER_DETAIL_EDIT,

    PROJECT_DETAIL,
    PROJECT_DETAIL_ANALYTICS,
    PROJECT_DETAIL_ANALYTICS_EDIT,
    PROJECT_DETAIL_CLIENT,
    PROJECT_DETAIL_CLIENT_EDIT,
    PROJECT_DETAIL_DOCUMENTS,
    PROJECT_DETAIL_INVESTMENT_LOGS,
    PROJECT_DETAIL_INVESTORS,
    PROJECT_DETAIL_TEAM,
    PROJECT_DETAIL_SECURED,
    PROJECT_DETAIL_TASKS,
    PROJECT_DETAIL_EDIT,
    PROJECT_INVESTMENT_LOG_DETAIL,
    PROJECT_INVESTMENT_LOG_DETAIL_EDIT,

    PROJECT_TASK,
    PROJECT_TASK_EDIT,

    REAL_ESTATE,
    REAL_ESTATE_DETAIL,
    REAL_ESTATE_DETAIL_EDIT,
    REAL_ESTATE_DETAIL_RENTERS,

    REAL_ESTATE_RENTER_DETAIL,
    REAL_ESTATE_RENTER_DETAIL_EDIT,

    REAL_ESTATE_ESTIMATION_DETAIL,
    REAL_ESTATE_ESTIMATION_DETAIL_EDIT,

    APPRAISERS,
    APPRAISER_DETAIL,
    APPRAISER_DETAIL_EDIT,
    APPRAISER_DETAIL_ESTIMATES,

    TASKS,
    TASKS_ONLY_TEAMS,
    TASKS_ONLY_USERS,

    DOCUMENTS,
    DOCUMENT_DETAIL,
    DOCUMENT_DETAIL_EDIT,

    USERS,
    USER_DETAIL,
    USER_DETAIL_PROJECTS,
    USER_DETAIL_TASKS,
    USER_DETAIL_EDIT,

    SETTINGS,
    SETTINGS_PROFILE,
    SETTINGS_PROFILE_PROJECTS,
    SETTINGS_PROFILE_TASKS,
    SETTINGS_SECURITY,
    SETTINGS_LOGS,
    SETTINGS_LOGS_TASKS,
    SETTINGS_LOGS_REAL_ESTATE,
};