import { getAccessToken } from '../../../contexts/UserIdentityContext';
const updateRealEstateRenter = async (realEstateId: number | string, data: any) => {
    const response = await fetch(`/api/v1/realestates/${realEstateId}/renters/${data.renterId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data),
    })
    return response
};

export default updateRealEstateRenter;