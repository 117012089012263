import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {Route, Switch, useHistory, useParams} from "react-router";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import {InfoBoxColumn, InfoBoxFullContainer} from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import PageHeader from "../../../components/shared/PageHeader";
import {ReactComponent as ITagIcon} from "../../../resources/icons/tag.svg";
import {ReactComponent as IInfoIcon} from "../../../resources/icons/circle-info.svg";
import {ReactComponent as ITimelineIcon} from "../../../resources/icons/timeline-arrow.svg";
import {Routes} from "../../../router/Routes";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import {UtilityButtonsContext} from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import toast from "react-hot-toast";
import fetchTransaction from "../api/finance/fetchTransaction";
import deleteTransaction from "../api/finance/deleteTransaction";
import {GetTransactionTypeLabel} from "../../../enums/FinanceEnums";
import dayjs from "dayjs";
import {formatPrice} from "../../../shared/utils/format";

const FinanceDetailPage = () => {
    const {slug}: { slug: string } = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const fetchFinanceData = async () => {
        const response = await fetchTransaction(slug);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst transakci.")
        }
    };

    useEffect(() => {
        fetchFinanceData();

        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Smazat"
                color="red"
                icon={<ITrashIcon/>}
                onClick={async () => {
                    if(window.confirm("Doopravdy chcete smazat transakci?")) {
                        const response = await deleteTransaction(slug);
                        if(response.status == 200) {
                            const fetchedData = await response.json();
                            history.push(Routes.PROJECTS_FINANCE);
                            toast.success("Transakce smazána.");
                        } else {
                            toast.error("Transakci se nepodařilo smazat.");
                        };
                    };
                }}/>
            <UtilityButton
                title="Exportovat"
                color="gray"
                icon={<ICloudIcon/>}
                onClick={() => console.log("export finance")}/>
            <UtilityButton
                title="Upravit"
                color="gray"
                icon={<IPenIcon/>}
                onClick={() => history.push(Routes.PROJECT_FINANCE_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<Container> {!loading && <>
        <PageHeader title={`Náklad ${data.general.internalName}`} subtitle={`Projekty   —   Finance   —  ${data.general.internalName}`} returnPath={Routes.PROJECTS_FINANCE}/>
        <BoxContainer>
            <InfoBoxFullContainer>
                <InfoBoxColumn>
                    <InfoBox title="Položka" icon={<ITagIcon/>}>
                        <InfoBoxItem title="Označení" text={data.general.internalName}/>
                        <InfoBoxItem title="Kategorie" text={GetTransactionTypeLabel(data.general?.type)}/>
                        <InfoBoxItem title="Datum" text={dayjs(data.general.transactionDate).format(`DD.MM.YYYY`)}/>
                        <InfoBoxItem title="Částka" text={<b>{formatPrice(data.general.amount)} Kč</b>}/>
                        <InfoBoxItem title="Podíl ERICONN" text={`${data.general.ericonnPercentage} % = ${formatPrice(data.general.amount * (data.general.ericonnPercentage) / 100)} Kč`}/>
                        <InfoBoxItem title="Podíl investorů" text={`${data.general.investorPercentage} % = ${formatPrice(data.general.amount * (data.general.investorPercentage) / 100)} Kč`}/>
                        <InfoBoxItem title="Projekt" text={<a href={Routes.PROJECT_DETAIL.replace(":slug", data.general.projectId)}>{data.general.projectName}</a>}/>
                    </InfoBox>
                    <InfoBox title="Popis" icon={<IInfoIcon/>}>
                        {data.note}
                    </InfoBox>
                </InfoBoxColumn>
                <InfoBoxColumn>
                    <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                        <InfoBoxItem title="Vytvořen" text={<>{
                            dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                        } <span>{
                            dayjs(data.activity?.createdOn).format(`HH:mm`)
                        }</span></>}/>
                        <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                            {data.activity?.createdByName}
                        </a> : ""}/>
                        <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                            dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                        } <span>{
                            dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                        }</span></> : ""}/>
                        <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                            {data.activity?.lastUpdatedByName}
                        </a> : ""}/>
                        <InfoBoxItem title="Součástí investičního zápisu" text={"-"}/>
                    </InfoBox>
                </InfoBoxColumn>
            </InfoBoxFullContainer>
        </BoxContainer>
    </>}
    </Container>)
}
;

const Container = styled.div`
padding: 0 24px;
`;

const BoxContainer = styled.div`
margin-top: -32px;
`;

export default FinanceDetailPage;