import { getAccessToken } from "../../../contexts/UserIdentityContext";

const fetchRealEstate = async (id: string) => {
    const response = await fetch(`/api/v1/realestates/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        }
    });
    return response;
};

export default fetchRealEstate;