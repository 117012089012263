import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import Pagination from "../../../../components/pagination/Pagination";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-pen.svg";
import {ReactComponent as ISackIcon} from "../../../../resources/icons/sack.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import useFilters from "../../../../components/methods/useFilters";

const ProjectSection = () => {
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        type: "",
        amountFrom: "", amountTo: "",
        ericonnCutFrom: "", ericonnCutTo: "",
        investorCutFrom: "", investorCutTo: "",
    });

    useEffect(() => {
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
        <TableContainer>
            <FilterWrap maxWidth={"75%"}>
                <SearchBar name={"projectsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                    label={`Typ`}
                    onChange={(filters: any) => changeFilter("type", filters)}
                    icon={<IMoneyCheckIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterRangeInput
                    label={`Částka`}
                    icon={<ISackIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["amountFrom", "amountTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl ERICONN`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["ericonnCutFrom", "ericonnCutTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl investorů`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["investorCutFrom", "investorCutTo"], [values.from, values.to])}
                />
            </FilterWrap>
        <Table
            headers={[
                {key: "date", value: "DATUM", sortable: true},
                {key: "original", value: "PŮVODCE", sortable: true},
                {key: "goal", value: "CÍL", sortable: false},
                {key: "description", value: "POPIS", sortable: false},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper([{id: 1}, {id: 2}, {id: 3} ,{id: 4}])}/>
            <Pagination
                total={30}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={10}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </Container>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            date: {value: (<DoubleLineValue firLine={
                <RegularBold>25.10.2021</RegularBold>
            } secLine={
                <ColoredText text={"18:24"} color={"grayBlue"}/>
            }/>)},
            original: {value: (<a>Adam Petřík</a>)},
            goal: {value: ("01_21_Tirpák_Kadaň")},
            description: {value: (<RegularBold>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </RegularBold>)},
            link: `${Routes.PROJECT_DETAIL.replace(":slug", o.id.toString())}`,
        });
    });
    return result;
}

export default ProjectSection;