import styled from 'styled-components';
import {ReactComponent as HChevronDown} from "../../../../resources/icons/chevron-down.svg";

export const SelectInputWrapper = styled.div<{active: boolean, fit?: boolean}>`
    position: relative;
    border: 1px solid ${props => props.theme.colors.lighterGray};
    border-radius: 4px;
    font-family: 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-left: 12px;
    width: ${props => props.fit ? 'fit-content' : 'auto'};
`;

export const SelectText = styled.div`
    padding: 9px 36px 9px 16px;
    user-select: none;
    cursor: pointer;
    position: relative;
    color: ${props => props.theme.colors.black};
`;

export const SelectList = styled.ul<{active: boolean}>`
    position: absolute;
    width: calc(100% - 2px);
    height: fit-content;
    background-color: ${props => props.theme.colors.lightGray};
    border: 1px solid ${props => props.theme.colors.gray};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    bottom: 31px;
    z-index: 2;
    right: 0;
    max-height: 100px;
    padding: 3px 0;
    display: ${props => props.active ? "block" : "none"};
`;

export const SelectItem = styled.li`
    padding: 3px 10px;
    text-align: center;
    transition: .3s background-color;
    color: ${props => props.theme.colors.black};
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.gray};
    }
`;

export const ChevronDown = styled(HChevronDown)`    
    width: 12px;
    height: 16px;
    position: absolute;    
    top: 8px;
    margin-left: 12px;
    fill: ${props => props.theme.colors.black};
`;