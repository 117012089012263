import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import CreateProviderModal from "./CreateProviderModal";
import {HighlightedText, TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import InvestmentLogsPreview from "../../components/shared/previews/InvestmentLogsPreview";
import ProfileColumn from "../../components/table/components/ProfileColumn";
import IPhoto from "../../resources/images/TempProfilePhoto.jpg";
import ColoredText from "../../components/table/components/ColoredText";
import DoubleLineValue from "../../components/table/components/DoubleLineValue";
import fetchProviders from "./api/provider/fetchProviders";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import SearchBar from "../../components/shared/inputs/SearchBar";
import {getPhotoFromCdn} from "../../shared/api/photoApi";
import Pagination from "../../components/pagination/Pagination";
import FilterDateInput from "../../components/shared/filters/FilterDateInput";
import FilterRangeInput from "../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import {ReactComponent as ITrophyIcon} from "../../resources/icons/trophy.svg";
import {ReactComponent as ICalendarIcon} from "../../resources/icons/calendar.svg";
import {ReactComponent as IRewindIcon} from "../../resources/icons/clock-rotate-left.svg";
import useFilters from "../../components/methods/useFilters";
import {formatPrice} from "../../shared/utils/format";

const ProjectProvidersOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    
    const [filters, changeFilter] = useFilters({
        actInvestmentsFrom: "", actInvestmentsTo: "",
        hisInvestmentsFrom: "", hisInvestmentsTo: "",
        date: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit zprostředkovatele"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateProviderModal/>)
                }}
            />
        </>)
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProviders(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepovedlo se načíst zprostředkovatele.");
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);
    return (<Container>
         <TableContainer>
            <FilterWrap maxWidth={"95%"}>
            <SearchBar name={"providersOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterRangeInput
                    label={`Aktivní investice`}
                    icon={<ITrophyIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["actInvestmentsFrom", "actInvestmentsTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Historické investice`}
                    icon={<IRewindIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["hisInvestmentsFrom", "hisInvestmentsTo"], [values.from, values.to])}
                />
                <FilterDateInput
                    label={`Začátek investování`}
                    icon={<ICalendarIcon/>}
                    onChange={(values: any) => changeFilter(["date"], [values])}
                />
            </FilterWrap>
            <Table
                headers={[
                    {key: "providerName", value: "JMÉNO", sortable: false},
                    {key: "activeProjects", value: "AKTIVNÍ PROJEKTY", sortable: true},
                    {key: "historicInvestments", value: "HISTORICKÉ INVESTICE", sortable: true},
                    {key: "residence", value: "SÍDLO", sortable: true},
                    {key: "providerSince", value: "ZPROSTŘEDKOVATELEM OD", sortable: false},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper( loading ? [] : (data.agents ?? []))}/>
            <Pagination
                total={loading ? 0 :data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.agents?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
        <InvestmentLogsPreview/>
    </Container>)
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            id: o.id,
            providerName: {value: (<ProfileColumn icon={
                    getPhotoFromCdn(o.imageName)
            } title={
                <b>{`${o.firstName} ${o.lastName}`}</b>
            } subtitle={
                <ColoredText text={o.email || "Nenastaveno"} color={"grayBlue"} semibold/>
            }/>)},
            activeProjects: {value: (<DoubleLineValue firLine={
                <ColoredText text={o.activeProjectsCount} color={"stripGreen"}/>
            } secLine={
                <ColoredText text={`${formatPrice(o.activeProjectsValue)} Kč`} color={"grayBlue"} semibold/>
            }/>)},
            historicInvestments: {value: (<DoubleLineValue firLine={
                <b>{o.expiredProjectsCount}</b>
            } secLine={
                <ColoredText text={`${formatPrice(o.expiredProjectsValue)} Kč`} color={"grayBlue"} semibold/>
            }/>)},
            residence: {value: (<DoubleLineValue firLine={
                <RegularBold>{o.location?.streetAddressFirstLine || "Nenastaveno"}</RegularBold>
            } secLine={
                <ColoredText text={o.location?.postalCode || "Nenastaveno"} color={"grayBlue"} semibold/>
            }/>)},
            providerSince: {value: (<DoubleLineValue firLine={
                <RegularBold>{dayjs(o.createdOn).format(`DD.MM.YYYY`)}</RegularBold>
            } secLine={
                <ColoredText text={`${dayjs(o.createdOn).diff(Date.now(), "year")} rok ${dayjs(o.createdOn).diff(Date.now(), "month")} měsíců`} color={"grayBlue"} semibold/>
            }/>)},
            link: `${Routes.PROJECT_PROVIDER_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default ProjectProvidersOverviewPage;