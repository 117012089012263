import styled from "styled-components";
import {ReactComponent as ISortDownIcon} from "../../resources/icons/arrow-down.svg";
import {ReactComponent as ISortUpIcon} from "../../resources/icons/arrow-up.svg";

export const OnHover = styled.span`
    visibility: hidden;
    position: relative;
`;

export const TableContainer = styled.div<{noSidePadding?: boolean}>`
    padding: ${({noSidePadding}) => noSidePadding ? "0 " : "0 36px 0 0"};
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.colors.lightGray};
        width: 0.5em;
        transition: .3s background-color;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${props => props.theme.colors.lightGray};
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        width: 0.5em;
        margin: 5px;
    }

    &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
        height: 2px;
    }
`;

export const TableWrapper = styled.table<{notWide?: boolean}>`
    background-color: ${props => props.theme.colors.white};
    border-collapse: collapse;
    ${({notWide}) => !notWide ? `width: 100%;` : `max-width: 600px;`}
`;

export const Header = styled.thead`
    border-bottom: 1px solid ${props => props.theme.colors.gray};
    border-top: 1px solid ${props => props.theme.colors.gray};
`;

export const HeaderItem = styled.th<{ isSortable: boolean, idHeader?: boolean; fit: string }>`
    padding: 20px 0 20px 10px;
    text-align: left;
    ${({isSortable, theme}) => isSortable && `cursor: pointer`};
    font-size: 12px;
    font-weight: 600;
    ${({fit, idHeader}) => idHeader ? `
        position: absolute;
        max-width: 20px;
    ` : `
        width: ${fit ? fit : 'auto'};
    `}
    color: ${props => props.theme.colors.grayBlue};
    &:hover {
        ${({isSortable, theme}) => isSortable && `color: ${theme.colors.blue}`};
    };
    transition: 0.5s all;
`;

export const HeaderItemInner = styled.div<{isSorted: boolean}>`
    display: flex;
    user-select: none;
    align-items: center;
    ${({isSorted, theme}) => isSorted && `color: ${theme.colors.blue}`};
`;

export const Body = styled.tbody`
`;

export const Row = styled.tr<{hover?: boolean; background?: any}>`
    position: relative;
    border-bottom: 1px solid ${props => props.theme.colors.gray};
    height: 64px;
    background-color: transparent;
    cursor: pointer;
    z-index: 1;

    &:nth-child(2n+2) {
        background-color: ${({theme}) => theme.colors.lightGray};
    }
    &:hover {
        background-color: ${props => props.hover ? props.theme.colors.veryLightGray : 'transparent'};
    }

    ${({theme, background}) => background && `
        background-color: ${
            background == "green" && theme.colors.greenWhite ||
            background == "red" && theme.colors.redWhite
        };
    `}

    &:hover ${OnHover} {
        visibility: visible;
    }
    transition: 0.2s all;
`;

export const Col = styled.td<{idCol?: boolean}>`
    //position: relative;

    padding: ${({idCol}) => idCol ? "0 0 0 16px" : "16px 0 16px 10px"};
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    color: ${props => props.theme.colors.black};
    z-index: 2;

    &:last-child {
        padding-right: 15px;
    }
    a {
        color: ${({theme}) => theme.colors.blue};
        font-weight: 600;
    }
`;

export const IndicatorWrapper = styled.div`
    width: 14px;
    height: 8px;
`;

export const Indicator = styled.div<{ indicate: boolean; }>`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.indicate ? props.theme.colors.red : 'transparent'};
    margin-left: auto;
`;

export const SortWrapper = styled.div`
    width: 12px;
    min-width: 12px;
    height: 14px;
    min-height: 14px;
    position: relative;
    margin-left: 12px;
    cursor: pointer;
`;

export const HighlightedText = styled.span<{ $contrast?: boolean }>`
    color: ${props => props.$contrast ? props.theme.colors.red : props.theme.colors.black};
    font-weight: ${props => props.$contrast ? 600 : 700};

    & svg {
        fill: ${props => props.$contrast ? props.theme.colors.red : props.theme.colors.black};
    }
`;

export const SortDownIcon = styled(ISortDownIcon)`
    width: 12px;
    min-width: 12px;
    height: 14px;
    fill: ${props => props.theme.colors.blue};
    cursor: pointer;
`;

export const SortUpIcon = styled(ISortUpIcon)`
    width: 12px;
    min-width: 12px;
    height: 14px;
    fill: ${props => props.theme.colors.blue};
    cursor: pointer;
`;