import React, { useState } from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import dayjs from "dayjs";
import { LabeledDateField } from "./styled/LabeledTextInput.styled";
import {ReactComponent as ICaretIcon} from "../../../resources/icons/caret-down.svg";
import ColoredText from "../../table/components/ColoredText";

interface StyledDatePickerInterface {
    date: string | undefined;
    valid: boolean;
    disable?: boolean;
    wide?: boolean;
    filter?: boolean;
    onChange(date: string): void;
}

const StyledDatePicker = ({date, onChange, disable, valid, wide, filter}: StyledDatePickerInterface) => {
    const handleChange = (e: any) => onChange(dayjs.utc(e).toISOString());
    const [isDisabled, setIsDisabled] = useState<boolean>(disable != undefined ? disable : false);
    const DateInput = React.forwardRef(({value, onClick}: any, ref: any) => (
        <LabeledDateField filter={filter} wide={wide} enabled={isDisabled} valid={valid} ref={ref} onClick={onClick}>
            {value ? value : <ColoredText text="Vyberte..." color={"grayBlue"}/>}
            <IconWrap>
                {<ICaretIcon/>}
            </IconWrap>
        </LabeledDateField>
    ));
    return (<DatePicker
        locale="cs"
        renderCustomHeader={({
            monthDate,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div>
              <button
                className={"react-datepicker__navigation react-datepicker__navigation--previous"}
                style={prevMonthButtonDisabled ? { visibility: "hidden" } : { visibility: "visible"}}
                type={'button'}
                onClick={decreaseMonth}>
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("cs", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                type={'button'}
                style={nextMonthButtonDisabled ? { visibility: "hidden" } : { visibility: "visible"}}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
        selected={date ? dayjs.utc(date).toDate() : undefined}
        onChange={handleChange}
        customInput={<DateInput/>}
        calendarContainer={({children}: any) => (<CalenderWrap>
            {children}
        </CalenderWrap>)}
        timeInputLabel="Čas:"
        onCalendarOpen={() => setIsDisabled(true)}
        onCalendarClose={() => setIsDisabled(false)}
        dateFormat={"dd.MM.yyyy"}
    />)
};

const IconWrap = styled.div`
    width: 9px;
    height: 16px;
    fill: ${({theme}) => theme.colors.stripGray};
`;

const CalenderWrap = styled.div`
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.colors.lighterGray};
    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
      background-color: ${({theme}) => theme.colors.grayBlue};
    }
    transition: all 0.2s;
`;

export default StyledDatePicker;