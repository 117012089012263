import React from "react";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom"
import {ReactComponent as ISquareIcon} from "../../../../resources/icons/square.svg";
import {useHistory} from "react-router";
import * as path from "path";

export interface InnerNavButtonInterface {
    text: string;
    route: string;
    exact?: boolean;
}

interface NavButtonInterface {
    icon: React.ReactNode;
    text: string;
    route: string;
    highlightedText?: string;
    exact?: boolean;
    innerLinks?: Array<InnerNavButtonInterface>;
}

const getStringOccurenceInStringArray = (array: Array<string>, value: string) => array.reduce((i, j) => (j === value ? i + 1 : i), 0);

const containsRouteAtPathnameStart = (pathname: string, route: string): boolean => {
    //Check if pathname has 2 slashes
    const pathnameStringArray = Array.from(pathname);
    const hasPathnameMoreSlashes = getStringOccurenceInStringArray(pathnameStringArray, "/") > 1;
    if (!hasPathnameMoreSlashes) {
        //Is pathname has only one slash then just check it right away
        return pathname.indexOf(route) > -1;
    } else {
        //If pathname has more slashes than just take the first part of pathname
        pathnameStringArray.shift(); //Throw away first slash - modifies the array
        const firstPartOfPathname = pathname.substring(0, pathnameStringArray.findIndex(o => o === "/") + 1);
        return firstPartOfPathname.indexOf(route) > -1;
    }
};

const getButtonActiveClassName = (pathname: string, route: string, innerLinks: Array<InnerNavButtonInterface>): string => {
    let isActive;
    isActive = containsRouteAtPathnameStart(pathname, route);

    //If link is still not active check inner links as well
    if (!isActive)
        isActive = innerLinks.some((item) => {
            const contains = containsRouteAtPathnameStart(pathname, item.route);
            return contains;
        });

    return isActive ? "active" : "not-active";

}

const NavButton = ({icon, text, route, highlightedText, exact, innerLinks = []}: NavButtonInterface) => {
    const {location} = useHistory();
    const {pathname} = location;
    return (<BaseContainer hasMultiple={innerLinks?.length > 0} className={getButtonActiveClassName(pathname, route, innerLinks)}>
        <LinkContainer to={route} exact={exact} activeClassName="active">
            <ButtonContainer>
                {icon}
                <div>
                    {text}
                    {(highlightedText !== undefined) && <RandomText>{highlightedText}</RandomText>}
                </div>
            </ButtonContainer>
        </LinkContainer>
        {innerLinks?.length > 0 &&
            <InnerLinksContainer>
                <Pipe/>
                {innerLinks?.map((l, i) =>
                    <InnerLink key={i} to={l.route} exact={l.exact}>
                        <ISquareIcon/>
                        {l.text}
                    </InnerLink>)}
            </InnerLinksContainer>}
    </BaseContainer>)
};

const InnerLinksContainer = styled.div`
    display: flex;
    align-items: center;
`;
const InnerLink = styled(NavLink)`
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: ${({theme}) => theme.colors.black};
    padding: 0 12px;
    transition: 0.5s color;

    svg {
        fill: ${({theme}) => theme.colors.black};
        height: 4px;
        margin-top: 12px;
        margin-bottom: 14px;
        transition: 0.5s fill;
    }

    &.active, &:hover {
        color: ${({theme}) => theme.colors.blue};

        svg {
            fill: ${({theme}) => theme.colors.blue};
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    transition: 0.5s color;
`;

const Pipe = styled.div`
    width: 1px;
    height: 47px;
    background-color: ${({theme}) => theme.colors.lighterGray};
    margin-left: 26px;
`;

const LinkContainer = styled(NavLink)`
    display: flex;
    align-items: center;

    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    color: ${({theme}) => theme.colors.black};

    &.active {
        & ~ ${InnerLinksContainer} {
            display: flex;
        }
    }
`;

const RandomText = styled.span`
    margin-left: 4px;
    color: ${({theme}) => theme.colors.blue};
`;

const BaseContainer = styled.div<{ hasMultiple: boolean }>`
    display: flex;
    padding: ${({hasMultiple}) => hasMultiple ? "8px 16px 8px 16px;" : "12px 17px 12px 17px;"};

    ${({hasMultiple}) => hasMultiple && css`
        & ${InnerLinksContainer} {
            display: none;
        }
    `}
    & ${ButtonContainer} {
        svg {
            fill: ${({theme}) => theme.colors.black};
            height: 24px;
            margin-bottom: 8px;
            transition: 0.5s fill;
        }
    }

    &.active, &:hover {
        ${({hasMultiple}) => hasMultiple && css`
            border: 1px solid ${({theme}) => theme.colors.lighterGray};
            border-radius: 4px;

            & ${InnerLinksContainer} {
                display: flex;
            }
        `}
        & ${ButtonContainer} {
            color: ${({theme}) => theme.colors.blue};

            svg {
                fill: ${({theme}) => theme.colors.blue};
            }
        }
    }
`;


export default NavButton;