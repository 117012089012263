import React, {ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {KeyIcon, LoginButton, LoginButtonSection, LoginForm, LoginFormWrapper, LoginLogo, LoginPageWrapper} from "./LoginPage.styled";
import LoginInput from "./components/LoginInput";
import {useForm} from "react-hook-form";
import resetPassword from "./api/resetPassword";
import {Routes} from "../../router/Routes";
import ILoadingGIF from "../../resources/images/loading.gif";
import styled from "styled-components";

const ResetPasswordPage = (props: any): ReactElement => {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const {handleSubmit, register, errors} = useForm();

    const searchQuery = new URLSearchParams(props.location.search);
    const token = searchQuery.get("token")?.replace(/ /g, "+") as string;
    const email = searchQuery.get("user") as string;

    const onSubmit = async (data: any) => {
        setLoading(true);
        const response = await resetPassword(email, token, data.password);
        if(response.status == 200) {
            history.push(Routes.LOGIN, { message: "Heslo úspěšně změněno." });
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    return <LoginPageWrapper>
        <LoginFormWrapper>
            <LoginLogo/>
            <LoginForm onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={loading}>
                    <LoginInput
                        label={'Nové heslo'}
                        name={'password'}
                        type={'password'}
                        defaultValue=""
                        disabled={loading}
                        valid={errors?.password}
                        ref={register({required: true})}
                        icon={<KeyIcon/>}
                    />
                    <LoginInput
                        label={'Nové heslo znovu'}
                        name={'passwordAgain'}
                        type={'password'}
                        defaultValue=""
                        disabled={loading}
                        valid={errors?.passwordAgain}
                        ref={register({required: true})}
                        icon={<KeyIcon/>}
                    />
                    <LoginButtonSection>
                        <div/>
                        <LoginButton disabled={loading} type={'submit'}>Potvrdit {loading && <Loading src={ILoadingGIF}/>}</LoginButton>
                    </LoginButtonSection>
                </fieldset>
            </LoginForm>
        </LoginFormWrapper>
    </LoginPageWrapper>;
};

export default ResetPasswordPage;

const Loading = styled.img`
    margin-bottom: -4px;
    margin-left: 8px;
    max-width: 20px;
    max-height: 20px;
`;