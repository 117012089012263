import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import ProfileColumn from "../../../../components/table/components/ProfileColumn";
import ColoredText from "../../../../components/table/components/ColoredText";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import fetchProjectInvestors from "../../api/project/fetchProjectInvestors";
import toast from "react-hot-toast";
import {GetRECollateralLabel, GetREOwnershipLabel, GetRETypeLabel} from "../../../../enums/RealEstateEnums";
import fetchProjectCollaterals from "../../api/project/fetchProjectCollaterals";
import AssignRealEstateModal from "../../AssignRealEstateModal";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IPinIcon} from "../../../../resources/icons/location-dot.svg";
import {ReactComponent as IShieldIcon} from "../../../../resources/icons/shield-check.svg";
import useFilters from "../../../../components/methods/useFilters";
import {formatPrice} from "../../../../shared/utils/format";

const SecuredSection = () => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        collateral: "",
        location: "",
        colValueFrom: "", colValueTo: "",
    });

    const fetchData = async () => {
        const response = await fetchProjectCollaterals(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst zajištění.")
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])

    useEffect(() => {
        if(data)
            buttons.setUtilityButtons(<>
                <UtilityButton
                title="Exportovat"
                color="gray"
                icon={<ICloudIcon/>}
                onClick={() => console.log("export zajištění")}/>
                <UtilityButton
                title="Přidat zajištění"
                color="blue"
                icon={<IPlusIcon/>}
                onClick={() => {
                    modal.openModal(<AssignRealEstateModal existingIds={data.collaterals.map((c: any) => c.id)} onCreate={fetchData}/>);
                }}/>
            </>)
    }, [data]);

    return (<SectionContainer>
          <TableContainer>
              <FilterWrap maxWidth={"50%"}>
            <SearchBar name={"collateralsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                    label={`Zajištění`}
                    onChange={(filters: string) => changeFilter("collateral", filters)}
                    icon={<IBuildingIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
            <FilterSelectInput
                label={`Lokalita`}
                onChange={(filters: string) => changeFilter("location", filters)}
                icon={<IPinIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterRangeInput
                label={`Hodnota zajištění`}
                icon={<IShieldIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
            />
             </FilterWrap>
             <Table headers={[
                {key: "secured", value: "ZAJIŠTĚNÍ", sortable: true},
                {key: "place", value: "LOKALITA", sortable: false},
                {key: "value", value: "HODNOTA", sortable: true},
                {key: "ownership", value: "VLASTNICTVÍ", sortable: true},
                {key: "renters", value: "NÁJEMNÍCI", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.collaterals ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.collaterals?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            secured: {value: (<ProfileColumn icon={
                <IBuildingIcon/>
            } title={
                <b>{GetRETypeLabel(o.realEstateType)}</b>
            } subtitle={
                <ColoredText text={GetRECollateralLabel(o.mainCollateralType)} color={"grayBlue"} semibold/>
            }/>)},
            place: {value: (<DoubleLineValue firLine={
                    o.location
            } secLine={
                <ColoredText text={o.postalCode} color={"grayBlue"} semibold/>
            }/>)},
            value: {value: (<DoubleLineValue firLine={
                <ColoredText text={`${formatPrice(o.value)} Kč`} color={"stripGreen"} semibold/>
            } secLine={
                <ColoredText text={`Tvoří ${Math.floor(o.valuePercentage)}% zajištění`} color={"grayBlue"}/>
            }/>)},
            ownership: {value: (<RegularBold>{GetREOwnershipLabel(o.ownershipType)}</RegularBold>)},
            renters: {value: (<DoubleLineValue firLine={
                <ColoredText text={o.rentersCount} color={"stripGreen"} semibold/>
            } secLine={
                <ColoredText text={`${formatPrice(o.monthlyRent)} Kč / měsíc`} color={"grayBlue"}/>
            }/>)},
            buttons: [
                { label: "Upravit", action: () => { console.log("upravit") } },
                { label: "Smazat", action: () => { console.log("smazat") } }
            ],
            link: `${Routes.REAL_ESTATE_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default SecuredSection;