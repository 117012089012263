import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import SectionContainer from "../../project-detail/components/SectionContainer";
import { Routes } from "../../../../router/Routes";
import deleteProvider from "../../api/provider/deleteProvider";
import toast from "react-hot-toast";

const ScoringSection = () => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat zprostředkovatele?")) {
                    const response = await deleteProvider(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        if(fetchedData.isSuccess) {
                            history.push(Routes.PROJECTS_PROVIDERS);
                            toast.success("Zprostředkovatel smazán.")
                        } else {
                            toast.error("Zprostředkovatele se nepodařilo smazat.")
                        }
                    } else {
                        toast.error("Zprostředkovatele se nepodařilo smazat.")
                    }
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export provider")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_PROVIDER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer>
        Scoring
    </SectionContainer>)
};

const Container = styled.div``;

export default ScoringSection;