import { getAccessToken } from './../../../contexts/UserIdentityContext';
const updateAppraiser = async (appraiserId: string | number, data: any) => {
    const response = await fetch(`/api/v1/appraisers/${appraiserId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data),
    })
    return response
};

export default updateAppraiser;