import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import {ModalContext, UtilityButtonsContext} from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import ColoredText from "../../../../components/table/components/ColoredText";
import {GetProjectStateLabel, GetProjectTypeColor, GetProjectTypeLabel} from "../../../../enums/ProjectEnums";
import dayjs from "dayjs";
import deleteProject from "../../api/project/deleteProject";
import toast from "react-hot-toast";
import {GetRETypeLabel} from "../../../../enums/RealEstateEnums";
import {formatDate} from "../../../../shared/utils/time";
import CreateProjectModal from "../../CreateProjectModal";
import ChangeProjectStateModal from "../../ChangeProjectStateModal";
import {formatPrice} from "../../../../shared/utils/format";

const OverviewSection = ({data, refetch}: any) => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat tento projekt?")) {
                    const response = await deleteProject(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        history.push(Routes.PROJECTS);
                        toast.success("Projekt byl smazán.")
                    } else {
                        toast.error("Nepovedlo se smazat tento projekt.")
                    };
                };
            }}/>
            <UtilityButton
                title="Změnit stav"
                color="gray"
                icon={<IPenIcon/>}
                onClick={
                    () => {
                        modal.openModal(<ChangeProjectStateModal projectId={slug} currentState={data.generalInformation.state} refetchData={refetch}/>)
                    }
                }/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, [data, slug]);
    return (<SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Základní údaje" icon={<IBuildingIcon/>}>
                    <InfoBoxItem title="Typ projektu" text={<ColoredText text={GetProjectTypeLabel(data.generalInformation?.type)} color={GetProjectTypeColor(data.generalInformation?.type)} semibold/>}/>
                    <InfoBoxItem title="Stav projektu" text={GetProjectStateLabel(data.generalInformation?.state)} />
                    {/**<InfoBoxItem title="Lokalita" text={data.generalInformation?.location.city}/>**/}
                    <InfoBoxItem title="Typ nemovitosti" text={GetRETypeLabel(data.generalInformation?.mainCollateralType)}/>
                    <InfoBoxItem title="Lokalita hlavního zajištění" text={<a href={Routes.REAL_ESTATE_DETAIL.replace(":slug", data.generalInformation?.mainCollateralId)}>{data.generalInformation?.mainCollateralLocation}</a>}/>
                    <InfoBoxItem title="Požadované datum schválení" text={formatDate(data.generalInformation?.requiredApprovalDate)}/>
                    <InfoBoxItem title="Požadované investiční období" text={<><b>{data.generalInformation?.requiredInvestmentMonthsPeriod}</b> měsíců</>}/>
                    <InfoBoxItem title="Investiční období" text={<><b>{data.generalInformation?.investmentMonthsPeriod}</b> měsíců</>}/>
                    <InfoBoxItem title="Požadovaná investice" text={<b>{formatPrice(data.generalInformation?.requiredInvestmentValue)} Kč</b>}/>
                    <InfoBoxItem title="Vstupní investice" text={<b>{formatPrice(data.generalInformation?.investmentValue)} Kč</b>}/>
                    {/**<InfoBoxItem title="Investováno" text={<b>{data.generalInformation?.totalInvested} Kč</b>}/>**/}
                    <InfoBoxItem title="Související náklady" text={<b>- Kč</b>}/>
                    <InfoBoxItem title="Hodnota zajištění" text={<><b>{formatPrice(data.generalInformation?.totalCollateralValue)} Kč</b> ({data.generalInformation?.collateralCount} položky)</>}/>
                    <InfoBoxItem title="LTV" text={<>{data.generalInformation?.loanToValue.toFixed(2)} %</>}/>
                    <InfoBoxItem title="Začátek až konec" text={<>{`${data.generalInformation?.startDate ? formatDate(data.generalInformation?.startDate) : "-" } až ${data.generalInformation.endDate ? formatDate(data.generalInformation.endDate) : "-"}`}</>}/>
                    <InfoBoxItem title="Počet investorů" text={data.generalInformation?.investorsCount}/>
                    <InfoBoxItem title="Zprostředkovatel" text={<a href={Routes.PROJECT_PROVIDER_DETAIL.replace(":slug", data.generalInformation?.agentId)}>{data.generalInformation?.agentName}</a>}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note ?? ""}
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer>)
};

export default OverviewSection;