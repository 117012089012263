import React,{useEffect, useState, useContext} from "react";
import styled from "styled-components";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../resources/icons/floppy-disk.svg";
import SectionContainer from "../../projects/project-detail/components/SectionContainer";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../components/shared/inputs/LabeledSelectInput";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import {ReactComponent as ITagIcon} from "../../../resources/icons/tag.svg";
import {ReactComponent as IInfoIcon} from "../../../resources/icons/circle-info.svg";
import {ReactComponent as ICaretIcon} from "../../../resources/icons/caret-down.svg";
import { Routes } from "../../../router/Routes";
import { useParams, useHistory } from "react-router";
import { useForm } from "react-hook-form";
import PageHeader from "../../../components/shared/PageHeader";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import toast from "react-hot-toast";
import fetchTransaction from "../api/finance/fetchTransaction";
import editTransaction from "../api/finance/editTransaction";
import { fetchSelectProjects } from "../api/fetchSelects";
import { GetTransactionTypes } from "../../../enums/FinanceEnums";

const EditFinancePage = () => {
    const {register, errors, handleSubmit} = useForm();
    const {slug}: {slug: string} = useParams();
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [projects, setProjects] = useState<any>([]);
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);

    const fetchFinanceData = async () => {
        const response = await fetchTransaction(slug);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst transakci.")
        }
    };

    const fetchProjects = async () => {
        const response = await fetchSelectProjects();
        if(response.status == 200) {
            const fetchedData = await response.json();
            setProjects(fetchedData.result);
        }
    };

    const onSubmit = async (data: any) => {
        data.transactionId = parseInt(slug);
        data.transactionType = parseInt(data.transactionType);
        data.projectId = parseInt(data.projectId);
        data.amount = parseInt(data.amount);
        data.percentageCut = parseInt(data.percentageCut);
        const response = await editTransaction(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Transakce upravena.");
        } else {
            toast.error("Transakci se nepodařilo upravit.");
        };
    };

    useEffect(() => {
        fetchFinanceData();
        fetchProjects();

        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.PROJECT_FINANCE_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<IDiskIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);
    return (<form>{!loading && <Container>
        <PageHeader title={`Náklad ${data.general.internalName}`}
        returnPath={Routes.PROJECTS_FINANCE}
        noContainerBottomMargin
        />
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Položka" icon={<ITagIcon/>}>    
                <LabeledTextInput
                    label='Označení'
                    type="text"
                    name="internalName"
                    valid={!errors.internalName}
                    errorMessage={errors.internalName?.message}
                    placeholder="Text..."
                    defaultValue={data.general.internalName}
                    ref={register}
                />
                    <LabeledSelectInput label="Typ" name="transactionType"
                    placeholder="Vyberte..."
                    valid={!errors.transactionType}
                    errorMessage={errors.transactionType?.message}
                    defaultValue={data.general.type}
                    wide
                    options={GetTransactionTypes()}
                    ref={register}
                    />
                    <LabeledSelectInput label="Projekt"
                    name="projectId"
                    placeholder={projects.length > 0 ? "Vyberte..." : "Projekty nejsou dostupné."}
                    disable={projects.length <= 0}
                    defaultValue={data.general.projectId}
                    wide
                    icon={<ICaretIcon/>}
                    valid={!errors.projectId}
                    errorMessage={errors.projectId?.message}
                    options={mapProjectsSelect(projects) || []}
                    ref={register}/>
                    <LabeledTextInput label="Datum" type="date" name="transactionDate"
                    defaultValue={data.general.transactionDate || ""}
                    placeholder="Datum..."
                    wide
                    valid={!errors.transactionDate}
                    errorMessage={errors.transactionDate?.messsage}
                    ref={register}
                    />
                    <LabeledTextInput label="Částka" type="number" name="amount"
                    defaultValue={data.general.amount}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.amount}
                    errorMessage={errors.amount?.messsage}
                    ref={register}
                    />
                    <LabeledTextInput label="Podíl ERICONN" type="number" name="percentageCut"
                    defaultValue={data.general.ericonnPercentage}
                    placeholder="Procenta..."
                    wide
                    valid={!errors.percentageCut}
                    errorMessage={errors.percentageCut?.message}
                    ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Popis" icon={<IInfoIcon/>}>
                    <LabeledTextInput label="Poznámka" type="textarea" name="note"
                    defaultValue={data.note || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.note}
                    errorMessage={errors.note?.message}
                    ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </Container>}</form>)
};

const idPad = (num: number, size: number) => String(num).padStart(size, '0');

export const mapProjectsSelect = (projects: Array<any>) => {
    let result: Array<any> = [];
    projects.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.internalName}`,
    }))
    return result;
};

export const mapAgentsSelect = (agents: Array<any>) => {
    let result: Array<any> = [];
    agents.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.fullName}`,
    }))
    return result;
};

export const mapAppraisersSelect = (appraisers: Array<any>) => {
    let result: Array<any> = [];
    appraisers.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.fullName}`,
    }))
    return result;
};

const Container = styled.div`
    padding: 0 24px;
`;

export default EditFinancePage;