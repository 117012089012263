import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const fetchTask = async (id: string | number) => {
    const response = await fetch(`/api/v1/tasks/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
    });
    return response
};

export default fetchTask;