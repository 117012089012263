export enum TransactionType {
        NotSet = 0,
        Expense = 1,
        Income = 2,
        Investment = 3
}

export const GetTransactionTypeLabel = (type: TransactionType): string => {
    switch (type){
        case TransactionType.NotSet: return "Nenastaveno";
        case TransactionType.Expense: return "Náklad";
        case TransactionType.Income: return "Příjem";
        case TransactionType.Investment: return "Investice";
        default: return "Nenastaveno";
    }
};

export const GetTransactionTypes = (): Array<any> => {
    return [
        {
            id: TransactionType.NotSet,
            label: GetTransactionTypeLabel(TransactionType.NotSet),
        },
        {
            id: TransactionType.Expense,
            label: GetTransactionTypeLabel(TransactionType.Expense),
        },
        {
            id: TransactionType.Income,
            label: GetTransactionTypeLabel(TransactionType.Income),
        },
        {
            id: TransactionType.Investment,
            label: GetTransactionTypeLabel(TransactionType.Investment),
        },
    ]
};