import styled from "styled-components";

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    @media screen and (max-width: 850px) {
        display: block;
    }
`;

export const LeftSide = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 850px) {
        width: 100%;
    }
`;

export const RightSide = styled.div`
    align-items: center;
    display: flex;
    @media screen and (max-width: 850px) {
        display: none;
    }
`;

export const Total = styled.span<{bold?: boolean}>`
    font-weight: ${({bold}) => bold ? "700" : "500"};
    font-size: 14px;
    color: ${props => props.theme.colors.grayBlue};
    @media screen and (max-width: 850px) {
        display: none;
    }
`;

export const Bold = styled.b`
    font-weight: 700;
`;

export const PageInput = styled.input`
    border-radius: 4px;
    width: 36px;
    border: 1px solid ${props => props.theme.colors.lighterGray};
    background-color: transparent;
    text-align: center;
    padding: 8px 0;
    font-weight: 700;
    font-size: 14px;
    color: ${props => props.theme.colors.black};
    margin-left: 20px;
    -moz-appearance: textfield;
    transition: .25s border;

    &:focus {
        border: 1px solid ${props => props.theme.colors.black};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-input-placeholder {
        color: ${props => props.theme.colors.gray};
    }

    :-ms-input-placeholder {
        color: ${props => props.theme.colors.gray};
    }

    ::placeholder {
        color: ${props => props.theme.colors.gray};
    }

    @media screen and (max-width: 850px) {
        display: none;
    }
`;

export const Paginator = styled.div`
    display: flex;
    @media screen and (max-width: 850px) {
        margin: 0 auto;
    }
`;

export const Page = styled.button<{ active: boolean }>`
    cursor: pointer;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: ${props => props.active ? props.theme.colors.blue : 'transparent'};
    border: none;
    font-weight: 700;
    font-size: 14px;
    margin-left: 12px;
    color: ${props => props.active ? props.theme.colors.white : props.theme.colors.black };
`;