import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {ModalContext, UtilityButtonsContext} from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import {useHistory, useParams} from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import CreateTasksModal from "../../../tasks/CreateTasksModal";
import toast from "react-hot-toast";
import fetchProjectTasks from "../../api/project/fetchProjectTasks";
import dayjs from "dayjs";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../../../components/shared/filters/FilterDateInput";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as ICalendarIcon} from "../../../../resources/icons/calendar.svg";
import {ReactComponent as IFlagIcon} from "../../../../resources/icons/flag.svg";
import useFilters from "../../../../components/methods/useFilters";

const TasksSection = () => {
    const {slug}: { slug: string } = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        state: "",
        solver: "",
        date: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                color="gray"
                icon={<ICloudIcon/>}
                onClick={() => console.log("export úkolů")}/>
            <UtilityButton
                title="Vytvořit úkol"
                color="blue"
                icon={<IPlusIcon/>}
                onClick={() => {
                    modal.openModal(<CreateTasksModal projectId={parseInt(slug)}/>)
                }}/>
        </>)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjectTasks(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
            if (response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst transakce.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])

    return (<SectionContainer>

        <TableContainer>
            <FilterWrap maxWidth={"40%"}>
            <SearchBar name={"tasksOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Řešitel`}
                onChange={(filters: any) => changeFilter("solver", filters)}
                icon={<IUserIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterSelectInput
                label={`Stav`}
                onChange={(filters: any) => changeFilter("state", filters)}
                icon={<IFlagIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterDateInput
                label={`Datum plnění`}
                onChange={(date: string) => changeFilter("date", filters)}
                icon={<ICalendarIcon/>}
            />
            </FilterWrap>
            <Table headers={[
                {key: "dateOfFilling", value: "DATUM PLNĚNÍ", sortable: true},
                {key: "title", value: "NÁZEV", sortable: false},
                {key: "solver", value: "ŘEŠITEL", sortable: false},
                {key: "state", value: "STAV", sortable: false},
                {key: "dateOfEntry", value: "DATUM ZADÁNÍ", sortable: true},
                {key: "partOfTask", value: "SOUČAST ZÁPISU Z", sortable: true},
                {key: "lastUpdate", value: "POSLEDNÍ AKTUALIZACE", sortable: true},
            ]}
                   sizes={["12px"]}
                   sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                   onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                   body={mapper(slug,loading ? [] : (data.tasks ?? []))}/>
                   <Pagination
                       total={loading ? 0 : data.totalCount}
                       pageSize={pageSize}
                       pageNumber={pageNum}
                       listed={data.tasks?.length ?? 0}
                       onLimitChange={(e) => setPageSize(e)}
                       onPageChange={(e) => setPageNum(e)}
                   />
        </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (projectId: any, items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "green",
            stateBackground: true,
            id: o.id,
            dateOfEntry: {value: (<DoubleLineValue firLine={
                    <RegularBold>{o.finishedOn ? dayjs(o.finishedOn).format("DD.MM.YYYY") : "-"}</RegularBold>
                } secLine={
                    <ColoredText text={o.finishedOn ? dayjs(o.finishedOn).format("HH:mm") : "-"} color={"grayBlue"}/>
                }/>)},
            title: {value: (<RegularBold>{o.name}</RegularBold>)},
            solver: {value: (<a href={Routes.USER_DETAIL.replace(":slug", o.assigneeId)}>{o.assigneeName}</a>)},
            state: {value: (<RegularBold>{o.state}</RegularBold>)},
            dateOfFilling: {value: (<DoubleLineValue firLine={
                    <b>{dayjs(o.expectedFinishDate).format("DD.MM.YYYY")}</b>
                } secLine={
                    <ColoredText text={`za ${dayjs(o.expectedFinishDate).diff(Date.now(), "day")} dní`} color={"grayBlue"} semibold/>
                }/>)},
            partOfTask: {value: (<DoubleLineValue firLine={
                    <RegularBold>{o.includedInList ? dayjs(o.includedInList).format("DD.MM.YYYY") : "-"}</RegularBold>
                } secLine={
                    <ColoredText text={o.includedInList ? dayjs(o.includedInList).format("HH:mm") : "-"} color={"grayBlue"}/>
                }/>)},
            buttons: [
                { label: "Upravit", action: () => { console.log("upravit") } },
                { label: "Smazat", action: () => { console.log("smazat") } }
            ],
            lastUpdate: {value: (<a>{o.lastUpdatedOn ? dayjs(o.lastUpdatedOn).format("DD.MM.YYYY") : "-"}</a>)},
            link: `${Routes.PROJECT_TASK.replace(":slug", projectId.toString()).replace(":secondslug", o.id.toString())}`
        });
    });
    return result;
}

export default TasksSection;