import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import { Routes } from "../../../../router/Routes";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import SectionContainer from "../../project-detail/components/SectionContainer";
import toast from "react-hot-toast";
import deleteInvestor from "../../api/investors/deleteInvestor";
import { GetInvestorTypeLabel } from "../../../../enums/InvestorEnums";
import dayjs from "dayjs";
import {formatPrice} from "../../../../shared/utils/format";

const ProfileSection = ({data}: any) => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat investora?")) {
                    const response = await deleteInvestor(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        toast.success("Investor smazán.");
                        history.push(Routes.PROJECTS_INVESTORS);
                    } else {
                        toast.error("Investora se nepodařilo smazat");
                    };
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export investor")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_INVESTOR_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer><InfoBoxFullContainer>
        <InfoBoxColumn>
            <InfoBox title="Fakturační údaje" icon={<IBuildingIcon/>}>
                <InfoBoxItem title="Typ investora" text={GetInvestorTypeLabel(data.investor?.investorType)}/>
                <InfoBoxItem title="Název" text={data.investor?.investorName || ""}/>
                <InfoBoxItem title="IČO" text={data.investor?.identificationNumber || ""}/>
                <InfoBoxItem title="Ulice 1. řádek" text={data.investor?.billingLocation?.streetAddressFirstLine || ""}/>
                <InfoBoxItem title="Ulice 2. řádek" text={data.investor?.billingLocation?.streetAddressSecondLine || ""}/>
                <InfoBoxItem title="PSČ" text={data.investor?.billingLocation?.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') || ""}/>
                <InfoBoxItem title="Obec/Město" text={data.investor?.billingLocation?.city || ""}/>
                <InfoBoxItem title="Země" text={data.investor?.billingLocation?.country || ""}/>
            </InfoBox>
            <InfoBox title="Kontaktní osoba" icon={<IUserIcon/>}>
                <InfoBoxItem title="Jméno a příjmení" text={`${data.investor?.firstName} ${data.investor?.lastName}`}/>
                <InfoBoxItem title="Email" text={data.investor?.email ? <a href={`mailto:${data.investor?.email}`}>{data.investor?.email}</a> : ""}/>
                <InfoBoxItem title="Telefon" text={data.investor?.phoneNumber ? <a href={`mailto:${data.investor?.phoneNumber}`}>{data.investor?.phoneNumber}</a> : ""}/>
            </InfoBox>
            <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                {data.note || ""}
            </InfoBox>
        </InfoBoxColumn>
        <InfoBoxColumn>
            <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                <InfoBoxItem title="Ulice 1. řádek" text={data.investor?.contactLocation?.streetAddressFirstLine || ""}/>
                <InfoBoxItem title="Ulice 2. řádek" text={data.investor?.billingLocation?.streetAddressSecondLine || ""}/>
                <InfoBoxItem title="PSČ" text={data.investor?.billingLocation?.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') || ""}/>
                <InfoBoxItem title="Obec/Město" text={data.investor?.billingLocation?.city || ""}/>
                <InfoBoxItem title="Země" text={data.investor?.billingLocation?.country || ""}/>
                <InfoBoxItem title="Email" text={data.investor?.contactEmail ? <a href={`mailto:${data.investor?.contactEmail}`}>{data.investor?.contactEmail}</a> : ""}/>
                <InfoBoxItem title="Telefon" text={data.investor?.contactPhoneNumber ? <a href={`mailto:${data.investor?.contactPhoneNumber}`}>{data.investor?.contactPhoneNumber}</a> : ""}/>
            </InfoBox>
            <InfoBox title="Finanční údaje" icon={<IMapIcon/>}>
                <InfoBoxItem title="Volné prostředky" text={`${formatPrice(data.investor?.availableFunds)} Kč`}/>
                <InfoBoxItem title="Úroková sazba p.a." text={`${data.investor?.interestRate} %`}/>
            </InfoBox>
            <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                <InfoBoxItem title="Vytvořen" text={<>{
                    dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(data.activity?.createdOn).format(`HH:mm`)
                }</span></>}/>
                <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                    {data.activity?.createdByName}
                </a> : ""}/>
                <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                    dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                }</span></> : ""}/>
                <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                    {data.activity?.lastUpdatedByName}
                </a> : ""}/>
                <InfoBoxItem title="Investorem od" text={""}/>
            </InfoBox>
        </InfoBoxColumn>
    </InfoBoxFullContainer></SectionContainer>)
};

export default ProfileSection;