import React, {useEffect, useState} from "react";
import StyledModal from "../shared/modal/StyledModal";
import Header from "./header/Header";
import styled from "styled-components";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

interface LayoutInterface {
    children: any;
}

type ModalContextType = {
    currentContent?: React.ReactElement,
    opened: boolean,
    openModal: (content: React.ReactElement) => void,
    closeCurrentModal: () => void,
}

export const ModalContext = React.createContext<ModalContextType>({
    currentContent: (<></>),
    opened: false,
    openModal: (content: React.ReactElement) => {
    },
    closeCurrentModal: () => {
    },
})

type UtilityButtonsContextType = {
    utilityButtons: React.ReactElement,
    setUtilityButtons: (utilityButtons: React.ReactElement) => void,
    clear: () => void,
};

export const UtilityButtonsContext = React.createContext<UtilityButtonsContextType>({
    utilityButtons: (<></>),
    setUtilityButtons: (utilityButtons: React.ReactElement) => {
    },
    clear: () => {
    },
});

interface ModalStackItem {
    hash: string;
    component: React.ReactElement;
}

const Layout = ({children}: LayoutInterface) => {
    const [modalStack, setModalStack] = useState<Array<ModalStackItem>>([]);
    const [utilityButtons, setUtilityButtons] = useState<React.ReactElement>(<></>);
    useEffect(() => {
        dayjs.extend(utc);
    }, [])

    const getCurrentModalContent = () => modalStack.length > 0 ? modalStack[modalStack.length - 1] : undefined;
    const closeCurrentModal = () => {
        modalStack.pop();
        setModalStack([...modalStack]);
    }

    const clearUtilityButtons = () => {
        setUtilityButtons(<></>);
    };
    return (<ModalContext.Provider
        value={{
            currentContent: getCurrentModalContent()?.component,
            opened: modalStack.length > 0,
            openModal: (modal) => setModalStack([...modalStack, { hash: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5), component: modal}]),
            closeCurrentModal: closeCurrentModal
        }}>
        <UtilityButtonsContext.Provider value={{utilityButtons: utilityButtons, setUtilityButtons: setUtilityButtons, clear: clearUtilityButtons}}>
            <StyledModal isOpen={modalStack.length > 0}>
                {modalStack.map((modal, index) => <ModalContainer key={modal.hash} displayed={index === modalStack.length - 1}>
                    {modal.component}
                </ModalContainer>)}
            </StyledModal>
            <Header/>
            <BodyContainer>
                {children}
            </BodyContainer>
        </UtilityButtonsContext.Provider>
    </ModalContext.Provider>)
};

const BodyContainer = styled.div`
    padding: 32px 24px;
`;

const ModalContainer = styled.div<{displayed: boolean}>`
    display: ${({displayed}) => displayed ? "block" : "none"};
`

export default Layout;