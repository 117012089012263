import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const editTask = async (data: any) => {
    const response = await fetch(`/api/v1/tasks/${data.taskId}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify(data),
    });
    return response
};

export default editTask;