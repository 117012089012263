import React, {ReactElement, useEffect, useState} from "react";
import {
    LabeledInputField,
    LabeledInputLabel,
    LabeledInputRow, LabeledInputTextArea,
    LabeledInputWrapper
} from "./styled/LabeledTextInput.styled"
import InputValidationMessage from "./InputValidationMessage";
import StyledDatePicker from "./StyledDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {formatPostalCode, formatPrice} from "../../../shared/utils/format";

export interface LabeledTextInputInterface {
    label: string;
    placeholder: string;
    defaultValue: string;
    name: string;
    type: "text" | "textarea" | "date" | "number";
    valid: boolean;
    disable?: boolean;
    errorMessage?: string;
    props?: any;
    wide?: boolean;
    overlayText?: string;
    onChange?(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    onDateChange?(e: string): string;
    isPrice?: boolean;
    isCode?: boolean;
}

const LabeledTextInput = React.forwardRef(({
                                               label,
                                               placeholder,
                                               name,
                                               defaultValue,
                                               valid,
                                               type,
                                               disable,
                                               errorMessage,
                                               props,
                                               wide,
                                               overlayText,
                                               onChange,
                                               isPrice,
                                                isCode,
                                                onDateChange
                                           }: LabeledTextInputInterface, ref: any): ReactElement => {
    const [date, setDate] = useState<string>(defaultValue || "");
    useEffect(() => {
        if(onDateChange) onDateChange(date);
    }, [date]);
    return <LabeledInputWrapper>
        <LabeledInputRow>
            <LabeledInputLabel valid={valid}>{label}</LabeledInputLabel>
        </LabeledInputRow>
        <LabeledInputRow isDate={type === "date"}>
            {overlayText && <OverlayText htmlFor={name}>{overlayText}</OverlayText>}
            {type === "date" && <>
                <input name={name} type="hidden" value={date} ref={ref}/>
                <StyledDatePicker date={date} disable={disable} valid={valid} onChange={(date) => {
                    if(onDateChange) {
                        const result = onDateChange(date);
                        setDate(result);
                    }
                    else setDate(date)
                }} wide={wide != undefined && wide}/>
            </>}
            {(type === "text" || type === "number") && <LabeledInputField defaultValue={isPrice ? formatPrice(defaultValue) : (isCode ? formatPostalCode(defaultValue) : defaultValue)} hasOverlay={overlayText != undefined} type={type} disabled={disable} id={name} enabled={disable} name={name} placeholder={overlayText ? '' : placeholder} ref={ref} onChange={(e => {
                if(onChange) onChange(e);
                if(isPrice) {
                    const formatted = formatPrice(e.currentTarget.value);
                    if(formatted != e.currentTarget.value) e.currentTarget.value = formatted;
                }
                if(isCode) {
                    const formatted = formatPostalCode(e.currentTarget.value);
                    if(formatted != e.currentTarget.value) e.currentTarget.value = formatted;
                }
            })} valid={valid} wide={wide != undefined && wide}/>}
            {type === "textarea" && <LabeledInputTextArea disabled={disable} enabled={disable} name={name} placeholder={placeholder} defaultValue={defaultValue} ref={ref} onChange={onChange} valid={valid}/>}
        </LabeledInputRow>
        {errorMessage &&
        <LabeledInputRow>
            <InputValidationMessage type="error" message={errorMessage}/>
        </LabeledInputRow>}
    </LabeledInputWrapper>;
});

const OverlayText = styled.label`
    position: absolute;
  z-index: 1;
  width: calc(100% - 24px);
  height: 100%;
  display: flex;
  padding: 0 12px;
  align-items: center;
  font-weight: 700;
`;

export default LabeledTextInput;