import styled from "styled-components";
import {ReactComponent as ILogo} from "../../resources/eric_logo.svg";
import {ReactComponent as IKeyIcon} from "../../resources/icons/key.svg";
import {ReactComponent as IUserIcon} from "../../resources/icons/user.svg";

import {Link} from "react-router-dom";

export const UserIcon = styled(IUserIcon)`
  width: 18px;
  height: 21px;
  position: absolute;
  left: 15px;
  bottom: 13px;
  fill: ${props => props.theme.colors.black};
`;

export const KeyIcon = styled(IKeyIcon)`
  width: 20px;
  height: 21px;
  position: absolute;
  left: 14px;
  bottom: 13px;
  fill: ${props => props.theme.colors.black};
`;

export const LoginPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const LoginFormWrapper = styled.div`
  background-color: ${props => props.theme.colors.lighterGray};
  flex: 0 1 972px;
  text-align: center;
`;

export const LoginLogo = styled(ILogo)`
  height: 96px;
  width: auto;
  margin: 36px 0;
`;

export const LoginForm = styled.form`
  width: 396px;
  margin: 0 auto;
`;

export const LoginButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 45px 0;
`;

export const ForgotPasswordLink = styled(Link)`
  color: ${props => props.theme.colors.darkGray};
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
`;

export const LoginButton = styled.button`
  background-color: ${props => props.theme.colors.gray};
  padding: 8px 24px;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
`;