import React, {useContext, useEffect, useMemo, useState} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalCancel, ModalInputRow, ModalSubmit} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as IHouseIcon} from "../../resources/icons/building-light.svg";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {ModalContext} from "../../components/layout/Layout";
import {Routes} from "../../router/Routes";
import {GetRETypeLabel, GetRETypes, RealEstateType} from "../../enums/RealEstateEnums";
import createRealEstate from "./api/createRealEstate";
import toast from "react-hot-toast";
import {ModalTileItem, ModalTileSelector} from "../../components/shared/modal/ModalTileSelector";
import CountryMapper, {GetCountryByValue} from "../../components/shared/CountryMapper";
import {formatPostalCode} from "../../shared/utils/format";


interface FirstStepModalProps {
    realEstateType: number;

    setRealEstateType(type: number): void;

    continue(): void;

    close(): void;
    getValues: any;
}

const FirstStepModal: React.FC<FirstStepModalProps> = (props) => {
    const realEstateTypes = useMemo<ModalTileItem[]>(() => GetRETypes().map(t => ({name: t.label, value: t.id.toString(), icon: t.icon})), []);

    return <ModalContentWrap
        cancelModal={props.close}
        icon={<IHouseIcon/>}
        title="Nová nemovitost"
        subtitle="Pro pokračování vyberte jednu z šablon určující typ nemovitosti."
    >
        <ModalTileSelector selectedValue={props.realEstateType.toString()} setSelectedValue={(value) => {
            props.setRealEstateType(parseInt(value));
        }} tiles={realEstateTypes}/>
        <ModalSubmit type={'button'} onClick={props.continue}>
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.close}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};


interface SecondStepModalProps {
    realEstateType: number;
    register: any;
    errors: any;
    watch: any;
    close(): void;

    goBack(): void;
    getValues: any;
    setValue: any;
}

const SecondStepModal: React.FC<SecondStepModalProps> = (props) => {
    const typeLabel = useMemo(() => GetRETypeLabel(props.realEstateType), []);
    const {register, errors, close, getValues, setValue, watch} = props;
    const [internal, setInternal] = useState(false);
    const postalCode = watch('postalCode');

    useEffect(() => {
        document.getElementsByName("estimatedValueInternal")[0].focus()
    }, []);

    return <ModalContentWrap
        cancelModal={close}
        icon={<IHouseIcon/>}
        title={typeLabel}
        subtitle="Pro dokončení tvorby nemovitosti vyplňte povinné parametry."
    >
        <ModalInputRow>
            <LabeledTextInput type="text" label="Hodnota nemovitosti interní"
                              name="estimatedValueInternal"
                              defaultValue=""
                              disable={internal}
                              isPrice
                              placeholder="Hodnota..."
                              onChange={(e) => {
                                  setValue('estimatedValueExternal', "");
                              }}
                              valid={!errors.estimatedValueInternal}
                              errorMessage={errors.estimatedValueInternal?.message}
                              ref={register}/>
            <LabeledTextInput type="text" label="Hodnota nemovitosti externí"
                              name="estimatedValueExternal"
                              defaultValue=""
                              isPrice
                              placeholder="Hodnota..."
                              valid={!errors.estimatedValueExternal}
                              onChange={(e) => {
                                  if(e.currentTarget.value != "" && parseInt(e.currentTarget.value) > 0) {
                                      setValue('estimatedValueInternal', 0, { shouldValidate: true });
                                      setInternal(true);
                                  }
                                  else setInternal(false);
                              }}
                              errorMessage={errors.estimatedValueExternal?.message}
                              ref={register}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput name="cadastralTerritory" label="Katastrální území" type="text"
                              placeholder="Text..."
                              defaultValue={""}
                              valid={!errors.cadastralTerritory}
                              errorMessage={errors.cadastralTerritory?.message}
                              ref={register({required: {value: true, message: "Katastrální území je povinné."}})}/>
            <LabeledTextInput name="listOfOwners" label="Číslo LV" type="text"
                              placeholder="Text..."
                              defaultValue={"LV"}
                              valid={!errors.listOfOwners}
                              errorMessage={errors.listOfOwners?.message}
                              ref={register({required: {value: true, message: "Číslo LV je povinné."}})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput
                label='Ulice'
                type="text"
                name="streetAddress"
                valid={!errors.streetAddress}
                errorMessage={errors.streetAddress?.message}
                placeholder="Text..."
                defaultValue=""
                ref={register}/>
            <LabeledTextInput
                label='Číslo popisné'
                type="text"
                name="streetAddressNumber"
                valid={!errors.streetAddressNumber}
                errorMessage={errors.streetAddressNumber?.message}
                placeholder="Text..."
                defaultValue=""
                ref={register}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput
                label='PSČ'
                type="text"
                name="postalCode"
                valid={!errors.postalCode}
                errorMessage={errors.postalCode?.message}
                placeholder="Číslo..."
                isCode
                defaultValue=""
                ref={register({ maxLength: 6, minLength: 6, required: {value: true, message: "PSČ je povinné."}})}/>
            <LabeledTextInput
                label='Obec/Město'
                type="text"
                name="city"
                valid={!errors.city}
                errorMessage={errors.city?.message}
                placeholder="Text..."
                defaultValue=""
                ref={register({required: {value: true, message: "Obec/město je povinné."}})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledSelectInput
                label={"Země"}
                placeholder={"Vyberte..."}
                name={"country"}
                disable={true}
                defaultValue={GetCountryByValue("CZ")?.id}
                valid={!errors.country}
                errorMessage={errors.country?.message}
                options={CountryMapper()}
                ref={register({required: {value: true, message: "Země je povinná."}})}
            />
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.goBack}>
            <ICrossIcon/> Vrátit se zpět
        </ModalCancel>
    </ModalContentWrap>
};

interface CreateRealEstateModalProps {
    defaultType?: RealEstateType;

    onRealEstateCreate?(id: number | string, type: RealEstateType): void;
}

const CreateRealEstateModal = ({defaultType, onRealEstateCreate}: CreateRealEstateModalProps) => {
    const {register, handleSubmit, errors, getValues, setValue, watch} = useForm();
    const [realEstateType, setRealEstateType] = useState<RealEstateType>(defaultType !== undefined ? defaultType : RealEstateType.HousingUnit);
    const [modalStep, setModalStep] = useState<number>(defaultType !== undefined ? 2 : 1);
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        const estimatedValueInternal = parseFloat(data.estimatedValueInternal?.replaceAll('.', '').replace(/\s+/g, ''));
        const estimatedValueExternal = parseFloat(data.estimatedValueExternal?.replaceAll('.', '').replace(/\s+/g, ''));
        data = {
            ...data,
            type: realEstateType,
            estimatedValueInternal: data.estimatedValueInternal == "" ? null : (isNaN(estimatedValueInternal) ? 0 : estimatedValueInternal),
            estimatedValueExternal: data.estimatedValueExternal == "" ? null : (isNaN(estimatedValueExternal) ? 0 : estimatedValueExternal)
        };
        const response = await createRealEstate(data);
        if (response.status == 200) {
            const data = await response.json();
            modal.closeCurrentModal();
            if (onRealEstateCreate)
                onRealEstateCreate(data.result, realEstateType);
            else
                history.push(Routes.REAL_ESTATE_DETAIL.replace(":slug", data.result));
        } else {
            toast.error("Nemovitost se nepodařilo vytvořit.");
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        {modalStep === 1 && <FirstStepModal realEstateType={realEstateType}
                                            setRealEstateType={setRealEstateType}
                                            continue={() => setModalStep(2)}
                                            getValues={getValues}
                                            close={() => modal.closeCurrentModal()}/>}
        {modalStep === 2 && <SecondStepModal realEstateType={realEstateType}
                                             register={register}
                                             watch={watch}
                                             errors={errors}
                                             goBack={() => defaultType !== undefined ? modal.closeCurrentModal() : setModalStep(1)}
                                             getValues={getValues}
                                             setValue={setValue}
                                             close={() => modal.closeCurrentModal()}/>}
    </form>)
};

export default CreateRealEstateModal;