import styled, {css} from "styled-components";

export const InfoBoxRowItem = styled.div<{ nextLine?: boolean; closed?: boolean; }>`
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  width: 100%;
  display: ${props => props.nextLine ? 'block' : 'flex'};
  padding-bottom: 4px;
  justify-content: space-between;

  ${props => props.closed ? css`
    border-bottom: 1px solid ${props.theme.colors.lightGray};

    & > div {
      color: ${props.theme.colors.lighterGray} !important;
    }
  ` : ``}
  &:not(:first-child) {
    margin-top: 12px;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const InfoBoxRowItemLeft = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.grayBlue};
`;

export const InfoBoxRowItemRight = styled.div<{ nextLine?: boolean; }>`
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.black};
  a {
    text-decoration: none;
    font-weight: 600;
    color: ${({theme}) => theme.colors.blue};
  }
  span {
    color: ${props => props.theme.colors.grayBlue};
  }
  ${props => props.nextLine ? 'margin-top: 12px;' : ''};
  @media screen and (max-width: 600px) {
    margin-top: 12px;
  }
`;