import { getAccessToken } from "../../../contexts/UserIdentityContext";

const deleteRealEstate = async (id: string) => {
    const response = await fetch(`/api/v1/realestates/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        }
    })
    return response;
};

export default deleteRealEstate;
