import React, {ReactElement} from "react";
import {LoginInputBox, LoginInputLabel, LoginInputWrapper} from "./LoginInput.styled";

interface LoginInputInterface {
    label: string;
    name: string;
    icon: ReactElement;
    defaultValue: string;
    type?: string;
    valid?: boolean;
    disabled?: boolean;
    onChange?(e: React.FormEvent<HTMLInputElement>): void
}

const LoginInput = React.forwardRef( ({
                        label,
                        name,
                        icon,
                        defaultValue,
                        onChange,
                        disabled,
                        valid = true,
                        type = "text"
                    }: LoginInputInterface, ref: any): ReactElement => {
    return <LoginInputWrapper>
        <LoginInputLabel valid={valid} htmlFor={name}>{label}</LoginInputLabel>
        {icon}
        <LoginInputBox disabled={disabled != undefined && disabled != false} type={type} id={name} valid={valid} onChange={onChange} name={name} defaultValue={defaultValue} ref={ref}/>
    </LoginInputWrapper>;
});

export default LoginInput;