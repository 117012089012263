import React, { useContext, useEffect } from "react";
import SectionContainer from "../../../projects/project-detail/components/SectionContainer";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as IToolsIcon} from "../../../../resources/icons/screwdriver-wrench.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as ISaveIcon} from "../../../../resources/icons/floppy-disk.svg";
import { Routes } from "../../../../router/Routes";
import { useHistory, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import updateUser from "../../api/updateUser";
import toast from "react-hot-toast";
import {UserCompanyPlacement, UserPermissionLevel, UserState} from "../../../../enums/UserEnums";
import {formatPostalCode} from "../../../../shared/utils/format";

const EditSection = ({data, onEdit}: any) => {
    const {register, errors, handleSubmit, watch} = useForm();
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const postalCode = watch('postalCode');

    const onSubmit = async (editedData: any) => {
        editedData.id = parseInt(slug);
        editedData.permissionLevel = parseInt(editedData.permissionLevel);
        editedData.companyPlacement = parseInt(editedData.companyPlacement);
        editedData.state = parseInt(editedData.state);
        editedData.country =  GetCountryByValue(editedData.country) ?? "";
        const response = await updateUser(slug, editedData);
        if(response.status == 200) {
            let fetchedData = await response.json();
            onEdit();
            toast.success("Uživatel upraven.");
        } else {
            toast.error("Při uprávě nastala chyba!");
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.USER_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<ISaveIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);
    return (<form>
        <SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                    <LabeledTextInput label="Jméno" type="text" name="firstName"
                    defaultValue={data.contact?.firstName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.firstName}
                    ref={register({required: {value: true, message: "Jméno je povinné."}})}
                    />
                    <LabeledTextInput label="Příjmení" type="text" name="lastName"
                    defaultValue={data.contact?.lastName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.lastName}
                    ref={register({required: {value: true, message: "Příjmení je povinné."}})}
                    />
                    <LabeledTextInput label="Email" type="text" name="email"
                    defaultValue={data.contact?.email || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.email}
                    ref={register}
                    />
                    <LabeledTextInput label="Telefon" type="text" name="phoneNumber"
                    defaultValue={data.contact?.phoneNumber || ""}
                    placeholder="Číslo.."
                    wide
                    valid={!errors.phoneNumber}
                    ref={register}
                    />
                    <LabeledTextInput label="Ulice 1. řádek" type="text" name="streetAddressFirstLine"
                    defaultValue={data.contact?.location.streetAddressFirstLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.streetAddressFirstLine}
                    ref={register}
                    />
                    <LabeledTextInput label="Ulice 2. řádek" type="text" name="streetAddressSecondLine"
                    defaultValue={data.contact?.location.streetAddressSecondLine || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.streetAddressSecondLine}
                    ref={register}
                    />
                    <LabeledTextInput label="PSČ" type="text" name="postalCode"
                    defaultValue={data.contact?.location.postalCode || ""}
                    placeholder="Číslo..."
                    wide
                    valid={!errors.postalCode}
                                      isCode
                    ref={register({maxLength: 6, minLength: 6})}
                    />
                    <LabeledTextInput label="Obec/Město" type="text" name="city"
                    defaultValue={data.contact?.location.city || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.city}
                    ref={register}
                    />
                    <LabeledSelectInput label="Země" name="country"
                    placeholder="Vyberte..."
                    defaultValue={GetCountryByValue(data.contact?.location.country)?.id}
                    valid={!errors.message}
                    options={CountryMapper()}
                    ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Systémové údaje" icon={<IToolsIcon/>}>
                    <LabeledSelectInput label="Oprávnění" name="permissionLevel"
                    placeholder="Vyberte..."
                    valid={!errors.permissionLevel}
                    defaultValue={data.systemInformation?.permissionLevel}
                    options={[
                        {
                            id: UserPermissionLevel.None,
                            label: "Žádná",
                        },
                        {
                            id: UserPermissionLevel.BasicUser,
                            label: "Uživatel",
                        },
                        {
                            id: UserPermissionLevel.SuperAdmin,
                            label: "Administrátor",
                        }
                    ]}
                    ref={register({required: {value: true, message: "Oprávnění je povinné."}})}
                    />
                    <LabeledSelectInput label="Umístění" name="companyPlacement"
                    placeholder="Vyberte..."
                    valid={!errors.companyPlacement}
                    defaultValue={data.systemInformation?.companyPlacement}
                    options={[
                        {
                            id: UserCompanyPlacement.NotSet,
                            label: "Nenastaveno",
                        },
                        {
                            id: UserCompanyPlacement.Internal,
                            label: "Interní",
                        },
                        {
                            id: UserCompanyPlacement.External,
                            label: "Externí",
                        }
                    ]}
                    ref={register({required: {value: true, message: "Umístění je povinné."}})}
                    />
                    <LabeledSelectInput label="Stav" name="state"
                    placeholder="Vyberte..."
                    valid={!errors.state}
                    defaultValue={data.systemInformation?.state}
                    options={[
                        {
                            id: UserState.NotSet,
                            label: "Nenastaveno",
                        },
                        {
                            id: UserState.Active,
                            label: "Aktivní",
                        },
                        {
                            id: UserState.Inactive,
                            label: "Neaktivní",
                        }
                    ]}
                    ref={register({required: {value: true, message: "Stav je povinný."}})}
                    />
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    <LabeledTextInput
                        type="text"
                        label="Poznámka"
                        name="note"
                        wide
                        placeholder={"Text..."}
                        valid={!errors.note}
                        defaultValue={data.note || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer>
    </form>)
};

export default EditSection;