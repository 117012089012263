import React, {ReactElement, useState} from "react";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {LoginButton, LoginButtonSection, LoginForm, LoginFormWrapper, LoginLogo, LoginPageWrapper, UserIcon} from "./LoginPage.styled";
import LoginInput from "./components/LoginInput";
import {useForm} from "react-hook-form";
import forgotPassword from "./api/forgotPassword";
import {Routes} from "../../router/Routes";
import ILoadingGIF from "../../resources/images/loading.gif";

const ForgotPasswordPage = (): ReactElement => {
    //const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const {handleSubmit, register, errors} = useForm();

    const onSubmit = async (data: any) => {
        setLoading(true)
        const response = await forgotPassword(data.email);
        if(response.status == 200) {
            history.push(Routes.LOGIN, { message: "Email pro resetování hesla byl odeslán." })
            setLoading(false)
        } else {
            setLoading(false)
        }
    };

    return <LoginPageWrapper>
        <LoginFormWrapper>
            <LoginLogo/>
            <LoginForm onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={loading}>
                    <LoginInput
                        label={'Email'}
                        name={'email'}
                        type={'email'}
                        defaultValue=""
                        disabled={loading}
                        valid={errors?.email}
                        ref={register({required: true})}
                        icon={<UserIcon/>}
                    />
                    <LoginButtonSection>
                        <div/>
                        <LoginButton disabled={loading} type={'submit'}>Odeslat {loading && <Loading src={ILoadingGIF}/>}</LoginButton>
                    </LoginButtonSection>
                </fieldset>
            </LoginForm>
        </LoginFormWrapper>
    </LoginPageWrapper>;
};

export default ForgotPasswordPage;

const Loading = styled.img`
    margin-bottom: -4px;
    margin-left: 8px;
    max-width: 20px;
    max-height: 20px;
`;