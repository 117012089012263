import React, {useContext, useEffect} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IUserIcon} from "../../resources/icons/user.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import {useHistory, useParams} from "react-router-dom";
import { ModalContext } from "../../components/layout/Layout";
import {Routes} from "../../router/Routes";
import {GetRETypes} from "../../enums/RealEstateEnums";
import createRealEstateRenter from "./api/createRealEstateRenter";
import toast from "react-hot-toast";

const CreateRealEstateRenterModal = () => {
    const {register, handleSubmit, errors} = useForm();
    const {slug}: any = useParams();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        data.realEstateId = parseInt(slug);
        const response = await createRealEstateRenter(data);
        if(response.status == 200) {
            const data = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.REAL_ESTATE_RENTER_DETAIL.replace(":slug", slug).replace(":secondslug", data.result));
        } else {
            toast.error("Nemovitost se nepodařilo vytvořit.");
        }
    };

    useEffect(() => {
        document.getElementsByName("firstName")[0].focus()
    }, []);

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<IUserIcon/>}
        title="Vytvořit nájemníka"
        >
        <ModalInputRow>
            <LabeledTextInput type="text" label="Jméno"
            name="firstName"
            defaultValue=""
            placeholder="Text..."
            valid={!errors.firstName}
            errorMessage={errors.firstName?.message}
            ref={register({required: {value: true, message: "Jméno je povinné."}})}/>
            <LabeledTextInput type="text" label="Příjmení"
            name="lastName"
            defaultValue=""
            placeholder="Text..."
            valid={!errors.lastName}
            errorMessage={errors.lastName?.message}
            ref={register({required: {value: true, message: "Příjmení je povinné."}})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

export default CreateRealEstateRenterModal;