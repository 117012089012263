import { useState } from "react";

const useFilters = (defaultFilters: Object) => {
    const [filters, setFilters] = useState<any>({...defaultFilters})
    const changeFilter = (filter: string | Array<string>, value: string | Array<string>): void => {
        if(typeof filter == "string") {
            setFilters(Object.defineProperty({...filters}, filter, { value: value }))
        } else {
            const copFilters: any = {...filters};
            filter.map((name, i) => { copFilters[name] = value[i] });
            setFilters(copFilters);
        }
    };
    return [
        filters,
        changeFilter,
    ]
};

export default useFilters;