import React from "react";
import styled from "styled-components";

interface DoubleLineValuesInteface {
    firLine: React.ReactNode | string;
    secLine: React.ReactNode | string;
}

const DoubleLineValue = ({firLine, secLine}: DoubleLineValuesInteface) => {
    return (<Container>
        {firLine !== "" ? firLine : "-"}
        <SecondLineWrapper>
            {secLine !== "" ? secLine : "-"}
        </SecondLineWrapper>
    </Container>)
};

const Container = styled.div`
    font-family: "Open Sans";
    line-height: 17px;
`;

const SecondLineWrapper = styled.span`
    font-size: 13px;
`;

export default DoubleLineValue;