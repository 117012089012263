import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {ReactComponent as IBackIcon} from "../../resources/icons/chevron-left.svg";

interface PageHeaderInterface {
    returnPath?: string | number;
    onReturn?(): void;
    image?: React.ReactNode | string;
    strip?: React.ReactNode;
    title: string;
    subtitle?: string;
    noContainerLeftMargin?: boolean;
    onUpload?(e: any): void;
    noContainerBottomMargin?: boolean;
}

const PageHeader = ({title, subtitle, image, strip, returnPath, onUpload, onReturn, noContainerLeftMargin, noContainerBottomMargin}: PageHeaderInterface) => {
    const history = useHistory();
    return (<Container marginLeft={noContainerLeftMargin == undefined || noContainerLeftMargin != true} marginBottom={noContainerBottomMargin == undefined || noContainerBottomMargin != true}>
        {(returnPath != undefined) && <>{returnPath != -1 ? <BackIconWrapperLink to={returnPath as string}>
            <BackIcon/>
        </BackIconWrapperLink> : <BackIconWrapper onClick={() => {
            if (onReturn)
                onReturn()
            else
                history.goBack()
        }}>
            <BackIcon/>
        </BackIconWrapper>}</>}
        {image != undefined && <>
            {(typeof image == "string" || React.isValidElement(image)) ? <>
                {React.isValidElement(image) && <IconWrapper marginLeft={returnPath != undefined}>{image}</IconWrapper>}
                {!React.isValidElement(image) && <StyledFileLabel htmlFor="imageUpload" marginLeft={returnPath != undefined}>
                    <StyledFileInput type="file" accept="image/*" id="imageUpload" onChange={(e) => {
                        if(onUpload) onUpload(e)
                    }}/>
                    <Picture src={image as string} alt="Fotka"/>
                </StyledFileLabel>}
            </> : <IconWrapper marginLeft={returnPath != undefined}>{(image as any).render()}</IconWrapper>}
        </>}
        {strip != undefined && <StripWrapper>{strip}</StripWrapper>}
        <TitleWrapper marginLeft={image == undefined && strip == undefined}>
            <Title marginBottom={subtitle != undefined}>
                {title}
            </Title>
            {subtitle && <Subtitle>
                {subtitle}
            </Subtitle>}
        </TitleWrapper>
    </Container>)
};

const Container = styled.div<{ marginLeft: boolean, marginBottom: boolean }>`
    ${({marginLeft, marginBottom}) => marginBottom ? `
        margin: ${marginLeft ? "0 0 32px 24px" : "0 0 32px 0px"};
    ` : `
        margin: ${marginLeft ? "0 0 0 24px" : "0 0 0 0px"};
    `};
    display: flex;
    min-height: 60px;
`;

const StyledFileLabel = styled.label<{ marginLeft: boolean }>`
    cursor: pointer;
    margin: ${({marginLeft}) => marginLeft ? "0 16px 0 24px" : "0 16px 0 0"};
`;

const StyledFileInput = styled.input`
    display: none;
`;

const BackIconWrapperLink = styled(Link)`
    background-color: ${({theme}) => theme.colors.lightGray};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
`;

const BackIconWrapper = styled.div`
    background-color: ${({theme}) => theme.colors.lightGray};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
`;

const BackIcon = styled(IBackIcon)`
    width: 8px;
    height: 32px;
    fill: ${({theme}) => theme.colors.black};
`;

const IconWrapper = styled.div<{ marginLeft: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin: ${({marginLeft}) => marginLeft ? "0 16px 0 24px" : "0 16px 0 0"};
    background-color: ${({theme}) => theme.colors.lighterGray};
    border-radius: 50%;

    svg {
        max-width: 24px;
        max-height: 42px;
    }
`;

const Picture = styled.img`
    border-radius: 50%;
    object-fit: cover;
    width: 60px;
    height: 60px;
`;

const StripWrapper = styled.div`
    margin-right: 12px;
    margin-left: 24px;
    max-height: 60px;
`;

const TitleWrapper = styled.div<{ marginLeft: boolean }>`
    margin-top: 10px;
    ${({marginLeft}) => marginLeft && "margin-left: 24px"};
`;

const Title = styled.div<{ marginBottom: boolean }>`
    font-size: 24px;
    color: ${({theme}) => theme.colors.black};
    font-weight: 600;
    margin-bottom: ${({marginBottom}) => marginBottom ? "8px" : "0"};
    margin-top: ${({marginBottom}) => marginBottom ? "0" : "10px"};
`;

const Subtitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.grayBlue};
`;

export default PageHeader;