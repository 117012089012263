import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import LabeledTextInput from "../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../components/shared/inputs/LabeledSelectInput";
import LabeledFileInput from "../../../components/shared/inputs/LabeledFileInput";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../components/shared/InfoBox/InfoBoxItem";
import {ReactComponent as IPDFIcon} from "../../../resources/icons/file-pdf.svg";
import {ReactComponent as IFileIcon} from "../../../resources/icons/file.svg";
import {ReactComponent as INoteIcon} from "../../../resources/icons/note.svg";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../resources/icons/floppy-disk.svg";
import editDocument from "../api/editDocument";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {GetDocumentCategoryTypes} from "../../../enums/DocumentEnums";
import fetchDocument from "../api/fetchDocument";

const EditDocument = () => {
    const {register, errors, handleSubmit} = useForm();
    const {slug}: {[index: string]: any} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const onSubmit = async (inputData: any) => {
        const documentData = new FormData();
        documentData.append("documentId", slug);
        documentData.append("name", inputData.name);
        documentData.append("category", inputData.category);
        if(inputData.file && inputData.file[0]) documentData.append("file", inputData.file[0]);
        const response = await editDocument(slug, documentData);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Dokument upraven.");
        } else {
            toast.error("Dokument se nepodařilo vytvořit.")
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={async () => history.push(Routes.DOCUMENT_DETAIL.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<IDiskIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
        const fetchData = async () => {
            const response = await fetchDocument(slug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Dokument se nepodařilo načíst.")
            }
        };
        fetchData();
    }, []);
    return (<form>{!loading && <Container>
        <PageHeader title={data.internalName}
        subtitle={`Dokumenty   —   ${data.internalName}`}
        image={<IPDFIcon/>}
        noContainerLeftMargin
        noContainerBottomMargin
        returnPath={Routes.DOCUMENTS}/>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Obecné" icon={<IFileIcon/>}>
                    <LabeledTextInput label="Název" type="text" name="name"
                    defaultValue={data.internalName || ""}
                    placeholder="Text..."
                    wide
                    valid={!errors.name}
                    errorMessage={errors.name?.message}
                    ref={register({required: {value: true, message: "Název je povinný"}})}
                    />
                    <LabeledSelectInput label="Kategorie" name="category"
                    placeholder="Vyberte..."
                    defaultValue={data.category}
                    valid={!errors.category}
                    errorMessage={errors.category?.message}
                    wide
                    options={GetDocumentCategoryTypes()}
                    ref={register}
                    />
                    <LabeledFileInput name="file"
                    label="Soubor"
                    valid={!errors.file}
                    accept={[
                        ".pdf", ".ppt", ".doc", ".xls"
                    ]}
                    errorMessage={errors.file?.message}
                    ref={register}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </Container>}</form>)
};

const Container = styled.div`
    padding: 0 24px;
`;

export default EditDocument;