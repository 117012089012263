import {getAccessToken} from "../../../contexts/UserIdentityContext";

const fetchUserTaskCount = async (id: string | number) => {
    const response = await fetch(`/api/v1/admins/${id}/tasks/count`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchUserTaskCount;