import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {InfoBoxColumn, InfoBoxFullContainer} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IBorderIcon} from "../../../../resources/icons/border-top-left.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import {useHistory, useParams} from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {UtilityButtonsContext} from "../../../../components/layout/Layout";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../../resources/icons/floppy-disk.svg";
import {Routes} from "../../../../router/Routes";
import {useForm, useWatch} from "react-hook-form";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import {GetREOwnerships, GetRETypes} from "../../../../enums/RealEstateEnums";
import StyledCheckboxInput from "../../../../components/shared/inputs/StyledCheckboxInput";
import updateRealEstate from "../../api/updateRealEstate";
import toast from "react-hot-toast";
import {formatPostalCode, formatPrice} from "../../../../shared/utils/format";

const REEditSection = ({data, onEdit}: any) => {
    const {slug}: { [index: string]: any } = useParams();
    const {register, errors, handleSubmit, setValue, watch, getValues} = useForm();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const [internal, setInternal] = useState(data.general?.estimatedValueExternal == null ? false : (!isNaN(data.general?.estimatedValueExternal) && data.general?.estmatedValueExternal != 0));
    const postalCode = watch('location.postalCode');

    const onSubmit = async (inputData: any) => {
        inputData.realEstateId = parseInt(slug);
        const estExtValue = parseFloat(inputData.estimatedValueExternal?.replaceAll('.', '').replace(/\s+/g, ''));
        const response = await updateRealEstate(parseInt(slug), {
            ...inputData,
            estimatedValueExternal: inputData.estimatedValueExternal == "" ? null : (isNaN(estExtValue) ? 0 : estExtValue)
        });
        if (response.status == 200) {
            const fetchedData = await response.json();
            onEdit();
            toast.success("Nemovitost upravena.");
            history.push(Routes.REAL_ESTATE_DETAIL.replace(":slug", slug));
        } else {
            toast.error("Nepodařilo se upravit nemovitost.")
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Zrušit"
                icon={<ICrossIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_DETAIL.replace(":slug", slug))}
            />
            <UtilityButton
                title="Uložit"
                icon={<IDiskIcon/>}
                color={"green"}
                onClick={handleSubmit(onSubmit)}
            />
        </>)
    }, []);
    return (<InfoBoxFullContainer>
        <InfoBoxColumn>
            <InfoBox title="Lokalita" icon={<IMapIcon/>}>
                <LabeledTextInput type="text" label="Hodnota nemovitosti interní"
                                  name="estimatedValueInternal"
                                  defaultValue={data.general?.estimatedValueInternal ?? 0}
                                  placeholder="Hodnota..."
                                  wide
                                  disable={internal}
                                  onChange={(e) => {
                                      setValue('estimatedValueExternal', "");
                                  }}
                                  isPrice
                                  valid={!errors.estimatedValueInternal}
                                  errorMessage={errors.estimatedValueInternal?.message}
                                  ref={register}/>
                <LabeledTextInput type="text" label="Hodnota nemovitosti externí"
                                  name="estimatedValueExternal"
                                  defaultValue={data.general?.estimatedValueExternal ?? ""}
                                  placeholder="Hodnota..."
                                  wide
                                  isPrice
                                  onChange={(e) => {
                                      if(e.currentTarget.value != "" && parseInt(e.currentTarget.value) > 0) {
                                          setValue('estimatedValueInternal', 0, { shouldValidate: true });
                                          setInternal(true);
                                      }
                                      else setInternal(false);
                                  }}
                                  valid={!errors.estimatedValueExternal}
                                  errorMessage={errors.estimatedValueExternal?.message}
                                  ref={register}/>

                <LabeledTextInput name="cadastralTerritory" label="Katastrální území" type="text"
                                  placeholder="Text..."
                                  defaultValue={data.general?.cadastralTerritory ?? ""}
                                  valid={!errors.cadastralTerritory}
                                  wide
                                  errorMessage={errors.cadastralTerritory?.message}
                                  ref={register({required: {value: true, message: "Katastrální území je povinné."}})}/>
                <LabeledTextInput name="listOfOwners" label="Číslo LV" type="text"
                                  placeholder="Text..."
                                  wide
                                  defaultValue={data.general?.listOfOwners ?? "LV"}
                                  valid={!errors.listOfOwners}
                                  errorMessage={errors.listOfOwners?.message}
                                  ref={register({required: {value: true, message: "Číslo LV je povinné."}})}/>
                <LabeledTextInput
                    type={"text"}
                    label={"Ulice"}
                                  wide
                    placeholder={"Text..."}
                    name={"location.streetAddressFirstLine"}
                    defaultValue={data.location?.streetAddressFirstLine ?? ""}
                    valid={!errors.location?.streetAddressFirstLine}
                    errorMessage={errors.location?.streetAddressFirstLine?.message}
                    ref={register({required: {value: true, message: "Ulice je povinná."}})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Číslo popisné"}
                    placeholder={"Text..."}
                                  wide
                    name={"location.streetAddressSecondLine"}
                    defaultValue={data.location?.streetAddressSecondLine ?? ""}
                    valid={!errors.location?.streetAddressSecondLine}
                    errorMessage={errors.location?.streetAddressSecondLine?.message}
                    ref={register({required: {value: false, message: "Číslo popisné je povinný."}})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Číslo jednotky"}
                    wide
                    placeholder={"Číslo..."}
                    name={"identificationNumber"}
                    defaultValue={data.general?.identificationNumber ?? ""}
                    valid={!errors.identificationNumber}
                    errorMessage={errors.identificationNumber?.message}
                    ref={register({valueAsNumber: true, required: false})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Podlaží umístění"}
                    placeholder={"Číslo..."}
                    name={"floorLocation"}
                    wide
                    defaultValue={data.roomsInformation?.floorLocation ?? ""}
                    valid={!errors.floorLocation}
                    errorMessage={errors.floorLocation?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"PSČ"}
                    placeholder={"Číslo..."}
                                  wide
                    name={"location.postalCode"}
                    defaultValue={data.location?.postalCode ?? ""}
                    valid={!errors.location?.postalCode}
                    isCode
                    errorMessage={errors.location?.postalCode?.message}
                    ref={register({maxLength: 6, minLength: 6, required: {value: true, message: "PSČ je povinné."}})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Obec/Město"}
                    placeholder={"Text..."}
                    name={"location.city"}
                                  wide
                    defaultValue={data.location?.city ?? ""}
                    valid={!errors.location?.city}
                    errorMessage={errors.location?.city?.message}
                    ref={register({required: {value: true, message: "Obec/Město je povinné."}})}
                />
                <LabeledSelectInput
                    label={"Země"}
                    placeholder={"Vyberte..."}
                    name={"location.country"}
                    defaultValue={GetCountryByValue(data.location?.country)?.id}
                    valid={!errors.location?.country}
                    errorMessage={errors.location?.country?.message}
                    options={CountryMapper()}
                    ref={register({required: {value: true, message: "Země je povinná."}})}
                />
            </InfoBox>
            {/**<InfoBox title="Obecné" icon={<IInfoIcon/>}>
                <LabeledSelectInput
                    label={"Typ"}
                    placeholder={"Vyberte..."}
                    name={"type"}
                    defaultValue={data.general?.type ?? -1}
                    valid={!errors.type}
                    errorMessage={errors.type?.message}
                    options={GetRETypes()}
                    ref={register({valueAsNumber: true, required: {value: true, message: "Typ je povinný."}})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Číslo jednotky"}
                                  wide
                    placeholder={"Číslo..."}
                    name={"identificationNumber"}
                    defaultValue={data.general?.identificationNumber ?? ""}
                    valid={!errors.identificationNumber}
                    errorMessage={errors.identificationNumber?.message}
                    ref={register({valueAsNumber: true, required: false})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"PENB"}
                                  wide
                    placeholder={"Text..."}
                    name={"penb"}
                    defaultValue={data.general?.penb ?? ""}
                    valid={!errors.penb}
                    errorMessage={errors.penb?.message}
                    ref={register({required: false})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Náklady na bydlení"}
                                  wide
                    placeholder={"Číslo..."}
                    name={"housingCosts"}
                    defaultValue={data.general?.housingCosts ?? ""}
                    valid={!errors.housingCosts}
                    errorMessage={errors.housingCosts?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Kategorie"}
                                  wide
                    placeholder={"Text..."}
                    name={"spaceCategory"}
                    defaultValue={data.general?.spaceCategory ?? ""}
                    valid={!errors.spaceCategory}
                    errorMessage={errors.spaceCategory?.message}
                    ref={register}
                />
                <LabeledSelectInput
                    label={"Vlastnictví"}
                    placeholder={"Text..."}
                    name={"ownership"}
                    options={GetREOwnerships()}
                    defaultValue={data.general?.ownership || 0}
                    valid={!errors.ownership}
                    errorMessage={errors.ownership?.message}
                    ref={register({valueAsNumber: true, required: {value: true, message: "Vlastnictví je povinné."}})}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Voda"}
                    placeholder={"Text..."}
                                  wide
                    name={"water"}
                    defaultValue={data.general?.water ?? ""}
                    valid={!errors.water}
                    errorMessage={errors.water?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Topení"}
                    placeholder={"Text..."}
                    name={"heating"}
                                  wide
                    defaultValue={data.general?.heating ?? ""}
                    valid={!errors.heating}
                    errorMessage={errors.heating?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Plyn"}
                                  wide
                    placeholder={"Text..."}
                    name={"gas"}
                    defaultValue={data.general?.gas ?? ""}
                    valid={!errors.gas}
                    errorMessage={errors.gas?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Elektřina"}
                    placeholder={"Text..."}
                                  wide
                    name={"eletricity"}
                    defaultValue={data.general?.eletricity ?? ""}
                    valid={!errors.eletricity}
                    errorMessage={errors.eletricity?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Odpad"}
                    placeholder={"Text..."}
                                  wide
                    name={"drain"}
                    defaultValue={data.general?.drain ?? ""}
                    valid={!errors.drain}
                    errorMessage={errors.drain?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Rok výstavby"}
                    placeholder={"Číslo..."}
                                  wide
                    name={"builtOn"}
                    defaultValue={data.general?.builtOn ?? ""}
                    valid={!errors.builtOn}
                    errorMessage={errors.builtOn?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Rok kolaudace"}
                    placeholder={"Číslo..."}
                                  wide
                    name={"approvedOn"}
                    defaultValue={data.general?.approvedOn ?? ""}
                    valid={!errors.approvedOn}
                    errorMessage={errors.approvedOn?.message}
                    ref={register({valueAsNumber: true})}
                />
                <StyledCheckboxInput
                    label={"Výtah"}
                    name={"elevator"}
                    isSolo
                    defaultValue={data.general?.elevator}
                    ref={register}
                />
                <StyledCheckboxInput
                    label={"Zařízený"}
                    name={"furnishedAccomodation"}
                    isSolo
                    defaultValue={data.general?.furnishedAccomodation}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Stav objektu"}
                    placeholder={"Text..."}
                    name={"stateDescription"}
                                  wide
                    defaultValue={data.general?.stateDescription ?? ""}
                    valid={!errors.stateDescription}
                    errorMessage={errors.stateDescription?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Budova"}
                    placeholder={"Text..."}
                                  wide
                    name={"buildingType"}
                    defaultValue={data.general?.buildingType ?? ""}
                    valid={!errors.buildingType}
                    errorMessage={errors.buildingType?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Umístění objektu"}
                    placeholder={"Text..."}
                    name={"placementDescription"}
                    defaultValue={data.general?.placementDescription ?? ""}
                    valid={!errors.placementDescription}
                    errorMessage={errors.placementDescription?.message}
                    ref={register}
                                  wide
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Poloha objektu"}
                    placeholder={"Text..."}
                    name={"locationDescription"}
                    defaultValue={data.general?.locationDescription ?? ""}
                    valid={!errors.locationDescription}
                    errorMessage={errors.locationDescription?.message}
                    ref={register}
                                  wide
                />
                <LabeledTextInput
                    type={"text"}
                    label={"development"}
                    placeholder={"Text..."}
                    name={"development"}
                    defaultValue={data.general?.development ?? ""}
                    valid={!errors.development}
                    errorMessage={errors.development?.message}
                    ref={register}
                                  wide
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Parkování"}
                    placeholder={"Text..."}
                    name={"parking"}
                    defaultValue={data.general?.parking ?? ""}
                    valid={!errors.parking}
                    errorMessage={errors.parking?.message}
                    ref={register}
                                  wide
                />
                <StyledCheckboxInput
                    label={"Garáž"}
                    name={"garage"}
                    isSolo
                    defaultValue={data.general?.garage}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Komunikace"}
                    placeholder={"Text..."}
                    name={"roads"}
                    defaultValue={data.general?.roads ?? ""}
                    valid={!errors.roads}
                    errorMessage={errors.roads?.message}
                    ref={register}
                                  wide
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Doprava"}
                    placeholder={"Text..."}
                    name={"transportation"}
                    defaultValue={data.general?.transportation ?? ""}
                    valid={!errors.transportation}
                    errorMessage={errors.transportation?.message}
                    ref={register}
                                  wide
                />
            </InfoBox>**/}
        </InfoBoxColumn>
        <InfoBoxColumn>
            <InfoBox title="Parametry" icon={<IInfoIcon/>}>
                <StyledCheckboxInput
                    label={"Existuje nájem"}
                    name={"isRentable"}
                    isSolo
                    defaultValue={data.general?.isRentable}
                    ref={register}
                />
            </InfoBox>
            {/**<InfoBox title="Místnosti" icon={<IBorderIcon/>}>
                <LabeledTextInput
                    type={"text"}
                    label={"Předsíň"}
                    placeholder={"Text..."}
                    name={"hallRoom"}
                    wide
                    defaultValue={data.roomsInformation?.hallRoom ?? ""}
                    valid={!errors.hallRoom}
                    errorMessage={errors.hallRoom?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Koupelna"}
                    placeholder={"Text..."}
                    name={"bathroom"}
                    wide
                    defaultValue={data.roomsInformation?.bathroom ?? ""}
                    valid={!errors.bathroom}
                    errorMessage={errors.bathroom?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Kuchyň"}
                    wide
                    placeholder={"Text..."}
                    name={"kitchen"}
                    defaultValue={data.roomsInformation?.kitchen ?? ""}
                    valid={!errors.kitchen}
                    errorMessage={errors.kitchen?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"WC"}
                    placeholder={"Text..."}
                    name={"toilet"}
                                  wide
                    defaultValue={data.roomsInformation?.toilet ?? ""}
                    valid={!errors.toilet}
                    errorMessage={errors.toilet?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"1. Pokoj"}
                    placeholder={"Text..."}
                    name={"firstRoom"}
                                  wide
                    defaultValue={data.roomsInformation?.firstRoom ?? ""}
                    valid={!errors.firstRoom}
                    errorMessage={errors.firstRoom?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"2. Pokoj"}
                    placeholder={"Text..."}
                                  wide
                    name={"secondRoom"}
                    defaultValue={data.roomsInformation?.secondRoom ?? ""}
                    valid={!errors.secondRoom}
                    errorMessage={errors.secondRoom?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Obývací pokoj"}
                    placeholder={"Text..."}
                    name={"livingRoom"}
                                  wide
                    defaultValue={data.roomsInformation?.livingRoom ?? ""}
                    valid={!errors.livingRoom}
                    errorMessage={errors.livingRoom?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Balkón"}
                    placeholder={"Text..."}
                    name={"balcony"}
                                  wide
                    defaultValue={data.roomsInformation?.balcony ?? ""}
                    valid={!errors.balcony}
                    errorMessage={errors.balcony?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"text"}
                    label={"Sklep"}
                    placeholder={"Text..."}
                    name={"cellar"}
                                  wide
                    defaultValue={data.roomsInformation?.cellar ?? ""}
                    valid={!errors.cellar}
                    errorMessage={errors.cellar?.message}
                    ref={register}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Podlaží umístění"}
                    placeholder={"Číslo..."}
                    name={"floorLocation"}
                                  wide
                    defaultValue={data.roomsInformation?.floorLocation ?? ""}
                    valid={!errors.floorLocation}
                    errorMessage={errors.floorLocation?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Počet podlaží"}
                    placeholder={"Číslo..."}
                    name={"numberOfFloors"}
                                  wide
                    defaultValue={data.roomsInformation?.numberOfFloors ?? ""}
                    valid={!errors.numberOfFloors}
                    errorMessage={errors.numberOfFloors?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Plocha užitná"}
                    placeholder={"Číslo..."}
                    name={"usableArea"}
                                  wide
                    defaultValue={data.roomsInformation?.usableArea ?? ""}
                    valid={!errors.usableArea}
                    errorMessage={errors.usableArea?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Plocha podlahová"}
                    placeholder={"Číslo..."}
                    name={"floorArea"}
                                  wide
                    defaultValue={data.roomsInformation?.floorArea ?? ""}
                    valid={!errors.floorArea}
                    errorMessage={errors.floorArea?.message}
                    ref={register({valueAsNumber: true})}
                />
                <LabeledTextInput
                    type={"number"}
                    label={"Celková výměra bytu"}
                    placeholder={"Číslo..."}
                    name={"totalArea"}
                                  wide
                    defaultValue={data.roomsInformation?.totalArea ?? ""}
                    valid={!errors.totalArea}
                    errorMessage={errors.totalArea?.message}
                    ref={register({valueAsNumber: true})}
                />
            </InfoBox>**/}
            <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                <LabeledTextInput
                    type={"textarea"}
                    label={"Poznámka"}
                    placeholder={"Text..."}
                    name={"note"}
                                  wide
                    defaultValue={data.note ?? ""}
                    valid={!errors.note}
                    errorMessage={errors.note?.message}
                    ref={register}
                />
            </InfoBox>
        </InfoBoxColumn>
    </InfoBoxFullContainer>)
};

const Container = styled.div``;

export default REEditSection;