import { getAccessToken } from "../../../../contexts/UserIdentityContext";

const createProvider = async (data: any) => {
    const response = fetch(`/api/v1/agents`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
    })
    return response;
};

export default createProvider;