export enum UserCompanyPlacement
{
    NotSet = 0,
    Internal = 1,
    External = 2
}

export enum UserPermissionLevel
{
    None = 0,
    BasicUser = 1,
    SuperAdmin = 2
}

export enum UserState
{
    NotSet = 0,
    Active = 1,
    Inactive = 2
}

export enum UserProjectRole
{
    None = 0,
    ProjectManager = 1,
    TaskManager = 2,
    Audit = 3,
}

export const GetUserProjectRoleLabel = (type: UserProjectRole) => {
    switch (type) {
        case UserProjectRole.None: return "Bez role";
        case UserProjectRole.ProjectManager: return "Manažer projektu";
        case UserProjectRole.TaskManager: return "Správce úkolů";
        case UserProjectRole.Audit: return "Audit";
        default: return "Bez role";
    }
};

interface UserProjectRoleInterface {
    id: 0 | 1 | 2 | 3,
    label: string,
}
export const GetUserProjectRoles = (): Array<UserProjectRoleInterface> => {
    return [
        {
            id: UserProjectRole.None,
            label: GetUserProjectRoleLabel(UserProjectRole.None),
        },
        {
            id: UserProjectRole.ProjectManager,
            label: GetUserProjectRoleLabel(UserProjectRole.ProjectManager),
        },
        {
            id: UserProjectRole.TaskManager,
            label: GetUserProjectRoleLabel(UserProjectRole.TaskManager),
        },
        {
            id: UserProjectRole.Audit,
            label: GetUserProjectRoleLabel(UserProjectRole.Audit),
        },
    ]
};