import { getAccessToken } from '../../../contexts/UserIdentityContext';
const fetchRealEstateRenter = async (realEstateid: string | number, renterId : string | number) => {
    const response = await fetch(`/api/v1/realestates/${realEstateid}/renters/${renterId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    })
    return response;
};

export default fetchRealEstateRenter;