import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import { useHistory } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import { Routes } from "../../../../router/Routes";
import { useParams } from "react-router";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import fetchRealEstateEstimation from "../../api/fetchRealEstateEstimation";
import {banks} from "../../../../components/shared/BankMapper";
import { Link } from "react-router-dom";
import {FileLink} from "../../../../components/shared/inputs/LabeledFileInput";
import {formatPrice} from "../../../../shared/utils/format";

const Color = styled.span<{ value: string; }>`
  color: ${({value}) => value} !important;
`;

const GetColorValue = (expiration?: Date): string => {
    if(!expiration) return "#222222";
    const today = Date.now();
    const diff = dayjs(expiration).diff(today, 'days');
    if(diff > 30) return "#00C853";
    if(diff <= 30 && diff > 0) return "#F88C00"
    return "#FF1744";
};

const REEstimationSection = () => {
    const {slug}: {[index: string]: any} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const history = useHistory();

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Upravit"
                icon={<IPenIcon/>}
                color={"gray"}
                onClick={() => history.push(Routes.REAL_ESTATE_ESTIMATION_DETAIL_EDIT.replace(":slug", slug))}
            />
        </>)
    }, []);


    const fetchData = async () => {
        const response = await fetchRealEstateEstimation(parseInt(slug));
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Odhad se nepodařilo načíst.")
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (<Container>
        {!loading && <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Odhad" icon={<IInfoIcon/>}>
                    <InfoBoxItem title="Externí hodnota" text={(formatPrice(data.info?.estimatedValueExternal) ?? "-") + " Kč"}/>
                    <InfoBoxItem title="Znalecký posudek" text={data.info?.file ? <FileLink target={'_blank'} href={'/api/v1/realestates/estimation/file/' + data.info?.file}>{data.info?.fileName}</FileLink> : "-"}/>
                    <InfoBoxItem title="Odhadce" text={data.info?.appraiserId ? <Link to={Routes.APPRAISER_DETAIL.replace(":slug", data.info?.appraiserId)}>{data.info?.appraiserName}</Link> : ""}/>
                    <InfoBoxItem title="Zpracováno pro" text={banks.filter(o => o.id == data.info?.estimationFor)[0]?.label ?? ""}/>
                    <InfoBoxItem title="Datum objednávky" text={data.info?.dateOfOrder && <>{
                        dayjs(data.info?.dateOfOrder).local().format(`DD.MM.YYYY`)
                    }</> || "-"}/>
                    <InfoBoxItem title="Datum vytvoření" text={data.info?.dateOfOrder && <>{
                        dayjs(data.info?.dateOfCreation).isValid() ? dayjs(data.info?.dateOfCreation).format(`DD.MM.YYYY`) : "-"
                    }</> || "-"}/>
                    <InfoBoxItem title="Datum platnosti" text={data.info?.dateOfOrder && <Color value={GetColorValue(dayjs(data.info?.expirationDate).toDate())}>{
                        dayjs(data.info?.expirationDate).isValid() ? dayjs(data.info?.expirationDate).format(`DD.MM.YYYY`) : "-"
                    }</Color> || "-"}/>
                    <InfoBoxItem title="Email odhadce" text={data.info?.appraiserEmail}/>
                    <InfoBoxItem title="Telefon odhadce" text={data.info?.appraiserPhone}/>
                    <InfoBoxItem title="Akceptace" text={data.info?.banks.map((o: any) => {
                        const bank = banks.filter(a => a.id == o)[0];
                        if(bank) return <div style={{textAlign: 'right'}}>{bank.label}</div>;
                    })}/>
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note ?? ""}
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>}
    </Container>)
};

const Container = styled.div``;

export default REEstimationSection;