import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

interface ButtonInterface {
    icon: React.ReactNode;
    action: () => void;
}

const IconButton = ({action, icon}: ButtonInterface) => {
    const history = useHistory();
    return (<Container onClick={action}>
        {icon}
    </Container>)
};

const Container = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.colors.lightGray};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: ${({theme}) => theme.colors.black};
        svg {
            fill: ${({theme}) => theme.colors.lightGray};
        }
    }
    transition: all 0.5s;
`;

export default IconButton;