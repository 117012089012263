import { getAccessToken } from './../../../../contexts/UserIdentityContext';
const createProject = async (data: any) => {
    const response = await fetch(`/api/v1/projects`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
    });
    return response;
};

export default createProject;