import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as ITransactionIcon} from "../../resources/icons/arrow-right-arrow-left.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import {Routes} from "../../router/Routes";
import {useHistory} from "react-router-dom";
import { ModalContext } from "../../components/layout/Layout";
import {fetchSelectProjects} from "./api/fetchSelects";
import createTask from "./api/tasks/createTask";
import toast from "react-hot-toast";
import createTransaction from "./api/finance/createTransaction";
import {mapProjectsSelect} from "../tasks/CreateTasksModal";
import { GetTransactionTypes } from "../../enums/FinanceEnums";

interface CreateFinanceModalInterface {
    projectId?: string, 
}

const CreateFinanceModal = ({projectId}: CreateFinanceModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const [projects, setProjects] = useState<any>([]);

    const onSubmit = async (data: any) => {
        data.projectId = projectId ? parseInt(projectId) : parseInt(data.projectId);
        data.transactionType = parseInt(data.transactionType);
        data.amount = parseInt(data.amount);
        const response = await createTransaction(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.PROJECT_FINANCE_DETAIL.replace(":slug", fetchedData.result));
        } else {
            toast.error("Transakci se nepodařilo vytvořit.")
        }
    };

    const fetchProjects = async () => {
        const response = await fetchSelectProjects();
        if(response.status == 200) {
            const fetchedData = await response.json();
            setProjects(fetchedData.result);
        }
    };
    useEffect(() => {
        fetchProjects();
    }, [])

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<ITransactionIcon/>}
        title="Vytvořit transakci"
        >
        <ModalInputRow>
            {!projectId && <LabeledSelectInput label="Projekt"
                                name="projectId"
                                placeholder={projects.length > 0 ? "Vyberte..." : "Projekty nejsou dostupné."}
                                disable={projects.length <= 0}
                                icon={<ICaretIcon/>}
                                valid={!errors.projectId}
                                errorMessage={errors.projectId?.message}
                                options={mapProjectsSelect(projects) || []}
                                ref={register({validate: (e) => e != "0" ? true : "Typ je povinný."})}/>}
            <LabeledSelectInput label="Typ"
            name="transactionType"
            placeholder="Vyberte..."
            icon={<ICaretIcon/>}
            valid={!errors.transactionType}
            errorMessage={errors.transactionType?.message}
            options={GetTransactionTypes()}
            ref={register({validate: (e) => e != "0" ? true : "Typ je povinný."})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput name="transactionDate" label="Datum" type="date"
            placeholder="Vyberte..."
            defaultValue={""}
            valid={!errors.transactionDate}
            errorMessage={errors.transactionDate?.message}
            ref={register({required: {value: true, message: "Datum je povinné."}})}/>
            <LabeledTextInput
                label='Částka'
                type="text"
                name="amount"
                valid={!errors.amount}
                errorMessage={errors.amount?.message}
                placeholder="Číslo..."
                defaultValue=""
                ref={register({required: {value: true, message: "Částka je povinná."}})}
            />
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

export default CreateFinanceModal;