import {getAccessToken} from "../../../contexts/UserIdentityContext";

const fetchUser = async (id: string): Promise<any> => {
    const response = await fetch(`/api/v1/admins/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + getAccessToken()
        },
    });
    return response;
};

export default fetchUser;