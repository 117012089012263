import React from "react";
import ReactModal from 'react-modal';

interface ModalInterface {
    children?: React.ReactNode;
    isOpen: boolean;
}

const StyledModal = ({isOpen, children}: ModalInterface) => {
    return (<ReactModal
        appElement={document.getElementById("root")!}
    isOpen={isOpen} 
    style={{
        overlay: {
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(171,171,171,0.25)',
        },
        content: {
            position: "static",
            width: "100%",
            maxWidth: "768px",
            padding: 0,
            WebkitBoxShadow: "0px 0px 36px 4px rgba(0,0,0,0.43)",
            boxShadow: "0px 0px 36px 4px rgba(0,0,0,0.43)",
        },
    }}
    >
        {children}
    </ReactModal>)
};

export default StyledModal;