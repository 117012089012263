import styled from "styled-components";

const ModalInputRow = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 576px;
    &:not(:first-of-type) {
        margin-top: 24px;
    }
`;

const ModalSubmit = styled.button`
    margin-top: 32px;
    padding: 16px 0;
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;
    color: ${({theme}) => theme.colors.white};
    border: 2px solid ${({theme}) => theme.colors.darkBlue};
    background-color: ${({theme}) => theme.colors.blue};
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 8px;
        fill: ${({theme}) => theme.colors.white};
        width: 16px;
        height: 16px;
    }
`;

const ModalCancel = styled.button`
    margin-top: 24px;
    margin-bottom: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: ${({theme}) => theme.colors.grayBlue};
    border: none;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        margin-right: 8px;
        fill: ${({theme}) => theme.colors.grayBlue};
        width: 16px;
        height: 16px;
    }
`;

export {
    ModalSubmit,
    ModalCancel,
    ModalInputRow
};