import React, { useContext, useEffect } from "react";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import {ReactComponent as ISaveIcon} from "../../../../resources/icons/floppy-disk.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import UtilityButton from "../../../../components/shared/UtilityButton";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import { useForm } from "react-hook-form";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import CountryMapper, {GetCountryByValue} from "../../../../components/shared/CountryMapper";
import editProjectClient from "../../api/project/editProjectClient";
import toast from "react-hot-toast";

const ClientEditSection = ({data, onEdit}: any) => {
    const {slug}: {slug: string} = useParams();
    const {register, handleSubmit, errors} = useForm();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        const response = await editProjectClient(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            onEdit();
            toast.success("Klient byl upraven.");
        } else {
            toast.error("Klienta se nepodařilo upravit.")
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL_CLIENT.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<ISaveIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, [])
    return (<form><SectionContainer>
        <InfoBoxFullContainer>
        <InfoBoxColumn>
                <InfoBox title="Osobné údaje" icon={<IBuildingIcon/>}>
                    <LabeledTextInput
                        type="text"
                        label="Jméno"
                        name="firstName"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.firstName?.message}
                        valid={!errors.firstName}
                        defaultValue={data.personal?.firstName || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Příjmení"
                        name="lastName"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.lastName?.message}
                        valid={!errors.lastName}
                        defaultValue={data.personal?.lastName || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Číslo občanského průkazu"
                        name="identificationCardNumber"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.identificationCardNumber?.message}
                        valid={!errors.identificationCardNumber}
                        defaultValue={data.personal?.identificationCardNumber || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Rodné číslo"
                        name="personalIdentificationNumber"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.personalIdentificationNumber?.message}
                        valid={!errors.personalIdentificationNumber}
                        defaultValue={data.personal?.personalIdentificationNumber || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                    <LabeledTextInput
                        type="text"
                        label="Ulice 1. řádek"
                        name="streetAddressFirstLine"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.streetAddressFirstLine?.message}
                        valid={!errors.streetAddressFirstLine}
                        defaultValue={data.contact?.location.streetAddressFirstLine || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Ulice 2. řádek"
                        name="streetAddressSecondLine"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.streetAddressSecondLine?.message}
                        valid={!errors.streetAddressSecondLine}
                        defaultValue={data.contact?.location.streetAddressSecondLine || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="PSČ"
                        name="postalCode"
                        wide
                        isCode
                        placeholder={"Číslo..."}
                        errorMessage={errors.postalCode?.message}
                        valid={!errors.postalCode}
                        defaultValue={data.contact?.location.postalCode || ""}
                        ref={register({maxLength: 6, minLength: 6})}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Obec/Město"
                        name="city"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.city?.message}
                        valid={!errors.city}
                        defaultValue={data.contact?.location.city || ""}
                        ref={register}
                    />
                    <LabeledSelectInput label="Země" name="country"
                        placeholder="Vyberte..."
                        errorMessage={errors.country?.message}
                        valid={!errors.country}
                        defaultValue={GetCountryByValue(data.contact?.location.country)?.id}
                        options={CountryMapper()}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Email"
                        name="email"
                        wide
                        placeholder={"Text..."}
                        errorMessage={errors.email?.message}
                        valid={!errors.email}
                        defaultValue={data.contact?.email || ""}
                        ref={register}
                    />
                    <LabeledTextInput
                        type="text"
                        label="Telefonní číslo"
                        name="phoneNumber"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.phoneNumber?.message}
                        valid={!errors.phoneNumber}
                        defaultValue={data.contact?.phoneNumber || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
            {/*<InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <LabeledTextInput
                        type="date"
                        label="Investorem od"
                        name="investorSince"
                        wide
                        placeholder={"Datum..."}
                        errorMessage={errors.message?.investorSince}
                        valid={!errors.message}
                        defaultValue={data.investorSince || ""}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>*/}
        </InfoBoxFullContainer>
    </SectionContainer></form>)
};

export default ClientEditSection;