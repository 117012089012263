import React, { useContext, useEffect } from "react";
import { ModalContext, UtilityButtonsContext } from "../../../components/layout/Layout";
import { Route, Switch } from "react-router";
import styled from "styled-components";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import SectionContainer from "../../projects/project-detail/components/SectionContainer";
import {Routes} from "../../../router/Routes";

import TasksSection from "./components/TasksSection";
import ProjectSection from "./components/ProjectSection";
import RealEstateSection from "./components/RealEstateSection";

const LogsPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    useEffect(() => {
        buttons.clear();
    }, [])
    return (<Container>
        <CategoryButtons
            buttons={[
                {
                    text: "Projekty",
                    route: Routes.SETTINGS_LOGS,
                    exact: true,
                },
                /*{
                    text: "Úkoly",
                    route: Routes.SETTINGS_LOGS_TASKS,
                    exact: true,
                },
                {
                    text: "Nemovitosti",
                    route: Routes.SETTINGS_LOGS_REAL_ESTATE,
                    exact: true,
                },*/
            ]}
        />
        <SectionContainer>
            <Switch>
                <Route path={Routes.SETTINGS_LOGS} component={ProjectSection} exact/>
                {/*<Route path={Routes.SETTINGS_LOGS_TASKS} component={TasksSection} exact/>
                <Route path={Routes.SETTINGS_LOGS_REAL_ESTATE} component={RealEstateSection} exact/>*/}
            </Switch>
        </SectionContainer>
    </Container>)
};

const Container = styled.div`
    margin-top: 8px;
    padding: 0 24px;
`;

export default LogsPage;