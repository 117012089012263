import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {HighlightedText, TableContainer} from "../../../components/table/Table.styled";
import Table from "../../../components/table/Table";
import {BodyInterface} from "../../../components/table/Table.interface";
import {Routes} from "../../../router/Routes";
import ProfileColumn from "../../../components/table/components/ProfileColumn";
import DoubleLineValue from "../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../components/table/components/ColoredText";
import IPhoto from "../../../resources/images/TempProfilePhoto.jpg";
import UtilityButton from "../../../components/shared/UtilityButton";
import CreateUserModal from "../CreateUserModal";
import {ReactComponent as IPlusIcon} from "../../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IFlagIcon} from "../../../resources/icons/flag.svg";
import {ReactComponent as ILightningIcon} from "../../../resources/icons/bolt-lightning.svg";
import {ReactComponent as IPersonIcon} from "../../../resources/icons/street-view.svg";
import {ReactComponent as ICalendarIcon} from "../../../resources/icons/calendar-exclamation.svg";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import { ModalContext } from "../../../components/layout/Layout";
import fetchUsers from "../api/fetchUsers";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import SearchBar from "../../../components/shared/inputs/SearchBar";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";
import FilterSelectInput from "../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../components/shared/filters/FilterWrap";
import Pagination from "../../../components/pagination/Pagination";
import useFilters from "../../../components/methods/useFilters";

const UsersListPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const modal = useContext(ModalContext);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");
        
    const [filters, changeFilter] = useFilters({
        state: "", permissions: "", placement: "",
        assignedTermsFrom: "", assignedTermsTo: "",
    });
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit uživatele"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateUserModal/>)
                }}
            />
        </>)
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await fetchUsers(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const data = await response.json();
                setData(data.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst uživatele.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);

    return (<Container>
        <TableContainer>
            <FilterWrap maxWidth={"50%"}>
            <SearchBar name={"usersOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                    label={`Stav`}
                    onChange={(filters: any) => changeFilter("state", filters)}
                    icon={<IFlagIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterSelectInput
                    label={`Oprávnění`}
                    onChange={(filters: any) => changeFilter("permissions", filters)}
                    icon={<ILightningIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterSelectInput
                    label={`Umístění`}
                    onChange={(filters: any) => changeFilter("placement", filters)}
                    icon={<IPersonIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
            />
            <FilterRangeInput
                label={`Přiřazených termínů`}
                icon={<ICalendarIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["assignedTermsFrom", "assignedTermsTo"], [values.from, values.to])}
            />
            </FilterWrap>
            <Table
                headers={[
                    {key: "name", value: "JMÉNO", sortable: true},
                    {key: "projects", value: "PŘIRAZENÝCH PROJEKTŮ", sortable: true},
                    {key: "permissions", value: "OPRÁVNĚNÍ", sortable: true},
                    {key: "location", value: "UMÍSTĚNÍ", sortable: false},
                    {key: "tasks", value: "ÚKOLY", sortable: false},
                    {key: "createdOn", value: "DATUM PŘIDÁNÍ", sortable: true},
                    {key: "state", value: "STAV", sortable: true},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(loading ? [] : (data.admins ?? []))}
            />
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.admins?.length}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </Container>)
};

const Container = styled.div`
`;

const UtilityButtons = styled.div`
    display: flex;
`;

const RegularBold = styled.b`
    font-weight: 400;
`;

const RowContainer = styled.div`
    display: flex;
    div:not(:first-of-type) {
        margin-left: 8px;
    }
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            name: {value: (<ProfileColumn icon={
                getPhotoFromCdn(o.imageName)
            } title={
                <b>{`${o.firstName} ${o.lastName}`}</b>
            } subtitle={
                <ColoredText text={o.email} color={"grayBlue"} semibold/>
            }/>)},
            projects: {value: (<a>{o.assignedProjectsCount}</a>)},
            permissions: {value: (<RegularBold>{o.permissionLevel}</RegularBold>)},
            location: {value: (<RegularBold>{o.companyPlacement}</RegularBold>)},
            tasks: {value: (<DoubleLineValue firLine={
                `${o.termsCount} celkem`
            } secLine={
                <RowContainer>
                    <ColoredText text={`${o.completedTermsCount} splněné`} color={"stripGreen"} semibold/>
                    <ColoredText text={`${o.delayedTermsCount} zpožděné`} color={"red"} semibold/>
                </RowContainer>
            }/>)},
            createdOn: {value: (<RegularBold>{dayjs(o.createdOn).format(`DD.MM.YYYY`)}</RegularBold>)},
            state: {value: (<RegularBold>{o.state}</RegularBold>)},
            link: `${Routes.USER_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default UsersListPage;