import { getAccessToken } from '../../../../contexts/UserIdentityContext';
import {ProjectState} from "../../../../enums/ProjectEnums";
const editProjectState = async (id: number | string, state: ProjectState) => {
    const response = await fetch(`/api/v1/projects/${id}/state`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify({state}),
    });
    return response;
};

export default editProjectState;