import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import CreateInvestorModal from "./CreateInvestorModal";
import {Col, HighlightedText, TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import InvestmentLogsPreview from "../../components/shared/previews/InvestmentLogsPreview";
import ProfileColumn from "../../components/table/components/ProfileColumn";
import IPhoto from "../../resources/images/TempProfilePhoto.jpg";
import ColoredText from "../../components/table/components/ColoredText";
import DoubleLineValue from "../../components/table/components/DoubleLineValue";
import fetchProjects from "./api/project/fetchProjects";
import toast from "react-hot-toast";
import fetchInvestors from "./api/investors/fetchInvestors";
import dayjs from "dayjs";
import SearchBar from "../../components/shared/inputs/SearchBar";
import {getPhotoFromCdn} from "../../shared/api/photoApi";
import Pagination from "../../components/pagination/Pagination";
import FilterSelectInput from "../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../components/shared/filters/FilterDateInput";
import FilterRangeInput from "../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import {ReactComponent as ITrophyIcon} from "../../resources/icons/trophy.svg";
import {ReactComponent as ICalendarIcon} from "../../resources/icons/calendar.svg";
import {ReactComponent as IRewindIcon} from "../../resources/icons/clock-rotate-left.svg";
import useFilters from "../../components/methods/useFilters";
import {GetInvestorTypeLabel} from "../../enums/InvestorEnums";
import {formatPrice} from "../../shared/utils/format";

const ProjectInvestorsOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>(""); 

    
    const [filters, changeFilter] = useFilters({
        actInvestmentsFrom: "", actInvestmentsTo: "",
        hisInvestmentsFrom: "", hisInvestmentsTo: "",
        date: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit investora"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateInvestorModal/>)
                }}
            />
        </>)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await fetchInvestors(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst investory.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])

    return (<Container>
         <TableContainer>
            <FilterWrap maxWidth={"95%"}>
                <SearchBar name={"investorsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterRangeInput
                    label={`Aktivní investice`}
                    icon={<ITrophyIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["actInvestmentsFrom", "actInvestmentsTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Historické investice`}
                    icon={<IRewindIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["hisInvestmentsFrom", "hisInvestmentsTo"], [values.from, values.to])}
                />
                <FilterDateInput
                    label={`Začátek investování`}
                    icon={<ICalendarIcon/>}
                    onChange={(values: any) => changeFilter(["date"], [values])}
                />
            </FilterWrap>
            <Table headers={[
                {key: "investorName", value: "JMÉNO", sortable: true},
                {key: "paCut", value: "ÚROKOVÁ SAZBA P.A.", sortable: true},
                {key: "activeInvestment", value: "AKTIVNÍ INVESTICE", sortable: false},
                {key: "historicInvestment", value: "HISTORICKÉ INVESTICE", sortable: false},
                {key: "availableFunds", value: "VOLNÉ PROSTŘEDKY", sortable: true},
                {key: "investorSince", value: "INVESTOREM OD", sortable: false},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.investors ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.investors?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
        <InvestmentLogsPreview/>
    </Container>)
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: o.state,
            stateBackground: false,
            id: o.id,
            investorName: {value: (<ProfileColumn icon={
                    getPhotoFromCdn(o.imageName)
            } title={
                <b>{o.firstName} {o.lastName}</b>
            } subtitle={
                <ColoredText text={GetInvestorTypeLabel(o.type)} color={"grayBlue"} semibold/>
            }/>)},
            paCut: {value: `${o.interestRate} %`},
            activeInvestment: {value: (<DoubleLineValue firLine={
                <ColoredText text={`${formatPrice(o.activeInvestmentsAmount)} Kč`} color={"stripGreen"}/>
            } secLine={
                <ColoredText text={`${o.activeInvestmentsCount} projektů`} color={"grayBlue"} semibold/>
            }/>)},
            historicInvestment: {value: (<DoubleLineValue firLine={
                    `${formatPrice(o.historicInvestmentsAmount)} Kč`
            } secLine={
                <ColoredText text={`${o.historicInvestmentsCount} projektů`} color={"grayBlue"} semibold/>
            }/>)},
            availableFunds: {value: (<RegularBold>{formatPrice(o.availableFunds)} Kč</RegularBold>)},
            investorSince: {value: (<DoubleLineValue firLine={
                <RegularBold>{dayjs(o.createdOn).format("DD.MM.YYYY")}</RegularBold>
            } secLine={
                <ColoredText text={""} color={"grayBlue"} semibold/>
            }/>)},
            link: `${Routes.PROJECT_INVESTOR_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default ProjectInvestorsOverviewPage;