import React, {useEffect, useState} from "react";
import PageHeader from "../PageHeader";
import Strip from "../Strip";
import {TableContainer} from "../../table/Table.styled";
import Table from "../../../components/table/Table";
import {BodyInterface} from "../../table/Table.interface";
import {Routes} from "../../../router/Routes";
import toast from "react-hot-toast";
import fetchInvestmentLogTasks from "../../../pages/projects/api/investment-logs/fetchInvestmentLogTasks";
import Pagination from "../../pagination/Pagination";

interface InvestmentLogTaskPreviewInterface {
    logDate: string;
    projectName: string;
    logId: string | number;
    onReturn(): void;
}

const InvestmentLogTaskPreview = ({logDate, projectName, logId, onReturn}: InvestmentLogTaskPreviewInterface) => {

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchInvestmentLogTasks(0, logId, pageNum, pageSize, "", sortId, sortOrder);
            if (response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst úkoly investičního zápisku.")
            }
        };
        fetchData();
    }, [logId, pageNum, pageSize, sortId, sortOrder])

    return (<>
        <PageHeader
            title={logDate}
            subtitle={projectName}
            strip={<Strip color="green"/>}
            noContainerLeftMargin
            onReturn={() => onReturn()}
            returnPath={-1}/>
        <TableContainer noSidePadding>
            <Table
                headers={[
                    {key: "title", value: "NÁZEV", sortable: true},
                    {key: "solver", value: "ŘEŠITEL", sortable: true},
                    {key: "state", value: "STAV", sortable: false},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={taskMapper(loading ? [] : (data.tasks ?? []))}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.tasks?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
        </TableContainer>
    </>)
};

const taskMapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            title: {value: o.name},
            solver: {value: o.assigneeName},
            state: {value: o.status},
            link: `${Routes.PROJECT_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default InvestmentLogTaskPreview;