import {getAccessToken} from "../../../contexts/UserIdentityContext";

const deleteUser = async (slug: string): Promise<any> => {
    const response = await fetch(`/api/v1/admins/${slug}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + getAccessToken()
        },
    })
    return response;
};

export default deleteUser;