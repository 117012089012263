import { getAccessToken } from "../../../contexts/UserIdentityContext";

const deleteRealEstateRenter = async (realEstateId: string | number, renterId: string | number) => {
    const response = await fetch(`/api/v1/realestates/${realEstateId}/renters/${renterId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        }
    })
    return response;
};

export default deleteRealEstateRenter;
