import { props } from "cypress/types/bluebird";
import React from "react";
import styled from "styled-components";

interface StripInterface {
    color: string;
    height?: string;
    hover?: boolean;
    top?: string;
    left?: string;
    absolute?: boolean;
}

const Strip = ({color, absolute, height, hover, top, left}: StripInterface) => {
    return (<Container color={color} height={height} hover={hover} absolute={absolute} top={top} left={left}/>)
};

const Container = styled.div<{color: string; left?: string; hover?: boolean; height?: string; absolute?: boolean; top?: string}>`
    ${({absolute}) => absolute && `position: absolute`};
    ${({top}) => top && `top: ${top}`};
    ${({left}) => left && `left: ${left}`};
    width: 6px;
    height: ${({height}) => height ? height : "60px"};
    ${({hover}) => hover && `cursor: pointer`}
    background-color: ${({theme, color}) => color == "green" && 
    theme.colors.stripGreen || color == "yellow" && 
    theme.colors.stripYellow || color == "gray" && 
    theme.colors.stripGray || color == "red" && 
    theme.colors.lightPink};
`;

export default Strip;