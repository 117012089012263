import React, { isValidElement } from "react";
import styled from "styled-components";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import dayjs from "dayjs";

interface FilterSelectButtonInterface {
    children: any,
    icon: React.ReactNode,
    onClick(): void,
    isActive: boolean,
    values?: Object,
    onReset(): void,
}

export const FilterSelectButton = ({onClick, values, children, icon, isActive, onReset}: FilterSelectButtonInterface) => {
    const renderRange = (): React.ReactNode => {
        const valObj: any = {...values};
        const formatValue = (value: string) => {
            if(!value.includes(":")) return value;
            return dayjs(value).format(`DD.MM.YYYY`);
        };
        return (<CFilterClick onClick={onClick}><Range>
            <Value isValueFilled={valObj.from}>
                {valObj.from ? formatValue(valObj.from) : "od"}
            </Value>
            <ValueDivider>-</ValueDivider>
            <Value isValueFilled={valObj.to}>
                {valObj.to ? formatValue(valObj.to) : "do" }
            </Value>
        </Range></CFilterClick>)
    };
    return (<CFilterButton >
        <CFilterClick onClick={onClick} isActive={isActive}>
            <IconWrap isGray={false}>
                {icon}
            </IconWrap>
            {children}
        </CFilterClick>
        {values && renderRange()}
        {isActive && <CFilterClick onClick={() => onReset()}>
            <IconWrap isGray={true}>
                <CrossIcon/>
            </IconWrap>
        </CFilterClick>}
    </CFilterButton>)
};

export const FilterSubmitButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border-top: 1px solid ${({theme}) => theme.colors.stripGray};
    color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.blue};
    margin-top: 14px;
    svg {
        margin-right: 8px;
    }
`;

export const FilterContainer = styled.div`
    position: absolute;
    margin-top: 16px; 
    background-color: ${({theme}) => theme.colors.lightGray};
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    border-radius: 10px;
    padding: 8px;
    z-index: 999;
`;

const CFilterButton = styled.div`
    display: flex;
    padding: 0 8px 16px 16px;
    font-size: 14px;
    font-weight: 600;
`;

const Range = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
`;

const Value = styled.div<{isValueFilled: boolean}>`
    ${({isValueFilled, theme}) => isValueFilled ? `
        color: ${theme.colors.blueGray};
    ` : `
        color: ${theme.colors.lighterGray};
    `};
    padding: 0 4px;
`;

const ValueDivider = styled.div`
    margin: 0 8px;
    color: ${({theme}) => theme.colors.blueGray};
`;

const IconWrap = styled.div<{isGray: boolean}>`
    ${({theme, isGray}) => isGray && `fill: ${theme.colors.grayBlue}`};
    min-width: 14px;
    min-width: 14px;
    max-width: 16px;
    max-height: 16px;
    margin-right: 8px;
`;

const CrossIcon = styled(ICrossIcon)`
    fill: ${({theme}) => theme.colors.grayBlue};
    max-width: 12px;
    max-height: 12px;
    margin-left: 8px;
`;

const CFilterClick = styled.span<{isActive?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    fill: ${({theme}) => theme.colors.grayBlue};
    color: ${({theme}) => theme.colors.grayBlue};
    ${({isActive, theme}) => isActive && `
        fill: ${theme.colors.blue};   
        color: ${theme.colors.blue};
    `};
    &:hover {
        ${({isActive, theme}) => isActive === false && `
            fill: ${theme.colors.blue};   
            color: ${theme.colors.blue};
        `}
    };
    transition: 0.5s all;
`;