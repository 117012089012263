import countries from "i18n-iso-countries";
import czechLang from "i18n-iso-countries/langs/cs.json";

interface CountryInfo {
    id: string;
    label: string;
}

const CountryMapper = (): Array<CountryInfo> => {
    countries.registerLocale(czechLang);
    const countryNames = countries.getNames("cs", {select: "official"});
    return Object.entries(countryNames).map(v => ({id: v[0], label: v[1]}))
};

export const GetCountryByValue = (value: string): CountryInfo | undefined => CountryMapper().find(c => c.id === value);

export default CountryMapper;