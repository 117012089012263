import React from "react";
import styled, {css} from "styled-components";
import {ReactComponent as IErrorIcon} from "../../../resources/icons/times-circle-solid.svg";
import {ReactComponent as IWarningIcon} from "../../../resources/icons/exclamation-triangle.svg";
import {ReactComponent as ISuccessIcon} from "../../../resources/icons/check-circle-solid.svg";

type ValidationMessageType = "success" | "warning" | "error";

interface InputValidationMessageInterface {
    type: ValidationMessageType;
    message: string;
}

const InputValidationMessage = ({type, message}: InputValidationMessageInterface): React.ReactElement => {
    return (<MessageWrapper type={type}>
        {type === "error" && <ErrorIcon/>}
        {type === "warning" && <WarningIcon/>}
        {type === "success" && <SuccessIcon/>}
        <MessageSpan>{message}</MessageSpan>
    </MessageWrapper>);
};

const MessageWrapper = styled.div<{ type: ValidationMessageType }>`
  display: flex;
  align-items: center;
  
  ${props => props.type === "success" && css`
    color: ${props.theme.colors.green}
  `}
  ${props => props.type === "warning" && css`
    color: ${props.theme.colors.darkYellow}
  `}
  ${props => props.type === "error" && css`
    color: ${props.theme.colors.red}
  `}
`;

const MessageSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-left: 4px;
`;

const ErrorIcon = styled(IErrorIcon)`
  width: 14px;
  height: 13px;
  fill: ${props => props.theme.colors.red}
`;
const WarningIcon = styled(IWarningIcon)`
  width: 14px;
  height: 13px;
  fill: ${props => props.theme.colors.darkYellow}
`;
const SuccessIcon = styled(ISuccessIcon)`
  width: 14px;
  height: 13px;
  fill: ${props => props.theme.colors.green}
`;

export default InputValidationMessage;