import { getAccessToken } from '../../../contexts/UserIdentityContext';

const createAppraiser = async (data: any) => {
    const response = await fetch(`/api/v1/appraisers`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: JSON.stringify(data),
    });
    return response;
};

export default createAppraiser;