import React, {useContext, useEffect, useState} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand-holding-dollar.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalCancel, ModalInputRow, ModalSubmit} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import {useForm} from "react-hook-form";
import {ModalContext} from "../../components/layout/Layout";
import toast from "react-hot-toast";
import {useParams} from "react-router";
import assignProjectInvestor from "./api/project/assignProjectInvestor";
import {fetchSelectInvestors} from "./api/fetchSelects";
import {getPhotoFromCdn} from "../../shared/api/photoApi";
import {GetInvestorTypes, InvestorType} from "../../enums/InvestorEnums";
import updateProjectInvestor from "./api/project/updateProjectInvestor";

interface AssignInvestorModalInterface {
    onCreate?: () => void;
    investorId: string | number;
    amount: string | number;
    investorFrom: Date;
}

const UpdateInvestorModal = ({onCreate, investorId, amount, investorFrom}: AssignInvestorModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const {slug}: any = useParams();

    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        data.projectId = parseInt(slug);
        data.investorId = parseInt(investorId.toString());
        data.totalInvested = parseInt(data.totalInvested);
        const response = await updateProjectInvestor(data);
        if (response.status == 200) {
            const fetchedData = await response.json();
            if (onCreate) onCreate();
            modal.closeCurrentModal();
            toast.success("Investor upraven.")
        } else {
            toast.error("Investora se nepodařilo upravit.")
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
            cancelModal={() => modal.closeCurrentModal()}
            icon={<IHandIcon/>}
            title="Upravit investora"
        >
            <ModalInputRow>
                <LabeledTextInput name="totalInvested" label="Částka investovaná" type="number"
                                  placeholder="Číslo..."
                                  defaultValue={amount.toString()}
                                  wide
                                  valid={!errors.totalInvested}
                                  errorMessage={errors.totalInvested?.message}
                                  ref={register({valueAsNumber: true, required: { value: true, message: "Částka je povinná."}})}/>
            </ModalInputRow>
            <ModalInputRow>
                <LabeledTextInput name="investorSince" label="Investorem od" type="date"
                                  placeholder="Vyberte..."
                                  defaultValue={investorFrom.toString()}
                                  wide
                                  valid={!errors.investorSince}
                                  errorMessage={errors.investorSince?.message}
                                  ref={register({required: {value: true, message: "Datum je povinné."}})}/>
            </ModalInputRow>
            <ModalSubmit type="submit">
                <ICheckIcon/> Dokončit a upravit
            </ModalSubmit>
            <ModalCancel onClick={() => modal.closeCurrentModal()}>
                <ICrossIcon/> Zavřít a nic nedělat
            </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const idPad = (num: number, size: number) => String(num).padStart(size, '0');

export default UpdateInvestorModal;