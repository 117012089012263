import { getAccessToken } from '../../../contexts/UserIdentityContext';

const deleteAppraiser = async (id: string | number) => {
    const response = await fetch(`/api/v1/appraisers/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default deleteAppraiser;