import React, {useContext, useEffect, useState} from "react";
import { ModalContext, UtilityButtonsContext } from "../../../components/layout/Layout";
import { Switch, useHistory, useParams, Route } from "react-router";
import styled from "styled-components"
import IProfilePhoto from "../../../resources/images/TempProfilePhoto.jpg";
import { Routes } from "../../../router/Routes";
import PageHeader from "../../../components/shared/PageHeader";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import ProfileSection from "./components/ProfileSection";
import ProjectsSection from "./components/ProjectsSection";
import TasksSection from "./components/TasksSection";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import toast from "react-hot-toast";
import { getCurrentUserId, UserIdentityContext } from "../../../contexts/UserIdentityContext";
import uploadUserImage from "../../users/api/uploadUserImage";
import fetchUserTaskCount from "../../users/api/fetchUserTaskCount";
import fetchUserImage from "../../users/api/fetchUserImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

const UserDetailPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const identity = useContext(UserIdentityContext);
    const name = identity.getName();
    const history = useHistory();
    const slug = getCurrentUserId();
    const [currentImage, setCurrentImage] = useState<string>("");

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title={"Upravit"}
            color={"gray"}
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.USER_DETAIL_EDIT.replace(":slug", slug as string))}/>
        </>);
    }, []);

    const fetchData = async () => {
            const imageResponse = await fetchUserImage(slug as string);
            if(imageResponse.status == 200) {
                const fetchedData = await imageResponse.json();
                setCurrentImage(fetchedData.result);
            } else {
                setCurrentImage("");
            }
    };

    useEffect(() => {
        fetchData();
    }, [identity.initialized]);

    const handleUpload = async (e: any) => {
        const response = await uploadUserImage(slug as string, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setCurrentImage(fetchedData.result);
            toast.success("Fotka uživatele byla změněna.");
        } else {
            toast.error("Fotku uživatele se nepodařilo změnit.");
        }
    };
    return (<Container>
        <PageHeader title={name} onUpload={handleUpload} subtitle={`Uživatelé — ${name}`} image={getPhotoFromCdn(currentImage)}/>
        <CategoryButtons buttons={[
                {
                    text: "Profil",
                    route: Routes.SETTINGS_PROFILE,
                    exact: true,
                },
                {
                    text: "Projekty",
                    route: Routes.SETTINGS_PROFILE_PROJECTS,
                    exact: true,
                },
                {
                    text: "Úkoly",
                    route: Routes.SETTINGS_PROFILE_TASKS,
                    exact: true,
                },
        ]}/>
        <Switch>
            <Route component={ProfileSection} path={Routes.SETTINGS_PROFILE} exact/>
            <Route component={ProjectsSection} path={Routes.SETTINGS_PROFILE_PROJECTS} exact/>
            <Route component={TasksSection} path={Routes.SETTINGS_PROFILE_TASKS} exact/>
        </Switch>
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

export default UserDetailPage;