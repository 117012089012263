import { getAccessToken } from '../../../contexts/UserIdentityContext';
const createDocument = async (data: any) => {
    const response = await fetch(`/api/v1/documents`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: data,
    });
    return response
};

export default createDocument;