import React, { useState } from "react";
import styled from "styled-components";
import {ReactComponent as IGlassIcon} from "../../../resources/icons/magnifying-glass.svg";

interface SearchBarInterface {
    name: string;
    placeholder?: string;
    notWide?: boolean;
    onSearch(search: string): void;
}

const SearchBar = ({name, notWide,placeholder, onSearch}: SearchBarInterface) => {
    const [search, setSearch] = useState<string>("");
    const handleSearch = ({target}: any) => {
        setSearch(target.value);
    };
    const handleKeyPress = ({key}: any) => {
        if(key === "Enter") onSearch(search);
    };
    return (<Container notWide={notWide != undefined && notWide} htmlFor={`${name}SearchBar`}>
        <GlassIcon/>
        <StyledInput type="text" id={`${name}SearchBar`} onChange={handleSearch} onKeyDown={handleKeyPress} placeholder={placeholder || ""}/>
    </Container>)
};

const Container = styled.label<{notWide: boolean}>`
    ${({notWide}) => !notWide && `
        width: 264px;
    `};
    height: 35px;
    border-radius: 4px;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    position: relative;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
`;

const GlassIcon = styled(IGlassIcon)`
    width: 24px;
    height: 24px;
    margin: 0 12px;
    fill: ${({theme}) => theme.colors.grayBlue};
`;

const StyledInput = styled.input`
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: none;
    position: relative;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    &::placeholder {
        color: ${({theme}) => theme.colors.grayBlue};
    }
`;

export default SearchBar;