import FilterParameters from '../../../components/methods/FilterParameters';
import { getAccessToken } from '../../../contexts/UserIdentityContext';

const fetchAppraiserEstimates = async (appraiserId: string | number, pageNum: number, pageSize: number, search: string, sortId: string, sortOrder: string) => {
    const response = await fetch(`/api/v1/appraisers/${appraiserId}/estimates?${sortId && `sorting=${sortId}&sortingorder=${sortOrder}&`}pageNumber=${pageNum}&pageSize=${pageSize}&search=${search}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`,
        },
    });
    return response;
};

export default fetchAppraiserEstimates;