export const banks = [
    {
        "label": "Komerční banka",
        "id": 1
    },
    {
        "label": "Československá obchodní banka",
        "id": 2
    },
    {
        "label": "MONETA Money Bank",
        "id": 3
    },
    {
        "label": "Česká spořitelna",
        "id": 4
    },
    {
        "label": "Fio banka",
        "id": 5
    },
    {
        "label": "TRINITY BANK a.s.",
        "id": 6
    },
    {
        "label": "Poštová banka",
        "id": 7
    },
    {
        "label": "Banka CREDITAS a.s.",
        "id": 8
    },
    {
        "label": "UniCredit Bank Czech Republic and Slovakia",
        "id": 9
    },
    {
        "label": "Air Bank a.s.",
        "id": 10
    },
    {
        "label": "BNP Paribas Personal Finance SA",
        "id": 11
    },
    {
        "label": "Expobank CZ a.s.",
        "id": 12
    },
    {
        "label": "ING Bank N.V.",
        "id": 13
    },
    {
        "label": "Raiffeisenbank a.s.",
        "id": 14
    },
    {
        "label": "J & T BANKA",
        "id": 15
    },
    {
        "label": "PPF banka a.s.",
        "id": 16
    },
    {
        "label": "Equa bank a.s.",
        "id": 17
    },
    {
        "label": "mBank S.A.",
        "id": 18
    },
    {
        "label": "BNP Paribas S.A.",
        "id": 19
    },
    {
        "label": "Sberbank CZ",
        "id": 20
    },
    {
        "label": "Waldviertler Sparkasse Bank AG",
        "id": 21
    },
    {
        "label": "Wüstenrot hypoteční banka a.s.",
        "id": 22
    }
];

interface BankInfo {
    id: number | string;
    label: string;
}

export const BankMapper = (list?: string[]): Array<BankInfo> => {
    if(list) {
        return banks.filter(a => list.includes(a.id.toString()));
    }

    return banks;
};
export const GetBankByValue = (value: number | string): BankInfo | undefined => BankMapper().find(c => c.id === parseInt(value.toString()));