export enum DocumentCategoryType {
    NotSet = 0,
    Template = 1,
    Other = 2,
}

export const GetDocumentCategoryTypeLabel = (type: DocumentCategoryType): string => {
    switch (type){
        case DocumentCategoryType.NotSet: return "Nenastaveno";
        case DocumentCategoryType.Template: return "Šablona";
        case DocumentCategoryType.Other: return "???";
        default: return "Nenastaveno";
    }
};

export const GetDocumentCategoryTypes = (): Array<any> => {
    return [
        {
            id: DocumentCategoryType.NotSet,
            label: GetDocumentCategoryTypeLabel(DocumentCategoryType.NotSet),
        },
        {
            id: DocumentCategoryType.Template,
            label: GetDocumentCategoryTypeLabel(DocumentCategoryType.Template),
        },
        {
            id: DocumentCategoryType.Other,
            label: GetDocumentCategoryTypeLabel(DocumentCategoryType.Other),
        },
    ]
};