import React, { useContext, useEffect } from "react";
import { ModalContext, UtilityButtonsContext } from "../../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../../components/shared/UtilityButton";
import CreateRealEstateModal from "../CreateTasksModal";
import styled from "styled-components";
import SectionContainer from "../../projects/project-detail/components/SectionContainer";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import {Routes} from "../../../router/Routes";
import { Route, Router, Switch, useHistory } from "react-router-dom";
import MyTeamsTasksSection from "./components/MyTeamsTasksSection";
import MyTasksSection from "./components/MyTasksSection";
import AllTasksSection from "./components/AllTasksSection";
import CreateTasksModal from "../CreateTasksModal";

const TaskPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit termín"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateTasksModal/>)
                }}
            />
        </>);
    }, [])
    return (<Container>
        <CategoryButtons
            buttons={[
                {
                    text: "Všechny",
                    route: Routes.TASKS,
                    exact: true,
                },
                {
                    text: "Mých týmů",
                    route: Routes.TASKS_ONLY_TEAMS,
                    exact: true,
                },
                {
                    text: "Pouze moje",
                    route: Routes.TASKS_ONLY_USERS,
                    exact: true,
                },
            ]}/>
        <SectionContainer>
            <Switch>
                <Route path={Routes.TASKS} component={AllTasksSection} exact/>
                <Route path={Routes.TASKS_ONLY_TEAMS} component={MyTeamsTasksSection} exact/>
                <Route path={Routes.TASKS_ONLY_USERS} component={MyTasksSection} exact/>
            </Switch>
        </SectionContainer>
    </Container>)
};

const Container = styled.div``;

export default TaskPage;