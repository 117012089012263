import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SectionContainer from "../../../projects/project-detail/components/SectionContainer";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import {ReactComponent as IToolsIcon} from "../../../../resources/icons/screwdriver-wrench.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import { Routes } from "../../../../router/Routes";
import dayjs from "dayjs";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import { useHistory, useParams } from "react-router";
import deleteUser from "../../api/deleteUser";
import toast from "react-hot-toast";
import {ReactComponent as ILockIcon} from "../../../../resources/icons/unlock.svg";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";

const ProfileSection = ({data}: any) => {
    const { slug }: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Resetovat heslo"
            color="gray"
            icon={<ILockIcon/>}
            onClick={() => console.log("reset password")}/>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                const response = await deleteUser(slug);
                if(response.status == 200) {
                    history.push(Routes.USERS);
                    toast.success("Uživatel smazán.");
                } else {
                    toast.error("Při mazání nastala chyba!");
                }
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export user")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.USER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                    <InfoBoxItem title="Jméno a příjmení" text={data.contact?.fullName}/>
                    <InfoBoxItem title="Email" text={<a href={`mailto:${data.contact?.email}`}>{data.contact?.email}</a>}/>
                    <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contact?.phoneNumber}`}>{data.contact?.phoneNumber}</a>}/>
                    <InfoBoxItem title="Ulice 1. řádek" text={data.contact?.location.streetAddressFirstLine}/>
                    <InfoBoxItem title="Ulice 2. řádek" text={data.contact?.location.streetAddressSecondLine}/>
                    <InfoBoxItem title="PSČ" text={data.contact?.location.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3')}/>
                    <InfoBoxItem title="Obec/Město" text={data.contact?.location.city}/>
                    <InfoBoxItem title="Země" text={data.contact?.location.country}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note}
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Systémové údaje" icon={<IToolsIcon/>}>
                    <InfoBoxItem title="Oprávnění" text={data.systemInformation?.permissionLevel}/>
                    <InfoBoxItem title="Umístění" text={data.systemInformation?.companyPlacement}/>
                    <InfoBoxItem title="Stav" text={data.systemInformation?.state}/>
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer>)
};

export default ProfileSection;