import React from "react";
import styled from "styled-components";

const BaseContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(284px, 1fr));
    gap: 8px;
`;

const TileContainer = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    min-height: 60px;
    padding: 16px;

    border: 1px solid ${({theme, selected}) => selected ? theme.colors.blue : theme.colors.lighterGray};
    border-radius: 4px;
    background-color: ${({theme, selected}) => selected ? theme.colors.backgroundBlue : "transparent"};

    &:hover {
        background-color: ${({theme}) => theme.colors.backgroundBlue};
        border-color: ${({theme}) => theme.colors.blue};
    }
`;

const TileIconWrapper = styled.div`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 8px;

    & svg {
        fill: ${({theme}) => theme.colors.blue};
        min-width: 32px;
        min-height: 32px;
    }
`;

const TileName = styled.div`
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.black}
`;

export interface ModalTileItem {
    name: string;
    value: string;
    icon?: any;
}

export interface ModalTileSelectorProps {
    selectedValue: string;

    setSelectedValue(value: string): void;

    tiles: Array<ModalTileItem>;
}

export const ModalTileSelector: React.FC<ModalTileSelectorProps> = (props) => {
    const {selectedValue, setSelectedValue, tiles} = props;

    return <BaseContainer>
        {tiles.map(tile => <TileContainer key={tile.name} onClick={() => setSelectedValue(tile.value)} selected={tile.value === selectedValue}>
            {tile.icon && <TileIconWrapper>
                {tile.icon}
            </TileIconWrapper>}
            <TileName>
                {tile.name}
            </TileName>
        </TileContainer>)}
    </BaseContainer>
}