import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import { Routes } from "../../../../router/Routes";
import SectionContainer from "../../project-detail/components/SectionContainer";
import toast from "react-hot-toast";
import deleteInvestor from "../../api/investors/deleteInvestor";

const ScoringSection = () => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat investora?")) {
                    const response = await deleteInvestor(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        toast.success("Investor smazán.");
                        history.push(Routes.PROJECTS_INVESTORS);
                    } else {
                        toast.error("Investora se nepodařilo smazat");
                    };
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export investor")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_INVESTOR_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer>
        Scoring
    </SectionContainer>)
};

const Container = styled.div``;

export default ScoringSection;