import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import SectionContainer from "../../project-detail/components/SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import { Routes } from "../../../../router/Routes";
import deleteProvider from "../../api/provider/deleteProvider";
import toast from "react-hot-toast";
import dayjs from "dayjs";

const ProfileSection = ({data}: any) => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat zprostředkovatele?")) {
                    const response = await deleteProvider(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        if(fetchedData.isSuccess) {
                            history.push(Routes.PROJECTS_PROVIDERS);
                            toast.success("Zprostředkovatel smazán.")
                        } else {
                            toast.error("Zprostředkovatele se nepodařilo smazat.")
                        }
                    } else {
                        toast.error("Zprostředkovatele se nepodařilo smazat.")
                    }
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export provider")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_PROVIDER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    return (<SectionContainer><InfoBoxFullContainer>
        <InfoBoxColumn>
            <InfoBox title="Fakturační údaje" icon={<IBuildingIcon/>}>
                <InfoBoxItem title="Název" text={data.billingInformation?.name || ""}/>
                <InfoBoxItem title="IČO" text={data.billingInformation?.identificationNumber || ""}/>
                <InfoBoxItem title="Ulice 1. řádek" text={data.billingInformation?.billingLocation.streetAddressFirstLine || ""}/>
                <InfoBoxItem title="Ulice 2. řádek" text={data.billingInformation?.billingLocation.streetAddressSecondLine || ""}/>
                <InfoBoxItem title="PSČ" text={data.billingInformation?.billingLocation.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') || ""}/>
                <InfoBoxItem title="Obec/Město" text={data.billingInformation?.billingLocation.city || ""}/>
                <InfoBoxItem title="Země" text={data.billingInformation?.billingLocation.country || ""}/>
            </InfoBox>
            <InfoBox title="Kontaktní osoba" icon={<IUserIcon/>}>
                <InfoBoxItem title="Jméno a příjmení" text={`${data.contactPerson?.firstName} ${data.contactPerson?.lastName}`}/>
                <InfoBoxItem title="Email" text={<a href={`mailto:${data.contactPerson?.email}`}>{data.contactPerson?.email}</a>}/>
                <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contactPerson?.phone}`}>{data.contactPerson?.phone}</a>}/>
            </InfoBox>
            <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                {data.note ?? ""}
            </InfoBox>
        </InfoBoxColumn>
        <InfoBoxColumn>
            <InfoBox title="Kontaktní údaje" icon={<IMapIcon/>}>
                <InfoBoxItem title="Ulice 1. řádek" text={data.contactInformation?.contactLocation.streetAddressFirstLine || ""}/>
                <InfoBoxItem title="Ulice 2. řádek" text={data.contactInformation?.contactLocation.streetAddressSecondLine || ""}/>
                <InfoBoxItem title="PSČ" text={data.contactInformation?.contactLocation.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3') || ""}/>
                <InfoBoxItem title="Obec/Město" text={data.contactInformation?.contactLocation.city || ""}/>
                <InfoBoxItem title="Země" text={data.contactInformation?.contactLocation.country || ""}/>
                <InfoBoxItem title="Email" text={<a href={`mailto:${data.contactInformation?.email}`}>{data.contactInformation?.email}</a>}/>
                <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contactInformation?.phone}`}>{data.contactInformation?.phone}</a>}/>
            </InfoBox>
            <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                <InfoBoxItem title="Vytvořen" text={<>{
                    dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(data.activity?.createdOn).format(`HH:mm`)
                }</span></>}/>
                <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                    {data.activity?.createdByName}
                </a> : ""}/>
                <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                    dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                }</span></> : ""}/>
                <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                    {data.activity?.lastUpdatedByName}
                 </a> : ""}/>
                <InfoBoxItem title="Investorem od" text={<>{
                    dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                } <span>{
                    dayjs(data.activity?.createdOn).format(`HH:mm`)
                }</span></>}/>
            </InfoBox>
        </InfoBoxColumn>
    </InfoBoxFullContainer></SectionContainer>)
};

export default ProfileSection;