import React, {useContext, useEffect, useState} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as ICalendarIcon} from "../../resources/icons/calendar-exclamation.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import PersonSelectorInput from "../../components/shared/inputs/PersonSelectorInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import IProfileIcon from "../../resources/images/TempProfilePhoto.jpg";
import { useForm } from "react-hook-form";
import {useHistory} from "react-router-dom";
import { ModalContext } from "../../components/layout/Layout";
import {Routes} from "../../router/Routes";
import createTask from "../projects/api/tasks/createTask";
import toast from "react-hot-toast";
import { fetchSelectInvestmentLogs, fetchSelectProjects, fetchSelectUsers } from "../projects/api/fetchSelects";
import dayjs from "dayjs";

interface CreateTaskInterface {
    projectId?: number;
    projectLogId?: number;
    onCreate?: () => void;
}

const CreateTasksModal = ({projectId, projectLogId, onCreate}: CreateTaskInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const [users, setUsers] = useState<any>([]);
    const [projects, setProjects] = useState<any>([]);
    const [investmentLogs, setInvestmentLogs] = useState<any>([]);
    const onSubmit = async (data: any) => {
        data.projectId = projectId ? projectId : parseInt(data.projectId);
        data.assigneeId = parseInt(data.assigneeId);
        data.logId = projectLogId ? projectLogId : parseInt(data.logId);
        const response = await createTask(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.PROJECT_TASK.replace(":slug", data.projectId).replace(":secondslug", fetchedData.result));
        } else {
            toast.error("Úkol se nepodařilo vytvořit.")
        };
    };
    const fetchUsers = async () => {
        const response = await fetchSelectUsers();
        if(response.status == 200) {
            const fetchedData = await response.json();
            setUsers(fetchedData.result);
        };
    };
    const fetchInvestmentLogs = async (id: number) => {
        const response = await fetchSelectInvestmentLogs(id);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setInvestmentLogs(fetchedData.result);
        }
    };
    const fetchProjects = async () => {
        const response = await fetchSelectProjects();
        if(response.status == 200) {
            const fetchedData = await response.json();
            setProjects(fetchedData.result);
        }
    };
    useEffect(() => {
        fetchProjects();
        fetchUsers();
        if(projectId) fetchInvestmentLogs(projectId)
    }, [])
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<ICalendarIcon/>}
        title="Vytvořit úkol"
        >
        <ModalInputRow>
            {!projectId && <LabeledSelectInput label="Projekt"
            name="projectId"
            placeholder={projects.length > 0 ? "Vyberte..." : "Projekty nejsou dostupné."}
            disable={projects.length <= 0}
            icon={<ICaretIcon/>}
            valid={!errors.projectId}
            errorMessage={errors.projectId?.message}
            options={mapProjectsSelect(projects) || []}
            onSelect={(id) => fetchInvestmentLogs(id as number)}
            ref={register({validate: (e) => e != "0" ? true : "Musíte si vybrat projekt."})}/>}
            <PersonSelectorInput label="Řešitel"
            name="assigneeId"
            placeholder={users.length > 0 ? "Vyberte..." : "Uživatelé nejsou dostupní."}
            disable={users.length <= 0}
            icon={<ICaretIcon/>}
            valid={!errors.assigneeId}
            errorMessage={errors.assigneeId?.message}
            options={mapUserSelect(users) || []}
            ref={register({validate: (e) => e != "" ? true : "Musíte si vybrat řešitele."})}/>
            {(projectId && !projectLogId) && <LabeledSelectInput label="Investiční zápis"
                name="logId"
                placeholder={investmentLogs.length > 0 ? "Vyberte..." : "Investiční zápisy nejsou k dispozici."}
                icon={<ICaretIcon/>}
                valid={!errors.logId}
                errorMessage={errors.logId?.message}
                options={mapInvestmentLogsSelect(investmentLogs) || []}
                ref={register({validate: (e) => e != "0" ? true : "Musíte si vybrat zápis."})}/>}
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput type="date" label="Datum plnění"
            name="finishDate"
            placeholder="Datum..."
            defaultValue=""
            valid={!errors.finishDate}
            errorMessage={errors.finishDate?.message}
            ref={register({required: {value: true, message: "Datum plnění je povinné."}})}/>
            <LabeledTextInput type="text" label="Název"
            name="name"
            placeholder="Název..."
            defaultValue=""
            valid={!errors.name}
            errorMessage={errors.name?.message}
            ref={register({required: {value: true, message: "Název je povinný."}})}/>
        </ModalInputRow>
        {(!projectId) && <ModalInputRow><LabeledSelectInput label="Investiční zápis"
            name="logId"
            placeholder={investmentLogs.length > 0 ? "Vyberte..." : "Investiční zápisy nejsou k dispozici."}
            icon={<ICaretIcon/>}
            valid={!errors.logId}
            errorMessage={errors.logId?.message}
            options={mapInvestmentLogsSelect(investmentLogs) || []}
            ref={register({validate: (e) => e != "0" ? true : "Musíte si vybrat zápis."})}/></ModalInputRow>}
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const idPad = (num: number, size: number) => String(num).padStart(size, '0');

export const mapProjectsSelect = (projects: Array<any>) => {
    let result: Array<any> = [];
    projects.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.internalName}`,
    }))
    return result;
};

const mapUserSelect = (users: Array<any>) => {
    let result: Array<any> = [];
    users.map(o => result.push({
        id: o.id,
        label: o.fullName,
        imgUrl: IProfileIcon, //o.imageUrl
    }))
    return result;
};

const mapInvestmentLogsSelect = (investmentLogs: Array<any>) => {
    let result: Array<any> = [];
    investmentLogs.map(o => result.push({
        id: o.id,
        label: dayjs(o.logDate).format(`DD.MM.YYYY`),
    }))
    return result;
};

export default CreateTasksModal;