import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import App from "./App";

const rootElement = document.getElementById("root");

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    rootElement);

registerServiceWorker();