import React, {useContext} from "react";
import styled from "styled-components";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IFileIcon} from "../../resources/icons/file-lines.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import LabeledFileInput from "../../components/shared/inputs/LabeledFileInput";
import LabeledSelectInput from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import { useForm } from "react-hook-form";
import {useHistory} from "react-router-dom";
import { ModalContext } from "../../components/layout/Layout";
import createDocument from "./api/createDocument";
import createProjectDocument from "../projects/api/project/createProjectDocument";
import toast from "react-hot-toast";
import { GetDocumentCategoryTypes } from "../../enums/DocumentEnums";
import { Routes } from "../../router/Routes";

interface CreateDocumentModalInterface {
    projectId?: number | string,
}

const CreateDocumentModal = ({projectId}: CreateDocumentModalInterface) => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const modal = useContext(ModalContext);
    const onSubmit = async (data: any) => {
        const documentData = new FormData();
        documentData.append("name", data.title);
        documentData.append("category", data.category);
        documentData.append("file", data.file[0]);
        if(projectId) {
            documentData.append("projectId", projectId.toString());
            const response = await createProjectDocument(projectId, documentData);
            if(response.status == 200) {
                const fetchedData = await response.json();
                modal.closeCurrentModal();
                history.push(Routes.DOCUMENT_DETAIL.replace(":slug", fetchedData.result))
            } else {
                toast.error("Dokument se nepodařilo vytvořit.")
            };
        } else {
            const response = await createDocument(documentData);
            if(response.status == 200) {
                const fetchedData = await response.json();
                modal.closeCurrentModal();
                history.push(Routes.DOCUMENT_DETAIL.replace(":slug", fetchedData.result))
            } else {
                toast.error("Dokument se nepodařilo vytvořit.")
            };
        };
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
        cancelModal={() => modal.closeCurrentModal()}
        icon={<IFileIcon/>}
        title={"Vytvořit dokument"}
        >
        <ModalInputRow>
            <LabeledTextInput type="text" label="Název"
            name="title"
            placeholder="Název..."
            defaultValue=""
            valid={!errors.title}
            errorMessage={errors.title?.message}
            ref={register({required: {value: true, message: "Název je povinný."}})}/>
            <LabeledSelectInput label="Kategorie"
            name="category"
            placeholder="Vyberte..."
            icon={<ICaretIcon/>}
            valid={!errors.category}
            errorMessage={errors.category?.message}
            options={GetDocumentCategoryTypes()}
            ref={register({validate: (e) => e != "0" ? true : "Kategorie musí být určena."})}/>
        </ModalInputRow>
        <ModalInputRow>
            <div/>
            <LabeledFileInput name="file"
            label="Soubor"
            valid={!errors.file}
            accept={[
                ".pdf", ".ppt", ".doc", ".xls"
            ]}
            errorMessage={errors.file?.message}
            ref={register({required: {value: true, message: "Dokument musí obsahovat soubor."}})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Dokončit a přidat
        </ModalSubmit>
        <ModalCancel onClick={() => modal.closeCurrentModal()}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
        </ModalContentWrap>
    </form>)
};

const Container = styled.div``;

export default CreateDocumentModal;