import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as ITrophyIcon} from "../../resources/icons/trophy.svg";
import LabeledTextInput from "../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput, {OptionInterface} from "../../components/shared/inputs/LabeledSelectInput";
import {ModalCancel, ModalInputRow, ModalSubmit} from "../../components/shared/modal/ModalInputs.styled";

import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import {useForm} from "react-hook-form";
import {ModalContext} from "../../components/layout/Layout";
import {fetchSelectRealEstates} from "./api/fetchSelects";
import {ModalTileItem, ModalTileSelector} from "../../components/shared/modal/ModalTileSelector";
import {GetProjectStateOptions, GetProjectTypeInfo, GetProjectTypes, ProjectState} from "../../enums/ProjectEnums";
import {GetRETypes, RealEstateType} from "../../enums/RealEstateEnums";
import createProject from "./api/project/createProject";
import {Routes} from "../../router/Routes";
import toast from "react-hot-toast";
import {useHistory} from "react-router";
import CreateRealEstateModal from "../real-estate/CreateRealEstateModal";
import {formatPrice} from "../../shared/utils/format";


interface FirstStepModalProps {
    projectType: number;

    setProjectType(projectType: number): void;

    continue(): void;

    close(): void;
}

const FirstStepModal: React.FC<FirstStepModalProps> = (props) => {
    const projectTypeTiles = useMemo<ModalTileItem[]>(() => GetProjectTypes().map(t => ({name: t.label, value: t.id.toString(), icon: t.icon})), []);

    return <ModalContentWrap
        cancelModal={props.close}
        icon={<ITrophyIcon/>}
        title="Nový projekt"
        subtitle="Pro pokračování vyberte jednu z šablon určující typ projektu."
    >
        <ModalTileSelector selectedValue={props.projectType.toString()} setSelectedValue={(value) => props.setProjectType(parseInt(value))} tiles={projectTypeTiles}/>
        <ModalSubmit onClick={props.continue}>
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.close}>
            <ICrossIcon/> Zavřít a nic nedělat
        </ModalCancel>
    </ModalContentWrap>
};

interface SecondStepModalProps {
    projectType: number;
    register: any;
    errors: any;
    close(): void;

    goBack(): void;
}

const COLLATERAL_CREATE_NEW_CONST = -9;

const SecondStepModal: React.FC<SecondStepModalProps> = (props) => {
    const {register, errors, close} = props;
    const [realEstates, setRealEstates] = useState<any>([]);
    const [selectedCollateralType, setSelectedCollateralType] = useState<number | undefined>(undefined);

    const [realEstateDefaultValue, setRealEstateDefaultValue] = useState<number | string>(-1);
    const modal = useContext(ModalContext);
    const projectInfo = useMemo(() => GetProjectTypeInfo(props.projectType), [props.projectType]);
    const projectStateOptions: OptionInterface[] = useMemo(() => GetProjectStateOptions(), []);
    const collateralTypeOptions: OptionInterface[] = useMemo(() => GetRETypes().map(t => ({id: t.id, label: t.label})), []);
    const mainCollateralCreateSelectOption: OptionInterface = {id: COLLATERAL_CREATE_NEW_CONST, label: "+ Vytvořit nemovitost"};

    const onRealEstateSelect = (id: number | string) => {
        if (id === COLLATERAL_CREATE_NEW_CONST) {
            setRealEstateDefaultValue(-1);
            modal.openModal(<CreateRealEstateModal defaultType={selectedCollateralType} onRealEstateCreate={onRealEstateCreated}/>);
        }
    }

    const onRealEstateCreated = async (id: number | string, type: any) => {
        await fetchRealEstates(type);
        setRealEstateDefaultValue(id);
    }

    const fetchRealEstates = useCallback(async (type: number) => {
        const response = await fetchSelectRealEstates([], type);
        if (response.status == 200) {
            const fetchedRealEstates = await response.json();
            setRealEstates(fetchedRealEstates.result);
        }
    }, []);

    useEffect(() => {
        if(selectedCollateralType)
            fetchRealEstates(selectedCollateralType);
    }, [selectedCollateralType])

    return <ModalContentWrap
        cancelModal={close}
        icon={projectInfo!.icon}
        title={projectInfo!.label}
        subtitle="Pro dokončení tvorby projektu vyplňte povinné parametry."
    >
        <ModalInputRow>
            <LabeledSelectInput name="state" label="Stav projektu"
                                placeholder={"Vyberte stav projektu"}
                                valid={!errors.state}
                                defaultValue={ProjectState.InPreparation}
                                errorMessage={errors.state?.message}
                                icon={<ICaretIcon/>}
                                options={projectStateOptions}
                                ref={register({valueAsNumber: true, validate: (e: any) => e !== "0" ? true : "Musíte vybrat stav projektu."})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledSelectInput name="collateralTypes" label="Typ nemovitosti"
                                placeholder={"Vyberte.."}
                                valid={true}
                                icon={<ICaretIcon/>}
                                options={collateralTypeOptions}
                                onSelect={(val) => setSelectedCollateralType(val as number)}
            />
        </ModalInputRow>
        <ModalInputRow>
            <LabeledSelectInput name="mainCollateralId" label="Hlavní zajíštění"
                                placeholder={selectedCollateralType !== undefined ? "Vyberte..." : "Nejsou k dispozici..."}
                                valid={!errors.mainCollateralId}
                                errorMessage={errors.mainCollateralId?.message}
                                defaultValue={realEstateDefaultValue}
                                disable={selectedCollateralType === undefined}
                                icon={<ICaretIcon/>}
                                onSelect={onRealEstateSelect}
                                options={selectedCollateralType !== undefined ? [mainCollateralCreateSelectOption, ...mapRESelect(realEstates)] : []}
                                ref={register({valueAsNumber: true, validate: (e: any) => (e !== "0" && e !== COLLATERAL_CREATE_NEW_CONST.toString()) ? true : "Musíte vybrat zajištění."})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput name="startDate" label="Požadované datum schválení" type="date"
                              placeholder="Vyberte..."
                              defaultValue=""
                              wide={true}
                              valid={!errors.startDate}
                              errorMessage={errors.startDate?.message}
                              ref={register({required: {value: true, message: "Datum je povinné."}})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput name="investmentPeriod" label="Požadované investiční období" type="number"
                              placeholder="Zadejte počet měsíců..."
                              defaultValue=""
                              wide={true}
                              valid={!errors.investmentPeriod}
                              errorMessage={errors.investmentPeriod?.message}
                              ref={register({valueAsNumber: true, required: {value: true, message: "Období je povinné."}})}/>
        </ModalInputRow>
        <ModalInputRow>
            <LabeledTextInput name="investmentValue" label="Požadovaná investice" type="text"
                              placeholder="Zadejte počet Kč..."
                              defaultValue=""
                              wide={true}
                              isPrice
                              valid={!errors.investmentValue}
                              errorMessage={errors.investmentValue?.message}
                              ref={register({required: {value: true, message: "Investice je povinná."}})}/>
        </ModalInputRow>
        <ModalSubmit type="submit">
            <ICheckIcon/> Potvrdit a pokračovat
        </ModalSubmit>
        <ModalCancel onClick={props.goBack}>
            <ICrossIcon/> Vrátit se zpět
        </ModalCancel>
    </ModalContentWrap>
};


const CreateProjectModal = () => {
    const {register, handleSubmit, errors} = useForm();
    const history = useHistory();
    const [modalStep, setModalStep] = useState<number>(1);
    const [projectType, setProjectType] = useState<number>(1);
    const modal = useContext(ModalContext);

    const onSubmit = async (data: any) => {
        const investmentValue = parseFloat(data.investmentValue?.replaceAll('.', '').replace(/\s+/g, ''));
        data = {
            ...data,
            investmentValue: data.investmentValue == "" ? null : (isNaN(investmentValue) ? 0 : investmentValue),
            type: projectType
        };

        const response = await createProject(data);
        if (response.status == 200) {
            const fetchedData = await response.json();
            modal.closeCurrentModal();
            history.push(Routes.PROJECT_DETAIL.replace(":slug", fetchedData.result));
        } else {
            toast.error("Nepodařilo se vytvořit projekt.")
        }
    };

    return (<form onSubmit={handleSubmit(onSubmit)}>
        {modalStep === 1 && <FirstStepModal projectType={projectType}
                                            setProjectType={setProjectType}
                                            continue={() => setModalStep(2)}
                                            close={() => modal.closeCurrentModal()}/>}
        {modalStep === 2 && <SecondStepModal projectType={projectType}
                                             register={register}
                                             errors={errors}
                                             goBack={() => setModalStep(1)}
                                             close={() => modal.closeCurrentModal()}/>}
    </form>)
};

const idPad = (num: number, size: number) => String(num).padStart(size, '0');

const mapRESelect = (realestates: Array<any>) => {
    let result: Array<any> = [];
    realestates.map(o => result.push({
        id: o.id,
        label: `[${idPad(o.id, 2)}] ${o.location}\n${formatPrice(o.value)} Kč`,
    }))
    return result;
};

export default CreateProjectModal;