import React from "react";
import { useState } from "react";
import styled from "styled-components";

interface UButtonInterface {
    title: string;
    icon: React.ReactNode;
    color: "blue" | "gray" | "green" | "red" | "none" | "fullGreen";
    type?: "submit" | "button" | "reset";
    onClick?: () => void;
}

const UtilityButton = ({title, type, icon, color, onClick}: UButtonInterface) => {
    return (<Container type={type ? type : "button"} color={color} onClick={onClick}>
        <IconWrapper>{icon}</IconWrapper>
        {title}
    </Container>)
};

const Container = styled.button<{color: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-height: 36px;
    padding: ${({color}) => color == "none" ? "0" : "10px 16px"};
    border: 2px solid ${({color, theme}) => (color == "blue" && theme.colors.darkBlue) || (color == "gray" && theme.colors.borderGray) || (color == "green" && theme.colors.borderGray) || (color == "fullGreen" && theme.colors.borderGray) || (color == "red" && theme.colors.grayRed)};
    background-color: ${({color, theme}) => (color == "blue" && theme.colors.blue) || (color == "green" && theme.colors.greenWhite) || (color == "fullGreen" && theme.colors.stripGreen)|| (color == "gray" && theme.colors.lightGray) || (color == "red" && theme.colors.redGray)};
    font-weight: 600;
    border-radius: 5px;
    &:not(:last-of-type) {
        margin-right: 8px;
    }
    ${({theme, color}) => color == "blue" && `
        color: ${theme.colors.white};
        svg {
            fill: ${theme.colors.white};
        }
    `}
    ${({theme, color}) => color == "red" && `
        color: ${theme.colors.lightPink};
        svg {
            fill: ${theme.colors.lightPink};
        }
    `}
    ${({theme, color}) => color == "green" && `
        color: ${theme.colors.stripGreen};
        svg {
            fill: ${theme.colors.stripGreen};
        }
    `}
    ${({theme, color}) => color == "fullGreen" && `
        color: ${theme.colors.white};
        svg {
            fill: ${theme.colors.white};
        }
    `}
`;

const IconWrapper = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    max-height: 16px;
    margin-right: 6px;
`;

export default UtilityButton;