import { getAccessToken } from '../../../contexts/UserIdentityContext';
const uploadRealEstateEstimationFile = async (id: number | string, file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(`/api/v1/realestates/${id}/estimation`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`,
        },
        body: formData,
    });
    return response;
};

export default uploadRealEstateEstimationFile;