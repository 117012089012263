import React, {useContext, useState} from "react";
import styled from "styled-components";

import {ForgotPasswordLink, KeyIcon, LoginButton, LoginButtonSection, LoginForm, LoginFormWrapper, LoginLogo, LoginPageWrapper, UserIcon} from "./LoginPage.styled";
import {Routes} from "../../router/Routes";

import LoginInput from "./components/LoginInput";

import {useForm} from "react-hook-form";
import ILoadingGIF from "../../resources/images/loading.gif";
import {UserIdentityContext} from "../../contexts/UserIdentityContext";
import {Redirect} from "react-router-dom";
import {verifyLogin} from "./api/verifyLogin";
import toast from "react-hot-toast";


const LoginPage = (): React.ReactElement => {
    const userIdentity = useContext(UserIdentityContext);
    const [loading, setLoading] = useState<boolean>(false);
    const {register, handleSubmit, errors} = useForm();

    const onSubmit = async (data: any): Promise<boolean> => {
        setLoading(true);
        const response = await verifyLogin(data.email, data.password);
        if (response.status != 200) {
            setLoading(false);
            toast.error("Nesprávné přihlašovací údaje!");
            return false;
        }
        const result = await response.json();
        userIdentity.signIn(JSON.stringify(result));
        return false;
    };

    return (<LoginPageWrapper>
        {userIdentity.isLoggedIn && <Redirect to={Routes.PROJECTS}/>}
        <LoginFormWrapper>
            <LoginLogo/>
            <LoginForm onSubmit={handleSubmit(onSubmit)}>
                <fieldset disabled={loading}>
                    <LoginInput
                        label={'Email'}
                        name={'email'}
                        type={'text'}
                        valid={!errors.email}
                        defaultValue=""
                        disabled={loading}
                        ref={register({required: {value: true, message: "Email je povinný."}})}
                        icon={<UserIcon/>}
                    />
                    <LoginInput
                        label={'Heslo'}
                        name={'password'}
                        type={'password'}
                        valid={!errors.password}
                        defaultValue=""
                        disabled={loading}
                        ref={register({required: {value: true, message: "Heslo je povinné."}})}
                        icon={<KeyIcon/>}
                    />
                    <LoginButtonSection>
                        <ForgotPasswordLink to={Routes.FORGOT_PASSWORD}>Zapomenuté heslo?</ForgotPasswordLink>
                        <LoginButton disabled={loading} type={'submit'}>Přihlásit se {loading && <Loading src={ILoadingGIF}/>}</LoginButton>
                    </LoginButtonSection>
                </fieldset>
            </LoginForm>
        </LoginFormWrapper>
    </LoginPageWrapper>);
};

export default LoginPage;

const Loading = styled.img`
    margin-bottom: -4px;
    margin-left: 8px;
    max-width: 20px;
    max-height: 20px;
`;

const FailedLogin = styled.div<{ active: boolean }>`
    color: white;
    padding: 12px;
    background-color: ${({theme}) => theme.colors.transparentRed};
    border-radius: 5px;
    max-width: 150px;
    margin: -25px auto 0 auto;
    opacity: ${({active}) => active ? "1" : "0"};
    transition: 0.2s all;
`;