import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PageHeader from "../../../components/shared/PageHeader";
import { Routes } from "../../../router/Routes";
import CategoryButtons from "../../../components/shared/CategoryButtons";
import { Route, Switch, useHistory, useParams } from "react-router";
import ProfileSection from "./components/ProfileSection";
import ProjectsSection from "./components/ProjectsSection";
import ScoringSection from "./components/ScoringSection";
import EditSection from "./components/EditSection";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../resources/icons/pen.svg";
import deleteProvider from "../api/provider/deleteProvider";
import fetchProvider from "../api/provider/fetchProvider";
import toast from "react-hot-toast";
import uploadProviderImage from "../api/provider/uploadProviderImage";
import {getPhotoFromCdn} from "../../../shared/api/photoApi";

const ProviderDetailPage = () => {
    const {slug}: {slug: string} = useParams();
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});

    const fetchData = async () => {
        const response = await fetchProvider(slug);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Zprostředkovatele se nepodařilo načíst.")
        }
    };

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat zprostředkovatele?")) {
                    const response = await deleteProvider(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        if(fetchedData.isSuccess) {
                            history.push(Routes.PROJECTS_PROVIDERS);
                            toast.success("Zprostředkovatel smazán.")
                        } else {
                            toast.error("Zprostředkovatele se nepodařilo smazat.")
                        }
                    } else {
                        toast.error("Zprostředkovatele se nepodařilo smazat.")
                    }
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export provider")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_PROVIDER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
        fetchData();
    }, []);
    const handleUpload = async (e: any) => {
        const response = await uploadProviderImage(slug, e.target.files[0]);
        if(response.status == 200) {
            const fetchedData = await response.json();
            fetchData();
            toast.success("Fotka zprostředkovatele byla změněna.");
        } else {
            toast.error("Fotku zprostředkovatele se nepodařilo změnit.");
        }
    };
    return (<Container>
        {!loading && <><HeaderWrap>
            <PageHeader title={`${data.contactPerson?.firstName} ${data.contactPerson?.lastName}`} subtitle={`Projekty   —   Zprostředkovatelé   —   ${data.contactPerson?.firstName} ${data.contactPerson?.lastName}`}
            image={getPhotoFromCdn(data.imageName)}
            onUpload={handleUpload}
            noContainerBottomMargin={history.location.pathname.includes("upravit")}
            noContainerLeftMargin
            returnPath={Routes.PROJECTS_PROVIDERS}/>
        </HeaderWrap>
        {!history.location.pathname.includes("upravit") && <CategoryButtons buttons={[
            {
                text: "Profil",
                index: slug,
                route: Routes.PROJECT_PROVIDER_DETAIL,
                exact: true,
            },
            {
                text: "Projekty",
                index: slug,
                route: Routes.PROJECT_PROVIDER_DETAIL_PROJECTS,
                exact: true,
            },
            {
                text: "Scoring",
                index: slug,
                route: Routes.PROJECT_PROVIDER_DETAIL_SCORING,
                exact: true,
            },
        ]}/>}
        <Switch>
            <Route path={Routes.PROJECT_PROVIDER_DETAIL} render={() => <ProfileSection data={data}/>} exact/>
            <Route path={Routes.PROJECT_PROVIDER_DETAIL_PROJECTS} render={() => <ProjectsSection/>} exact/>
            <Route path={Routes.PROJECT_PROVIDER_DETAIL_SCORING} render={() => <ScoringSection/>} exact/>
            <Route path={Routes.PROJECT_PROVIDER_DETAIL_EDIT} render={() => <EditSection data={data}/>} exact/>
        </Switch></>}
    </Container>)
};

const Container = styled.div`
    padding: 0 24px;
`;

const HeaderWrap = styled.div`
    padding: 8px;
`;

export default ProviderDetailPage;