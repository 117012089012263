import {getAccessToken} from "../../../contexts/UserIdentityContext";

const createUser = async (data: any): Promise<any> => {
    const response = await fetch(`/api/v1/admins`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getAccessToken()
        },
        body: JSON.stringify(data),
    });
    return response;
};

export default createUser;