import React, {ReactElement} from "react";
import styled from "styled-components";
import {
    InfoBoxBody,
    InfoBoxHeader,
    InfoBoxWrapper
} from "./styled/InfoBox.styled";

export interface InfoBoxInterface {
    title: string;
    icon?: ReactElement;
    children: React.ReactNode;
}

const InfoBox = ({title, icon, children}: InfoBoxInterface): ReactElement => {
    return <InfoBoxWrapper>
        <InfoBoxHeader>
            {icon != undefined && <IconStyler>{icon}</IconStyler>}
            {title}
        </InfoBoxHeader>
        <InfoBoxBody>
            {children}
        </InfoBoxBody>
    </InfoBoxWrapper>;
};

const IconStyler = styled.div`
    width: 18px;
    height: 18px;
    margin-right: 16px;
    fill: ${({theme}) => theme.colors.black};
`;

export default InfoBox;