import React, {ReactElement, useContext} from "react";
import {Redirect, Route} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {UserIdentityContext} from "../contexts/UserIdentityContext";
import {Routes} from "./Routes";

interface ProtectedRouteInterface {
    component: any;
    path: string;
    exact?: boolean;
}

const ProtectedRoute = ({component: Component, path, exact = false, ...rest}: ProtectedRouteInterface): ReactElement => {
    const userIdentity = useContext(UserIdentityContext);

    return (
        <Route {...rest} path={path} exact render={matchProps => {
            if (userIdentity.initialized && !userIdentity.isLoggedIn)
                return <Redirect to={Routes.LOGIN}/>

            return (
                <Layout {...rest}>
                    <Component {...matchProps} />
                </Layout>
            )
        }}/>
    )
};

export default ProtectedRoute;