import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as ITagIcon} from "../../../../resources/icons/tag.svg";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import {ReactComponent as ICoinsIcon} from "../../../../resources/icons/coins.svg";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as IDiskIcon} from "../../../../resources/icons/floppy-disk.svg";
import {ReactComponent as ICrossIcon} from "../../../../resources/icons/xmark.svg";
import deleteProject from "../../api/project/deleteProject";
import toast from "react-hot-toast";
import fetchProjectTeam from "../../api/project/fetchProjectTeam";
import fetchProjectTransactions from "../../api/project/fetchProjectTransactions";
import dayjs from "dayjs";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import {GetTransactionTypeLabel} from "../../../../enums/FinanceEnums";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import CreateFinanceModal from "../../CreateFinanceModal";
import LabeledTextInput from "../../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../../components/shared/inputs/LabeledSelectInput";
import { useForm } from "react-hook-form";
import editProjectAnalytics from "../../api/project/editProjectAnalytics";

interface EditAnalyticsSectionInterface {
    data: any;
    onEdit(): void;
}

const EditAnalyticsSection = ({data, onEdit}: EditAnalyticsSectionInterface) => {
    const {slug}: {slug: string} = useParams();
    const {register, errors, handleSubmit} = useForm();
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();

    const onSubmit = async (data: any) => {
        const response = await editProjectAnalytics(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.error("Analytika upravena.");
            onEdit();
        } else {
            toast.error("Analytiku se nepodařilo upravit.");
        }
    };

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL_ANALYTICS.replace(":slug", slug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<IDiskIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>)
    }, []);

    return (<InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Postoupení pohledávky" icon={<ITagIcon/>}>
                    <LabeledTextInput
                        type="number"
                        label="Investováno"
                        name="totalInvested"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.totalInvested?.message}
                        valid={!errors.totalInvested}
                        defaultValue={data.generalInformation?.totalInvested || 0}
                        ref={register({required: {value: true, message: "Investovaná částka je povinná."}})}
                    />
                    <LabeledTextInput
                        type="number"
                        label="Zisk investora"
                        name="investorCut"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.investorCut?.message}
                        valid={!errors.investorCut}
                        defaultValue={data.generalInformation?.investorCut || 0}
                        ref={register({required: {value: true, message: "Zisk je povinný."}})}
                    />
                    <LabeledTextInput
                        type="number"
                        label="Úrok"
                        name="interestRate"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.interestRate?.message}
                        valid={!errors.interestRate}
                        defaultValue={data.generalInformation?.interestRate || 0}
                        ref={register({required: {value: true, message: "Úrok je povinný."}})}
                    />
                    <LabeledTextInput
                        type="number"
                        label="Úrok"
                        name="interestRate"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.interestRate?.message}
                        valid={!errors.interestRate}
                        defaultValue={data.generalInformation?.interestRate || 0}
                        ref={register({required: {value: true, message: "Úrok je povinný."}})}
                    />
                    <LabeledTextInput
                        type="number"
                        label="Úrok/rok (p.a.)"
                        name="yearlyInterestRate"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.yearlyInterestRate?.message}
                        valid={!errors.yearlyInterestRate}
                        defaultValue={data.generalInformation?.yearlyInterestRate || 0}
                        ref={register({required: {value: true, message: "Úrok/rok je povinný."}})}
                    />
                    <LabeledTextInput
                        type="number"
                        label="Investiční období"
                        name="investmentMonthsPeriod"
                        wide
                        placeholder={"Číslo..."}
                        errorMessage={errors.investmentMonthsPeriod?.message}
                        valid={!errors.investmentMonthsPeriod}
                        defaultValue={data.generalInformation?.investmentMonthsPeriod || 0}
                        ref={register({required: {value: true, message: "Investiční období je povinné."}})}
                    />
                </InfoBox>
                <InfoBox title="Popis" icon={<IInfoIcon/>}>
                    <LabeledTextInput
                        type="date"
                        label="Začátek (investor platí)"
                        name="startDate"
                        wide
                        placeholder={"Vyberte..."}
                        errorMessage={errors.startDate?.message}
                        valid={!errors.startDate}
                        defaultValue={data.generalInformation?.startDate || ""}
                        ref={register({required: {value: true, message: "Datum začátku je povinné."}})}
                    />
                    <LabeledTextInput
                        type="date"
                        label="Očekávaný konec (investor vyplacen)"
                        name="estimatedEndDate"
                        wide
                        placeholder={"Vyberte..."}
                        errorMessage={errors.estimatedEndDate?.message}
                        valid={!errors.estimatedEndDate}
                        defaultValue={data.generalInformation?.estimatedEndDate || ""}
                        ref={register({required: {value: true, message: "Datum konce je povinné."}})}
                    />
                    <LabeledTextInput
                        type="date"
                        label="Vyčíslení postupovaného nároku"
                        name="assignedClaimDate"
                        wide
                        placeholder={"Vyberte..."}
                        errorMessage={errors.assignedClaimDate?.message}
                        valid={!errors.assignedClaimDate}
                        defaultValue={data.generalInformation?.assignedClaimDate || ""}
                        ref={register({required: {value: true, message: "Datum vyčíslení je povinné."}})}
                    />
                    <LabeledTextInput
                        type="date"
                        label="Ukončení růstu nároku"
                        name="claimGrowthEndDate"
                        wide
                        placeholder={"Vyberte..."}
                        errorMessage={errors.claimGrowthEndDate?.message}
                        valid={!errors.claimGrowthEndDate}
                        defaultValue={data.generalInformation?.claimGrowthEndDate || ""}
                        ref={register({required: {value: true, message: "Datum vyčíslení je povinné."}})}
                    />
                    {/*<InfoBoxItem title="Ukončení růstu nároku" text={<YellowWrap>15. zář 2020</YellowWrap>} />
                    <InfoBoxItem title="LTV" text={"30 %"} />
                    <InfoBoxItem title="Hodnota zajištění" text={"5.000.000 Kč"} />
                    <InfoBoxItem title="Plánovaná prodejní cena" text={"4.500.000 Kč"} />
                    <InfoBoxItem title="Cena za postoupení pohledávky" text={"1.500.000 Kč"} />
                    <InfoBoxItem title="Nárok pohledávky ke dni splatnosti" text={"2.000.000 Kč"} />
                    <InfoBoxItem title="Jistina pohledávky" text={"1.000.000 Kč"} />
                    <InfoBoxItem title="Splátka/měsíc" text={"10.000 Kč po 12 měsíců"} />
                    <InfoBoxItem title="Úrok/měsíc (p.m.)" text={"14.860 Kč"} />
                    <InfoBoxItem title="Celkový výnos úrokové sazby" text={"121.667 Kč"} />
                    <InfoBoxItem title="Úrok z prodlení" text={"5.000 Kč po 365 dní"} />
                    <InfoBoxItem title="Celkový výnos úroků z prodlení" text={"1.825.000"} />
    <InfoBoxItem title="Koncový nárok pohledávky" text={<YellowWrap>3.946 667.Kč</YellowWrap>} />*/}
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Počty z celkové investice" icon={<IPercentIcon/>}>
                    {/*<InfoBoxItem title="Minimální výnos investora" text={"10 %  10 % p.a."} />
                    <InfoBoxItem title="Podíl investora nad minimální výnos" text={"60 %"} />
<InfoBoxItem title="Sdílený výnos ERICONN" text={"40 %"} />*/}
                </InfoBox>
                <InfoBox title="Výnosy, náklady a zisky, ztráty" icon={<ICoinsIcon/>}>
                    {/*<InfoBoxItem title="Vstupní poplatek ERICONN" text={<>4 % z 1.500.000 Kč = <RedWrap>60.000 Kč</RedWrap></>} />
                    <InfoBoxItem title="Náklady hrazeny ERICONN" text={<RedWrap>30.000 Kč</RedWrap>} />
                    <InfoBoxItem title="Náklady hrazené investory" text={<RedWrap>90.000 Kč</RedWrap>} />
                    <InfoBoxItem title="Celkový čistý výnos" text={<><GreenWrap><b>2.296.667 Kč </b></GreenWrap>139,2 %  139,2 % p.a.</>} />
                    <InfoBoxItem title="Základní minimální výnos investorů" text={<><GreenWrap>165.000 Kč </GreenWrap>10 %  10 % p.a.</>} />
                    <InfoBoxItem title="Sdílený podílový výnos investorů" text={<><GreenWrap>1 279.000 Kč </GreenWrap>77,5 % 77,5 % p.a.</>} />
                    <InfoBoxItem title="Celkový čistý výnos investorů" text={<><GreenWrap><b>1 444.000 Kč </b></GreenWrap>87,5 %  87,5 % p.a.</>} />
                    <InfoBoxItem title="Hrubý zisk ERICONN" text={<><GreenWrap>852.667 Kč </GreenWrap>51,7 % 51,7 % p.a.</>} />
<InfoBoxItem title="Celkový čistý výnos ERICONN" text={<><GreenWrap><b>822.667 Kč </b></GreenWrap>49,9 %  49,9 % p.a.</>} />*/}
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>)
};

export default EditAnalyticsSection;