import React from "react";
import styled from "styled-components";

interface FilterWrapInterface {
    children: any;
    maxWidth?: string;
}

const FilterWrap = ({maxWidth, children}: FilterWrapInterface) => {
    return (<Container maxWidth={maxWidth ? maxWidth : "100%"}>
        {children}
    </Container>)
};

const Container = styled.div<{maxWidth: string}>`
    display: flex;
    justify-content: space-between;
    max-width: ${({maxWidth}) => maxWidth};
    align-items: center;
`;

export default FilterWrap;