import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import {ReactComponent as ISquareIcon} from "../../../resources/icons/square2.svg";
import {ReactComponent as IGearIcon} from "../../../resources/icons/gear.svg";
import {ReactComponent as ICheckIcon} from "../../../resources/icons/square-check.svg";
import {ReactComponent as ISCheckIcon} from "../../../resources/icons/check.svg";
import StyledDatePicker from "../inputs/StyledDatePicker";
import { FilterSelectButton, FilterSubmitButton, FilterContainer } from "./Filter.styled";

interface FilterInterface {
    label: string;
    name: string;
}

interface FilterSelectInterface {
    label: string;
    icon?: React.ReactNode;
    onChange(filters: string): void;
}

const FilterDateInput = ({label, icon, onChange}: FilterSelectInterface) => {
    const [open, setOpen] = useState<boolean>(false);
    const [savedDate, setSavedDate] = useState<string>("");
    const [selectedDate, setSelectedDate] = useState<string>("");
    const handleDateChange = (date: string) => {
        setSelectedDate(date);
    };
    const handleClose = () => {
        if(open) setSelectedDate("");
        if(!open) {
            setSelectedDate(savedDate);
        };
        setOpen(!open);
    };
    const handleSubmit = () => {
        setSavedDate(selectedDate);
        onChange(selectedDate);
        handleClose();
    };
    return (<div>
        <FilterSelectButton onClick={handleClose} onReset={() => {
            setSavedDate("")
            setSelectedDate("")
            onChange("");
        }} icon={icon ? icon : <IGearIcon/>} isActive={savedDate != ""}>
            {label}
        </FilterSelectButton>
        {open && <FilterContainer>
            <StyledDatePicker
                date={selectedDate}
                valid={true}
                onChange={handleDateChange}
            />
            <FilterSubmitButton onClick={handleSubmit}>
                <SCheckIcon/> Potvrdit vybrané
            </FilterSubmitButton>
        </FilterContainer>}
    </div>)
};

const SCheckIcon = styled(ISCheckIcon)`
    fill: ${({theme}) => theme.colors.white};
    height: 16px;
    width: 16px;
`;

export default FilterDateInput;