import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import {ReactComponent as ISquareIcon} from "../../../resources/icons/square2.svg";
import {ReactComponent as ICheckIcon} from "../../../resources/icons/square-check.svg";
import {ReactComponent as IGearIcon} from "../../../resources/icons/gear.svg";
import {ReactComponent as ISCheckIcon} from "../../../resources/icons/check.svg";
import StyledDatePicker from "../inputs/StyledDatePicker";
import toast from "react-hot-toast";
import { LabeledInputField } from "../inputs/styled/LabeledTextInput.styled";
import { FilterSelectButton, FilterSubmitButton, FilterContainer } from "./Filter.styled";

interface FilterInterface {
    label: string;
    name: string;
}

interface FilterSelectInterface {
    label: string;
    icon?: React.ReactNode;
    dateRange?: boolean;
    onChange(values: Object): void;
}

interface FilterValuesInterface {
    [index: string]: null | number | string;
}

const FilterRangeInput = ({label, dateRange, icon, onChange}: FilterSelectInterface) => {
    const [open, setOpen] = useState<boolean>(false);
    const defaultValues = { from: "", to: "" };
    const [savedValues, setSavedValues] = useState<Object>({...defaultValues});
    const [currentValues, setCurrentValues] = useState<Object>({...defaultValues});
    const handleValueChange = (key: string, value: number | string) => {
        const copValues: any = {...currentValues};
        copValues[key] = value;
        setCurrentValues(copValues);
    };
    const handleClose = () => {
        if(open) setCurrentValues({...defaultValues});
        if(!open) {
            setCurrentValues({...savedValues});
        };
        setOpen(!open);
    };
    const countEmptyValues = (values: Object): number => {
        let count = 0; Object.values(values).map(o => o == "" && count++);
        return count
    };
    const handleSubmit = () => {
        const copValues = {...currentValues};
        setSavedValues(copValues);
        const emptyValues = countEmptyValues(copValues);
        if(emptyValues != 2) {
            onChange(copValues);
        } else {
            toast.error("Musíte zadat, alespoň jednu hodnotu.");
        };
        handleClose();
    };

    const renderInputs = () => {
        const copValues: any = {...currentValues};
        const fromValue = copValues.from != "";
        const toValue = copValues.to != "";
        return (<SquishedWrap squish={dateRange != undefined && dateRange}>
            {dateRange && <MargedWrap>
                <StyledDatePicker
                    date={((currentValues as FilterValuesInterface).from as string) || ""}
                    valid={fromValue}
                    filter={true}
                    onChange={(date: string) => handleValueChange("from", date)}
                />
                <StyledDatePicker
                    date={((currentValues as FilterValuesInterface).to as string) || ""}
                    valid={toValue}
                    filter={true}
                    onChange={(date: string) => handleValueChange("to", date)}
                />
            </MargedWrap>}
            {!dateRange && <MargedWrap>
                <LabeledInputField
                    type="number"
                    valid={fromValue}
                    defaultValue={copValues.from || ""}
                    onChange={({target}) => handleValueChange("from", target.value)}
                />
                <LabeledInputField
                    type="number"
                    defaultValue={copValues.to || ""}
                    valid={toValue}
                    onChange={({target}) => handleValueChange("to", target.value)}
                />
            </MargedWrap>}
        </SquishedWrap>)
    };
    return (<div>
        <FilterSelectButton onClick={handleClose} onReset={() => {
            setSavedValues({...defaultValues});
            setCurrentValues({...defaultValues});
            onChange({...defaultValues});
        }} icon={icon ? icon : <IGearIcon/>} values={savedValues} isActive={countEmptyValues(savedValues) != 2}>
            {label}
        </FilterSelectButton>
        {open && <FilterContainer>
            {renderInputs()}
            <FilterSubmitButton onClick={handleSubmit}>
                <SCheckIcon/> Potvrdit vybrané
            </FilterSubmitButton>
        </FilterContainer>}
    </div>)
};

const SCheckIcon = styled(ISCheckIcon)`
    fill: ${({theme}) => theme.colors.white};
    height: 16px;
    width: 16px;
`;

const SquishedWrap = styled.div<{squish: boolean}>`
    max-width: ${({squish}) => squish ? "218px" : "256px"};
`;

const MargedWrap = styled.div`
    & > div {
        :not(:first-of-type) {
            margin-top: 8px;
        }
    }
    & > input {
        :not(:first-of-type) {
            margin-top: 8px;
        }
    }
`;

export default FilterRangeInput;