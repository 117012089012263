import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import ProfileColumn from "../../../../components/table/components/ProfileColumn";
import ColoredText from "../../../../components/table/components/ColoredText";
import IPhoto from "../../../../resources/images/TempProfilePhoto.jpg";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import fetchInvestors from "../../api/investors/fetchInvestors";
import AssignInvestorModal from "../../AssignInvestorModal";
import toast from "react-hot-toast";
import fetchProjectInvestors from "../../api/project/fetchProjectInvestors";
import dayjs from "dayjs";
import {getPhotoFromCdn} from "../../../../shared/api/photoApi";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterDateInput from "../../../../components/shared/filters/FilterDateInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as ITrophyIcon} from "../../../../resources/icons/trophy.svg";
import {ReactComponent as ICalendarIcon} from "../../../../resources/icons/calendar.svg";
import {ReactComponent as IRewindIcon} from "../../../../resources/icons/clock-rotate-left.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import useFilters from "../../../../components/methods/useFilters";
import {GetInvestorTypeLabel, InvestorType} from "../../../../enums/InvestorEnums";
import deleteProjectInvestor from "../../api/project/deleteProjectInvestor";
import UpdateInvestorModal from "../../UpdateInvestorModal";
import {formatPrice} from "../../../../shared/utils/format";

const InvestorsSection = () => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        investedFrom: "", investedTo: "",
        actInvestmentsFrom: "", actInvestmentsTo: "",
        hisInvestmentsFrom: "", hisInvestmentsTo: "",
        date: "",
    });
    
    const fetchData = async () => {
        const response = await fetchProjectInvestors(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
        if(response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nepodařilo se načíst investory.")
        }
    };

    const deleteInvestor = async (id: string) => {
        const response = await deleteProjectInvestor(slug, id);
        if(response.status == 200) {
            await response.json();
            fetchData();
        } else {
            toast.error("Nepodařilo se odstranit investora.")
        }
    };

    const updateInvestor = async (id: string, amount: number, investorFrom: Date) => {
        modal.openModal(<UpdateInvestorModal
            onCreate={fetchData}
            investorId={id}
            amount={amount}
            investorFrom={investorFrom}
        />)
    };

    useEffect(() => {
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export investoři")}/>
            <UtilityButton
            title="Přidat investora"
            color="blue"
            icon={<IPlusIcon/>}
            onClick={() => {
                modal.openModal(<AssignInvestorModal allowInvestor={!data?.investors.some((i:any) => i.type === InvestorType.MainInvestor) ?? true} excludedInvestors={data?.investors.map((i: any) => i.id)} onCreate={fetchData}/>)
            }}/>
        </>)
    }, [data, loading]);

    return (<SectionContainer>
        <TableContainer>
            <FilterWrap maxWidth={"85%"}>
            <SearchBar name={"investorsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterRangeInput
                    label={`Podíl v této investici`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["investedFrom", "investedTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Aktivní investice`}
                    icon={<ITrophyIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["actInvestmentsFrom", "actInvestmentsTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Historické investice`}
                    icon={<IRewindIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["hisInvestmentsFrom", "hisInvestmentsTo"], [values.from, values.to])}
                />
                <FilterDateInput
                    label={`Začátek investování`}
                    icon={<ICalendarIcon/>}
                    onChange={(values: any) => changeFilter(["date"], [values])}
                />
            </FilterWrap>
            <Table headers={[
                {key: "investorName", value: "JMÉNO", sortable: true},
                {key: "paCut", value: "ÚROKOVÁ SAZBA P.A.", sortable: true},
                {key: "investmentCut", value: "PODÍL V TÉTO INVESTICI", sortable: false},
                {key: "activeInvestment", value: "AKTIVNÍ INVESTICE", sortable: false},
                {key: "historicInvestment", value: "HISTORICKÉ INVESTICE", sortable: false},
                {key: "availableFunds", value: "VOLNÉ PROSTŘEDKY", sortable: true},
                {key: "investorSince", value: "INVESTOREM OD", sortable: false},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (data.investors ?? []), deleteInvestor, updateInvestor)}/>
            <Pagination
                total={loading ? 0 : data.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={data.investors?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </SectionContainer>)
};

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>, deleteInvestor: any, updateInvestor: any): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: o.state,
            stateBackground: false,
            id: o.id,
            investorName: {value: (<ProfileColumn icon={
                    getPhotoFromCdn(o.imageName)
                } title={
                    <b>{o.firstName} {o.lastName}</b>
                } subtitle={
                    <ColoredText text={GetInvestorTypeLabel(o.type)} color={"grayBlue"} semibold/>
                }/>)},
            paCut: {value: `${o.interestRate} %`},
            investmentCut: {value: (<DoubleLineValue firLine={
                    `${o.projectInvestmentCut}%`
                } secLine={
                    <ColoredText text={`${formatPrice(o.projectInvestmentValue)} Kč`} color={"grayBlue"} semibold/>
                }/>)},
            activeInvestment: {value: (<DoubleLineValue firLine={
                    <ColoredText text={`${formatPrice(o.activeInvestmentsAmount)} Kč`} color={"stripGreen"}/>
                } secLine={
                    <ColoredText text={`${o.activeInvestmentsCount} projektů`} color={"grayBlue"} semibold/>
                }/>)},
            historicInvestment: {value: (<DoubleLineValue firLine={
                    `${formatPrice(o.historicInvestmentsAmount)} Kč`
                } secLine={
                    <ColoredText text={`${o.historicInvestmentsCount} projektů`} color={"grayBlue"} semibold/>
                }/>)},
            availableFunds: {value: (<RegularBold>{formatPrice(o.availableFunds)} Kč</RegularBold>)},
            investorSince: {value: (<DoubleLineValue firLine={
                    <RegularBold>{dayjs(o.createdOn).format("DD.MM.YYYY")}</RegularBold>
                } secLine={
                    <ColoredText text={""} color={"grayBlue"} semibold/>
                }/>)},
            buttons: [
                { label: "Upravit", action: () => updateInvestor(o.id, o.projectInvestmentValue, o.createdOn) },
                { label: "Smazat", action: () => deleteInvestor(o.id) }
            ],
            link: `${Routes.PROJECT_INVESTOR_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default InvestorsSection;