import React from "react";
import styled from "styled-components";

const ModalChecked = () => {
    return (<Container>
        <Circle/>
    </Container>)
};

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 36px;
    height: 21px;
    border-radius: 15px;
    background-color: ${({theme}) => theme.colors.blue};
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
    margin-right: 12px;
    position: relative;
`;

const Circle = styled.div`
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    background-color: ${({theme}) => theme.colors.white};
    border-radius: 50%; 
    left: 1rem;
`;

export default ModalChecked;