import React, {useContext, useMemo} from "react";
import ModalContentWrap from "../../components/shared/modal/ModalContentWrap";
import {ReactComponent as IHandIcon} from "../../resources/icons/hand-holding-box.svg";
import LabeledSelectInput, {OptionInterface} from "../../components/shared/inputs/LabeledSelectInput";
import {ModalSubmit, ModalCancel, ModalInputRow} from "../../components/shared/modal/ModalInputs.styled";
import {ReactComponent as ICheckIcon} from "../../resources/icons/check.svg";
import {ReactComponent as ICrossIcon} from "../../resources/icons/xmark.svg";
import {ReactComponent as ICaretIcon} from "../../resources/icons/caret-down.svg";
import {useForm} from "react-hook-form";
import {ModalContext} from "../../components/layout/Layout";
import toast from "react-hot-toast";
import {GetProjectStateOptions, ProjectState} from "../../enums/ProjectEnums";
import editProjectState from "./api/project/editProjectState";

interface ChangeProjectStateModalProps {
    projectId: string | number;
    currentState: ProjectState;
    refetchData(): void;
}

const ChangeProjectStateModal: React.FC<ChangeProjectStateModalProps> = (props) => {
    const {register, handleSubmit, errors} = useForm();
    const {closeCurrentModal} = useContext(ModalContext);
    const projectStateOptions: OptionInterface[] = useMemo(() => GetProjectStateOptions().filter(s => s.id !== props.currentState), []);

    const onSubmit = async (data: any) => {
        const response = await editProjectState(props.projectId, data.state);
        if (response.status == 200) {
            closeCurrentModal();
            props.refetchData();
            toast.success("Stav projektu úspěšně změněn.");
        } else {
            toast.error("Stav projektu se nepodařilo vytvořit.");
        }
    };
    return (<form onSubmit={handleSubmit(onSubmit)}>
        <ModalContentWrap
            cancelModal={() => closeCurrentModal()}
            icon={<IHandIcon/>}
            title="Změnit stav projektu"
        >
            <ModalInputRow>
                <LabeledSelectInput name="state" label="Stav projektu"
                                    placeholder={"Vyberte stav projektu"}
                                    valid={!errors.state}
                                    errorMessage={errors.state?.message}
                                    icon={<ICaretIcon/>}
                                    options={projectStateOptions}
                                    ref={register({valueAsNumber: true, validate: (e: any) => e !== "0" ? true : "Musíte vybrat stav projektu."})}/>
            </ModalInputRow>
            <ModalSubmit type="submit">
                <ICheckIcon/> Dokončit a změnit stav
            </ModalSubmit>
            <ModalCancel onClick={() => closeCurrentModal()}>
                <ICrossIcon/> Zavřít a nic nedělat
            </ModalCancel>
        </ModalContentWrap>
    </form>)
};

export default ChangeProjectStateModal;