import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../../../components/layout/Layout";
import SectionContainer from "./SectionContainer";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as ITagIcon} from "../../../../resources/icons/tag.svg";
import {ReactComponent as IInfoIcon} from "../../../../resources/icons/circle-info.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import {ReactComponent as ICoinsIcon} from "../../../../resources/icons/coins.svg";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../../router/Routes";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import deleteProject from "../../api/project/deleteProject";
import toast from "react-hot-toast";
import fetchProjectTransactions from "../../api/project/fetchProjectTransactions";
import dayjs from "dayjs";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import {GetTransactionTypeLabel} from "../../../../enums/FinanceEnums";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import Pagination from "../../../../components/pagination/Pagination";
import CreateFinanceModal from "../../CreateFinanceModal";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-pen.svg";
import {ReactComponent as ISackIcon} from "../../../../resources/icons/sack.svg";
import useFilters from "../../../../components/methods/useFilters";
import {formatPrice} from "../../../../shared/utils/format";

const AnalyticsSection = () => {
    const {slug}: {slug: string} = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();

    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat tento projekt?")) {
                    const response = await deleteProject(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        history.push(Routes.PROJECTS);
                        toast.success("Projekt byl smazán.")
                    } else {
                        toast.error("Nepovedlo se smazat tento projekt.")
                    };
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export user")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_DETAIL_ANALYTICS_EDIT.replace(":slug", slug))}/>
            <UtilityButton
            title="Přidat transakci"
            color="blue"
            icon={<IPlusIcon/>}
            onClick={() => {
                modal.openModal(<CreateFinanceModal projectId={slug}/>);
            }}/>
        </>)
    }, []);

    const [filters, changeFilter] = useFilters({
        type: "",
        amountFrom: "", amountTo: "",
        ericonnCutFrom: "", ericonnCutTo: "",
        investorCutFrom: "", investorCutTo: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjectTransactions(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst transakce.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters]);

    return (<SectionContainer>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Postoupení pohledávky" icon={<ITagIcon/>}>
                    <InfoBoxItem title="Investováno" text={"1.650.000 Kč"} />
                    <InfoBoxItem title="Zisk investora" text={"1.444.000 Kč"} />
                    <InfoBoxItem title="Úrok" text={"87.5 %"} />
                    <InfoBoxItem title="Úrok/rok (p.a.)" text={"87.5 %"} />
                    <InfoBoxItem title="Investiční období" text={<><b>2/12</b> měsíců</>} />
                </InfoBox>
                <InfoBox title="Popis" icon={<IInfoIcon/>}>
                    <InfoBoxItem title="Začátek (investor platí)" text={"29. zář 2020"} />
                    <InfoBoxItem title="Očekávaný konec (investor vyplacen)" text={"29. zář 2021"} />
                    <InfoBoxItem title="Vyčíslení postupovaného nároku" text={"15. zář 2020"} />
                    <InfoBoxItem title="Ukončení růstu nároku" text={<YellowWrap>15. zář 2020</YellowWrap>} />
                    <InfoBoxItem title="LTV" text={"30 %"} />
                    <InfoBoxItem title="Hodnota zajištění" text={"5.000.000 Kč"} />
                    <InfoBoxItem title="Plánovaná prodejní cena" text={"4.500.000 Kč"} />
                    <InfoBoxItem title="Cena za postoupení pohledávky" text={"1.500.000 Kč"} />
                    <InfoBoxItem title="Nárok pohledávky ke dni splatnosti" text={"2.000.000 Kč"} />
                    <InfoBoxItem title="Jistina pohledávky" text={"1.000.000 Kč"} />
                    <InfoBoxItem title="Splátka/měsíc" text={"10.000 Kč po 12 měsíců"} />
                    <InfoBoxItem title="Úrok/měsíc (p.m.)" text={"14.860 Kč"} />
                    <InfoBoxItem title="Celkový výnos úrokové sazby" text={"121.667 Kč"} />
                    <InfoBoxItem title="Úrok z prodlení" text={"5.000 Kč po 365 dní"} />
                    <InfoBoxItem title="Celkový výnos úroků z prodlení" text={"1.825.000"} />
                    <InfoBoxItem title="Koncový nárok pohledávky" text={<YellowWrap>3.946 667.Kč</YellowWrap>} />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Počty z celkové investice" icon={<IPercentIcon/>}>
                    <InfoBoxItem title="Minimální výnos investora" text={"10 %  10 % p.a."} />
                    <InfoBoxItem title="Podíl investora nad minimální výnos" text={"60 %"} />
                    <InfoBoxItem title="Sdílený výnos ERICONN" text={"40 %"} />
                </InfoBox>
                <InfoBox title="Výnosy, náklady a zisky, ztráty" icon={<ICoinsIcon/>}>
                    <InfoBoxItem title="Vstupní poplatek ERICONN" text={<>4 % z 1.500.000 Kč = <RedWrap>60.000 Kč</RedWrap></>} />
                    <InfoBoxItem title="Náklady hrazeny ERICONN" text={<RedWrap>30.000 Kč</RedWrap>} />
                    <InfoBoxItem title="Náklady hrazené investory" text={<RedWrap>90.000 Kč</RedWrap>} />
                    <InfoBoxItem title="Celkový čistý výnos" text={<><GreenWrap><b>2.296.667 Kč </b></GreenWrap>139,2 %  139,2 % p.a.</>} />
                    <InfoBoxItem title="Základní minimální výnos investorů" text={<><GreenWrap>165.000 Kč </GreenWrap>10 %  10 % p.a.</>} />
                    <InfoBoxItem title="Sdílený podílový výnos investorů" text={<><GreenWrap>1 279.000 Kč </GreenWrap>77,5 % 77,5 % p.a.</>} />
                    <InfoBoxItem title="Celkový čistý výnos investorů" text={<><GreenWrap><b>1 444.000 Kč </b></GreenWrap>87,5 %  87,5 % p.a.</>} />
                    <InfoBoxItem title="Hrubý zisk ERICONN" text={<><GreenWrap>852.667 Kč </GreenWrap>51,7 % 51,7 % p.a.</>} />
                    <InfoBoxItem title="Celkový čistý výnos ERICONN" text={<><GreenWrap><b>822.667 Kč </b></GreenWrap>49,9 %  49,9 % p.a.</>} />
                </InfoBox>
            </InfoBoxColumn>
             <InfoBoxColumn>
                <MargedTableContainer>
                <FilterWrap maxWidth={"70%"}>
                <SearchBar name={"analyticsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                    label={`Typ`}
                    onChange={(filters: any) => changeFilter("type", filters)}
                    icon={<IMoneyCheckIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterRangeInput
                    label={`Částka`}
                    icon={<ISackIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["amountFrom", "amountTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl ERICONN`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["ericonnCutFrom", "ericonnCutTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl investorů`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["investorCutFrom", "investorCutTo"], [values.from, values.to])}
                />
            </FilterWrap>
                <Table headers={[
                    {key: "date", value: "DATUM", sortable: true},
                    {key: "type", value: "TYP", sortable: true},
                    {key: "amount", value: "ČÁSTKA", sortable: false},
                    {key: "ericonnCut", value: "PODÍL ERICONN", sortable: false},
                    {key: "investorCut", value: "PODÍL INVESTORŮ", sortable: false},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(loading ? [] : (data.transactions ?? []))}/>
                <Pagination
                    total={loading ? 0 : data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.transactions?.length ?? 0}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
                </MargedTableContainer>
            </InfoBoxColumn>
        </InfoBoxFullContainer>
    </SectionContainer>)
};

const YellowWrap = styled.b`
    color: ${({theme}) => theme.colors.lightOrange};
    font-weight: 400;
`;

const RedWrap = styled.b`
    color: ${({theme}) => theme.colors.lightPink};
    font-weight: 400;
`;

const GreenWrap = styled.b`
    color: ${({theme}) => theme.colors.stripGreen};
    font-weight: 400;
`;

const MargedTableContainer = styled(TableContainer)`
    margin-top: 48px;
`;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "red",
            id: o.id,
            date: {value: (<RegularBold>{dayjs(o.transactionDate).format("DD.MM.YYYY")}</RegularBold>)},
            type: {value: (<RegularBold>{GetTransactionTypeLabel(o.type)}</RegularBold>)},
            amount: {value: (<b>{formatPrice(o.amount)} Kč</b>)},
            ericonnCut: {value: (<DoubleLineValue firLine={
                    (<RegularBold>{o.ericonnPercentage} %</RegularBold>)
                } secLine={
                    <ColoredText color={"grayBlue"} text={`${formatPrice(o.amount * (o.ericonnPercentage) / 100)} Kč`} semibold/>
                }/>)},
            investorCut: {value: (<DoubleLineValue firLine={
                    (<RegularBold>{o.investorPercentage} %</RegularBold>)
                } secLine={
                    <ColoredText color={"grayBlue"} text={`${formatPrice(o.amount * (o.investorPercentage) / 100)} Kč`} semibold/>
                }/>)},
            link: `${Routes.PROJECT_FINANCE_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default AnalyticsSection;