import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PageHeader from "../PageHeader";
import Strip from "../Strip";
import Preview from "./Preview.styled";
import {HighlightedText, TableContainer} from "../../../components/table/Table.styled";
import Table from "../../../components/table/Table";
import {BodyInterface} from "../../../components/table/Table.interface";
import {Routes} from "../../../router/Routes";
import fetchProjects from "../../../pages/projects/api/project/fetchProjects";
import toast from "react-hot-toast";
import SearchBar from "../inputs/SearchBar";
import fetchProjectInvestmentLogsMinimal from "../../../pages/projects/api/project/fetchProjectInvestmentLogsMinimal";
import InvestmentLogTaskPreview from "./InvestmentLogTaskPreview";
import dayjs from "dayjs";
import Pagination from "../../pagination/Pagination";

const InvestmentLogsPreview = () => {
    const [currentLogDate, setCurrentLogDate] = useState<string>("");
    const [currentLogProject, setCurrentLogProject] = useState<string>("");
    const [selectedLog, setSelectedLog] = useState<number>(-1)
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProjectInvestmentLogsMinimal(pageNum, pageSize, search, sortId, sortOrder);
            if (response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst investiční zápisky.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder])

    return (<Preview.PreviewContainer>
        {selectedLog == -1 && <>
            <Preview.PreviewTitle title="Investiční zápisky"/>
            <SearchBar name="investmentLogs" placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <TableContainer>
                <Table
                    headers={[
                        {key: "date", value: "DATUM", sortable: true},
                        {key: "projectId", value: "ID PROJEKTU", sortable: true},
                        {key: "tasks", value: "ÚKOLŮ", sortable: true},
                    ]}
                    sizes={["12px"]}
                    body={projectMapper(loading ? [] : (data.investmentLogs ?? []), (id: number, logDate: string, projectName: string) => {
                        setSelectedLog(id);
                        setCurrentLogDate(logDate);
                        setCurrentLogProject(projectName);
                    })}
                    sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                    onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                    />
                <Pagination
                    total={loading ? 0 : data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.investmentLogs?.length ?? 0}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
            </TableContainer>
        </>}
        {selectedLog != -1 && <>
            <InvestmentLogTaskPreview logDate={currentLogDate} projectName={currentLogProject} logId={selectedLog} onReturn={() => setSelectedLog(-1)} />
        </>}
    </Preview.PreviewContainer>)
};

const projectMapper = (items: Array<any>, itemCallback: Function): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: o.state,
            id: o.id,
            date: {value: dayjs(o.logDate).format("DD.MM.YYYY")},
            projectId: {value: o.projectName},
            tasks: {value: (<RegularBold>{o.tasksCount}</RegularBold>)},
            onClick: () => itemCallback(o.id, dayjs(o.logDate).format("DD.MM.YYYY"), o.projectName),
        });
    });
    return result;
}

const RegularBold = styled.b`
    font-weight: 400;
`;

export default InvestmentLogsPreview;