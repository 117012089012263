import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import {ModalContext, UtilityButtonsContext} from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPlusIcon} from "../../../../resources/icons/plus.svg";
import toast from "react-hot-toast";
import fetchRealEstateRenters from "../../api/fetchRealEstateRenters";
import {useHistory, useParams} from "react-router";
import dayjs from "dayjs";
import Pagination from "../../../../components/pagination/Pagination";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import {ReactComponent as IFlagIcon} from "../../../../resources/icons/flag.svg";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-pen.svg";
import CreateRealEstateRenterModal from "../../CreateRealEstateRenterModal";
import deleteRealEstateRenter from "../../api/deleteRealEstateRenter";
import useFilters from "../../../../components/methods/useFilters";
import {formatPrice} from "../../../../shared/utils/format";

const RERenterSection = () => {
    const {slug}: { slug: string } = useParams();
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const history = useHistory();
    const [data, setData] = useState<any>({});
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        state: "",
        rentFrom: "", rentTo: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Přidat nájemníka"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateRealEstateRenterModal/>)
                }}
            />
        </>)
    }, []);

    const fetchData = async () => {
        const response = await fetchRealEstateRenters(parseInt(slug), pageNum, pageSize, search, sortId, sortOrder, filters);
        if (response.status == 200) {
            const fetchedData = await response.json();
            setData(fetchedData.result);
            setLoading(false);
        } else {
            toast.error("Nájemníky se nepodařilo načíst.")
        }
    };

    useEffect(() => {
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
        {!loading && <TableContainer>
            <FilterWrap maxWidth={"35%"}>
            <SearchBar name={"rentersOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
            <FilterSelectInput
                label={`Stav`}
                onChange={(filters: string) => changeFilter("state", filters)}
                icon={<IFlagIcon/>}
                filters={[
                    {label: "XXX", key: 1},
                ]}
            />
            <FilterRangeInput
                label={`Výše nájmu`}
                icon={<IMoneyCheckIcon/>}
                dateRange={false}
                onChange={(values: any) => changeFilter(["rentFrom", "rentTo"], [values.from, values.to])}
            />
            </FilterWrap>
            <Table
                headers={[
                    {key: "renterName", value: "JMÉNO", sortable: false},
                    {key: "rent", value: "NÁJEM", sortable: true},
                    {key: "renterFrom", value: "NÁJEMNÍKEM OD", sortable: false},
                    {key: "renterUntil", value: "NÁJEMNÍKEM DO", sortable: false},
                    {key: "status", value: "STAV", sortable: true},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(data.realEstateRenters, slug, history, () => fetchData())}/>
                <Pagination
                    total={data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.realEstateRenters?.length}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
        </TableContainer>}
    </Container>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>, realEstateSlug: string, history: any, onAction: Function): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            renterName: {value: (<b>{o.firstName} {o.lastName}</b>)},
            rent: {
                value: (<DoubleLineValue firLine={
                    `${formatPrice(o.monthlyPayment)} Kč / měsíc`
                } secLine={
                    <ColoredText text={`${formatPrice(o.annualPayment)} Kč / rok`} color={"grayBlue"} semibold/>
                }/>)
            },
            renterFrom: {value: (<RegularBold>{o.renterFrom != null ? dayjs(o.renterFrom).format(`DD.MM.YYYY`) :  "-"}</RegularBold>)},
            renterUntil: {value: (<RegularBold>{o.renterUntil !== null ? dayjs(o.renterUntil).format(`DD.MM.YYYY`) : "-"}</RegularBold>)},
            status: {value: (<ColoredText text={o.isActive ? "Aktivní" : "Neaktivní"} color={"stripGreen"}/>)},
            buttons: [
                { label: "Upravit", action: () => history.push(Routes.REAL_ESTATE_RENTER_DETAIL.replace(":slug", realEstateSlug).replace(":secondslug", o.id.toString())) },
                { label: "Smazat", action: async () => {
                    if(window.confirm("Doopravdy si přejete smazat nájemníka?")) {
                        const response = await deleteRealEstateRenter(realEstateSlug, o.id.toString());
                        if(response.status == 200) {
                            const fetchedData = await response.json();
                            onAction();
                            toast.success("Nájemník smazán.");
                        } else {
                            toast.error("Nájemníka se nepodařilo smazat.");
                        }
                    }
                }},
            ],
            link: `${Routes.REAL_ESTATE_RENTER_DETAIL.replace(":slug", realEstateSlug).replace(":secondslug", o.id.toString())}`
        });
    });
    return result;
}

export default RERenterSection;