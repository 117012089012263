import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { UtilityButtonsContext } from "../../../components/layout/Layout";
import { Routes } from "../../../router/Routes";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import UtilityButton from "../../../components/shared/UtilityButton";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";
import {ReactComponent as IDiskIcon} from "../../../resources/icons/floppy-disk.svg";
import PageHeader from "../../../components/shared/PageHeader";
import Strip from "../../../components/shared/Strip";
import { InfoBoxColumn, InfoBoxFullContainer } from "../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../components/shared/InfoBox/InfoBox";
import {ReactComponent as ITagIcon} from "../../../resources/icons/tag.svg";
import {ReactComponent as ITimelineIcon} from "../../../resources/icons/timeline-arrow.svg";
import {ReactComponent as IInfoIcon} from "../../../resources/icons/circle-info.svg";
import LabeledTextInput from "../../../components/shared/inputs/LabeledTextInput";
import LabeledSelectInput from "../../../components/shared/inputs/LabeledSelectInput";
import PersonSelectorInput from "../../../components/shared/inputs/PersonSelectorInput";
import toast from "react-hot-toast";
import fetchTask from "../api/tasks/fetchTask";
import editTask from "../api/tasks/editTask";
import { GetTransactionTypes } from "../../../enums/FinanceEnums";

const EditTaskPage = () => {
    const {register, errors, handleSubmit} = useForm();
    const {slug, secondslug}: {[index: string]: any} = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [task, setTask] = useState<any>({});
    const buttons = useContext(UtilityButtonsContext);
    const history = useHistory();
    const onSubmit = async (data: any) => {
        data.taskId = parseInt(secondslug);
        data.transactionType = parseInt(data.transactionType);
        data.amount = parseInt(data.amount);
        data.percentageCut = parseInt(data.percentageCut);
        const response = await editTask(data);
        if(response.status == 200) {
            const fetchedData = await response.json();
            toast.success("Úkol upraven.");
        } else {
            toast.error("Úkol se nepodařilo upravit.")
        }
    };
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Zrušit"
            color="gray"
            icon={<ICrossIcon/>}
            onClick={async () => history.push(Routes.PROJECT_TASK.replace(":slug", slug).replace(":secondslug", secondslug))}/>
            <UtilityButton
            title="Uložit"
            color="green"
            icon={<IDiskIcon/>}
            onClick={handleSubmit(onSubmit)}/>
        </>);
        const fetchData = async () => {
            const response = await fetchTask(secondslug);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setTask(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Úkol se nepodařilo načíst.")
            }
        };
        fetchData();
    }, []);
    return (<Container>
        {!loading && <><PageHeader
        title={task.data?.taskName}
        subtitle={`Projekty   —   Přehled   —   ${task.data?.projectInternalName}   —   Úkoly   —   ${task.data?.taskName}`}
        noContainerBottomMargin
        noContainerLeftMargin
        returnPath={Routes.PROJECT_TASK.replace(":slug", slug).replace(":secondslug", secondslug)}
        strip={<Strip color="gray"/>}/>
        <InfoBoxFullContainer>
            <InfoBoxColumn>
                <InfoBox title="Položka" icon={<ITagIcon/>}>
                    <LabeledTextInput
                        type={"text"}
                        label={"Název"}
                        placeholder={"Text..."}
                        name={"name"}
                        defaultValue={task.data?.taskName || ""}
                        valid={!errors.name}
                        errorMessage={errors.name?.message}
                        ref={register({required: {value: true, message: "Název je povinný."}})}
                    />
                    <LabeledTextInput
                        type={"text"}
                        label={"Označení"}
                        placeholder={"Text..."}
                        name={"internalName"}
                        defaultValue={task.data?.internalName || ""}
                        valid={!errors.internalName}
                        errorMessage={errors.internalName?.message}
                        ref={register({required: {value: true, message: "Označení je povinné."}})}
                    />
                     <LabeledSelectInput label="Typ" name="transactionType"
                        placeholder="Vyberte..."
                        valid={!errors.transactionType}
                        errorMessage={errors.transactionType?.message}
                        defaultValue={task.data?.type}
                        wide
                        options={GetTransactionTypes()}
                        ref={register}
                    />
                    <LabeledTextInput
                        type={"date"}
                        label={"Datum plnění"}
                        placeholder={"Vyberte..."}
                        name={"expectedFinishDate"}
                        defaultValue={task.data?.expectedFinishDate || ""}
                        valid={!errors.expectedFinishDate}
                        errorMessage={errors.expectedFinishDate?.message}
                        ref={register({required: {value: true, message: "Datum plnění je povinné."}})}
                    />
                    <LabeledTextInput
                        type={"number"}
                        label={"Částka"}
                        placeholder={"Číslo..."}
                        name={"amount"}
                        defaultValue={task.data?.amount || ""}
                        valid={!errors.amount}
                        errorMessage={errors.amount?.message}
                        ref={register({required: {value: true, message: "Částka je povinná."}})}
                    />
                    <LabeledTextInput
                        type={"number"}
                        label={"Podíl ERICONN"}
                        placeholder={"Číslo..."}
                        name={"percentageCut"}
                        defaultValue={task.data?.ericonnPercentage || ""}
                        valid={!errors.percentageCut}
                        errorMessage={errors.percentageCut?.message}
                        ref={register({required: {value: true, message: "Podíl je povinný."}})}
                    />
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
                <InfoBox title="Popis" icon={<IInfoIcon/>}>
                    <LabeledTextInput
                        type={"textarea"}
                        label={"Poznámka"}
                        placeholder={"Text..."}
                        name={"note"}
                        defaultValue={task.note || ""}
                        valid={!errors.note}
                        errorMessage={errors.note?.message}
                        ref={register}
                    />
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer></>}
    </Container>)
};

const Container = styled.div``;

export default EditTaskPage;