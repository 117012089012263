import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import { ModalContext, UtilityButtonsContext } from "../../components/layout/Layout";
import {ReactComponent as IPlusIcon} from "../../resources/icons/plus.svg";
import {ReactComponent as ICloudIcon} from "../../resources/icons/cloud-arrow-down.svg";
import UtilityButton from "../../components/shared/UtilityButton";
import CreateFinanceModal from "./CreateFinanceModal";
import {HighlightedText, TableContainer} from "../../components/table/Table.styled";
import Table from "../../components/table/Table";
import {BodyInterface} from "../../components/table/Table.interface";
import {Routes} from "../../router/Routes";
import InvestmentLogsPreview from "../../components/shared/previews/InvestmentLogsPreview";
import DoubleLineValue from "../../components/table/components/DoubleLineValue";
import ColoredText from "../../components/table/components/ColoredText";
import fetchProjects from "./api/project/fetchProjects";
import toast from "react-hot-toast";
import fetchTransactions from "./api/finance/fetchTransactions";
import { GetTransactionTypeLabel } from "../../enums/FinanceEnums";
import dayjs from "dayjs";
import SearchBar from "../../components/shared/inputs/SearchBar";
import Pagination from "../../components/pagination/Pagination";
import FilterSelectInput from "../../components/shared/filters/FilterSelectInput";
import FilterRangeInput from "../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../components/shared/filters/FilterWrap";
import {ReactComponent as IMoneyCheckIcon} from "../../resources/icons/money-check-pen.svg";
import {ReactComponent as ISackIcon} from "../../resources/icons/sack.svg";
import {ReactComponent as IPercentIcon} from "../../resources/icons/percent.svg";
import useFilters from "../../components/methods/useFilters";
import {formatPrice} from "../../shared/utils/format";

const ProjectFinanceOverviewPage = () => {
    const buttons = useContext(UtilityButtonsContext);
    const modal = useContext(ModalContext);
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        type: "",
        amountFrom: "", amountTo: "",
        ericonnCutFrom: "", ericonnCutTo: "",
        investorCutFrom: "", investorCutTo: "",
    });

    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
                title="Exportovat"
                icon={<ICloudIcon/>}
                color={"gray"}
                onClick={() => "sheesh"}
            />
            <UtilityButton
                title="Vytvořit transakci"
                icon={<IPlusIcon/>}
                color={"blue"}
                onClick={() => {
                    modal.openModal(<CreateFinanceModal/>)
                }}
            />
        </>)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchTransactions(pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const fetchedData = await response.json();
                setData(fetchedData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst transakce.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<Container>
        <TableContainer>
            <FilterWrap maxWidth={"95%"}>
                <SearchBar name={"investorsOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                    label={`Typ`}
                    onChange={(filters: any) => changeFilter("type", filters)}
                    icon={<IMoneyCheckIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterRangeInput
                    label={`Částka`}
                    icon={<ISackIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["amountFrom", "amountTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl ERICONN`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["ericonnCutFrom", "ericonnCutTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Podíl investorů`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["investorCutFrom", "investorCutTo"], [values.from, values.to])}
                />
            </FilterWrap>
            <Table headers={[
                     {key: "date", value: "DATUM", sortable: true},
                    {key: "projectAndTitle", value: "PROJEKT A OZNAČENÍ", sortable: false},
                    {key: "type", value: "TYP", sortable: true},
                    {key: "amount", value: "ČÁSTKA", sortable: false},
                    {key: "ericonnCut", value: "PODÍL ERICONN", sortable: false},
                    {key: "investorCut", value: "PODÍL INVESTORŮ", sortable: false},
                ]}
                sizes={["12px"]}
                sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
                onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
                body={mapper(loading ? [] : (data.transactions ?? []))}/>
                <Pagination
                    total={loading ? 0 : data.totalCount}
                    pageSize={pageSize}
                    pageNumber={pageNum}
                    listed={data.transactions?.length ?? 0}
                    onLimitChange={(e) => setPageSize(e)}
                    onPageChange={(e) => setPageNum(e)}
                />
        </TableContainer>
        <InvestmentLogsPreview/>
    </Container>)
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        result.push({
            tableRowState: "none",
            id: o.id,
            date: {value: (<RegularBold>{dayjs(o.transactionDate).format("DD.MM.YYYY")}</RegularBold>)},
            projectAndTitle: {value: (<DoubleLineValue firLine={
                    o.projectInternalName
            } secLine={
                <ColoredText color={"grayBlue"} text={o.transactionName}/>
            }/>)},
            type: {value: (<RegularBold>{GetTransactionTypeLabel(o.transactionType)}</RegularBold>)},
            amount: {value: (<b>{formatPrice(o.amount)} Kč</b>)},
            ericonnCut: {value: (<DoubleLineValue firLine={
                (<RegularBold>{o.ericonnPercentage} %</RegularBold>)
            } secLine={
                <ColoredText color={"grayBlue"} text={`${formatPrice(o.amount * (o.ericonnPercentage) / 100)} Kč`} semibold/>
            }/>)},
            investorCut: {value: (<DoubleLineValue firLine={
                (<RegularBold>{o.investorPercentage} %</RegularBold>)
            } secLine={
                <ColoredText color={"grayBlue"} text={`${formatPrice(o.amount * (o.investorPercentage) / 100)} Kč`} semibold/>
            }/>)},
            link: `${Routes.PROJECT_FINANCE_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default ProjectFinanceOverviewPage;