import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router";
import { UtilityButtonsContext } from "../../../../components/layout/Layout";
import UtilityButton from "../../../../components/shared/UtilityButton";
import {ReactComponent as ITrashIcon} from "../../../../resources/icons/trash.svg";
import {ReactComponent as ICloudIcon} from "../../../../resources/icons/cloud-arrow-down.svg";
import {ReactComponent as IPenIcon} from "../../../../resources/icons/pen.svg";
import {ReactComponent as IFlagIcon} from "../../../../resources/icons/flag.svg";
import {ReactComponent as IMoneyCheckIcon} from "../../../../resources/icons/money-check-pen.svg";
import {ReactComponent as IBuildingIcon} from "../../../../resources/icons/building.svg";
import {ReactComponent as IHandDollarIcon} from "../../../../resources/icons/hand-holding-dollar.svg";
import {ReactComponent as IHandBoxIcon} from "../../../../resources/icons/hand-holding-box.svg";
import {ReactComponent as IMapIcon} from "../../../../resources/icons/map-location.svg";
import {ReactComponent as IUsersIcon} from "../../../../resources/icons/users.svg";
import {ReactComponent as IShieldIcon} from "../../../../resources/icons/shield-check.svg";
import {ReactComponent as ISackIcon} from "../../../../resources/icons/sack.svg";
import {ReactComponent as IPercentIcon} from "../../../../resources/icons/percent.svg";
import {ReactComponent as ICalendarsIcon} from "../../../../resources/icons/calendars.svg";
import {HighlightedText, TableContainer} from "../../../../components/table/Table.styled";
import Table from "../../../../components/table/Table";
import {BodyInterface} from "../../../../components/table/Table.interface";
import {Routes} from "../../../../router/Routes";
import SectionContainer from "../../project-detail/components/SectionContainer";
import DoubleLineValue from "../../../../components/table/components/DoubleLineValue";
import ColoredText from "../../../../components/table/components/ColoredText";
import deleteProvider from "../../api/provider/deleteProvider";
import toast from "react-hot-toast";
import fetchProviderProjects from "../../api/provider/fetchProviderProjects";
import dayjs from "dayjs";
import { GetRETypeLabel } from "../../../../enums/RealEstateEnums";
import Pagination from "../../../../components/pagination/Pagination";
import SearchBar from "../../../../components/shared/inputs/SearchBar";
import FilterSelectInput from "../../../../components/shared/filters/FilterSelectInput";
import FilterDateInput from "../../../../components/shared/filters/FilterDateInput";
import FilterRangeInput from "../../../../components/shared/filters/FilterRangeInput";
import FilterWrap from "../../../../components/shared/filters/FilterWrap";
import useFilters from "../../../../components/methods/useFilters";
import {formatPrice} from "../../../../shared/utils/format";

const ProjectsSection = () => {
    const {slug}: {slug: string} = useParams();
    const [pageNum, setPageNum] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(8);
    const [search, setSearch] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [fetchedData, setFetchedData] = useState<any>({});
    const history = useHistory();
    const buttons = useContext(UtilityButtonsContext);
    
    const [sortId, setSortId] = useState<string>("");
    const [sortOrder, setSortOrder] = useState<string>("");

    const [filters, changeFilter] = useFilters({
        state: "",
        type: "",
        collateral: "",
        investor: "",
        agent: "",
        location: "",
        admin: "",
        colValueFrom: "", colValueTo: "",
        totalInvestedFrom: "", totalInvestedTo: "",
        ltvFrom: "", ltvTo: "",
        dateFrom: "", dateTo: ""
    });
    
    useEffect(() => {
        buttons.setUtilityButtons(<>
            <UtilityButton
            title="Smazat"
            color="red"
            icon={<ITrashIcon/>}
            onClick={async () => {
                if(window.confirm("Doopravdy chcete smazat zprostředkovatele?")) {
                    const response = await deleteProvider(slug);
                    if(response.status == 200) {
                        const fetchedData = await response.json();
                        if(fetchedData.isSuccess) {
                            history.push(Routes.PROJECTS_PROVIDERS);
                            toast.success("Zprostředkovatel smazán.")
                        } else {
                            toast.error("Zprostředkovatele se nepodařilo smazat.")
                        }
                    } else {
                        toast.error("Zprostředkovatele se nepodařilo smazat.")
                    }
                };
            }}/>
            <UtilityButton
            title="Exportovat"
            color="gray"
            icon={<ICloudIcon/>}
            onClick={() => console.log("export provider")}/>
            <UtilityButton
            title="Upravit"
            color="gray"
            icon={<IPenIcon/>}
            onClick={() => history.push(Routes.PROJECT_PROVIDER_DETAIL_EDIT.replace(":slug", slug))}/>
        </>)
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchProviderProjects(slug, pageNum, pageSize, search, sortId, sortOrder, filters);
            if(response.status == 200) {
                const responseData = await response.json();
                setFetchedData(responseData.result);
                setLoading(false);
            } else {
                toast.error("Nepodařilo se načíst projekty.")
            }
        };
        fetchData();
    }, [pageNum, pageSize, search, sortId, sortOrder, filters])
    return (<SectionContainer>
        <TableContainer>
        <FilterWrap maxWidth={"65%"}>
                <SearchBar name={"projectOverview"} placeholder="Hledat..." onSearch={(search: string) => setSearch(search)}/>
                <FilterSelectInput
                    label={`Stav`}
                    onChange={(filters: string) => changeFilter("state", filters)}
                    icon={<IFlagIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Druh`}
                    onChange={(filters: string) => changeFilter("type", filters)}
                    icon={<IMoneyCheckIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Zajištění`}
                    onChange={(filters: string) => changeFilter("collateral", filters)}
                    icon={<IBuildingIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Investor`}
                    onChange={(filters: string) => changeFilter("investor", filters)}
                    icon={<IHandDollarIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Zprostředkovatel`}
                    onChange={(filters: string) => changeFilter("provider", filters)}
                    icon={<IHandBoxIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Lokalita`}
                    onChange={(filters: string) => changeFilter("location", filters)}
                    icon={<IMapIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
                <FilterSelectInput
                    label={`Uživatelé`}
                    onChange={(filters: string) => changeFilter("admin", filters)}
                    icon={<IUsersIcon/>}
                    filters={[
                        {label: "XXX", key: 1},
                    ]}
                />
            </FilterWrap>
        <FilterWrap maxWidth={"57.5%"}>
                <FilterRangeInput
                    label={`Hodnota zajištění`}
                    icon={<IShieldIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Investováno`}
                    icon={<ISackIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["colValueFrom", "colValueTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`LTV`}
                    icon={<IPercentIcon/>}
                    dateRange={false}
                    onChange={(values: any) => changeFilter(["ltvFrom", "ltvTo"], [values.from, values.to])}
                />
                <FilterRangeInput
                    label={`Období`}
                    icon={<ICalendarsIcon/>}
                    dateRange={true}
                    onChange={(values: any) => changeFilter(["dateFrom", "dateTo"], [values.from, values.to])}
                />
            </FilterWrap>
        <Table
            headers={[
                {key: "projectId", value: "ID PROJEKTU", sortable: true},
                {key: "type", value: "TYP INVESTICE", sortable: true},
                {key: "collateral", value: "ZAJIŠTĚNÍ", sortable: false},
                {key: "investors", value: "INVESTORŮ", sortable: false},
                {key: "collateralValue", value: "HODNOTA ZAJIŠTĚNÍ", sortable: false},
                {key: "investments", value: "INVESTOVÁNO", sortable: false},
                {key: "ltv", value: "LTV", sortable: true},
                {key: "period", value: "OBDOBÍ", sortable: true},
                {key: "updatedOn", value: "AKTUALIZACE", sortable: true},
            ]}
            sizes={["12px"]}
            sortState={sortId ? Object.defineProperty({}, sortId, {value: sortOrder}) : {}}
            onSortChange={(filter: string, filterOrder: string) => {setSortId(filter); setSortOrder(filterOrder)}}
            body={mapper(loading ? [] : (fetchedData.projects ?? []))}/>
            <Pagination
                total={loading ? 0 : fetchedData.totalCount}
                pageSize={pageSize}
                pageNumber={pageNum}
                listed={fetchedData.projects?.length ?? 0}
                onLimitChange={(e) => setPageSize(e)}
                onPageChange={(e) => setPageNum(e)}
            />
            </TableContainer>
    </SectionContainer>)
};

const Container = styled.div``;

const RegularBold = styled.b`
    font-weight: 400;
`;

const mapper = (items: Array<any>): Array<BodyInterface> => {
    const result: Array<BodyInterface> = [];
    items.map((o) => {
        const colorType = Math.floor(Math.random() * 3)
        const color = colorType == 0 && "purple" ||
        colorType == 1 && "lightBlue" ||
        colorType == 2 && "blueGreen"
        result.push({
            tableRowState: o.state,
            id: o.id,
            projectId: {value: (<b>{o.internalName}</b>)},
            type: {value: (<ColoredText text={o.type} color={color} semibold/>)},
            collateral: {value: <DoubleLineValue firLine={GetRETypeLabel(o.mainCollateralType)} secLine={<ColoredText text={`+ ${o.otherCollateralCount} další`} color={"grayBlue"}/>}/>},
            investors: {value: <DoubleLineValue firLine={o.investorCount} secLine={<ColoredText text={`podíl ${o.investorPercentageCut} % `} color={"grayBlue"}/>}/>},
            collateralValue: {value: `${formatPrice(o.collateralValue)} Kč`},
            investments: {value: `${formatPrice(o.totalInvested)} Kč`},
            ltv: {value: (<RegularBold>64 %</RegularBold>)},
            period: {value: <DoubleLineValue firLine={<><b>{o.endDate ? dayjs(o.startDate).diff(o.endDate, "month") : "?"}</b>/12</>} secLine={<ColoredText text={`${dayjs(o.startDate).format(`DD.MM.YYYY`)} - ${o.endDate ? dayjs(o.endDate).format(`DD.MM.YYYY`) : "Nenastaveno"}`} color={"grayBlue"}/>}/>},
            updatedOn: {value: <DoubleLineValue firLine={dayjs(o.lastUpdateOn).format("DD.MM.YYYY")} secLine={<ColoredText text={dayjs(o.lastUpdateOn).format(`HH:mm`)} color={"grayBlue"} semibold/>}/>},
            link: `${Routes.PROJECT_DETAIL.replace(":slug", o.id.toString())}`
        });
    });
    return result;
}

export default ProjectsSection;