import { getAccessToken } from "../../../contexts/UserIdentityContext";

const deleteEstimationFile = async (id: string | number) => {
    const response = await fetch(`/api/v1/realestates/${id}/estimation`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        }
    })
    return response;
};

export default deleteEstimationFile;
