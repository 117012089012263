import React, {ReactElement} from "react";
import {ReactComponent as IBuildingIcon} from "../resources/icons/hotel-light.svg";
import {ReactComponent as ICouchIcon} from "../resources/icons/couch-light.svg";
import {ReactComponent as IFenceIcon} from "../resources/icons/bench-tree-light.svg";
import {ReactComponent as IHouseIcon} from "../resources/icons/house-chimney-light.svg";
import {ReactComponent as IShop} from "../resources/icons/shop-light.svg";
import {ReactComponent as IQuestionIcon} from "../resources/icons/question.svg";

export enum RealEstateType {
    ApartmentBuilding = 1,
    FamilyHouse = 2,
    HousingUnit = 3,
    Land = 4,
    TenementHouse = 5,
    ShoppingSpace = 6
}

export enum RealEstateOwnershipType {
    NotSet = 0,
    Personal = 1,
    Squad = 2
}

export enum RealEstateCollateralType {
    NotSet = 0,
    Primary = 1,
    Secondary = 2
}

export const GetRETypeLabel = (type: RealEstateType): string => {
    switch (type){
        case RealEstateType.ApartmentBuilding: return "Bytový dům";
        case RealEstateType.FamilyHouse: return "Rodinný dům";
        case RealEstateType.HousingUnit: return "Bytová jednotka";
        case RealEstateType.Land: return "Pozemek";
        case RealEstateType.TenementHouse: return "Činžovní dům";
        case RealEstateType.ShoppingSpace: return "Obchodní prostor";
        default: return "Nenastaveno";
    }
};

export const GetRETypeIcon = (type: RealEstateType): React.ReactNode => {
    switch (type){
        case RealEstateType.ApartmentBuilding: return IBuildingIcon;
        case RealEstateType.FamilyHouse: return IHouseIcon;
        case RealEstateType.HousingUnit: return ICouchIcon;
        case RealEstateType.Land: return IFenceIcon;
        default: return IQuestionIcon;
    }
};

export const GetREOwnershipLabel = (type: RealEstateOwnershipType): string => {
    switch (type){
        case RealEstateOwnershipType.NotSet: return "Nenastaveno";
        case RealEstateOwnershipType.Personal: return "Osobní";
        case RealEstateOwnershipType.Squad: return "Družstvo";
        default: return "Nenastaveno";
    }
};

export const GetRECollateralLabel = (type: RealEstateCollateralType): string => {
    switch (type){
        case RealEstateCollateralType.NotSet: return "Nenastaveno";
        case RealEstateCollateralType.Primary: return "Hlavní zajištění";
        case RealEstateCollateralType.Secondary: return "Vedlejší zajištění";
        default: return "Nenastaveno";
    }
};

export interface RETypeInterface {
    id: RealEstateType;
    label: string;
    icon: ReactElement;
}

export const GetRETypes = (): Array<RETypeInterface> => {
    return [
        {
            id: RealEstateType.HousingUnit,
            label: GetRETypeLabel(RealEstateType.HousingUnit),
            icon: <ICouchIcon/>
        },
        {
            id: RealEstateType.FamilyHouse,
            label: GetRETypeLabel(RealEstateType.FamilyHouse),
            icon: <IHouseIcon/>
        },
        {
            id: RealEstateType.TenementHouse,
            label: GetRETypeLabel(RealEstateType.TenementHouse),
            icon: <IBuildingIcon/>
        },
        {
            id: RealEstateType.Land,
            label: GetRETypeLabel(RealEstateType.Land),
            icon: <IFenceIcon/>
        },
        {
            id: RealEstateType.ShoppingSpace,
            label: GetRETypeLabel(RealEstateType.ShoppingSpace),
            icon: <IShop/>
        },
    ]
};

interface REOwnershipInterface {
    id: 0 | 1 | 2,
    label: string,
}

export const GetREOwnerships = (): Array<REOwnershipInterface> => {
    return [
        {
            id: RealEstateOwnershipType.NotSet,
            label: GetREOwnershipLabel(RealEstateOwnershipType.NotSet),
        },
        {
            id: RealEstateOwnershipType.Personal,
            label: GetREOwnershipLabel(RealEstateOwnershipType.Personal),
        },
        {
            id: RealEstateOwnershipType.Squad,
            label: GetREOwnershipLabel(RealEstateOwnershipType.Squad),
        },
    ]
};