import React from "react";
import styled from "styled-components";
import {defaultTheme} from "../../theme/defaultTheme";

interface ColoredTextInterface {
    text: React.ReactNode | string;
    color?: string | false;
    semibold?: boolean
}

const ColoredText = ({text, color, semibold}: ColoredTextInterface) => {
    return (<Container color={color ? color as string : "purple"} semibold={semibold}>
        {text == "" ? "-" : text}
    </Container>)
};

const Container = styled.div<{color?: string; semibold?: boolean}>`
    ${({theme, color}) => color && `
        color: ${theme.colors[color]};
    `}
    ${({semibold}) => semibold && "font-weight: 600"};
`;

export default ColoredText;