import React from "react";
import styled from "styled-components";
import {ReactComponent as ICrossIcon} from "../../../resources/icons/xmark.svg";

interface ModalContentInterface {
    children: React.ReactNode;
    icon: React.ReactNode;
    title: string;
    subtitle?: string;
    cancelModal: () => void;
}

const ModalContentWrap = ({children, cancelModal, icon, title, subtitle}: ModalContentInterface) => {
    return (<Container>
        <CancelButton onClick={() => cancelModal()}/>
        <FlexWrap>
            <IconWrap>
                {icon}
            </IconWrap>
            <Title bigMarginBottom={subtitle === undefined}>
                {title}
            </Title>
            { subtitle && <Subtitle>{subtitle}</Subtitle> }
            <ContentContainer>
                {children}
            </ContentContainer>
        </FlexWrap>
    </Container>)
};

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 48px;
`;

const FlexWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CancelButton = styled(ICrossIcon)`
    position: absolute;
    top: 24px;
    right: 24px;
    
    width: 16px;
    height: 16px;
    fill: ${({theme}) => theme.colors.black};
    cursor: pointer;
`;

const IconWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        fill: ${({theme}) => theme.colors.grayBlue};
        height: 56px;
    }
`;

const Title = styled.div<{bigMarginBottom: boolean}>`
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
    color: ${({theme}) => theme.colors.black};
    font-weight: 700;
    margin-bottom: ${({bigMarginBottom}) => bigMarginBottom ? "40px" : "8px"};
`;

const Subtitle = styled.div`
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 32px;
    color: ${({theme}) => theme.colors.stripGray};
    font-weight: 600;
`;


const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 576px;
`;

export default ModalContentWrap;