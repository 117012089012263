import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {InfoBoxFullContainer, InfoBoxColumn} from "../../../../components/shared/InfoBox/styled/InfoBox.styled";
import InfoBox from "../../../../components/shared/InfoBox/InfoBox";
import InfoBoxItem from "../../../../components/shared/InfoBox/InfoBoxItem";
import {ReactComponent as IToolsIcon} from "../../../../resources/icons/screwdriver-wrench.svg";
import {ReactComponent as IUserIcon} from "../../../../resources/icons/user.svg";
import {ReactComponent as INoteIcon} from "../../../../resources/icons/note.svg";
import {ReactComponent as ITimelineIcon} from "../../../../resources/icons/timeline-arrow.svg";
import { Routes } from "../../../../router/Routes";
import toast from "react-hot-toast";
import fetchUser from "../../../users/api/fetchUser";
import { getCurrentUserId } from "../../../../contexts/UserIdentityContext";
import dayjs from "dayjs";

const ProfileSection = () => {
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const slug = getCurrentUserId();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await fetchUser(slug as string);
            if(response.status == 200) {
                let data = await response.json();
                data.result.contact.fullName = `${data.result.contact?.firstName} ${data.result.contact?.lastName}`;
                setData(data.result);
                setLoading(false);
            } else {
                toast.error("Uživatel nenalezen!");
            }
        };
        fetchData();
    }, [])
    return (<Container>
        {!loading && <InfoBoxFullContainer>
            <InfoBoxColumn>
            <InfoBox title="Kontaktní údaje" icon={<IUserIcon/>}>
                    <InfoBoxItem title="Jméno a příjmení" text={data.contact?.fullName}/>
                    <InfoBoxItem title="Email" text={<a href={`mailto:${data.contact?.email}`}>{data.contact?.email}</a>}/>
                    <InfoBoxItem title="Telefon" text={<a href={`tel:${data.contact?.phoneNumber}`}>{data.contact?.phoneNumber}</a>}/>
                    <InfoBoxItem title="Ulice 1. řádek" text={data.contact?.location.streetAddressFirstLine}/>
                    <InfoBoxItem title="Ulice 2. řádek" text={data.contact?.location.streetAddressSecondLine}/>
                    <InfoBoxItem title="PSČ" text={data.contact?.location.postalCode?.replace(/^(.*)(\d)(\d)/,'$1 $2$3')}/>
                    <InfoBoxItem title="Obec/Město" text={data.contact?.location.city}/>
                    <InfoBoxItem title="Země" text={data.contact?.location.country}/>
                </InfoBox>
                <InfoBox title="Poznámka" icon={<INoteIcon/>}>
                    {data.note}
                </InfoBox>
            </InfoBoxColumn>
            <InfoBoxColumn>
            <InfoBox title="Původ" icon={<ITimelineIcon/>}>
                    <InfoBoxItem title="Vytvořen" text={<>{
                        dayjs(data.activity?.createdOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.createdOn).format(`HH:mm`)
                    }</span></>}/>
                    <InfoBoxItem title="Vytvořil/a" text={data.activity?.createdById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.createdById.toString())}>
                        {data.activity?.createdByName}
                    </a> : ""}/>
                    <InfoBoxItem title="Upraveno" text={data.activity?.lastUpdatedOn ? <>{
                        dayjs(data.activity?.lastUpdatedOn).format(`DD.MM.YYYY`)
                    } <span>{
                        dayjs(data.activity?.lastUpdatedOn).format(`HH:mm`)
                    }</span></> : ""}/>
                    <InfoBoxItem title="Upravil/a" text={data.activity?.lastUpdatedById ? <a href={Routes.USER_DETAIL.replace(":slug", data.activity?.lastUpdatedById.toString())}>
                        {data.activity?.lastUpdatedByName}
                    </a> : ""}/>
                </InfoBox>
                <InfoBox title="Systémové údaje" icon={<IToolsIcon/>}>
                    <InfoBoxItem title="Oprávnění" text={data.systemInformation?.permissionLevel}/>
                    <InfoBoxItem title="Umístění" text={data.systemInformation?.companyPlacement}/>
                    <InfoBoxItem title="Stav" text={data.systemInformation?.state}/>
                </InfoBox>
            </InfoBoxColumn>
        </InfoBoxFullContainer>}
    </Container>)
};

const Container = styled.div`
    margin-top: 15px;
    padding: 24px;
    border: 1px solid ${({theme}) => theme.colors.lighterGray};
`;

export default ProfileSection;